import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage, ModalDialog, Select, Text } from 'libs.nucleus';
import { ApiClientService, AuthContext, useApiClient, useToastNotification } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
import { GenericUtils } from '../../utils';
const DEFAULT_OPTION = { label: '1', value: '1' };
export const ExtendTimeDialog = ({ environment, onClose, onTimeExtended }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { logEvent } = useContext(FirebaseAppContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const [selectedExtendTime, setSelectedExtendTime] = useState(DEFAULT_OPTION);
    const extendTimeOptions = useMemo(() => {
        return Array.from({ length: 24 }, (_, index) => {
            const hour = (index + 1).toString();
            return { label: hour, value: hour };
        });
    }, []);
    const remainingTime = useMemo(() => {
        if (environment && environment.metadata.expiresAt) {
            const { hour, minute, day } = GenericUtils.getDatesDiff(new Date(environment.metadata.expiresAt), new Date());
            return day === 1
                ? translate('{hour}h {minute}m', { hour, minute })
                : translate('{day}d {hour}h {minute}m', { day: day - 1, hour, minute });
        }
        return '';
    }, [environment]);
    const onSaveClick = async () => {
        try {
            const ttl = parseInt(selectedExtendTime.value) * 60 * 60; // time in hours
            await momClient.put(`/v1/entities/${entityId}/studies/${studyId}/environments/${environment.id}`, { ttl });
            logEvent('extend_time_travel_sandbox', {
                study_id: studyId,
                hours: selectedExtendTime.value,
                sandbox_name: environment.name,
            });
            addNotification({
                title: translate('Lifetime extended'),
                subtitle: translate(`The lifetime of the temporary sandbox has been extended by {hours} hours`, {
                    hours: selectedExtendTime.value,
                }),
                type: 'success',
            });
            onTimeExtended();
            return true;
        }
        catch (error) {
            console.error(error);
            addNotification({
                title: translate('Error extending lifetime'),
                subtitle: translate('An error occurred while trying to extend the lifetime of the temporary sandbox'),
                type: 'error',
            });
            return false;
        }
    };
    return (_jsx(ModalDialog, { title: translate('Extend temporary sandbox lifetime'), body: _jsxs("section", { className: 'flex flex-col gap-4', children: [_jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('Extend the time that your temporary sandbox will remain available for.') }), _jsxs("div", { children: [_jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('Time remaining') }), _jsx(Text, { children: remainingTime })] }), _jsx(Select, { label: translate('Extend time by hours'), onChange: setSelectedExtendTime, options: extendTimeOptions, value: selectedExtendTime, width: 'full' })] }), width: 'lg', actionButtons: [
            { label: translate('Save'), onClick: onSaveClick },
            { label: translate('Cancel'), onClick: onClose },
        ], isOpen: true, closeDialog: onClose }));
};
