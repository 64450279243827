import { jsx as _jsx } from "react/jsx-runtime";
import { getStorage, ref as storageRef, uploadBytes, connectStorageEmulator, getDownloadURL, } from 'firebase/storage';
import { forwardRef, useMemo, useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
export const ChatFileUpload = forwardRef((props, ref) => {
    const firebaseApp = useContext(FirebaseAppContext);
    const storage = useMemo(() => getStorage(firebaseApp.app, firebaseApp.app.options.storageBucket), [firebaseApp]);
    useMemo(() => {
        if (firebaseApp.app.options.projectId === 'demo-local') {
            connectStorageEmulator(storage, 'localhost', 9199);
        }
    }, [firebaseApp, storage]);
    const handleUpload = async (e) => {
        try {
            const file = e.target?.files?.[0];
            if (!file) {
                throw new Error('No file selected');
            }
            const uploadPath = storageRef(storage, `assistant/uploads/${file.name}`);
            const snapshot = await uploadBytes(uploadPath, file);
            const downloadUrl = await getDownloadURL(snapshot.ref);
            if (props.onUpload) {
                props.onUpload({ downloadUrl });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    return _jsx("input", { type: 'file', ref: ref, className: 'hidden', onChange: handleUpload, accept: '.pdf' });
});
ChatFileUpload.displayName = 'ChatFileUpload';
