import jsonLogic from 'json-logic-js';
import { ActivityType } from '@axon/rosetta-sdk';
import { StudyTestPlan_StepValidContexts, StudyTestPlan_StepValidExecutionModes, StudyTestPlan_Timezones, StudyTestPlanExecutionModes, StudyTestPlanStepContextValues, StudyTestPlanStepType, StudyTestPlanStepUserTypes, StudyTestPlanStepValidUser, } from '../../types';
import { GenericUtils } from '../generic';
/**
 * Custom operation for returning default.
 */
jsonLogic.add_operation('any', () => true);
/**
 * Custom operation for checking if an array includes any of the elements in another array.
 */
// eslint-disable-next-line eqeqeq
jsonLogic.add_operation('includes_any', (a, b) => b.some((item) => (a.length ? a.includes(item) : a == item)));
/**
 * Custom operation for checking if an array includes all of the elements in another array.
 */
// eslint-disable-next-line eqeqeq
jsonLogic.add_operation('includes_all', (a, b) => b.every((item) => (a.length ? a.includes(item) : a == item)));
/**
 * Custom operation for checking if an array does not include any of the items in another array.
 */
/* eslint-disable eqeqeq */
jsonLogic.add_operation('includes_none_of', (a, b) => b.every((item) => (a.length ? !a.includes(item) : a != item)));
/**
 * Convert a string context to an object
 * @param context The context string to convert
 * @returns The context object with key-value pairs
 */
export const convertTestPlanContextToObject = (context) => {
    if (!context) {
        return {};
    }
    const parameters = context.split('\n');
    return parameters.reduce((acc, e) => {
        const parameter = e.split(':')[0].trim();
        const value = e.substring(e.indexOf(':') + 1).trim();
        return Object.assign(acc, { [parameter]: value });
    }, {});
};
/**
 * Convert an object with key-value pairs back to a context string
 * @param obj The object to convert
 * @returns The context string in 'key:value' format
 */
export const convertObjectToTestPlanContext = (obj) => {
    const entries = Object.entries(obj);
    const contextString = entries.map(([key, value]) => `${key}:${value}`).join('\n');
    return contextString;
};
/**
 * Generate a user name for next user in CSV
 * @param userType The type of user to generate
 * @param users The list of users in the CSV
 * @returns The next user name
 */
export const generateNextUser = (userType, users) => {
    const userPrefix = userType;
    const counts = users.reduce((acc, user) => {
        if (user.startsWith(userPrefix)) {
            const num = parseInt(user.replace(userPrefix, ''), 10);
            if (!isNaN(num)) {
                acc.push(num);
            }
        }
        return acc;
    }, []);
    counts.sort((a, b) => b - a);
    let nextUser = 1;
    if (counts.length > 0) {
        nextUser = counts[0] + 1;
    }
    return `${userPrefix}${nextUser}`;
};
/**
 * Find users in a test plan CSV
 * @param testPlan The test plan to search
 * @returns The list of users in the test plan
 */
export const findUsersInTestPlanCSV = (testPlanCsv) => {
    // check all rows which have Step Type = CreateUser or CreateParticipant
    const rows = testPlanCsv.filter((row) => row['Step Type'] &&
        [StudyTestPlanStepType.CreateUser, StudyTestPlanStepType.CreateParticipant].includes(row['Step Type']));
    const siteUsers = [];
    const participants = [];
    rows.forEach((row) => {
        const context = convertTestPlanContextToObject(row.Context);
        if (row['Step Type'] === StudyTestPlanStepType.CreateUser) {
            const user = context.User;
            if (user) {
                siteUsers.push(user);
            }
        }
        else if (row['Step Type'] === StudyTestPlanStepType.CreateParticipant) {
            const participant = context.User;
            if (participant) {
                participants.push(participant);
            }
        }
    });
    return {
        [StudyTestPlanStepUserTypes.ADMIN]: ['Admin'],
        [StudyTestPlanStepUserTypes.SITE_USER]: siteUsers,
        [StudyTestPlanStepUserTypes.PARTICIPANT]: participants,
    };
};
/**
 * Get available field options for a context field
 * @param field The context field to get options for
 * @param context Other context fields to use for filtering
 * @param state The state of the test plan & study
 * @returns The available options for the field
 */
export const getContextFieldOptionsInCSV = (field, context, state) => {
    const { users, visits, activities, notifications, statuses, ecoaActivities } = state;
    switch (field) {
        case StudyTestPlanStepContextValues.SITE_USER:
            return users[StudyTestPlanStepUserTypes.SITE_USER].map((user) => {
                return {
                    value: user,
                    label: user,
                };
            });
        case StudyTestPlanStepContextValues.PARTICIPANT:
            return users[StudyTestPlanStepUserTypes.PARTICIPANT].map((user) => {
                return {
                    value: user,
                    label: user,
                };
            });
        case StudyTestPlanStepContextValues.USER:
            return [...users[StudyTestPlanStepUserTypes.SITE_USER], ...users[StudyTestPlanStepUserTypes.PARTICIPANT]].map((user) => {
                return {
                    value: user,
                    label: user,
                };
            });
        case StudyTestPlanStepContextValues.USER_ROLE:
            return [
                {
                    value: 'Axon Site User',
                    label: 'Site User',
                },
            ];
        case StudyTestPlanStepContextValues.PARTICIPANT_TASKS: {
            const selectedVisit = context?.GroupName;
            if (!selectedVisit) {
                return activities
                    .filter((activity) => [ActivityType.PARTICIPANT].includes(activity.type))
                    .map((activity) => ({
                    value: activity.name,
                    label: activity.name,
                }));
            }
            const visit = visits.find((visit) => GenericUtils.isSameString(visit.name, selectedVisit));
            const participantActivities = visit
                ? Object.values(visit.activities).filter((activity) => [ActivityType.PARTICIPANT].includes(activity.type))
                : [];
            return participantActivities.map((activity) => {
                if (activity.libraryId) {
                    const libraryActivity = ecoaActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId);
                    return {
                        value: libraryActivity?.data.full_name || activity.name,
                        label: libraryActivity?.data.full_name || activity.name,
                    };
                }
                return {
                    value: activity.name,
                    label: activity.name,
                };
            });
        }
        case StudyTestPlanStepContextValues.GROUPS:
            return visits.map((visit) => ({
                value: visit.name,
                label: visit.name,
            }));
        case StudyTestPlanStepContextValues.SITE_TASKS: {
            const selectedVisit = context?.GroupName;
            if (!selectedVisit) {
                return [];
            }
            const visit = visits.find((visit) => GenericUtils.isSameString(visit.name, selectedVisit));
            const siteActivities = visit
                ? Object.values(visit.activities).filter((activity) => [ActivityType.SITE, ActivityType.SITE_WORKFLOW, ActivityType.OBSERVER].includes(activity.type))
                : [];
            return siteActivities.map((activity) => {
                if (activity.libraryId) {
                    const libraryActivity = ecoaActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId);
                    return {
                        value: libraryActivity?.data.full_name || activity.name,
                        label: libraryActivity?.data.full_name || activity.name,
                    };
                }
                return {
                    value: activity.name,
                    label: activity.name,
                };
            });
        }
        case StudyTestPlanStepContextValues.NOTIFICATION:
            return notifications.map((notification) => ({
                value: notification.name,
                label: notification.name,
            }));
        case StudyTestPlanStepContextValues.STATUS:
            return statuses.map((status) => ({ label: status.value, value: status.value }));
        case StudyTestPlanStepContextValues.TIMEZONE:
            return StudyTestPlan_Timezones.map((timezone) => ({
                value: timezone,
                label: timezone,
            }));
        case StudyTestPlanStepContextValues.END_OF_DAY:
            return [
                {
                    value: 'Yes',
                    label: 'Yes',
                },
            ];
        default:
            return [];
    }
};
/**
 * Find type of context fields when contextFields is a selector (via a scoring system)
 * @param context The context to match the type
 * @param contextFields The context fields to define the type
 * @returns The type of context fields
 */
export const findContextType = (context, contextFields) => {
    const { options } = contextFields;
    let bestMatch = options[0];
    let bestMatchScore = 0;
    if (context) {
        for (const option of options) {
            const { fields } = option;
            let score = 0;
            let allRequiredFieldsMatched = true;
            for (const field of fields) {
                if (context[field.key] !== undefined) {
                    score++;
                }
                else if (field.required) {
                    allRequiredFieldsMatched = false;
                }
            }
            if (allRequiredFieldsMatched) {
                return option;
            }
            if (score > bestMatchScore) {
                bestMatch = option;
                bestMatchScore = score;
            }
        }
    }
    return bestMatch;
};
/**
 * Sanitize a test plan based on a study
 * @param testPlan The test plan to sanitize
 * @param study The study to sanitize against
 * @returns The sanitized test plan
 */
export const sanitizeTestPlanCSV = (testPlan, study, ecoaActivities) => {
    let newCsv = testPlan;
    const visits = Object.values(study.getAllVisits());
    const activities = study.getAllActivities().filter((activity) => activity.type === ActivityType.PARTICIPANT);
    newCsv = newCsv.map((row) => {
        const newRow = { ...row };
        const stepType = newRow['Step Type'];
        if (!stepType) {
            return newRow;
        }
        const context = convertTestPlanContextToObject(newRow.Context || '');
        const contextFields = StudyTestPlan_StepValidContexts[stepType] || [];
        delete context.Email;
        if (context.TaskCode || context.TaskCodes) {
            if ([StudyTestPlanStepType.CompleteSiteTask].includes(stepType)) {
                const taskCode = context.TaskCode;
                const groupName = context.GroupName;
                delete context.TaskCode;
                const visitActivities = Object.values(visits.find((visit) => GenericUtils.isSameString(visit.name, groupName))?.activities || {});
                const activity = visitActivities.find((activity) => GenericUtils.isSameString(activity.code, taskCode));
                if (activity) {
                    const taskName = ecoaActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId)?.data.full_name ||
                        activity.name;
                    context.TaskName = taskName;
                }
            }
            else if ([
                StudyTestPlanStepType.AssertSiteTaskAvailability,
                StudyTestPlanStepType.AssertSiteTaskNonAvailability,
            ].includes(stepType)) {
                if (context.TaskCode && context.GroupName) {
                    const taskCode = context.TaskCode;
                    const groupName = context.GroupName;
                    delete context.TaskCode;
                    const visitActivities = Object.values(visits.find((visit) => GenericUtils.isSameString(visit.name, groupName))?.activities || {});
                    const activity = visitActivities.find((activity) => GenericUtils.isSameString(activity.code, taskCode));
                    if (activity) {
                        const taskName = ecoaActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId)?.data.full_name ||
                            activity.name;
                        context.TaskName = taskName;
                    }
                }
                else {
                    const taskCodes = context.TaskCodes || context.TaskCode || '';
                    const type = context.TaskCodes ? 'TaskNames' : 'TaskName';
                    delete context.TaskCode;
                    delete context.TaskCodes;
                    const taskCodeList = taskCodes.split(',');
                    const siteVisits = visits
                        .filter((visit) => visit.code && taskCodeList.find((code) => GenericUtils.isSameString(code, visit.code)))
                        .map((visit) => visit.name);
                    if (siteVisits.length) {
                        context[type] = siteVisits.join(',');
                    }
                }
            }
            else if ([
                StudyTestPlanStepType.CompleteParticipantTask,
                StudyTestPlanStepType.AssertParticipantTaskAvailability,
                StudyTestPlanStepType.AssertParticipantTaskNonAvailability,
            ].includes(stepType)) {
                const taskCodes = context.TaskCodes || context.TaskCode || '';
                const type = context.TaskCodes ? 'TaskNames' : 'TaskName';
                delete context.TaskCode;
                delete context.TaskCodes;
                const taskCodeList = taskCodes.split(',');
                const participantActivities = activities.filter((activity) => taskCodeList.find((code) => GenericUtils.isSameString(code, activity.code)));
                const taskNames = participantActivities.map((activity) => {
                    if (activity.libraryId) {
                        return (ecoaActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId)?.data.full_name ||
                            activity.name);
                    }
                    return activity.name;
                });
                if (taskNames.length) {
                    context[type] = taskNames.join(',');
                }
            }
        }
        // remove any fields that are not in the context type
        if (contextFields.type === 'selector') {
            const contextType = findContextType(context, contextFields);
            const { fields } = contextType;
            Object.keys(context).forEach((key) => {
                if (!fields.find((field) => field.key === key)) {
                    delete context[key];
                }
            });
        }
        newRow.Context = convertObjectToTestPlanContext(context);
        if (!newRow['Execution Mode']) {
            newRow['Execution Mode'] = StudyTestPlanExecutionModes.BACKEND;
        }
        return newRow;
    });
    return newCsv;
};
/**
 * Validate a test plan against a study
 * @param testPlan The test plan to validate
 * @param study The study to validate against
 * @returns Whether the test plan is valid or not and the {row, column} of all invalid cells with specific error messages
 */
export const validateTestPlanCSV = (testPlan, study, ecoaActivities) => {
    const users = findUsersInTestPlanCSV(testPlan);
    const visits = Object.values(study.getAllVisits());
    const activities = study.getAllActivities().filter((activity) => activity.type === ActivityType.PARTICIPANT);
    const { notifications } = study;
    const statuses = study.participantStatuses;
    const getFieldOptions = (field, fieldState) => {
        return getContextFieldOptionsInCSV(field, fieldState, {
            users,
            visits,
            activities,
            notifications,
            statuses,
            ecoaActivities,
        });
    };
    const errors = [];
    let Column;
    (function (Column) {
        Column["StepType"] = "Step Type";
        Column["User"] = "User";
        Column["Context"] = "Context";
        Column["ExecutionMode"] = "Execution Mode";
        Column["UserInput"] = "User Input";
    })(Column || (Column = {}));
    const ColumnIndex = {
        [Column.StepType]: 0,
        [Column.User]: 1,
        [Column.Context]: 2,
        [Column.ExecutionMode]: 3,
        [Column.UserInput]: 4,
    };
    testPlan.forEach((row, rowIndex) => {
        const newRow = { ...row };
        const stepType = newRow[Column.StepType];
        const user = newRow[Column.User];
        const context = convertTestPlanContextToObject(newRow.Context || '');
        const executionMode = newRow[Column.ExecutionMode];
        const userInput = newRow[Column.UserInput];
        if (!stepType) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.StepType],
                columnName: Column.StepType,
                error: 'This field is required',
            });
        }
        const validUsersForStep = StudyTestPlanStepValidUser[stepType] || [];
        const validUsers = validUsersForStep.map((userType) => users[userType]).flat();
        if (!user) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.User],
                columnName: Column.User,
                error: 'This field is required',
            });
        }
        else if (!validUsers.includes(user)) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.User],
                columnName: Column.User,
                error: 'Invalid user',
            });
        }
        const validateFields = (fields) => {
            fields.forEach((field) => {
                const value = context[field.key];
                if (!value && field.required) {
                    errors.push({
                        row: rowIndex,
                        column: ColumnIndex[Column.Context],
                        columnName: Column.Context,
                        error: `This field is required for ${field.key}`,
                    });
                }
                else if (value) {
                    switch (field.value) {
                        case StudyTestPlanStepContextValues.PLAIN_TEXT: {
                            break;
                        }
                        case StudyTestPlanStepContextValues.DATE: {
                            break;
                        }
                        case StudyTestPlanStepContextValues.TIME_OF_DAY: {
                            break;
                        }
                        case StudyTestPlanStepContextValues.DURATION: {
                            break;
                        }
                        default: {
                            const options = getFieldOptions(field.value, context);
                            if (field.multiple) {
                                const values = value.split(',');
                                values.forEach((val) => {
                                    if (!options.find((option) => GenericUtils.isSameString(option.value, val))) {
                                        errors.push({
                                            row: rowIndex,
                                            column: ColumnIndex[Column.Context],
                                            columnName: Column.Context,
                                            error: `Invalid value for ${field.key}`,
                                        });
                                    }
                                });
                            }
                            else if (!options.find((option) => GenericUtils.isSameString(option.value, value))) {
                                errors.push({
                                    row: rowIndex,
                                    column: ColumnIndex[Column.Context],
                                    columnName: Column.Context,
                                    error: `Invalid value for ${field.key}`,
                                });
                            }
                        }
                    }
                }
            });
        };
        const contextFields = StudyTestPlan_StepValidContexts[stepType] || [];
        if (!context || Object.keys(context).length === 0) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.Context],
                columnName: Column.Context,
                error: 'This field is required',
            });
        }
        else if (contextFields.type === 'selector') {
            const contextType = findContextType(context, contextFields);
            const { fields } = contextType;
            validateFields(fields);
        }
        else {
            validateFields(contextFields.fields);
        }
        const validExecutionModes = StudyTestPlan_StepValidExecutionModes[stepType] || [];
        if (!executionMode) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.ExecutionMode],
                columnName: Column.ExecutionMode,
                error: 'This field is required',
            });
        }
        else if (!validExecutionModes.includes(executionMode)) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.ExecutionMode],
                columnName: Column.ExecutionMode,
                error: 'Invalid execution mode',
            });
        }
        if (!userInput &&
            [StudyTestPlanStepType.CompleteParticipantTask, StudyTestPlanStepType.CompleteSiteTask].includes(stepType) &&
            executionMode !== StudyTestPlanExecutionModes.BACKEND) {
            errors.push({
                row: rowIndex,
                column: ColumnIndex[Column.UserInput],
                columnName: Column.UserInput,
                error: 'This field is required',
            });
        }
    });
    return {
        valid: errors.length === 0,
        errors,
    };
};
/**
 * Generate a screen configuration from an iframe event with branched logic
 * @param iframeEvent The iframe event to generate the screen configuration from
 * @returns The screen configuration
 */
const generateScreenConfigFromIframeEvents = (iframeEvent) => {
    const { events, responses } = iframeEvent;
    function findCompletionPath() {
        let stackToUse = [];
        let stack = [];
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event.name === 'next') {
                if (stack[stack.length - 1] !== event.stepId) {
                    stack.push(event.stepId);
                }
            }
            else if (event.name === 'back') {
                if (stack.length > 0) {
                    stack.pop();
                }
            }
            else if (event.name === 'completion') {
                stackToUse = [...stack];
                stack = [];
            }
        }
        return stackToUse;
    }
    const path = findCompletionPath();
    const responseObject = responses.reduce((acc, r) => {
        acc[r.stepId] = r;
        return acc;
    }, {});
    const screensInput = path.map((stepId) => ({
        // @ts-expect-error - missing properties
        status: 'answered',
        ...responseObject[stepId],
        stepId,
    }));
    return screensInput;
};
/**
 * Generate a screen configuration from an ecoa activity (generate random values if not provided)
 * @param stepId The step id to generate the screen configuration for
 * @param screen The screen configuration from the ecoa activity
 * @param value The value to use for the screen or generate a random value
 * @returns  The screen configuration
 */
const getScreenValue = (stepId, screen, value) => {
    const { name } = screen;
    switch (screen.type) {
        case 'instruction': {
            return {
                stepId,
                name,
                type: 'next',
            };
        }
        case 'nrs': {
            const { startValue, endValue, valueStep } = screen;
            const stepCount = Math.floor((endValue - startValue) / valueStep) + 1;
            let index = Math.floor(Math.random() * stepCount);
            const randomValue = startValue + index * valueStep;
            if (value) {
                index = Math.floor((Number(value) - startValue) / valueStep);
            }
            return {
                stepId,
                name,
                type: 'selector',
                value: value ?? randomValue,
                index,
            };
        }
        case 'vrs': {
            const { choices } = screen;
            let index = Math.floor(Math.random() * choices.length);
            if (value) {
                index = choices.sort((a, b) => a.order - b.order).findIndex((c) => c.value === value);
            }
            const choice = choices[index].value;
            return {
                stepId,
                name,
                type: 'selector',
                value: choice,
                index,
            };
        }
        case 'nde': {
            let { min, max, decimals } = screen;
            min = min ?? 0;
            max = max ?? 100;
            decimals = decimals ?? 0;
            let randomValue = min + Math.random() * (max - min);
            randomValue = Number(randomValue.toFixed(decimals));
            return {
                stepId,
                name,
                type: 'form',
                fields: [
                    {
                        type: 'text',
                        value: value ?? randomValue,
                    },
                ],
                noWrapper: true,
            };
        }
        case 'date_time': {
            // TODO: add boundary handling
            const { input_type } = screen;
            if (input_type === "complete_date_time" /* InputType.COMPLETE_DATE_TIME */) {
                return {
                    stepId,
                    name,
                    type: 'form',
                    fields: [
                        {
                            type: 'datetime-local',
                            value: value ?? '2024-11-14T23:08',
                        },
                    ],
                    noWrapper: true,
                };
            }
            return {
                stepId,
                name,
                type: 'form',
                fields: [
                    {
                        type: 'date',
                        value: value ?? '2024-11-14',
                    },
                ],
                noWrapper: true,
            };
        }
        case 'eqvas': {
            let { min, max } = screen;
            min = min ?? 0;
            max = max ?? 100;
            const randomValue = Math.floor(Math.random() * (max - min + 1) + min);
            return {
                stepId,
                name,
                type: 'form',
                fields: [
                    {
                        type: 'range',
                        value: value ?? randomValue,
                    },
                ],
                noWrapper: true,
            };
        }
        case 'vas': {
            let { min, max } = screen;
            min = min ?? 0;
            max = max ?? 100;
            const randomValue = Math.floor(Math.random() * (max - min + 1) + min);
            return {
                stepId,
                name,
                type: 'form',
                fields: [
                    {
                        type: 'range',
                        value: value ?? randomValue,
                    },
                ],
                noWrapper: true,
            };
        }
        case 'text_input': {
            let { min, max, format } = screen;
            min = min ?? 0;
            max = max ?? 100;
            let randomValue = 'a'.repeat(Math.floor(Math.random() * (max - min + 1) + min));
            if (format) {
                switch (format.position) {
                    case "starts_with" /* Position.STARTS_WITH */: {
                        randomValue = format.text + randomValue;
                        break;
                    }
                    case "ends_with" /* Position.ENDS_WITH */: {
                        randomValue = randomValue + format.text;
                        break;
                    }
                    case "contains" /* Position.CONTAINS */: {
                        randomValue = randomValue + format.text;
                    }
                }
            }
            return {
                stepId,
                name,
                type: 'form',
                fields: [
                    {
                        type: 'textarea',
                        value: value ?? randomValue,
                    },
                ],
                noWrapper: true,
            };
        }
        case 'completion': {
            return null;
        }
    }
};
/**
 * Generate step input from screens and input values
 * @param screens Screen configuration from the ecoa activity
 * @param inputValues Input values from the iframe event
 * @returns The step input
 */
const generateStepInput = (screens, inputValues) => {
    const stepInput = [];
    for (const inputValueEvent of inputValues) {
        if (inputValueEvent.status === 'answered') {
            const { value, stepId } = inputValueEvent;
            const screen = screens.find((s) => s.key === inputValueEvent.stepId);
            if (!screen) {
                console.log('Screen not found', inputValueEvent);
                continue;
            }
            const step = getScreenValue(stepId, screen, value);
            if (screen.type === 'completion') {
                return stepInput;
            }
            if (step) {
                stepInput.push(step);
            }
        }
    }
    return stepInput;
};
/**
 * Generate random user input from ecoa activity
 * @param screens The screens from the ecoa activity
 * @returns The user input
 */
const generateRandomUserInput = (screens) => {
    const stepInput = [];
    const answers = {};
    for (let i = 0; i < screens.length;) {
        const screen = screens[i];
        const { transition } = screen;
        const next = () => {
            i++;
        };
        const navigate = (destination) => {
            const index = screens.findIndex(({ key }) => key === destination);
            if (index === -1) {
                console.error(`Could not find screen with id ${destination}`);
                return stepInput;
            }
            i = index;
        };
        const nextCallback = () => {
            if (transition?.conditions?.length) {
                const match = transition.conditions.find(({ condition }) => jsonLogic.apply(condition, { answers }));
                if (match) {
                    if (match.destination === '_NEXT_') {
                        return next();
                    }
                    if (match.destination === '_EXIT_') {
                        return;
                    }
                    navigate(match.destination);
                    return;
                }
            }
            if (transition?.default) {
                if (transition.default === '_NEXT_') {
                    return next();
                }
                if (transition.default === '_EXIT_') {
                    return;
                }
                navigate(transition.default);
                return;
            }
            next();
        };
        const stepId = screen.key;
        if (screen.type === 'completion') {
            return stepInput;
        }
        const currentStepInput = getScreenValue(stepId, screen);
        if (currentStepInput) {
            stepInput.push(currentStepInput);
            answers[stepId] = { value: currentStepInput.value };
            nextCallback();
        }
        else {
            throw new Error(`Unknown screen type: ${screen.type}`);
        }
    }
    return stepInput;
};
/**
 * Generate user input from iframe events
 * @param iframeEvent The iframe event to generate user input from
 * @param ecoaActivity The ecoa activity to generate user input for
 * @returns The user input
 */
export const generateUserInputFromIframeEvents = (iframeEvent, ecoaActivity) => {
    const screensInput = generateScreenConfigFromIframeEvents(iframeEvent);
    const stepInput = generateStepInput(ecoaActivity.screens, screensInput);
    return {
        screens: stepInput,
        isWithinIframe: true,
    };
};
/**
 * Generate user input from instrument submit
 * @param param0 The parameters to generate user input from
 * @param ecoaActivity The ecoa activity to generate user input for
 * @returns The user input
 */
export const generateUserInputFromInstrumentSubmit = ({ history, answers, }, ecoaActivity) => {
    const screensInput = history.map((stepId) => ({
        stepId,
        status: 'answered',
        ...(answers[stepId]
            ? {
                value: answers[stepId]?.value,
                original_value: answers[stepId]?.text,
            }
            : {
                status: 'answered',
            }),
    }));
    const stepInput = generateStepInput(ecoaActivity.screens, screensInput);
    return {
        screens: stepInput,
        isWithinIframe: true,
    };
};
/**
 * Generate user input from ecoa activity
 * @param ecoActivity The ecoa activity to generate user input for
 * @returns The user input
 */
export const generateRandomUserInputFromEcoaActivity = (ecoActivity) => {
    const { screens } = ecoActivity;
    const stepInput = generateRandomUserInput(screens);
    return {
        screens: stepInput,
        isWithinIframe: true,
    };
};
