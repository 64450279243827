import { AutoLinkNode } from '@lexical/link';
import { isHTMLElement } from 'lexical';
import { linkClasses } from '../utils';
export class CustomAutoLinkNode extends AutoLinkNode {
    static getType() {
        return 'custom_auto_link';
    }
    static clone(node) {
        return new CustomAutoLinkNode(node.__url, { target: node.__target, rel: node.__rel, title: node.__title }, node.__key);
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.setAttribute('rel', 'noopener noreferrer');
        dom.setAttribute('target', '_blank');
        dom.setAttribute('class', linkClasses);
        return dom;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'custom_auto_link',
            version: 1,
            isUnlinked: this.getIsUnlinked(),
        };
    }
    /**
     * Overrides the exportDOM method to remove additional classes, styles, and attributes from an a tag.
     * @override
     */
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            element.removeAttribute('class');
            element.removeAttribute('rel');
            element.removeAttribute('target');
        }
        return { element };
    }
}
