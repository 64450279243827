// These colors map to medable/styles/tailwind_default.config.ts colors for now
// Hex colors are used in chart.js data
export const COLORS_HEX = {
    red: '#FECACA',
    redDark: '#EF4444',
    orange: '#FED7AA',
    orangeDark: '#FB923C',
    yellow: '#FEF08A',
    yellowDark: '#FACC15',
    green: '#BBF7D0',
    greenDark: '#22C55E',
    blue: '#DBEAFE',
    blueDark: '#60A5FA',
    purple: '#D4C9FF',
    purpleDark: '#8F7DFF',
    purple1: '#E7E1FF',
    purple2: '#D4C9FF',
    purple3: '#B3A0FF',
    purple4: '#8F7DFF',
    purple5: '#5F46CF',
    neutral: '#F3F4F6',
};
// text colors are used on text, e.g. internally in Number chart
export const COLORS_TEXT = {
    red: 'text-red-200',
    redDark: 'text-red-500',
    orange: 'text-orange-200',
    orangeDark: 'text-orange-400',
    yellow: 'text-yellow-200',
    yellowDark: 'text-yellow-400',
    green: 'text-green-200',
    greenDark: 'text-green-500',
    blue: 'text-blue-100',
    blueDark: 'text-blue-400',
    purple: 'text-purple-200',
    purpleDark: 'text-purple-400',
    purple1: 'text-purple-100',
    purple2: 'text-purple-200',
    purple3: 'text-purple-300',
    purple4: 'text-purple-400',
    purple5: 'text-purple-600',
    neutral: 'text-neutral-100',
};
// named colors are used as constants, mapping to hex and text colors
export const COLORS_NAME = {
    red: 'red',
    redDark: 'redDark',
    orange: 'orange',
    orangeDark: 'orangeDark',
    yellow: 'yellow',
    yellowDark: 'yellowDark',
    green: 'green',
    greenDark: 'greenDark',
    blue: 'blue',
    blueDark: 'blueDark',
    purple: 'purple',
    purpleDark: 'purpleDark',
    purple1: 'purple1',
    purple2: 'purple2',
    purple3: 'purple3',
    purple4: 'purple4',
    purple5: 'purple5',
    neutral: 'neutral',
};
