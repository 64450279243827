import { EIframeMessageTypes } from '../../../../constants/iframe_message.enum';
export const ecoa_hooks = `<script type='text/javascript'>

    function post(type, message) {
        window.parent.postMessage({ type, message }, '*');
    }

    function submit({ responses, events }) {
        post(${JSON.stringify(EIframeMessageTypes.SUBMIT)}, { responses, events })
    }

    /**
     * Sends tracking data to the client app.
     *
     * @param { object } event - tracking event as a json object
     */
    function track(event) {
        post(${JSON.stringify(EIframeMessageTypes.TRACK)}, event)
    }

    /**
     * Sends a Cancel request to the client app.
     * The app should close the task.
     */
    function cancel() {
        post(${JSON.stringify(EIframeMessageTypes.CANCEL)}, '')
    }
</script>`;
