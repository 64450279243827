export var LibraryEndpoint;
(function (LibraryEndpoint) {
    LibraryEndpoint["GET_ACTIVITY_RESOURCES"] = "/v1/activity";
    LibraryEndpoint["GET_CUSTOMIZATIONS"] = "/v1/customization";
    LibraryEndpoint["GET_STUDY_CONFIGS"] = "/v1/study_config";
    LibraryEndpoint["GET_TEMPLATES"] = "/v1/template";
    LibraryEndpoint["GET_TEST_PLANS"] = "/v1/study_test_plan";
    LibraryEndpoint["GET_TEST_REPORTS"] = "/v1/study_test_report";
})(LibraryEndpoint || (LibraryEndpoint = {}));
export var LibraryResourceType;
(function (LibraryResourceType) {
    LibraryResourceType["ACTIVITY"] = "ACTIVITY";
    LibraryResourceType["GRAVITON_INSTRUMENTS"] = "GRAVITON_INSTRUMENTS";
    LibraryResourceType["STUDY_CONFIG"] = "STUDY_CONFIG";
    LibraryResourceType["TEMPLATE"] = "TEMPLATE";
})(LibraryResourceType || (LibraryResourceType = {}));
export var LibraryResourceStatus;
(function (LibraryResourceStatus) {
    LibraryResourceStatus["APPROVED"] = "APPROVED";
    LibraryResourceStatus["DRAFT"] = "DRAFT";
    LibraryResourceStatus["PUBLISHED"] = "PUBLISHED";
})(LibraryResourceStatus || (LibraryResourceStatus = {}));
export var LibraryTemplateStatus;
(function (LibraryTemplateStatus) {
    LibraryTemplateStatus["DRAFT"] = "DRAFT";
    LibraryTemplateStatus["READY_FOR_USE"] = "READY_FOR_USE";
})(LibraryTemplateStatus || (LibraryTemplateStatus = {}));
export var LibraryActivityInstrumentType;
(function (LibraryActivityInstrumentType) {
    LibraryActivityInstrumentType["CLINRO"] = "clinro";
    LibraryActivityInstrumentType["PRO"] = "pro";
    LibraryActivityInstrumentType["SITE_WORKFLOW"] = "workflow";
    LibraryActivityInstrumentType["OBSRO"] = "obSRO";
})(LibraryActivityInstrumentType || (LibraryActivityInstrumentType = {}));
