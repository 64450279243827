import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
export const useWorkspaceSites = () => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchSites = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/sites`);
        return response?.data || [];
    };
    const { isFetching, isLoading, isError, error, data } = useQuery({
        queryKey: ['entities', entityId.toString(), 'sites'],
        queryFn: fetchSites,
        placeholderData: keepPreviousData,
    });
    return {
        isFetching,
        isLoading,
        isError,
        error,
        workspaceSites: data || [],
    };
};
