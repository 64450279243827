import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, useLocalizeMessage } from 'libs.nucleus';
export const ParticipantStatusConditions = ({ participantStatuses }) => {
    const translate = useLocalizeMessage();
    const expandTriggerType = useMemo(() => {
        return (triggerType) => triggerType.replace(/([A-Z])/g, ' $1').trim();
    }, []);
    const createStepConditionKey = useMemo(() => {
        return (status, activity, step, operator, value) => `${status}-${activity}-${step.stepName || step.stepKey}-${operator}-${value}`;
    }, []);
    const flattenParticipantStatuses = useMemo(() => {
        if (!participantStatuses) {
            return [];
        }
        return Object.values(participantStatuses).flatMap((conditions) => conditions.map((condition, index) => ({
            ...condition,
            isFirstInGroup: index === 0,
            groupLength: conditions.length,
        })));
    }, [participantStatuses]);
    const columns = useMemo(() => [
        {
            header: translate('Status'),
            accessorKey: 'status',
            cell: ({ row }) => (row.original.isFirstInGroup ? row.original.status : ''),
        },
        {
            header: translate('Visit'),
            cell: ({ row }) => row.original.visit || '',
        },
        {
            header: translate('Activity condition'),
            cell: ({ row }) => `${row.original.activityName} ${expandTriggerType(row.original.triggerType)}`,
            size: 250,
        },
        {
            header: translate('Screen condition'),
            cell: ({ row }) => {
                const conditions = row.original.stepConditions;
                if (!conditions?.length) {
                    return null;
                }
                return (_jsx("ul", { children: conditions.map((sc) => (_jsx("li", { children: `${sc.stepName || sc.stepKey} ${sc.operator} ${sc.value}` }, createStepConditionKey(row.original.status, row.original.activityName, sc, sc.operator, sc.value)))) }));
            },
        },
        {
            header: translate('Config type'),
            accessorKey: 'configType',
        },
    ], [translate, expandTriggerType, createStepConditionKey]);
    if (!Object.keys(participantStatuses || {}).length) {
        return null;
    }
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Participant status conditions') }), _jsx(Table, { data: flattenParticipantStatuses, columns: columns })] }));
};
