import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { NumberFormat } from '@medable/i18n-core';
import { LocaleContext, Text } from 'libs.nucleus';
import { COLORS_TEXT } from '../reporting.constants';
const TEXT_SIZE_MAP = {
    'xs': 'text-xs',
    'sm': 'text-sm',
    'base': 'text-base',
    'lg': 'text-lg',
    'xl': 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
    '7xl': 'text-7xl',
    '8xl': 'text-8xl',
    '9xl': 'text-9xl',
};
export const NumberChart = ({ data, className = '' }) => {
    const locale = useContext(LocaleContext).toString();
    return (_jsx("div", { className: `flex flex-1 items-center justify-items-center ${className}`, children: data &&
            data.datasets &&
            data.datasets.map(({ number, label, title, unit = '', numberSize = 'base', color }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            _jsxs("div", { className: 'flex flex-col mx-auto', children: [_jsx("div", { className: 'flex flex-1 text-text-secondary', children: title }), _jsxs("div", { className: `flex flex-1 ${color ? COLORS_TEXT[color] : 'text-text-primary'} ${TEXT_SIZE_MAP[numberSize]}`, children: [typeof number === 'number' ? new NumberFormat(locale).format(number) : number, _jsx("span", { className: 'flex text-base items-center', children: unit })] }), _jsx(Text, { size: 'xs', className: 'flex flex-1 text-text-secondary', children: label })] }, `number-chart-container-${index}`))) }));
};
