import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { Actions } from './spreadsheet.actions';
export const SpreadsheetDispatchContext = createContext({
    updateInitialState: () => { },
    setData: () => { },
    selectEntireRow: () => { },
    selectEntireColumn: () => { },
    selectEntireWorksheet: () => { },
    setSelection: () => { },
    select: () => { },
    activate: () => { },
    setCellData: () => { },
    setCellDimensions: () => { },
    paste: () => { },
    keyPress: () => { },
    keyDown: () => { },
    dragStart: () => { },
    dragEnd: () => { },
    commit: () => { },
    copy: () => { },
    cut: () => { },
    edit: () => { },
    view: () => { },
    clear: () => { },
    blur: () => { },
    showContextMenu: () => { },
    hideContextMenu: () => { },
    moveRow: () => { },
    insertRow: () => { },
    deleteRow: () => { },
    showSelect: () => { },
    hideSelect: () => { },
    clearOtherCells: () => { },
    resetState: () => { },
});
export const SpreadsheetDispatchProvider = ({ children, dispatch }) => {
    function updateInitialState(state) {
        dispatch({
            type: Actions.UPDATE_INITIAL_STATE,
            payload: { state },
        });
    }
    function setData(data) {
        dispatch({
            type: Actions.SET_DATA,
            payload: { data },
        });
    }
    function selectEntireRow(row, extend) {
        dispatch({
            type: Actions.SELECT_ENTIRE_ROW,
            payload: { row, extend },
        });
    }
    function selectEntireColumn(column, extend) {
        dispatch({
            type: Actions.SELECT_ENTIRE_COLUMN,
            payload: { column, extend },
        });
    }
    function selectEntireWorksheet() {
        dispatch({ type: Actions.SELECT_ENTIRE_WORKSHEET });
    }
    function setSelection(selection) {
        dispatch({ type: Actions.SET_SELECTION, payload: { selection } });
    }
    function select(point) {
        dispatch({
            type: Actions.SELECT,
            payload: { point },
        });
    }
    function activate(point) {
        dispatch({
            type: Actions.ACTIVATE,
            payload: { point },
        });
    }
    function setCellData(active, data) {
        dispatch({
            type: Actions.SET_CELL_DATA,
            payload: { active, data },
        });
    }
    function setCellDimensions(point, dimensions) {
        dispatch({
            type: Actions.SET_CELL_DIMENSIONS,
            payload: { point, dimensions },
        });
    }
    function paste(data) {
        dispatch({
            type: Actions.PASTE,
            payload: { data },
        });
    }
    function keyPress(event) {
        dispatch({
            type: Actions.KEY_PRESS,
            payload: { event },
        });
    }
    function keyDown(event) {
        dispatch({
            type: Actions.KEY_DOWN,
            payload: { event },
        });
    }
    function dragStart() {
        dispatch({ type: Actions.DRAG_START });
    }
    function dragEnd() {
        dispatch({ type: Actions.DRAG_END });
    }
    function commit(changes) {
        dispatch({
            type: Actions.COMMIT,
            payload: { changes },
        });
    }
    function copy() {
        dispatch({ type: Actions.COPY });
    }
    function cut() {
        dispatch({ type: Actions.CUT });
    }
    function edit() {
        dispatch({ type: Actions.EDIT });
    }
    function view() {
        dispatch({ type: Actions.VIEW });
    }
    function clear() {
        dispatch({ type: Actions.CLEAR });
    }
    function blur() {
        dispatch({ type: Actions.BLUR });
    }
    function showContextMenu(e, type = 'point') {
        dispatch({
            type: Actions.SHOW_CONTEXT_MENU,
            payload: {
                e,
                type,
            },
        });
    }
    function hideContextMenu() {
        dispatch({ type: Actions.HIDE_CONTEXT_MENU });
    }
    function moveRow(from, to) {
        dispatch({
            type: Actions.MOVE_ROW,
            payload: {
                from,
                to,
            },
        });
    }
    function insertRow(position) {
        dispatch({
            type: Actions.INSERT_ROW,
            payload: {
                position,
            },
        });
    }
    function deleteRow() {
        dispatch({ type: Actions.DELETE_ROW });
    }
    function showSelect(payload) {
        dispatch({ type: Actions.SHOW_SELECT, payload });
    }
    function hideSelect() {
        dispatch({ type: Actions.HIDE_SELECT });
    }
    function clearOtherCells(point) {
        dispatch({ type: Actions.CLEAR_OTHER_CELLS, payload: point });
    }
    function resetState(data) {
        dispatch({ type: Actions.RESET_STATE, payload: data });
    }
    return (_jsx(SpreadsheetDispatchContext.Provider, { value: {
            updateInitialState,
            setData,
            selectEntireRow,
            selectEntireColumn,
            selectEntireWorksheet,
            setSelection,
            select,
            activate,
            setCellData,
            setCellDimensions,
            paste,
            keyPress,
            keyDown,
            dragStart,
            dragEnd,
            commit,
            copy,
            cut,
            edit,
            view,
            clear,
            blur,
            showContextMenu,
            hideContextMenu,
            moveRow,
            insertRow,
            deleteRow,
            showSelect,
            hideSelect,
            clearOtherCells,
            resetState,
        }, children: children }));
};
export const useSpreadsheetDispatch = () => {
    const context = useContext(SpreadsheetDispatchContext);
    if (!context) {
        throw new Error('useSpreadsheetDispatch must be used within an SpreadsheetDispatchProvider');
    }
    return context;
};
