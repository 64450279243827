import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { isEmpty, isEqual, mapValues, omit, trim } from 'lodash';
import { useContext, useState } from 'react';
import { ModalWindow, useLocalizeMessage } from 'libs.nucleus';
import { AuthContext, StepIndicator, useToastNotification } from 'libs.react';
import { EditSiteDetailsStep } from './edit_site_details_step.component';
import { buildCreateEnvironmentSitePayload, buildEditEnvironmentSitePayload, buildInitFormValue, useSiteFormValidation, } from './environment_site_modal.helpers';
import { SelectEnvironmentStep } from './select_environment_step.component';
import { SelectWorkspaceSiteStep } from './select_workspace_site_step.component';
import { StudyConfigurationContext } from '../../contexts';
import { useEnvironmentSiteMutation } from '../../hooks';
const TOTAL_STEPS = 3;
var Step;
(function (Step) {
    Step[Step["SELECT_ENVIRONMENT"] = 1] = "SELECT_ENVIRONMENT";
    Step[Step["SELECT_WORKSPACE_SITE"] = 2] = "SELECT_WORKSPACE_SITE";
    Step[Step["ADD_SITE_DETAILS"] = 3] = "ADD_SITE_DETAILS";
})(Step || (Step = {}));
export const EnvironmentSiteModal = ({ environmentSite, workspaceSite, onClose }) => {
    const isEditMode = !!environmentSite;
    const initFormValue = buildInitFormValue({ environmentSite, workspaceSite });
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { studyId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const { validateFormFieldOnBlur, validateFormFieldOnChange } = useSiteFormValidation();
    const [currentStep, setCurrentStep] = useState(isEditMode ? Step.ADD_SITE_DETAILS : Step.SELECT_ENVIRONMENT);
    const [selectedEnvironmentId, setSelectedEnvironmentId] = useState();
    const [selectedWorkspaceSite, setSelectedWorkspaceSite] = useState();
    const [formValue, setFormValue] = useState(initFormValue);
    const [errors, setErrors] = useState({});
    const siteMutation = useEnvironmentSiteMutation(environmentSite?.environmentId || selectedEnvironmentId, environmentSite?.id);
    /* site details form handlers */
    const getSelectValue = (options, value) => {
        return isEmpty(value) ? { label: '', value: '' } : options.find((option) => option.value === value);
    };
    const getMultiselectValue = (options, value) => {
        return isEmpty(value) ? [] : options.filter((option) => value.includes(option.value));
    };
    const handleInputFieldChange = (event) => {
        const { name, value } = event.target;
        const error = validateFormFieldOnChange(name, value);
        setFormValue((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => (error ? { ...prevState, [name]: error } : omit(prevState, name)));
    };
    const handleInputFieldBlur = (event) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();
        const error = validateFormFieldOnBlur(name, trimmedValue);
        if (error !== errors[name]) {
            setErrors((prevState) => (error ? { ...prevState, [name]: error } : omit(prevState, name)));
        }
        if (trimmedValue !== formValue[name]) {
            setFormValue((prevState) => ({ ...prevState, [name]: trimmedValue }));
        }
    };
    const handleSelectFieldChange = (name, value) => {
        const error = validateFormFieldOnChange(name, value.value);
        setFormValue((prevState) => ({ ...prevState, [name]: value.value }));
        setErrors((prevState) => (error ? { ...prevState, [name]: error } : omit(prevState, name)));
    };
    const handleMultiselectFieldChange = (name, value) => {
        const preparedValue = value.map((option) => option.value);
        const error = validateFormFieldOnChange(name, preparedValue);
        setFormValue((prevState) => ({ ...prevState, [name]: preparedValue }));
        setErrors((prevState) => (error ? { ...prevState, [name]: error } : omit(prevState, name)));
    };
    const handleSubmitForm = async () => {
        const payload = isEditMode
            ? buildEditEnvironmentSitePayload({ siteFormValue: formValue })
            : buildCreateEnvironmentSitePayload({
                entityId,
                studyId,
                environmentId: selectedEnvironmentId,
                workspaceSiteId: selectedWorkspaceSite.id,
                siteFormValue: formValue,
            });
        try {
            await siteMutation.mutateAsync(payload);
            addNotification({
                title: isEditMode ? translate('Site updated') : translate('Site created'),
                type: 'success',
            });
        }
        catch (error) {
            console.error('Failed to submit form', error);
            addNotification({
                title: translate('Error'),
                type: 'error',
            });
        }
        finally {
            handleClose();
        }
    };
    /* stepper handlers */
    const onNext = () => {
        if (currentStep === Step.SELECT_ENVIRONMENT) {
            setCurrentStep(Step.SELECT_WORKSPACE_SITE);
        }
        else if (currentStep === Step.SELECT_WORKSPACE_SITE) {
            setFormValue(buildInitFormValue({ workspaceSite: selectedWorkspaceSite }));
            setCurrentStep(Step.ADD_SITE_DETAILS);
        }
        else {
            handleSubmitForm();
        }
    };
    const handleBack = () => {
        if (currentStep === Step.ADD_SITE_DETAILS) {
            setCurrentStep(Step.SELECT_WORKSPACE_SITE);
        }
        else if (currentStep === Step.SELECT_WORKSPACE_SITE) {
            setCurrentStep(Step.SELECT_ENVIRONMENT);
        }
    };
    const handleClose = () => {
        onClose();
    };
    const handleNext = (event) => {
        event.preventDefault();
        onNext();
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            onNext();
        }
    };
    const getContinueButtonDisabledState = () => {
        if (currentStep === Step.SELECT_ENVIRONMENT) {
            return !selectedEnvironmentId;
        }
        else if (currentStep === Step.SELECT_WORKSPACE_SITE) {
            return !selectedWorkspaceSite?.id;
        }
        else if (currentStep === Step.ADD_SITE_DETAILS) {
            if (!isEmpty(errors)) {
                return true;
            }
            if (isEditMode) {
                const preparedFromValue = mapValues(formValue, (value) => (typeof value === 'string' ? trim(value) : value));
                return isEqual(preparedFromValue, initFormValue);
            }
            return false;
        }
    };
    const continueButton = {
        label: currentStep === Step.ADD_SITE_DETAILS ? translate('Done') : translate('Continue'),
        onClick: handleNext,
        disabled: getContinueButtonDisabledState(),
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: handleClose,
    };
    const backButton = {
        label: translate('Back'),
        onClick: handleBack,
        disabled: currentStep === Step.SELECT_ENVIRONMENT,
    };
    const getFooterCenterContent = () => {
        if (!isEditMode) {
            return (_jsxs("div", { className: 'nucleus-inline-flex', children: [_jsxs("p", { className: 'pr-6', children: ["Step ", currentStep, " of ", TOTAL_STEPS] }), _jsx(StepIndicator, { totalSteps: TOTAL_STEPS, currentStep: currentStep })] }));
        }
    };
    return (_jsx(ModalWindow, { title: isEditMode ? translate('Edit site') : translate('Add Site to study'), subtitle: isEditMode ? environmentSite?.metadata.name : selectedWorkspaceSite?.name, isOpen: true, closeWindow: handleClose, footerPrimaryActionButton: continueButton, footerSecondaryActionButtons: isEditMode ? [cancelButton] : [cancelButton, backButton], footerCenterContent: getFooterCenterContent(), width: 'full', children: _jsxs("form", { className: 'mt-10 mb-10', autoComplete: 'off', onSubmit: handleNext, onKeyUp: handleEnterPress, children: [!isEditMode && currentStep === Step.SELECT_ENVIRONMENT && (_jsx(SelectEnvironmentStep, { value: selectedEnvironmentId, onChange: setSelectedEnvironmentId })), !isEditMode && currentStep === Step.SELECT_WORKSPACE_SITE && (_jsx(SelectWorkspaceSiteStep, { value: selectedWorkspaceSite, onSelect: setSelectedWorkspaceSite })), currentStep === Step.ADD_SITE_DETAILS && (_jsx(EditSiteDetailsStep, { value: formValue, errors: errors, getSelectValue: getSelectValue, getMultiselectValue: getMultiselectValue, onInputFieldChange: handleInputFieldChange, onInputFieldBlur: handleInputFieldBlur, onSelectFieldChange: handleSelectFieldChange, onMultiselectFieldChange: handleMultiselectFieldChange }))] }) }));
};
