import { jsx as _jsx } from "react/jsx-runtime";
import { Button, useLocalizeMessage, Text } from 'libs.nucleus';
import { FormattedDateTime } from 'libs.react';
import { DeploymentStatus } from '../deployment_status';
export const useStudyDeploymentsColumns = (showErrorDetails) => {
    const translate = useLocalizeMessage();
    return [
        {
            header: translate('Deployer'),
            cell: ({ row }) => (_jsx(Text, { size: 'sm', className: 'text-text-secondary font-normal', children: row.original.owner?.email })),
        },
        {
            header: translate('Date of deployment'),
            cell: ({ row }) => _jsx(FormattedDateTime, { date: row.original.createdAt }),
        },
        { header: translate('Version deployed'), accessorKey: 'studyConfigVersion' },
        {
            header: translate('Status'),
            cell: ({ row }) => _jsx(DeploymentStatus, { workflow: row.original }),
        },
        {
            id: 'view-details',
            cell: ({ row }) => {
                if (row.original.status === 'failed') {
                    return (_jsx(Button, { type: 'button', variant: 'transparent', label: translate('View details'), onClick: () => showErrorDetails(row.original) }));
                }
            },
        },
    ];
};
