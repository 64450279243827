import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { useStudyL10nMap } from 'libs.localization.react';
import { Locale, useLocalizeMessage, Link, InlineNotification } from 'libs.nucleus';
import { AssetLocaleVersionList, DashboardExportAction, DashboardImportAction } from '../../components';
import { MatrixFilter } from '../../components/locale_matrix/filter.component';
import { TranslationReport } from '../../components/translation_report/translation_report.component';
import { TranslationWorkbenchContext } from '../../contexts';
import { StudyAssetType } from '../../types';
import { TRANSLATION_MANAGEMENT_FLAGS } from '../../utils';
import { toSentenceCase } from '../../utils/string_utils';
export const StudyTranslationsDashboard = () => {
    const t = useLocalizeMessage();
    const { studyId } = useParams();
    const { isPending: isSettingStudyL10nMap, setStudyL10nMap } = useStudyL10nMap();
    const { logEvent } = useContext(FirebaseAppContext);
    const flagManager = useContext(FlagContext);
    const dashboardTranslationFlag = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.dashboardTranslationFlag);
    const { locales: allLocales, isStudyEnglishOnly, libraryAssets, resourcesAssets, studyAssets, notificationsAssets, studyLocalizationStatus, study, updateStudy, isAssetsDataLoading, } = useContext(TranslationWorkbenchContext);
    let showNoLocaleNotification = false;
    const locales = isStudyEnglishOnly ? [Locale.en_US] : allLocales;
    if (!isStudyEnglishOnly && locales.length === 1 && locales[0] === Locale.en_US) {
        showNoLocaleNotification = true;
    }
    const filterData = useMemo(() => [
        {
            label: t('Type'),
            accessorKey: 'type',
            disabled: false,
            size: 'w-[14rem]',
            multiple: true,
            options: Object.values(StudyAssetType)
                .filter((type) => type !== StudyAssetType.SITE_PARTICIPANT_RESOURCES)
                .map((type) => ({ label: toSentenceCase(type), value: type })),
        },
        {
            label: t('Locale(s)'),
            accessorKey: 'locale',
            size: 'w-[11rem]',
            options: locales
                .map((locale) => ({ label: locale, value: locale }))
                .filter((locale) => locale.value !== 'en-US'),
            multiple: true,
            disabled: locales.length === 1 && locales[0] === Locale.en_US,
        },
        {
            label: t('Status(es)'),
            accessorKey: 'status',
            size: 'w-[11rem]',
            disabled: false,
            options: Object.values(LocalizationStatus)
                .filter((status) => status !== LocalizationStatus.NOT_AVAILABLE)
                .map((status) => ({ label: toSentenceCase(status), value: status })),
            multiple: true,
        },
    ], [locales]);
    const initialFilters = filterData.reduce((acc, filter) => ({
        ...acc,
        [filter.accessorKey]: filter.multiple ? [] : 'all',
    }), {});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(initialFilters);
    const allDefaultAssets = useMemo(() => ({ ...libraryAssets, ...studyAssets, ...resourcesAssets, ...notificationsAssets }), [libraryAssets, studyAssets, resourcesAssets, notificationsAssets]);
    const filteredLocales = useMemo(() => {
        if (selectedFilters.locale.length === 0) {
            return locales;
        }
        return locales.filter((locale) => selectedFilters.locale?.includes(locale));
    }, [locales, selectedFilters.locale]);
    const filteredAssets = useMemo(() => {
        const temp = { ...allDefaultAssets };
        const filteredAssetsRows = Object.entries(temp).filter(([assetName, assetData]) => {
            return (Object.keys(selectedFilters).every((filterKey) => {
                const filterValue = selectedFilters[filterKey];
                if (filterValue === 'all') {
                    return true;
                }
                switch (filterKey) {
                    case 'type':
                        if ((filterValue.includes(StudyAssetType.PARTICIPANT_RESOURCES) &&
                            assetData.type === StudyAssetType.SITE_PARTICIPANT_RESOURCES) ||
                            (filterValue.includes(StudyAssetType.SITE_RESOURCES) &&
                                assetData.type === StudyAssetType.SITE_PARTICIPANT_RESOURCES)) {
                            return true;
                        }
                        return filterValue?.length ? filterValue.includes(assetData?.type || '') : true;
                    case 'status':
                        if (assetData.currentVersionStatus === undefined || filterValue?.length === 0) {
                            return true;
                        }
                        return Object.keys(assetData.currentVersionStatus)
                            .filter((key) => key !== Locale.en_US)
                            .some((key) => {
                            return (assetData.currentVersionStatus &&
                                filterValue.includes(assetData.currentVersionStatus[key].status));
                        });
                    default:
                        return true;
                }
            }) && assetName.toLowerCase().includes(searchTerm.toLowerCase()));
        });
        return Object.fromEntries([...filteredAssetsRows]);
    }, [searchTerm, selectedFilters, allDefaultAssets]);
    const resourcesForExport = useMemo(() => {
        return Object.keys(filteredAssets).reduce((acc, key) => {
            const asset = filteredAssets[key];
            if (asset.assetType !== AssetTypes.RESOURCES_PDF) {
                acc[key] = asset;
            }
            return acc;
        }, {});
    }, [filteredAssets]);
    const handleSearch = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, [setSearchTerm]);
    const handleFilter = useCallback((key, value) => {
        setSelectedFilters((prev) => ({ ...prev, [key]: value }));
        logEvent('translations_dashboard_filter', {
            studyId: study.id,
            filter: key,
            filterValue: Array.isArray(value) ? value.join(',') : value,
        });
    }, [setSelectedFilters]);
    const handleImport = useCallback((response) => {
        if (!studyId) {
            console.error(new Error('Internal error'));
            return;
        }
        // Get the successfully imported assets (skipping library activities)
        const assetLocaleVersionSet = response?.data?.success?.filter(({ id }) => !id?.startsWith('activities.'));
        if (!assetLocaleVersionSet || !assetLocaleVersionSet.length) {
            return;
        }
        const currentL10nMapId = study.l10nMapResourceId;
        setStudyL10nMap({ studyId: parseInt(studyId, 10), currentL10nMapId, assetLocaleVersionSet }, {
            onSuccess: (response) => {
                if (response?.data?.id) {
                    study.l10nMapResourceId = response.data.id;
                    updateStudy?.(study);
                }
            },
        });
    }, [studyId, study, updateStudy, setStudyL10nMap]);
    const isStudyinDraft = studyLocalizationStatus === StudyLocalizationStatus.DRAFT;
    const isActionDisabled = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const isImportActionDisabled = isStudyEnglishOnly || isActionDisabled;
    if (!studyId) {
        // No Study ID
        return null;
    }
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [showNoLocaleNotification && (_jsx(InlineNotification, { title: t('No locales configured'), subtitle: t('Please set required locales.'), action: _jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/build#locales`, children: t('Go to locales settings') }) })), isStudyEnglishOnly && (_jsx(InlineNotification, { title: t(`Study is ${Locale.en_US} only`), subtitle: t(`Study has been set to en-US only in Build Settings. Translation imports are not available. To export the study assets in en-US, you must lock the study.`), action: _jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/build#locales`, children: t('Go to locales settings') }) })), isStudyinDraft && (_jsx(InlineNotification, { title: t('Build unlocked'), subtitle: t('The build has been unlocked and study configuration is in progress. If strings change, it can invalidate translations.'), type: 'warning' })), _jsxs("div", { className: 'flex justify-between gap-4', children: [_jsx("div", { className: 'flex gap-4 items-end flex-wrap', children: _jsx(MatrixFilter, { filters: filterData, searchTerm: searchTerm, selectedFilters: selectedFilters, handleFilter: handleFilter, handleSearch: handleSearch }) }), _jsxs("div", { className: 'flex gap-4 items-end', children: [_jsx(DashboardImportAction, { isDisabled: isImportActionDisabled || isSettingStudyL10nMap, supportedLocales: locales, showTooltip: isImportActionDisabled, tooltipText: isStudyEnglishOnly
                                    ? t('This action is disabled when study is en-US only')
                                    : t('This action is disabled until the study configuration is completed and translation status is set to ready'), trackingContext: { context: 'study', id: study.id }, onImport: handleImport }), _jsx(DashboardExportAction, { assets: resourcesForExport, locales: locales, isDisabled: isActionDisabled, showTooltip: isActionDisabled, tooltipText: isStudyEnglishOnly
                                    ? t('This action is disabled when the study is unlocked.')
                                    : t('This action is disabled until the study configuration is completed and translation status is set to ready'), trackingContext: { context: 'study', id: study.id }, studyId: studyId, baseLocale: study.configuration.studyMetadata.baseLocale })] })] }), dashboardTranslationFlag && (_jsx(TranslationReport, { filteredAssets: filteredAssets, filteredLocales: filteredLocales })), _jsx(AssetLocaleVersionList, { data: filteredAssets, isLoading: isAssetsDataLoading, locales: filteredLocales })] }));
};
