export const DeviceOptions = {
    iphonex: {
        device: 'iPhone X',
        hasLandscape: false,
        width: 375,
        height: 812,
        scale: 1,
    },
    iphone8: {
        device: 'iPhone 8',
        hasLandscape: false,
        width: 375,
        height: 667,
        scale: 1,
    },
    iphonexr: {
        device: 'iPhone XR',
        hasLandscape: false,
        width: 414,
        height: 896,
        scale: 1,
    },
    iphonese: {
        device: 'iPhone SE (2nd Gen 2022)',
        hasLandscape: false,
        width: 375,
        height: 667,
        scale: 1,
    },
    galaxya11: {
        device: 'Samsung Galaxy A11',
        hasLandscape: false,
        width: 360,
        height: 780,
        scale: 1,
    },
    galaxya12: {
        device: 'Samsung Galaxy A12',
        hasLandscape: false,
        width: 360,
        height: 800,
        scale: 1,
    },
    galaxya13: {
        device: 'Samsung Galaxy A13',
        hasLandscape: false,
        width: 412,
        height: 915,
        scale: 1,
    },
    ipadmini: {
        device: 'iPad Mini',
        hasLandscape: false,
        width: 768,
        height: 1024,
        scale: 0.7,
    },
    ipad: {
        device: 'iPad',
        hasLandscape: false,
        width: 810,
        height: 1010,
        scale: 0.7,
    },
    desktopxl: {
        device: 'Desktop (1920x1080)',
        hasLandscape: true,
        width: 1920,
        height: 1080,
        scale: 0.6,
    },
    desktopl: {
        device: 'Desktop (1366x768)',
        hasLandscape: true,
        width: 1366,
        height: 768,
        scale: 0.6,
    },
};
