import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, AuthContext, LibraryEndpoint, useApiClient, useToastNotification, } from 'libs.react';
import { StudyConfigurationContext } from '../study_configuration';
export const StudyTestPlanContext = createContext({
    testPlans: [],
    setTestPlans: () => { },
    fetchTestPlan: async () => {
        return undefined;
    },
    fetchTestPlans: async () => { },
    createTestPlan: async () => {
        return undefined;
    },
    updateTestPlan: async () => {
        return undefined;
    },
    deleteTestPlan: () => { },
    executeTestPlan: () => { },
    deleteExecutionHistory: () => { },
    getTestPlanHistory: async () => { },
    testPlanHistory: {},
    fetchReportUrl: async () => {
        return '';
    },
});
export const StudyTestPlanProvider = ({ children }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const autobuildClient = useApiClient(ApiClientService.AUTOBUILD);
    const momClient = useApiClient(ApiClientService.MOM);
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const pollingRef = useRef([]);
    const [testPlans, setTestPlans] = useState([]);
    const [testPlanHistory, setTestPlanHistory] = useState({});
    const getCortexToken = async (envId) => {
        const { data } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
        return data.data;
    };
    const deleteTestPlan = async (testPlanId) => {
        const index = testPlans.findIndex((plan) => plan.id === testPlanId);
        const testPlan = { ...testPlans[index] };
        if (index !== -1) {
            setTestPlans((prev) => prev.filter((plan) => plan.id !== testPlanId));
        }
        try {
            const endpoint = `${LibraryEndpoint.GET_TEST_PLANS}/${testPlanId}`;
            await libClient.delete(endpoint);
            addNotification({
                type: 'success',
                title: 'Test plan deleted',
            });
        }
        catch (error) {
            addNotification({
                type: 'error',
                title: 'Error deleting test plan',
            });
            // Restore the test plan if the deletion fails
            setTestPlans((prev) => [...prev.slice(0, index), testPlan, ...prev.slice(index)]);
        }
    };
    const createFakeHistoryEntry = (testPlanId, sandboxId, packageData) => {
        const newTestPlanHistory = structuredClone(testPlanHistory);
        const fakeId = uuidv4();
        newTestPlanHistory[testPlanId] = [
            {
                fake: true,
                id: fakeId,
                name: 'test-plan-execution',
                studyId,
                metadata: {
                    testPlanId,
                    sandboxId,
                    package: packageData,
                    autobuildRequestId: '',
                },
                status: 'PROCESSING',
                version: 0,
                type: '',
                blobPath: undefined,
                active: false,
                libraryId: '',
                createdAt: '',
                updatedAt: '',
                data: {
                    error: undefined,
                    duration: undefined,
                },
                externalVersion: null,
                key: '',
                ownerId: 0,
                path: '',
                latest: false,
            },
            ...(newTestPlanHistory[testPlanId] || []),
        ];
        setTestPlanHistory({ ...newTestPlanHistory });
        return fakeId;
    };
    const addHistoryEntry = async ({ testPlanId, sandboxId, autobuildRequestId, package: currentPackage, fakeId, }) => {
        const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_REPORTS}`, {
            name: 'test-plan-execution',
            key: uuidv4(),
            studyId,
            metadata: {
                testPlanId,
                sandboxId,
                autobuildRequestId,
                package: currentPackage,
            },
            status: 'RUNNING',
        });
        const historyEntry = response.data;
        const newTestPlanHistory = structuredClone(testPlanHistory);
        newTestPlanHistory[testPlanId] = [historyEntry, ...(newTestPlanHistory[testPlanId] || [])].filter((history) => {
            if (fakeId && history.id === fakeId) {
                return false;
            }
            return true;
        });
        setTestPlanHistory({ ...newTestPlanHistory });
        return historyEntry;
    };
    const updateErrorHistoryEntry = async ({ testPlanId, historyEntryId, error, duration, }) => {
        const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`, {
            status: 'ERROR',
            data: {
                error,
                duration,
            },
        });
        setTestPlanHistory((prev) => ({
            ...prev,
            [testPlanId]: prev[testPlanId].map((history) => {
                if (history.id === historyEntryId) {
                    return response.data;
                }
                return history;
            }),
        }));
    };
    const uploadReport = async ({ testPlanId, historyEntryId, status, file, duration, error, }) => {
        const formData = new FormData();
        formData.append('file', file, `test-report-${historyEntryId}.pdf`);
        formData.append('status', status);
        formData.append('data', JSON.stringify({ duration, error }));
        const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        setTestPlanHistory((prev) => ({
            ...prev,
            [testPlanId]: prev[testPlanId].map((history) => {
                if (history.id === historyEntryId) {
                    return response.data;
                }
                return history;
            }),
        }));
    };
    const deleteExecutionHistory = async (historyEntryId) => {
        await libClient.delete(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`);
        const newHistory = {};
        for (const testPlanId of Object.keys(testPlanHistory)) {
            newHistory[testPlanId] = [...testPlanHistory[testPlanId]].filter((history) => history.id !== historyEntryId);
        }
        setTestPlanHistory(newHistory);
    };
    const generateTokenForTestPlan = async (sandboxId) => {
        return getCortexToken(sandboxId);
    };
    const executeTestPlan = async ({ testPlanId, csv, sandboxId, package: currentPackage, }) => {
        // create a new history entry
        let historyEntry = null;
        try {
            if (!sandboxId) {
                addNotification({ type: 'error', title: 'Sandbox not found' });
                return;
            }
            const token = await generateTokenForTestPlan(sandboxId);
            const blob = new Blob([csv], { type: 'text/csv' });
            const fakeId = createFakeHistoryEntry(testPlanId, sandboxId, currentPackage);
            // Create FormData object
            const formData = new FormData();
            formData.append('file', blob, 'test-plan.csv');
            formData.append('token', token);
            const { data: { requestId }, } = await autobuildClient.post('/testplan/execute/csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            historyEntry = await addHistoryEntry({
                testPlanId,
                sandboxId,
                autobuildRequestId: requestId,
                package: currentPackage,
                fakeId,
            });
            const job = await pollExecutionStatus(requestId, token);
            if (job.status === 'ERROR') {
                updateErrorHistoryEntry({
                    testPlanId: historyEntry.metadata.testPlanId,
                    historyEntryId: historyEntry.id,
                    error: job.error,
                    duration: job.duration,
                });
            }
            else {
                const file = await fetchAndSaveTestReport(requestId, token);
                await uploadReport({
                    testPlanId,
                    historyEntryId: historyEntry.id,
                    status: job.status,
                    file,
                    duration: job.duration,
                    error: job.error,
                });
            }
        }
        catch (e) {
            console.error(e);
            if (historyEntry) {
                updateErrorHistoryEntry({ testPlanId, historyEntryId: historyEntry.id, error: e.toString() });
            }
        }
    };
    // Function to check status(every 30s) until completed
    const pollExecutionStatus = async (requestId, token, pollInterval = 30 * 1000) => {
        return new Promise((resolve) => {
            pollingRef.current.push(requestId);
            const interval = setInterval(async () => {
                try {
                    const resp = await autobuildClient.post('/testplan/execute/csv/status', { requestId, token });
                    const { status } = resp.data;
                    if (status?.match(/completed|error/i)) {
                        clearInterval(interval); // Stop polling
                        pollingRef.current = pollingRef.current.filter((id) => id !== requestId);
                        if (!resp.data.testResults?.failed) {
                            resolve({ status: 'PASSED', duration: resp.data.testResults?.duration });
                        }
                        else {
                            resolve({
                                status: 'FAILED',
                                duration: resp.data.testResults?.duration,
                                error: resp.data.testResults?.error,
                            });
                        }
                    }
                }
                catch (error) {
                    clearInterval(interval);
                    pollingRef.current = pollingRef.current.filter((id) => id !== requestId);
                    resolve({ status: 'ERROR', error: error.toString() });
                }
            }, pollInterval);
        });
    };
    // Function to fetch and save the report
    const fetchAndSaveTestReport = async (requestId, token) => {
        const response = await autobuildClient.post('/testplan/execute/csv/report', { requestId, token }, { responseType: 'blob' });
        // Create a Blob from the response data
        const file = new Blob([response.data], { type: 'application/pdf' });
        // update file URL to history entry
        return file;
    };
    const checkDraftedTestPlan = async (history = []) => {
        const draftedTestPlan = history.filter((historyEntry) => historyEntry.status === 'RUNNING');
        if (draftedTestPlan && draftedTestPlan.length > 0) {
            for (const historyEntry of draftedTestPlan) {
                if (pollingRef.current.includes(historyEntry.metadata.autobuildRequestId) ||
                    !historyEntry.metadata.autobuildRequestId) {
                    continue;
                }
                try {
                    const token = await generateTokenForTestPlan(historyEntry.metadata.sandboxId);
                    const job = await pollExecutionStatus(historyEntry.metadata.autobuildRequestId, token);
                    if (job.status === 'ERROR') {
                        updateErrorHistoryEntry({
                            testPlanId: historyEntry.metadata.testPlanId,
                            historyEntryId: historyEntry.id,
                            error: job.error,
                            duration: job.duration,
                        });
                    }
                    else {
                        const file = await fetchAndSaveTestReport(historyEntry.metadata.autobuildRequestId, token);
                        await uploadReport({
                            testPlanId: historyEntry.metadata.testPlanId,
                            historyEntryId: historyEntry.id,
                            status: job.status,
                            file,
                            duration: job.duration,
                            error: job.error,
                        });
                    }
                }
                catch (error) {
                    console.error('Error checking drafted test plan', error);
                    updateErrorHistoryEntry({
                        testPlanId: historyEntry.metadata.testPlanId,
                        historyEntryId: historyEntry.id,
                        error: error.toString(),
                    });
                }
            }
        }
    };
    const getTestPlanHistory = async (testPlanId) => {
        try {
            let query = `${LibraryEndpoint.GET_TEST_REPORTS}?studyId=${studyId}`;
            if (testPlanId) {
                query += `&testPlanId=${testPlanId}`;
            }
            const { data: response } = await libClient.get(query);
            if (testPlanId) {
                const newTestPlanHistory = structuredClone(testPlanHistory);
                newTestPlanHistory[testPlanId] = response.data;
                setTestPlanHistory(newTestPlanHistory);
            }
            else {
                const history = response.data.reduce((acc, historyEntry) => {
                    if (!acc[historyEntry.metadata.testPlanId]) {
                        acc[historyEntry.metadata.testPlanId] = [];
                    }
                    acc[historyEntry.metadata.testPlanId].push(historyEntry);
                    return acc;
                }, {});
                setTestPlanHistory(history);
            }
            checkDraftedTestPlan(response.data);
        }
        catch (error) {
            console.error('Error fetching test plan history', error);
            addNotification({ type: 'error', title: 'Error fetching test plan history' });
        }
    };
    const fetchReportUrl = async (historyEntryId) => {
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}?withUrl=true`);
            return response.data.blobUrl;
        }
        catch (error) {
            console.error('Error fetching blob', error);
            addNotification({ type: 'error', title: 'Error fetching blob' });
            return '';
        }
    };
    const fetchTestPlans = async () => {
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_PLANS}?studyId=${studyId}`);
            setTestPlans(response.data);
        }
        catch (error) {
            console.error('Error fetching test plans', error);
            addNotification({ type: 'error', title: translate('Error fetching test plans') });
        }
    };
    const fetchTestPlan = async (testPlanId) => {
        const testPlan = testPlans ? testPlans.find((testPlan) => testPlan.id === testPlanId) : null;
        if (testPlan) {
            return Promise.resolve(testPlan);
        }
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_PLANS}/${testPlanId}`);
            return response.data;
        }
        catch (error) {
            console.error('Error fetching test plan', error);
            addNotification({ type: 'error', title: translate('Error fetching test plan') });
        }
    };
    const createTestPlan = async ({ name, description, version, csv, }) => {
        try {
            const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_PLANS}`, {
                name,
                key: uuidv4(),
                data: {
                    description,
                    csv,
                },
                metadata: {
                    name,
                    version,
                },
                studyId,
            });
            setTestPlans((prev) => [response.data, ...prev]);
            return response.data;
        }
        catch (error) {
            console.error('Error creating test plan', error);
            addNotification({ type: 'error', title: translate('Error creating test plan') });
        }
    };
    const updateTestPlan = async ({ testPlan, name, description, csv, version, status, errorSubtitle, }) => {
        try {
            if (!testPlan) {
                addNotification({ type: 'error', title: 'Test plan not found' });
                return;
            }
            let config;
            if (status) {
                config = {
                    status,
                };
            }
            else {
                config = {
                    name: name ?? testPlan.metadata.name,
                    data: {
                        ...testPlan.data,
                        description: description ?? testPlan.data.description,
                        csv: csv ?? testPlan.data.csv,
                    },
                    metadata: {
                        ...testPlan.metadata,
                        name: name ?? testPlan.metadata.name,
                        version: version ?? testPlan.metadata.version,
                    },
                    status: status ?? testPlan.status,
                };
            }
            const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_PLANS}/${testPlan.id}`, config);
            setTestPlans((prev) => prev.map((tp) => {
                if (tp.id === response.data.id) {
                    return response.data;
                }
                return tp;
            }));
            return response.data;
        }
        catch (error) {
            console.error('Error updating test plan', error);
            addNotification({ type: 'error', title: translate('Error updating test plan'), subtitle: errorSubtitle });
        }
    };
    return (_jsx(StudyTestPlanContext.Provider, { value: {
            testPlans,
            setTestPlans,
            fetchTestPlan,
            fetchTestPlans,
            createTestPlan,
            updateTestPlan,
            deleteTestPlan,
            executeTestPlan,
            getTestPlanHistory,
            testPlanHistory,
            fetchReportUrl,
            deleteExecutionHistory,
        }, children: children }));
};
