import { jsx as _jsx } from "react/jsx-runtime";
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useLocalizeMessage, Table } from 'libs.nucleus';
import { ApiClientService, AuthContext, DEFAULT_DEBOUNCE_TIME, DEFAULT_PAGE_SIZE, LibraryEndpoint, LibraryResourceStatus, useApiClient, } from 'libs.react';
import { useActivitiesTableColumns } from './activities_table_columns.hook';
export const ActivitiesTable = ({ onActivitiesChange, initialSelectedActivities, instrumentType, }) => {
    const translate = useLocalizeMessage();
    const activitiesColumns = useActivitiesTableColumns();
    const { entityId } = useContext(AuthContext);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [internalSearch, setInternalSearch] = useState('');
    const [search] = useDebounce(internalSearch, DEFAULT_DEBOUNCE_TIME);
    const initialSelectedIds = useMemo(() => initialSelectedActivities?.map((activity) => activity.id), []);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE });
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const getActivities = async () => {
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, {
            params: {
                entityId: String(entityId),
                limit: String(DEFAULT_PAGE_SIZE),
                page: String(pagination.pageIndex + 1),
                status: LibraryResourceStatus.PUBLISHED,
                ...(instrumentType && { instrument_type: instrumentType }),
                ...(search && { search }),
            },
        });
        return { activities: response.data, totalPages: Math.ceil(response.total / pagination.pageSize) };
    };
    const { data: { totalPages = 0, activities = [] } = {}, isFetching } = useQuery({
        queryKey: ['activities', { page: pagination.pageIndex + 1 }, search],
        queryFn: getActivities,
        placeholderData: keepPreviousData,
    });
    const onRowSelectionChange = useCallback((selectedRows) => {
        // we filter out any activity inside activities from selectedActivities
        const previousSelection = selectedActivities.filter((selectedActivity) => !activities.some((activityInPage) => activityInPage.id === selectedActivity.id));
        const activitiesOnPage = activities.filter((activity) => selectedRows.includes(activity.id));
        previousSelection.push(...activitiesOnPage);
        setSelectedActivities(previousSelection);
        onActivitiesChange(previousSelection);
    }, [activities, selectedActivities, onActivitiesChange]);
    return (_jsx("section", { "data-testid": 'activities-table-wrapper', children: _jsx(Table, { columns: activitiesColumns, data: activities, getRowId: 'id', hasPagination: true, initialPageSize: 10, initialSelectedIds: initialSelectedIds, isLoading: isFetching, isSelectable: true, labelNoResults: translate('No Activities'), labelSearchField: translate('Search...'), manualFiltering: true, manualPagination: true, manualPaginationState: pagination, onPaginationChange: setPagination, onRowSelectionChange: onRowSelectionChange, onSearch: (value) => setInternalSearch(value || ''), onSearchClear: () => setInternalSearch(''), pageCount: totalPages, paginationNavigationScreenReaderLabel: translate('Select activities navigation'), useInternalSearch: true }) }));
};
