import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const Row = ({ sortableProps, children, row }) => {
    const { isOver, active, transform, transition, setNodeRef, activeIndex, rect } = sortableProps;
    const style = {
        borderBottom: isOver && active ? '2px solid darkgray' : '',
    };
    const movingStyle = {
        position: 'fixed',
        backgroundColor: 'gray',
        opacity: 0.4,
        ...rect.current,
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (_jsxs("tr", { ref: setNodeRef, style: style, children: [children, activeIndex === row ? _jsx("div", { style: movingStyle }) : null] }));
};
export default Row;
export const enhance = (RowComponent) => {
    return function RowWrapper(props) {
        const sortableProps = useSortable({
            id: props.row,
        });
        return _jsx(RowComponent, { ...props, sortableProps: sortableProps });
    };
};
