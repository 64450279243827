import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, Dropdown, LocaleContext, useLocalizeMessage, useDialog, Table, Toggle, } from 'libs.nucleus';
import { ListInformation, SelectLocalesModal, getLocalesTableArray, useBooleanState, } from 'libs.react';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { EditLocaleModal } from './edit_locale_modal.component';
import { StudyConfigurationContext } from '../../../contexts';
const stringSortFn = (a, b) => a.code.localeCompare(b.code);
const getInitialLocales = (currentLocale, study) => {
    let locales = getLocalesTableArray(currentLocale);
    if (study.enforceBaseLocale) {
        locales = locales.filter((locale) => locale.code === 'en-US');
    }
    const studyLocales = locales.reduce((acc, locale) => {
        const type = study.participantLocales.includes(locale.code) && study.siteLocales.includes(locale.code)
            ? 'both'
            : study.participantLocales.includes(locale.code)
                ? 'participant'
                : undefined;
        if (type) {
            acc.push({ ...locale, type });
        }
        return acc;
    }, []);
    return studyLocales.sort(stringSortFn);
};
export const StudyLocales = () => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { currentStudy, isReadOnlyMode, assignStudy, studyId } = useContext(StudyConfigurationContext);
    const [isAddLocalesModalOpen, openAddLocalesModal, closeAddLocalesModal] = useBooleanState(false);
    const [isEditLocaleModalOpen, openEditLocaleModal, closeEditLocaleModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const [locales, setLocales] = useState([]);
    const dialog = useDialog();
    const [localeToEdit, setLocaleToEdit] = useState();
    useEffect(() => {
        setLocales(getInitialLocales(currentLocale, currentStudy));
    }, [currentStudy]);
    const handleOnAddLocales = (selectedLocales) => {
        const newLocales = [...locales, ...selectedLocales];
        setLocales(newLocales.sort(stringSortFn));
        const siteLocales = [];
        const participantLocales = [];
        newLocales.forEach((locale) => {
            switch (locale.type) {
                case 'participant':
                    participantLocales.push(locale.code);
                    break;
                case 'both':
                    siteLocales.push(locale.code);
                    participantLocales.push(locale.code);
                    break;
            }
        });
        currentStudy.participantLocales = participantLocales;
        currentStudy.siteLocales = siteLocales;
        assignStudy(currentStudy);
        logEvent('locales_added', { study_id: studyId, locales: newLocales.map((locale) => locale.code).join(', ') });
        closeAddLocalesModal();
    };
    const localesColumns = useMemo(() => {
        return [
            { header: translate('Code'), accessorKey: 'code' },
            { header: translate('Language/Script'), accessorKey: 'language' },
            { header: translate('Country/Region'), accessorKey: 'region' },
            {
                header: translate('Availability'),
                accessorFn: (locale) => locale.type === 'participant' ? translate('Participant') : translate('Site and Participant'),
            },
            {
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    const dropdownItems = [
                        {
                            id: 'edit',
                            content: translate('Edit'),
                            onClick: () => handleOpenEditLocaleModal(row.original),
                            disabled: isReadOnlyMode,
                        },
                        {
                            id: 'delete',
                            content: translate('Delete'),
                            onClick: () => onDeleteLocale(row.original),
                            destructive: true,
                            disabled: row.original.code === 'en-US' || isReadOnlyMode,
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            },
        ];
    }, [locales]);
    const onDeleteLocale = async (locale) => {
        // @TODO check the status of the locale in the study.
        // if status === LocaleStatus.NOT_STARTED, delete the locale without confirmation
        // if status !== LocaleStatus.NOT_STARTED, show a confirmation dialog
        const ok = await dialog.open({
            title: translate('Remove locale'),
            body: (_jsx("div", { className: 'min-h-[300px]', children: translate(`The ${locale.code} locale has been used for translation in this study. This locale will be removed from displaying in translation workflows. Progress will be archived and disabled.`) })),
            actionButtons: [{ isDestructive: true, label: translate('Remove') }, { label: translate('Cancel') }],
        });
        if (ok) {
            removeLocale(locale);
            logEvent('locale_removed', { study_id: studyId, locale: locale.code });
        }
    };
    const removeLocale = (localeToRemove) => {
        const newLocales = locales.filter((locale) => locale.code !== localeToRemove.code).map((locale) => ({ ...locale }));
        setLocales(newLocales.sort(stringSortFn));
        const { participantLocales, siteLocales } = currentStudy;
        switch (localeToRemove.type) {
            case 'participant':
                participantLocales.splice(participantLocales.indexOf(localeToRemove.code), 1);
                break;
            case 'both':
                siteLocales.splice(siteLocales.indexOf(localeToRemove.code), 1);
                participantLocales.splice(participantLocales.indexOf(localeToRemove.code), 1);
                break;
        }
        currentStudy.participantLocales = participantLocales;
        currentStudy.siteLocales = siteLocales;
        assignStudy(currentStudy);
    };
    const handleOpenEditLocaleModal = (locale) => {
        setLocaleToEdit(locale);
        openEditLocaleModal();
    };
    const handleCloseEditLocaleModal = () => {
        setLocaleToEdit(undefined);
        closeEditLocaleModal();
    };
    const handleOnEditLocale = (localeType) => {
        const newLocales = locales.map((locale) => {
            if (locale.code === localeToEdit?.code) {
                return { ...locale, type: localeType };
            }
            return locale;
        });
        setLocales(newLocales);
        const { participantLocales, siteLocales } = currentStudy;
        switch (localeType) {
            case 'participant':
                if (localeToEdit.type !== 'both') {
                    participantLocales.push(localeToEdit.code);
                }
                siteLocales.splice(siteLocales.indexOf(localeToEdit.code), 1);
                break;
            case 'both':
                if (localeToEdit.type === 'participant') {
                    siteLocales.push(localeToEdit.code);
                }
                break;
        }
        currentStudy.participantLocales = participantLocales;
        currentStudy.siteLocales = siteLocales;
        assignStudy(currentStudy);
        logEvent('locale_updated', { study_id: studyId, locale: localeToEdit.code, type: localeType });
        closeEditLocaleModal();
        setLocaleToEdit(undefined);
    };
    const handleEnglishOnlyToggle = (value) => {
        currentStudy.enforceBaseLocale = value;
        assignStudy(currentStudy);
        setLocales(getInitialLocales(currentLocale, currentStudy));
        logEvent('enforce_US_locale_only_toggled', { study_id: studyId, us_locale_only: value });
    };
    const content = useMemo(() => {
        return [
            {
                title: translate('Set study to en-US only?'),
                description: translate('Enable this setting to disable translation services and utilize this study for en-US users only.'),
                actionContent: (_jsx(Toggle, { checked: currentStudy.enforceBaseLocale, dataTestId: 'english-only-toggle', disabled: isReadOnlyMode, onChange: handleEnglishOnlyToggle })),
            },
        ];
    }, [currentStudy.enforceBaseLocale, isReadOnlyMode, handleEnglishOnlyToggle]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-6', children: [isReadOnlyMode && _jsx(TranslationStartedNotification, {}), _jsx(ListInformation, { items: content, descriptionColor: 'secondary', dataTestId: 'study-notification-en-us-only' }), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Select locales'), onClick: openAddLocalesModal, disabled: currentStudy.enforceBaseLocale || isReadOnlyMode }) }), _jsx(Table, { data: locales, columns: localesColumns, labelNoResults: translate('No locales'), getRowId: 'code', hasPagination: true, paginationNavigationScreenReaderLabel: translate('Site locale table navigation'), initialPageSize: 10 }), isAddLocalesModalOpen && (_jsx(SelectLocalesModal, { hideLocales: locales.map((locale) => locale.code), onAddLocales: handleOnAddLocales, onClose: closeAddLocalesModal, showLocaleTypeSelectStep: true, subtitle: currentStudy.name })), isEditLocaleModalOpen && (_jsx(EditLocaleModal, { onSave: handleOnEditLocale, onClose: handleCloseEditLocaleModal, initialLocaleType: localeToEdit?.type ?? 'both' }))] }) }));
};
