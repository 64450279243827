import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LocalizationStatus, LocalizationOrigin } from 'libs.localization.common';
import { Pill, Tooltip, Icon } from 'libs.nucleus';
import { toSentenceCase } from '../../utils/string_utils';
const statusColorMap = {
    [LocalizationStatus.READY]: 'greenPale',
    [LocalizationStatus.IN_PROGRESS]: 'orangePale',
    [LocalizationStatus.IN_REVIEW]: 'yellowPale',
    [LocalizationStatus.NOT_STARTED]: 'redPale',
};
export var AssetVersionLoadingState;
(function (AssetVersionLoadingState) {
    AssetVersionLoadingState["LOADING"] = "loading";
    AssetVersionLoadingState["DONE"] = "done";
    AssetVersionLoadingState["AI_TRANSLATING"] = "ai_translating";
    AssetVersionLoadingState["AI_MIGRATING"] = "ai_migrating";
})(AssetVersionLoadingState || (AssetVersionLoadingState = {}));
export const AssetVersion = ({ currentVersion, loadingState }) => {
    const isVersionAvailable = Boolean(currentVersion && (currentVersion.status || (currentVersion.version && currentVersion.status)));
    // Default to loading state if no version is available
    const isAssetLoading = (loadingState &&
        [
            AssetVersionLoadingState.LOADING,
            AssetVersionLoadingState.AI_TRANSLATING,
            AssetVersionLoadingState.AI_MIGRATING,
        ].includes(loadingState)) ||
        !isVersionAvailable;
    if (isAssetLoading) {
        if (loadingState === AssetVersionLoadingState.AI_TRANSLATING ||
            loadingState === AssetVersionLoadingState.AI_MIGRATING) {
            return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Icon, { name: 'animatedSparkles', color: 'primary' }) }));
        }
        return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Pill, { label: '', backgroundColor: 'gray' }) }));
    }
    if (currentVersion?.status === LocalizationStatus.NOT_AVAILABLE) {
        return '-';
    }
    if (!currentVersion) {
        return null;
    }
    const { status, version, origin = LocalizationOrigin.USER } = currentVersion;
    const showAIBadge = status !== LocalizationStatus.READY &&
        (origin === LocalizationOrigin.AI_TRANSLATION || origin === LocalizationOrigin.AI_MIGRATION);
    return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center', children: _jsx("div", { className: 'relative flex', children: _jsxs(Tooltip, { placement: 'top', title: `${version > 0 ? `v${version}` : 'No version'}, ${toSentenceCase(status)}`, children: [_jsx(Pill, { label: version > 0 ? `v${version}` : '', backgroundColor: statusColorMap[status], "data-testid": `locale-status-icon-${status.replace(' ', '-').toLowerCase()}` }), showAIBadge && (_jsx("div", { className: 'absolute -end-2.5 -top-2.5 size-5 border border-neutral-300 rounded-full grid place-content-center bg-white z-10', children: _jsx(Icon, { name: 'sparklesSolid', color: 'primary', size: 'xs' }) }))] }) }) }));
};
