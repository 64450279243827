import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus';
import { SOAGraphNodeType } from '../../types';
import { EnhancedMatrixRow, EnhancedMatrix, } from '../enhanced_matrix';
import { UserIcon, BuildingIcon } from '../svg';
const TASK_BG_MAP = {
    participant: 'bg-gradient-to-r from-purple-400 via-purple-400 to-purple-400/70 hover:from-purple-300 hover:via-purple-300 hover:to-purple-300/70',
    site: 'bg-gradient-to-r from-[#47B670] via-[#47B670] to-[#47B670]/70 hover:from-[#6BC08A] hover:via-[#6BC08A] hover:to-[#6BC08A]/70',
    disabled: 'bg-gray-200',
    success: 'bg-green-100',
};
export const SOATimelineMatrix = ({ headerLabels, sections, tasks, isOnboarding, completeTask }) => {
    const translate = useLocalizeMessage();
    const initialCell = { content: '', key: 'activityName', width: 280 };
    const headers = [
        initialCell,
        ...headerLabels.map((label) => ({ content: label, key: label })),
    ];
    const getActivityIcon = (type) => {
        switch (type) {
            case 'participant':
                return _jsx(UserIcon, { className: 'text-purple-500' });
            case 'site':
            default:
                return _jsx(BuildingIcon, { className: 'text-green-500' });
        }
    };
    const MatrixRow = ({ item: activity, dataTestId, rowIndex, headers, getCellStyles, defaultCellWidth, }) => {
        const taskNodes = activity.assignmentIds
            .map((id) => tasks[id])
            .filter((task) => task)
            .reduce((acc, curr) => {
            if (curr.visitName) {
                acc[curr.visitName] = [...(acc[curr.visitName] || []), curr];
            }
            else if (isOnboarding) {
                acc.Onboarding = [...(acc.Onboarding || []), curr];
            }
            return acc;
        }, {});
        const isNonVisitActivity = Object.keys(taskNodes).length === 0;
        const totalWidth = headers.slice(1).reduce((acc, curr) => acc + (curr.width || defaultCellWidth), 0);
        const getTaskCell = (index, task, header) => {
            const isUnavailable = task?.status === SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE;
            const isAvailable = task?.status === SOAGraphNodeType.ASSIGNMENT_AVAILABLE;
            const isCompleted = task?.status === SOAGraphNodeType.ASSIGNMENT_COMPLETE;
            const type = isCompleted ? 'success' : isUnavailable ? 'disabled' : task.type;
            return (_jsx("div", { className: getCellStyles(index, { isEmpty: header.isEmpty }), style: { width: header.width }, children: _jsxs("div", { className: `flex flex-row w-full items-center justify-start rounded m-1 p-4 relative group ${TASK_BG_MAP[type]}`, children: [isAvailable && (_jsx("button", { onClick: () => completeTask(task.id), className: 'h-full bg-transparent absolute inset-0 p-4 rounded flex flex-row items-center justify-start', children: translate('Complete') })), isCompleted && _jsx("span", { className: 'text-xs text-green-900', children: translate('Success') })] }) }, `timeline-${task.id}-cell-${header.key}`));
        };
        const getNonVisitActivityCells = () => {
            const taskNodes = activity.assignmentIds.map((id) => tasks[id]).filter((task) => task);
            if (taskNodes.length === 0) {
                return (_jsx("div", { className: getCellStyles(1), style: { width: totalWidth } }, `timeline-empty-cell-nonVisitActivity`));
            }
            return getTaskCell(1, taskNodes[0], {
                key: 'nonVisitActivity',
                width: totalWidth,
            });
        };
        return (_jsxs(EnhancedMatrixRow, { dataTestId: dataTestId, rowIndex: rowIndex, children: [_jsx("div", { className: getCellStyles(0, { isEmpty: headers[0].isEmpty }), style: {
                        width: headers[0].width,
                    }, children: _jsxs("div", { className: 'sticky left-0 flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', "data-testid": 'schedule-timeline-activity-name-cell-container', children: [getActivityIcon(activity.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-row-name', title: activity.activityName, children: activity.activityName })] }) }, `timeline-activityName`), isNonVisitActivity
                    ? getNonVisitActivityCells()
                    : headers.slice(1).map((header, index) => {
                        const tasks = taskNodes[header.key];
                        if (!tasks || tasks.length === 0) {
                            return (_jsx("div", { className: getCellStyles(index + 1, { isEmpty: header.isEmpty }), style: { width: header.width } }, `timeline-empty-cell-${header.key}`));
                        }
                        const task = tasks[0];
                        return getTaskCell(index + 1, task, header);
                    })] }));
    };
    return (_jsx(EnhancedMatrix, { headers: headers, sections: sections.map((section) => ({
            ...section,
            RowComponent: MatrixRow,
        })), stickyColumn: true }));
};
