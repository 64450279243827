export const ECOA_BUILDER_FLAGS = {
    generativeAI: {
        id: 'generativeAI',
        value: false,
        description: 'Enables the ability to use AI to generate an activity',
        owner: 'eCOA Builder',
        name: 'Generative Activity (genCOA)',
        extra: { section: 'eCOA Builder' },
    },
};
