import { AssetRole } from 'libs.localization.common';
import { mapStudyAssetToRole } from './data_utils';
import { getDeviceList } from './device_list';
export function getDevicelistForAssetType(assetType) {
    const role = mapStudyAssetToRole(assetType);
    if (role === AssetRole.SITE) {
        return getDeviceList('large');
    }
    return getDeviceList();
}
