import { AssetTypes } from 'libs.localization.common';
export const getSiteVisitScheduleAsset = (study) => ({
    type: AssetTypes.SITE_VISIT_SCHEDULE,
    id: study.id,
    version: '1',
    messages: [
        {
            namespace: 'site_visit_schedule',
            messages: Object.keys(study.timelineInfo).reduce((acc, timelineId) => ({ ...acc, [timelineId]: study.timelineInfo[timelineId].name }), {}),
        },
    ],
    preview: {
        engine: 'react',
        id: AssetTypes.SITE_VISIT_SCHEDULE,
        default_viewport: 'iPadLandscape',
        info: {
            display_name: 'Visit schedule',
            list: [],
        },
    },
});
