export const TRANSLATION_MANAGEMENT_FLAGS = {
    instantMigrationFlag: {
        id: 'instantMigration',
        value: false,
        description: 'Enables instant migration of eCOA paper translation using AI',
        owner: 'Translation Team',
        name: 'Instant eCOA Migration (AI)',
        extra: { section: 'Translation' },
    },
    aiTranslationFlag: {
        id: 'aiTranslation',
        value: false,
        description: 'Enables instant translation using generative AI',
        owner: 'Translation Team',
        name: 'Generate Translation (AI)',
        extra: { section: 'Translation' },
    },
    dashboardTranslationFlag: {
        id: 'dashboardTranslationFlag',
        value: false,
        description: 'Display study translation dashboard',
        owner: 'Translation Team',
        name: 'Study translation dashboard',
        extra: { section: 'Translation' },
    },
    aiStudyTranslationFlag: {
        id: 'aiStudyTranslationFlag',
        value: false,
        description: 'Display "Automated candidates" section on the translation dashboard',
        owner: 'Translation Team',
        name: 'AI Study Translation',
        extra: { section: 'Translation' },
    },
    targetedAiStudyTranslation: {
        id: 'targetedAiStudyTranslation',
        value: false,
        description: 'Enables targeted AI study translation',
        owner: 'Translation Team',
        name: 'Targeted AI study translation',
        extra: { section: 'Translation' },
    },
};
