import { useContext } from 'react';
import { FlagContext } from 'libs.flags.react';
import { LocalizationStatus, AssetTypes } from 'libs.localization.common';
import { Locale, useLocalizeMessage } from 'libs.nucleus';
import { AUTOMATED_CANDIDATE_TYPES, NO_RESULTS } from './translation_report.constants';
import { TRANSLATION_MANAGEMENT_FLAGS } from '../../utils';
const filterStatuses = (filteredAssets, filteredLocales) => {
    const result = {};
    Object.entries(filteredAssets).forEach(([assetName, item]) => {
        const { currentVersionStatus } = item;
        if (!currentVersionStatus) {
            return;
        }
        Object.entries(currentVersionStatus).forEach(([language, versionStatus]) => {
            if (language === Locale.en_US ||
                !filteredLocales.includes(language) ||
                versionStatus.status === LocalizationStatus.NOT_AVAILABLE) {
                return;
            }
            const { status } = versionStatus;
            if (Object.values(LocalizationStatus).includes(status)) {
                if (!result[status]) {
                    result[status] = {};
                }
                const activityName = assetName;
                result[status][activityName] = (result[status][activityName] || 0) + 1;
            }
        });
    });
    return result;
};
export const useStatusData = (filteredAssets, filteredLocales) => {
    const t = useLocalizeMessage();
    let hasNoResults = false;
    const statuses = {
        [LocalizationStatus.NOT_STARTED]: { count: 0, color: '#FECACA', label: t('Not started') },
        [LocalizationStatus.IN_PROGRESS]: { count: 0, color: '#FED7AA', label: t('In progress') },
        [LocalizationStatus.IN_REVIEW]: { count: 0, color: '#FEF08A', label: t('In review') },
        [LocalizationStatus.READY]: { count: 0, color: '#BBF7D0', label: t('Ready') },
    };
    if (!Object.keys(filteredAssets).length || !filteredLocales.filter((locale) => locale !== Locale.en_US).length) {
        hasNoResults = true;
        return {
            data: [1],
            backgroundColor: ['rgba(209,213,219,0.5)'],
            labels: [t(NO_RESULTS)],
            sum: 0,
            statuses,
            hasNoResults,
        };
    }
    const result = filterStatuses(filteredAssets, filteredLocales);
    Object.keys(result).forEach((status) => {
        if (status in statuses) {
            statuses[status].count = Object.values(result[status]).reduce((sum, count) => sum + count, 0);
        }
    });
    const data = [];
    const backgroundColor = [];
    const labels = [];
    Object.values(statuses).forEach(({ count, color, label }) => {
        data.push(count);
        backgroundColor.push(color);
        labels.push(label);
    });
    const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return { data, backgroundColor, labels, sum, statuses, hasNoResults };
};
export const useAssetsTranslateWithAI = (filteredAssets) => {
    const flagManager = useContext(FlagContext);
    const targetedAiStudyTranslation = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.targetedAiStudyTranslation);
    const assetsTranslateWithAI = Object.keys(filteredAssets).reduce((acc, key) => {
        const asset = filteredAssets[key];
        const shouldInclude = targetedAiStudyTranslation
            ? AUTOMATED_CANDIDATE_TYPES.includes(asset.assetType)
            : asset.assetType !== AssetTypes.RESOURCES_PDF;
        if (shouldInclude) {
            acc[key] = asset;
        }
        return acc;
    }, {});
    return { assetsTranslateWithAI };
};
