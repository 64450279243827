import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EnvironmentsContext } from '../../contexts/environments';
export const useCurrentEnvironment = (overrideStudyId) => {
    const { studyId } = useParams();
    const studyIdToUse = overrideStudyId || studyId;
    const { environments } = useContext(EnvironmentsContext);
    const environment = environments?.find((env) => +env.id === +studyIdToUse);
    return {
        environment,
    };
};
