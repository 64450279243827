import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useSpreadsheetSelector } from '../../../contexts/spreadsheet';
import { StudyTestPlanExecutionModes, StudyTestPlanStepType } from '../../../types';
import { convertTestPlanContextToObject } from '../../../utils';
import { SpreadsheetStyles } from '../../spreadsheet';
const TestPlanUserInputEditor = ({ showUserInputModal, ...props }) => {
    const { row: rowNumber } = props;
    const row = useSpreadsheetSelector((state) => state.data[rowNumber]);
    const config = useMemo(() => {
        const stepType = row[0]?.value;
        const executionMode = row[3]?.value;
        if (!stepType) {
            return undefined;
        }
        if (![StudyTestPlanStepType.CompleteParticipantTask, StudyTestPlanStepType.CompleteSiteTask].includes(stepType)) {
            return undefined;
        }
        const context = row[2]?.value;
        if (!context) {
            return undefined;
        }
        const obj = convertTestPlanContextToObject(context);
        const taskName = obj.TaskName;
        if (!taskName) {
            return undefined;
        }
        if (!executionMode || executionMode === StudyTestPlanExecutionModes.BACKEND) {
            return undefined;
        }
        return {
            stepType,
            taskName,
        };
    }, [row]);
    useEffect(() => {
        if (config) {
            showUserInputModal({
                ...props,
                ...config,
            });
        }
    }, [config]);
    return (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth, {
            [SpreadsheetStyles.cellReadOnly]: !config,
        }) }));
};
export default TestPlanUserInputEditor;
