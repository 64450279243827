import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LibraryActivityInstrumentType } from 'libs.react';
import { ScheduledRemoteActivityTypeSelection } from './scheduled_remote_activity_type_selection.component';
import { ActivitiesTable } from '../../activities_table';
import { ActivityDependencies } from '../../schedule_timeline_scheduled_activities/activity_dependencies';
export const ScheduleRemoteActivity = ({ step, onFormChange, onTypeSelect, scheduleRemoteActivityForm, activityDependencyUtils, onPristineChange, showSpecificStartTime, onSpecificStartTimeChange, }) => {
    const onActivitiesChange = (selectedActivities) => {
        onFormChange({ selectedActivities });
    };
    return (_jsxs(_Fragment, { children: [step === 2 && (_jsx(ScheduledRemoteActivityTypeSelection, { onSelect: onTypeSelect, selectedType: scheduleRemoteActivityForm.type })), step === 3 && (_jsx(ActivitiesTable, { initialSelectedActivities: scheduleRemoteActivityForm.selectedActivities, instrumentType: scheduleRemoteActivityForm.type !== 'adhoc' ? LibraryActivityInstrumentType.PRO : undefined, onActivitiesChange: onActivitiesChange })), step === 4 && (_jsx("form", { className: 'flex min-h-[16rem] flex-col gap-4', autoComplete: 'off', children: _jsx(ActivityDependencies, { remoteType: scheduleRemoteActivityForm.type, activityDependencyUtils: activityDependencyUtils, onPristineChange: onPristineChange, isRemoteActivity: true, showSpecificStartTime: showSpecificStartTime, onShowSpecificStartTimeChange: onSpecificStartTimeChange }) }))] }));
};
