var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, ListInformation, PageHeader, PageWrapper, useApiClient, } from 'libs.react';
// This code safely retrieves environment variables CI_COMMIT_SHORT_SHA and BUILD_DATE.
// It's structured this way because 'process' and 'process.env' are not globally
// available in all environments, but the specific env variables are.
// The try-catch ensures it works even if accessing process.env throws an error.
// This approach eliminates the need for local .env file configuration.
let CI_COMMIT_SHORT_SHA;
let BUILD_DATE;
let CI_COMMIT_TAG;
try {
    CI_COMMIT_TAG = (_a = process.env.CI_COMMIT_TAG) !== null && _a !== void 0 ? _a : '';
    CI_COMMIT_SHORT_SHA = (_b = process.env.CI_COMMIT_SHORT_SHA) !== null && _b !== void 0 ? _b : 'local';
    BUILD_DATE = (_c = process.env.BUILD_DATE) !== null && _c !== void 0 ? _c : 'current';
}
catch (e) {
    CI_COMMIT_TAG = '';
    CI_COMMIT_SHORT_SHA = 'local';
    BUILD_DATE = 'current';
}
export const BuildInfo = () => {
    const translate = useLocalizeMessage();
    const iamClient = useApiClient(ApiClientService.IAM);
    const momClient = useApiClient(ApiClientService.MOM);
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const [versions, setVersions] = useState({ iam: '', mom: '', library: '' });
    useEffect(() => {
        getVersions();
    }, []);
    const getVersions = () => __awaiter(void 0, void 0, void 0, function* () {
        const [{ data: iamResponse }, { data: momResponse }, { data: libraryResponse }] = yield Promise.all([
            iamClient.get('/version'),
            momClient.get('/version'),
            libraryClient.get('/version'),
        ]);
        setVersions({
            iam: iamResponse.result.version,
            library: libraryResponse.result.version,
            mom: momResponse.result.version,
        });
    });
    const listItems = [
        { title: translate('Studio App'), description: `${CI_COMMIT_TAG || CI_COMMIT_SHORT_SHA} (${BUILD_DATE})` },
        { title: translate('IAM'), description: versions.iam },
        { title: translate('MOM'), description: versions.mom },
        { title: translate('Library'), description: versions.library },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Studio versions') }), _jsx(ListInformation, { items: listItems })] }));
};
