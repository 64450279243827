export const isValidResource = (resource) => {
    return resource && (resource === '*' || (resource.includes('/') && resource.split('/').length % 2 === 0));
};
const validateInput = (scopes, permission, resource) => {
    if (!scopes) {
        throw new TypeError('Invalid scopes');
    }
    if (!permission) {
        throw new TypeError(`Invalid permission"`);
    }
    if (resource && resource !== true && !isValidResource(resource)) {
        throw new TypeError(`Invalid resource "${resource}"`);
    }
};
/**
 *
 * @param scopes The scopes to validate against.
 * @param permission The permission to check.
 * @param resource The resource being accessed.
 * @param anyResource Used to validate for any resource for the given permission
 * @returns boolean indicating whether the identity has the permission on the resource.
 */
export const checkScopes = ({ scopes, permission, resource, anyResource }) => {
    validateInput(scopes, permission, anyResource || resource);
    const resourcesForPermission = scopes[permission];
    if (!resourcesForPermission || resourcesForPermission.length === 0) {
        return false;
    }
    if (anyResource) {
        return true;
    }
    return resourcesForPermission.some((allowedResource) => {
        let newAllowedResource = allowedResource;
        if (newAllowedResource === '*') {
            return true;
        }
        // remove trailing wildcard if present
        if (newAllowedResource.endsWith('/*')) {
            newAllowedResource = newAllowedResource.slice(0, -2);
        }
        const resourceParts = resource.split('/');
        const allowedParts = newAllowedResource.split('/');
        // Check for downward propagation (allowed resource is a parent of or equal to the resource)
        // e.g. resource = 'entity/123/project/xyz/environment/abc'
        //      allowedResource = 'entity/123'
        return resourceParts.slice(0, allowedParts.length).join('/') === newAllowedResource;
    });
};
