import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus';
import { useToastNotification, useApiClient, ApiClientService, LibraryEndpoint, } from 'libs.react';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
import { sanitizeStudy, sanitizeTestPlanCSV } from '../../utils';
export const useTestPlanDetails = () => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { testPlanId } = useParams();
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const { studyId } = useContext(StudyConfigurationContext);
    const { fetchTestPlan } = useContext(StudyTestPlanContext);
    const [testPlan, setTestPlan] = useState(undefined);
    const [study, setStudy] = useState(undefined);
    const [ecoaActivities, setEcoaActivities] = useState([]);
    const fetchStudy = async (newTestPlan) => {
        if (!newTestPlan.metadata.version.id) {
            addNotification({
                title: translate('Error'),
                subtitle: translate('This test plan was created without a version'),
                type: 'error',
            });
            return;
        }
        const { data: studyConfigResponse } = await libClient.get(LibraryEndpoint.GET_STUDY_CONFIGS, {
            params: { id: newTestPlan.metadata.version.id },
        });
        const study = sanitizeStudy(studyConfigResponse.data[0].data);
        const activities = study.getAllActivities().filter((activity) => activity.libraryId);
        const { data: activitesResponse } = await libClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, {
            params: { id: activities.map((activity) => activity.libraryId) },
        });
        const sanitizedTestPlanCSV = sanitizeTestPlanCSV(newTestPlan.data.csv, study, activitesResponse.data);
        setTestPlan({
            ...newTestPlan,
            data: {
                ...newTestPlan.data,
                csv: sanitizedTestPlanCSV,
            },
        });
        setStudy(study);
        setEcoaActivities(activitesResponse.data);
    };
    const retrieveTestPlan = async (planId) => {
        const newTestPlan = await fetchTestPlan(planId);
        if (newTestPlan) {
            fetchStudy(newTestPlan);
        }
        else {
            navigate(`/studies/${studyId}/test-plans`);
        }
    };
    useEffect(() => {
        if (testPlanId && !testPlan) {
            retrieveTestPlan(testPlanId);
        }
    }, [testPlanId]);
    return { studyId, testPlan, setTestPlan, study, ecoaActivities };
};
