import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { StudySummaryWorkflowMatrix } from './study_summary_workflow_matrix.component';
import { isVisitConfirmation } from '../../utils';
export const StudySummaryWorkflow = ({ studyData: nodes }) => {
    const translate = useLocalizeMessage();
    const timelines = useMemo(() => {
        const timelines = {
            Onboarding: {
                visits: new Set(),
                groupOrder: {},
                visitActivities: {},
                nonVisitActivities: {},
            },
        };
        Object.values(nodes).forEach((node) => {
            let { taskName: activityName, visitName, visitSchedule: timeline = 'Onboarding', type, assignmentId, sequence: order, groupSequence: groupOrder, } = node;
            let activityId = activityName.toLowerCase().replace(/ /g, '-');
            if (!timelines[timeline]) {
                timelines[timeline] = {
                    visits: new Set(),
                    groupOrder: {},
                    visitActivities: {},
                    nonVisitActivities: {},
                };
            }
            if (isVisitConfirmation(activityName)) {
                activityName = 'Visit Confirmation';
                activityId = 'visit-confirmation';
                order = -1;
            }
            if (visitName) {
                timelines[timeline].visits.add(visitName);
                timelines[timeline].groupOrder[visitName] = groupOrder;
                timelines[timeline].visitActivities[activityId] = {
                    activityName,
                    type,
                    order,
                    assignmentIds: [...(timelines[timeline].visitActivities[activityId]?.assignmentIds || []), assignmentId],
                };
            }
            else {
                timelines[timeline].nonVisitActivities[assignmentId] = {
                    activityName,
                    type,
                    order,
                    assignmentIds: [...(timelines[timeline].nonVisitActivities[assignmentId]?.assignmentIds || []), assignmentId],
                };
            }
        });
        const sortedTimelines = {};
        const mapActivities = (activities) => {
            return Object.values(activities)
                .sort((a, b) => a.order - b.order)
                .map((activity) => ({
                ...activity,
                assignmentIds: Array.from(new Set(activity.assignmentIds)),
            }));
        };
        Object.entries(timelines).forEach(([timeline, data]) => {
            const visits = Array.from(data.visits).sort((a, b) => data.groupOrder[a] - data.groupOrder[b]);
            const visitActivities = mapActivities(data.visitActivities);
            const nonVisitActivities = mapActivities(data.nonVisitActivities);
            sortedTimelines[timeline] = {
                visits,
                visitActivities,
                nonVisitActivities,
            };
        });
        const { Onboarding, ...rest } = sortedTimelines;
        return {
            Onboarding,
            Schedule: rest,
        };
    }, [nodes]);
    const activities = nodes.reduce((acc, curr) => {
        acc[curr.assignmentId] = curr;
        return acc;
    }, {});
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h4", { className: 'text-lg font-semibold text-gray-800 mb-4', children: translate('Onboarding') }), _jsxs("div", { children: [_jsx(StudySummaryWorkflowMatrix, { isOnboarding: true, activities: activities, headerLabels: [translate('Onboarding')], sections: [
                            {
                                dataTestId: 'schedule-timeline-visit-activities',
                                header: 'onboarding',
                                isStatic: true,
                                items: [...timelines.Onboarding.visitActivities, ...timelines.Onboarding.nonVisitActivities].sort((a, b) => a.order - b.order),
                                minRowsDisplayed: 1,
                            },
                        ] }), timelines.Schedule ? (_jsx("div", { children: Object.entries(timelines.Schedule).map(([timeline, data]) => (_jsxs("div", { className: 'bg-white mt-8', children: [_jsx("h4", { className: 'text-lg font-semibold text-gray-800 mb-4', children: timeline }), _jsx(StudySummaryWorkflowMatrix, { activities: activities, headerLabels: data.visits, sections: [
                                        {
                                            header: translate('VISIT ACTIVITIES'),
                                            items: data.visitActivities,
                                            minRowsDisplayed: 1,
                                            dataTestId: 'schedule-timeline-visit-activities',
                                        },
                                        {
                                            header: translate('NON-VISIT ACTIVITIES'),
                                            items: data.nonVisitActivities,
                                            minRowsDisplayed: 1,
                                            dataTestId: 'schedule-timeline-remote-activities',
                                        },
                                    ] })] }, timeline))) })) : null] })] }));
};
