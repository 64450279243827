import { AssetTypes } from 'libs.localization.common';
export const getParticipantStatusesAsset = (study) => ({
    type: AssetTypes.PARTICIPANT_STATUSES,
    id: study.id,
    version: '1',
    messages: [
        {
            namespace: 'participant_statuses',
            messages: study.configuration.studyMetadata.participantStatuses.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {}),
        },
    ],
    preview: {
        engine: 'react',
        id: AssetTypes.PARTICIPANT_STATUSES, // template_id => participant_statuses
        default_viewport: 'iPadLandscape',
        info: {
            display_name: 'Participant Statuses',
            list: [],
        },
    },
});
