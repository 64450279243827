import { addHours, addDays, addWeeks, addMonths, startOfDay, startOfWeek, startOfMonth, isAfter, isEqual, parseISO, } from 'date-fns';
import { PlainDate, Now } from 'temporal-luxon';
const activityHasLastResponse = (groupTask, lastResponse) => lastResponse && lastResponse?.c_task?._id === groupTask.c_group_task.c_assignment._id;
/**
 *
 * Eligibility, Consent, and Authentication Task types always show in the app as individual surveys and not in the My Activities list
 * Authentication Tasks should never have anything dependent on its completion in a flow rule
 *
 * @param {Object} groupTask - the task being evaluated
 * @param {string} task.c_type - The task type
 * @param {Date} task.c_start_date - The earliest the task will be available
 * @param {Date} task.c_end_date - The latest the task will be available
 * @param {Array<Object>} lastResponse - the last task response for this task
 * @param {Date} lastResponse[].date - the last task response date
 * @param {*} lastResponse[].value - the last task response success value
 *
 * @returns {Boolean} Returns true if task should be visible / false otherwise
 */
export const isActivityAvailableForCompletion = (groupTask, lastResponse) => hasValidTaskDates(groupTask) &&
    hasValidScheduling(groupTask, lastResponse ?? null) &&
    hasValidFlowRules(groupTask, lastResponse ?? null);
export const hasValidTaskDates = (groupTask) => {
    /**
     * isBeforeOrEqual - is the date before or equal to now
     * isAfterOrEqual - is the date after or equal to now
     * We use plain dates in these comparisons i.e "2023-05-18" without time components.
     *
     * More explanation when using PlainDate.compare found here
     * https://tc39.es/proposal-temporal/docs/plaindate.html#compare
     * */
    const isBeforeOrEqual = (startDate) => PlainDate.compare(PlainDate.from(startDate), Now.plainDateISO()) <= 0;
    const isAfterOrEqual = (endDate) => PlainDate.compare(PlainDate.from(endDate), Now.plainDateISO()) >= 0;
    return ((!groupTask.c_group_task.c_start_date || isBeforeOrEqual(groupTask.c_group_task.c_start_date)) &&
        (!groupTask.c_group_task.c_end_date || isAfterOrEqual(groupTask.c_group_task.c_end_date)));
};
export const hasValidScheduling = (groupTask, lastResponse) => {
    // Fetch the last response for this task
    const hasLastResponse = activityHasLastResponse(groupTask, lastResponse);
    // If I haven't ever done this task, then we're good
    if (!hasLastResponse) {
        return true;
    }
    // Validate that I can do the task right now
    // Based on my previous response and the schedule type / schedule value
    // Today must be greater than or equal to the next date that the task can be available
    const today = new Date();
    const taskDate = getNextTaskDate(groupTask, lastResponse);
    return isAfter(today, taskDate) || isEqual(today, taskDate);
};
export const hasValidFlowRules = (groupTask, lastResponse) => {
    const flowRules = groupTask.c_group_task.c_flow_rules;
    const failedFlowRule = flowRules
        // We find the first flow rule that fails
        .find((flowRule) => {
        const hasLastResponse = activityHasLastResponse(groupTask, lastResponse);
        // Validate that the last response for this rule matches the expected result
        if (hasLastResponse && lastResponse?.c_success === getFlowRuleExpectedValue(flowRule.c_type)) {
            // The flow rule matched, we return false since we want to find the first rule that fails
            return false;
        }
        // If we reached here, the last response doesn't exist, or its value doensn't match
        // So this flow rule failed :'(
        return true;
    });
    // If we find a failed flow rule this method returns false
    // Otherwise it returns true, and flow rules succeed
    return !failedFlowRule;
};
const getFlowRuleExpectedValue = (flowRuleType) => {
    switch (flowRuleType) {
        case 'complete-success':
            return true;
        case 'complete-failure':
            return false;
        default:
            throw new Error('Invalid flow rule dependency type.');
    }
};
const minDate = new Date(-8640000000000000);
const getResponseDate = (lastResponse) => lastResponse?.c_end || lastResponse?.created;
/**
 * Implements filtering of active tasks based on whats documented in
 * https://confluence.devops.medable.com/display/DMT/Axon+Administrator+Guide+V2.1#AxonAdministratorGuideV2.1-Assignment/Schedules
 *
 * @param {*} scheduleType - Sets the recurrence schedule for that task in the group. See Below for a description of task schedules.
 *                              One Time - User can only fill out the survey one time.
 *                              Always Available - User can only fill out the survey as many times as they would like with no restrictions.
 *                              Hours - Example: Every 2 hours.
 *                              Day(s) - Specifically related to a 24-hour Day.
 *                              Calendar Day - rela ted to the next day. So, if you fill out a survey at 10PM on May 1, then its available as soon as May 2 arrives.
 *                              Calendar Week - If it's Wednesday when I do my survey, you can do the next one as soon as the next week starts.
 *                              Calendar Month - If it's January 23 when I do my survey, you can do the next one as soon as the next month starts.
 * @param {*} scheduleValue - This field is used to set the number of skip hours, days, weeks, or months until the task is shown again.
 *                              This field only shows when the user selects a compatible schedule type.
 *                              (Valid for Hour, Day, Calendar Day, Calendar Week, and Calendar Month)
 * @param {*} lastResponseDate - The date that this type of task was last performed
 */
const getNextTaskDate = (groupTask, response) => {
    const scheduleType = groupTask.c_group_task.c_schedule;
    const scheduleValue = groupTask.c_group_task.c_schedule_value || 0;
    const responseDate = response ? getResponseDate(response) : '';
    const lastResponseDate = parseISO(responseDate);
    switch (scheduleType) {
        // We return the smallest date to signal that the task can be done.
        case 'always_available':
            return minDate;
        case 'hour':
            return addHours(lastResponseDate, scheduleValue);
        case 'day':
            return addDays(lastResponseDate, scheduleValue);
        case 'calendar_day':
            return startOfDay(addDays(lastResponseDate, scheduleValue));
        case 'calendar_week':
            return startOfWeek(addWeeks(lastResponseDate, scheduleValue));
        case 'calendar_month':
            return startOfMonth(addMonths(lastResponseDate, scheduleValue));
        case 'one_time':
        default:
            // If we reached the default, we return NaN, to force an invalid comparisson
            // Example one_time, if we reached here should not be available
            // Same for any schedule types not defined above
            return new Date(NaN);
    }
};
