import { jsx as _jsx } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { LocaleContext } from 'libs.nucleus';
import { ActivityEcoaView } from './activity_ecoa/activity_ecoa_view';
import { ActivityStandardView } from './activity_standard/activity_standard_view';
import { useOrgContext } from '../../../hooks/use_org_context';
import { useSiteContext } from '../../../hooks/use_site_context';
import { activityManager } from '../helper_managers/activity_manager';
export const ActivityView = ({ visitActivity, setIsActivityModalClosed }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    const currentVisit = visitActivity.visit;
    const org = useOrgContext();
    const site = useSiteContext();
    const currentLocale = useContext(LocaleContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const initActivityResponseData = () => {
        // when an error occurs, there's re-render and so we dont want to re-init the activity response
        if (isEmpty(activityManager.activityResponse)) {
            activityManager.initActivityResponseData({
                activityId: activity._id,
                studyId: org._id,
                publicUserId: match?.params.participantId || '',
                currentGroupId: currentVisit.c_group,
                visitId: currentVisit.c_visit,
                siteId: site._id,
                currentLocale,
            });
            return activityManager.activityResponse;
        }
        return activityManager.activityResponse;
    };
    const currentActivityResponse = initActivityResponseData();
    if (activity.c_type === 'web_form') {
        return (_jsx(ActivityEcoaView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed, activityResponse: currentActivityResponse, activityManager: activityManager }));
    }
    return (_jsx(ActivityStandardView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed, activityResponse: currentActivityResponse, activityManager: activityManager }));
};
