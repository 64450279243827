import { isEmpty, omitBy } from 'lodash';
import { isEmail, isMobilePhone } from 'validator';
import { useLocalizeMessage } from 'libs.nucleus';
// eslint-disable-next-line no-control-regex
const SITE_NAME_VALIDATION_REGEX = /^[0-9]|[\u0007\u0008\u0009\u000A\u000B\u000C\u000D]/;
export const buildInitFormValue = ({ workspaceSite, environmentSite, }) => {
    return {
        workspaceSiteName: workspaceSite?.name || '',
        workspaceSiteNumber: workspaceSite?.number || '',
        siteName: environmentSite?.metadata.name || workspaceSite?.name || '',
        siteNumber: environmentSite?.metadata.number || '',
        therapeuticArea: environmentSite?.metadata.therapeuticArea || '',
        principalInvestigator: environmentSite?.metadata.principalInvestigator || '',
        timezone: environmentSite?.metadata.timezone || workspaceSite?.timezone || '',
        siteLocales: environmentSite?.metadata.siteLocales || workspaceSite?.siteLocales || [],
        participantLocales: environmentSite?.metadata.participantLocales || [],
        email: environmentSite?.metadata.email || workspaceSite?.email || '',
        phone: environmentSite?.metadata.address.phone[0].number || workspaceSite?.address.phone[0].number || '',
        countryCode: environmentSite?.metadata.address.countryCode || workspaceSite?.address.countryCode || '',
        addressLine: environmentSite?.metadata.address.addressLine || workspaceSite?.address.addressLine || '',
        city: environmentSite?.metadata.address.city || workspaceSite?.address.city || '',
        region: environmentSite?.metadata.address.region || workspaceSite?.address.region || '',
        postalCode: environmentSite?.metadata.address?.postalCode || workspaceSite?.address?.postalCode || '',
    };
};
export const buildEnvironmentSiteMetadata = (siteFormValue) => {
    const preparedSiteFormValue = omitBy(siteFormValue, isEmpty);
    const metadata = {
        name: preparedSiteFormValue.siteName,
        number: preparedSiteFormValue.siteNumber,
        therapeuticArea: preparedSiteFormValue.therapeuticArea,
        principalInvestigator: preparedSiteFormValue.principalInvestigator,
        timezone: preparedSiteFormValue.timezone,
        siteLocales: preparedSiteFormValue.siteLocales,
        participantLocales: preparedSiteFormValue.participantLocales,
        email: preparedSiteFormValue.email,
        address: {
            addressLine: preparedSiteFormValue.addressLine,
            city: preparedSiteFormValue.city,
            region: preparedSiteFormValue.region,
            postalCode: preparedSiteFormValue.postalCode,
            countryCode: preparedSiteFormValue.countryCode,
            phone: [{ number: preparedSiteFormValue.phone, type: 'daytime' }],
        },
    };
    return omitBy(metadata, isEmpty);
};
export const buildCreateEnvironmentSitePayload = ({ environmentId, studyId, entityId, workspaceSiteId, siteFormValue, }) => {
    return {
        environmentId,
        studyId,
        entityId,
        siteId: workspaceSiteId,
        metadata: buildEnvironmentSiteMetadata(siteFormValue),
    };
};
export const buildEditEnvironmentSitePayload = ({ siteFormValue, }) => {
    return {
        metadata: buildEnvironmentSiteMetadata(siteFormValue),
    };
};
export const useSiteFormValidation = () => {
    const translate = useLocalizeMessage();
    const validateFormFieldOnChange = (field, value) => {
        switch (field) {
            case 'siteName': {
                if (value && value.length > 100) {
                    return translate('Maximum 100 characters');
                }
                if (SITE_NAME_VALIDATION_REGEX.test(value)) {
                    return translate("Can't start with a number or have non-printable characters");
                }
                break;
            }
            default:
                return undefined;
        }
    };
    const validateFormFieldOnBlur = (field, value) => {
        switch (field) {
            case 'siteName': {
                if (!value) {
                    return translate('Name is required');
                }
                if (value.length > 100) {
                    return translate('Maximum 100 characters');
                }
                if (SITE_NAME_VALIDATION_REGEX.test(value)) {
                    return translate("Can't start with a number or have non-printable characters");
                }
                break;
            }
            case 'email': {
                if (!isEmpty(value) && !isEmail(value)) {
                    return translate('Invalid email address');
                }
                break;
            }
            case 'phone': {
                if (isEmpty(value)) {
                    return translate('Phone is required');
                }
                if (!isMobilePhone(value)) {
                    return translate('Invalid phone number');
                }
                break;
            }
            case 'addressLine': {
                if (isEmpty(value)) {
                    return translate('Address line is required');
                }
                break;
            }
            case 'city': {
                if (isEmpty(value)) {
                    return translate('City is required');
                }
                break;
            }
            case 'countryCode': {
                if (isEmpty(value)) {
                    return translate('Country is required');
                }
                break;
            }
            case 'region': {
                if (isEmpty(value)) {
                    return translate('Region is required');
                }
                break;
            }
            default:
                return undefined;
        }
        return undefined;
    };
    return {
        validateFormFieldOnChange,
        validateFormFieldOnBlur,
    };
};
