export var MOMRegion;
(function (MOMRegion) {
    MOMRegion["CN"] = "cn";
    MOMRegion["EU"] = "eu";
    MOMRegion["US"] = "us";
    MOMRegion["LOCAL"] = "local";
})(MOMRegion || (MOMRegion = {}));
export var EnvironmentCluster;
(function (EnvironmentCluster) {
    EnvironmentCluster["EDGE"] = "edge";
    EnvironmentCluster["DEV"] = "dev";
    EnvironmentCluster["EPHEMERAL"] = "ephemeral";
    EnvironmentCluster["INT_DEV"] = "int-dev";
    EnvironmentCluster["LOCAL"] = "local";
    EnvironmentCluster["QA"] = "qa";
    EnvironmentCluster["PROD"] = "prod";
})(EnvironmentCluster || (EnvironmentCluster = {}));
export var MOMClusterType;
(function (MOMClusterType) {
    MOMClusterType["DEV"] = "dev";
    MOMClusterType["EPHEMERAL"] = "ephemeral";
    MOMClusterType["INTERNAL"] = "internal";
    MOMClusterType["PROD"] = "prod";
})(MOMClusterType || (MOMClusterType = {}));
