import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus';
import { extractClassicAppsBaseUrl } from '../../helpers/study.helpers';
// import { TEnvironment } from '../../types/studies_list.types';
import { useCurrentEnvironment } from '../use_current_environment';
export const useNavigation = () => {
    const { studyId } = useParams();
    const { environment } = useCurrentEnvironment();
    const translate = useLocalizeMessage();
    const getExternalAppUrl = (appName, path) => {
        if (!environment) {
            return '';
        }
        return `${extractClassicAppsBaseUrl(environment.cortexURL, appName)}${path}?orgCode=${environment.code}`;
    };
    const navItems = useMemo(() => {
        const items = [
            // TODO: Site Dashboard should be added after MVP
            // { title: translate('Home'), path: `/site/study/${studyId}/home`, icon: 'navCube' },
            { title: translate('Participants'), path: `/site/study/${studyId}/participants`, icon: 'navUsers' },
            {
                title: translate('Data Changes'),
                path: getExternalAppUrl('datachange', ''),
                icon: 'navProtocol',
                externalIcon: true,
                target: '_blank',
            },
            {
                title: translate('Study Data'),
                path: getExternalAppUrl('portal', ''),
                icon: 'navData',
                externalIcon: true,
                target: '_blank',
            },
            {
                title: translate('Queries'),
                path: getExternalAppUrl('site', '/queries'),
                icon: 'navQueries',
                externalIcon: true,
                target: '_blank',
            },
            { title: translate('Resources'), path: `/site/study/${studyId}/help-support`, icon: 'navWorkflows' },
        ];
        return items;
    }, [environment]);
    return { navItems };
};
