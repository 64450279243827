import { useLocalizeMessage } from 'libs.nucleus';
export const useCountriesOptions = () => {
    const translate = useLocalizeMessage();
    return [
        { label: translate('Afghanistan'), value: 'AF' },
        { label: translate('Albania'), value: 'AL' },
        { label: translate('Algeria'), value: 'DZ' },
        { label: translate('American Samoa'), value: 'AS' },
        { label: translate('Andorra'), value: 'AD' },
        { label: translate('Angola'), value: 'AO' },
        { label: translate('Anguilla'), value: 'AI' },
        { label: translate('Antarctica'), value: 'AQ' },
        { label: translate('Antigua and Barbuda'), value: 'AG' },
        { label: translate('Argentina'), value: 'AR' },
        { label: translate('Armenia'), value: 'AM' },
        { label: translate('Aruba'), value: 'AW' },
        { label: translate('Australia'), value: 'AU' },
        { label: translate('Austria'), value: 'AT' },
        { label: translate('Azerbaijan'), value: 'AZ' },
        { label: translate('Bahamas (the)'), value: 'BS' },
        { label: translate('Bahrain'), value: 'BH' },
        { label: translate('Bangladesh'), value: 'BD' },
        { label: translate('Barbados'), value: 'BB' },
        { label: translate('Belarus'), value: 'BY' },
        { label: translate('Belgium'), value: 'BE' },
        { label: translate('Belize'), value: 'BZ' },
        { label: translate('Benin'), value: 'BJ' },
        { label: translate('Bermuda'), value: 'BM' },
        { label: translate('Bhutan'), value: 'BT' },
        { label: translate('Bolivia (Plurinational State of)'), value: 'BO' },
        { label: translate('Bonaire, Sint Eustatius and Saba'), value: 'BQ' },
        { label: translate('Bosnia and Herzegovina'), value: 'BA' },
        { label: translate('Botswana'), value: 'BW' },
        { label: translate('Bouvet Island'), value: 'BV' },
        { label: translate('Brazil'), value: 'BR' },
        { label: translate('British Indian Ocean Territory (the)'), value: 'IO' },
        { label: translate('Brunei Darussalam'), value: 'BN' },
        { label: translate('Bulgaria'), value: 'BG' },
        { label: translate('Burkina Faso'), value: 'BF' },
        { label: translate('Burundi'), value: 'BI' },
        { label: translate('Cabo Verde'), value: 'CV' },
        { label: translate('Cambodia'), value: 'KH' },
        { label: translate('Cameroon'), value: 'CM' },
        { label: translate('Canada'), value: 'CA' },
        { label: translate('Cayman Islands (the)'), value: 'KY' },
        { label: translate('Central African Republic (the)'), value: 'CF' },
        { label: translate('Chad'), value: 'TD' },
        { label: translate('Chile'), value: 'CL' },
        { label: translate('China'), value: 'CN' },
        { label: translate('Christmas Island'), value: 'CX' },
        { label: translate('Cocos (Keeling) Islands (the)'), value: 'CC' },
        { label: translate('Colombia'), value: 'CO' },
        { label: translate('Comoros (the)'), value: 'KM' },
        { label: translate('Congo (the Democratic Republic of the)'), value: 'CD' },
        { label: translate('Congo (the)'), value: 'CG' },
        { label: translate('Cook Islands (the)'), value: 'CK' },
        { label: translate('Costa Rica'), value: 'CR' },
        { label: translate('Croatia'), value: 'HR' },
        { label: translate('Cuba'), value: 'CU' },
        { label: translate('Curaçao'), value: 'CW' },
        { label: translate('Cyprus'), value: 'CY' },
        { label: translate('Czechia'), value: 'CZ' },
        { label: translate("Côte d'Ivoire"), value: 'CI' },
        { label: translate('Denmark'), value: 'DK' },
        { label: translate('Djibouti'), value: 'DJ' },
        { label: translate('Dominica'), value: 'DM' },
        { label: translate('Dominican Republic (the)'), value: 'DO' },
        { label: translate('Ecuador'), value: 'EC' },
        { label: translate('Egypt'), value: 'EG' },
        { label: translate('El Salvador'), value: 'SV' },
        { label: translate('Equatorial Guinea'), value: 'GQ' },
        { label: translate('Eritrea'), value: 'ER' },
        { label: translate('Estonia'), value: 'EE' },
        { label: translate('Eswatini'), value: 'SZ' },
        { label: translate('Ethiopia'), value: 'ET' },
        { label: translate('Falkland Islands (the) [Malvinas]'), value: 'FK' },
        { label: translate('Faroe Islands (the)'), value: 'FO' },
        { label: translate('Fiji'), value: 'FJ' },
        { label: translate('Finland'), value: 'FI' },
        { label: translate('France'), value: 'FR' },
        { label: translate('French Guiana'), value: 'GF' },
        { label: translate('French Polynesia'), value: 'PF' },
        { label: translate('French Southern Territories (the)'), value: 'TF' },
        { label: translate('Gabon'), value: 'GA' },
        { label: translate('Gambia (the)'), value: 'GM' },
        { label: translate('Georgia'), value: 'GE' },
        { label: translate('Germany'), value: 'DE' },
        { label: translate('Ghana'), value: 'GH' },
        { label: translate('Gibraltar'), value: 'GI' },
        { label: translate('Greece'), value: 'GR' },
        { label: translate('Greenland'), value: 'GL' },
        { label: translate('Grenada'), value: 'GD' },
        { label: translate('Guadeloupe'), value: 'GP' },
        { label: translate('Guam'), value: 'GU' },
        { label: translate('Guatemala'), value: 'GT' },
        { label: translate('Guernsey'), value: 'GG' },
        { label: translate('Guinea'), value: 'GN' },
        { label: translate('Guinea-Bissau'), value: 'GW' },
        { label: translate('Guyana'), value: 'GY' },
        { label: translate('Haiti'), value: 'HT' },
        { label: translate('Heard Island and McDonald Islands'), value: 'HM' },
        { label: translate('Holy See (the)'), value: 'VA' },
        { label: translate('Honduras'), value: 'HN' },
        { label: translate('Hong Kong'), value: 'HK' },
        { label: translate('Hungary'), value: 'HU' },
        { label: translate('Iceland'), value: 'IS' },
        { label: translate('India'), value: 'IN' },
        { label: translate('Indonesia'), value: 'ID' },
        { label: translate('Iran (Islamic Republic of)'), value: 'IR' },
        { label: translate('Iraq'), value: 'IQ' },
        { label: translate('Ireland'), value: 'IE' },
        { label: translate('Isle of Man'), value: 'IM' },
        { label: translate('Israel'), value: 'IL' },
        { label: translate('Italy'), value: 'IT' },
        { label: translate('Jamaica'), value: 'JM' },
        { label: translate('Japan'), value: 'JP' },
        { label: translate('Jersey'), value: 'JE' },
        { label: translate('Jordan'), value: 'JO' },
        { label: translate('Kazakhstan'), value: 'KZ' },
        { label: translate('Kenya'), value: 'KE' },
        { label: translate('Kiribati'), value: 'KI' },
        { label: translate("Korea (the Democratic People's Republic of)"), value: 'KP' },
        { label: translate('Korea (the Republic of)'), value: 'KR' },
        { label: translate('Kuwait'), value: 'KW' },
        { label: translate('Kyrgyzstan'), value: 'KG' },
        { label: translate("Lao People's Democratic Republic (the)"), value: 'LA' },
        { label: translate('Latvia'), value: 'LV' },
        { label: translate('Lebanon'), value: 'LB' },
        { label: translate('Lesotho'), value: 'LS' },
        { label: translate('Liberia'), value: 'LR' },
        { label: translate('Libya'), value: 'LY' },
        { label: translate('Liechtenstein'), value: 'LI' },
        { label: translate('Lithuania'), value: 'LT' },
        { label: translate('Luxembourg'), value: 'LU' },
        { label: translate('Macao'), value: 'MO' },
        { label: translate('Madagascar'), value: 'MG' },
        { label: translate('Malawi'), value: 'MW' },
        { label: translate('Malaysia'), value: 'MY' },
        { label: translate('Maldives'), value: 'MV' },
        { label: translate('Mali'), value: 'ML' },
        { label: translate('Malta'), value: 'MT' },
        { label: translate('Marshall Islands (the)'), value: 'MH' },
        { label: translate('Martinique'), value: 'MQ' },
        { label: translate('Mauritania'), value: 'MR' },
        { label: translate('Mauritius'), value: 'MU' },
        { label: translate('Mayotte'), value: 'YT' },
        { label: translate('Mexico'), value: 'MX' },
        { label: translate('Micronesia (Federated States of)'), value: 'FM' },
        { label: translate('Moldova (the Republic of)'), value: 'MD' },
        { label: translate('Monaco'), value: 'MC' },
        { label: translate('Mongolia'), value: 'MN' },
        { label: translate('Montenegro'), value: 'ME' },
        { label: translate('Montserrat'), value: 'MS' },
        { label: translate('Morocco'), value: 'MA' },
        { label: translate('Mozambique'), value: 'MZ' },
        { label: translate('Myanmar'), value: 'MM' },
        { label: translate('Namibia'), value: 'NA' },
        { label: translate('Nauru'), value: 'NR' },
        { label: translate('Nepal'), value: 'NP' },
        { label: translate('Netherlands (the)'), value: 'NL' },
        { label: translate('New Caledonia'), value: 'NC' },
        { label: translate('New Zealand'), value: 'NZ' },
        { label: translate('Nicaragua'), value: 'NI' },
        { label: translate('Niger (the)'), value: 'NE' },
        { label: translate('Nigeria'), value: 'NG' },
        { label: translate('Niue'), value: 'NU' },
        { label: translate('Norfolk Island'), value: 'NF' },
        { label: translate('Northern Mariana Islands (the)'), value: 'MP' },
        { label: translate('Norway'), value: 'NO' },
        { label: translate('Oman'), value: 'OM' },
        { label: translate('Pakistan'), value: 'PK' },
        { label: translate('Palau'), value: 'PW' },
        { label: translate('Palestine, State of'), value: 'PS' },
        { label: translate('Panama'), value: 'PA' },
        { label: translate('Papua New Guinea'), value: 'PG' },
        { label: translate('Paraguay'), value: 'PY' },
        { label: translate('Peru'), value: 'PE' },
        { label: translate('Philippines (the)'), value: 'PH' },
        { label: translate('Pitcairn'), value: 'PN' },
        { label: translate('Poland'), value: 'PL' },
        { label: translate('Portugal'), value: 'PT' },
        { label: translate('Puerto Rico'), value: 'PR' },
        { label: translate('Qatar'), value: 'QA' },
        { label: translate('Republic of North Macedonia'), value: 'MK' },
        { label: translate('Romania'), value: 'RO' },
        { label: translate('Russian Federation (the)'), value: 'RU' },
        { label: translate('Rwanda'), value: 'RW' },
        { label: translate('Réunion'), value: 'RE' },
        { label: translate('Saint Barthélemy'), value: 'BL' },
        { label: translate('Saint Helena, Ascension and Tristan da Cunha'), value: 'SH' },
        { label: translate('Saint Kitts and Nevis'), value: 'KN' },
        { label: translate('Saint Lucia'), value: 'LC' },
        { label: translate('Saint Martin (French part)'), value: 'MF' },
        { label: translate('Saint Pierre and Miquelon'), value: 'PM' },
        { label: translate('Saint Vincent and the Grenadines'), value: 'VC' },
        { label: translate('Samoa'), value: 'WS' },
        { label: translate('San Marino'), value: 'SM' },
        { label: translate('Sao Tome and Principe'), value: 'ST' },
        { label: translate('Saudi Arabia'), value: 'SA' },
        { label: translate('Senegal'), value: 'SN' },
        { label: translate('Serbia'), value: 'RS' },
        { label: translate('Seychelles'), value: 'SC' },
        { label: translate('Sierra Leone'), value: 'SL' },
        { label: translate('Singapore'), value: 'SG' },
        { label: translate('Sint Maarten (Dutch part)'), value: 'SX' },
        { label: translate('Slovakia'), value: 'SK' },
        { label: translate('Slovenia'), value: 'SI' },
        { label: translate('Solomon Islands'), value: 'SB' },
        { label: translate('Somalia'), value: 'SO' },
        { label: translate('South Africa'), value: 'ZA' },
        { label: translate('South Georgia and the South Sandwich Islands'), value: 'GS' },
        { label: translate('South Sudan'), value: 'SS' },
        { label: translate('Spain'), value: 'ES' },
        { label: translate('Sri Lanka'), value: 'LK' },
        { label: translate('Sudan (the)'), value: 'SD' },
        { label: translate('Suriname'), value: 'SR' },
        { label: translate('Svalbard and Jan Mayen'), value: 'SJ' },
        { label: translate('Sweden'), value: 'SE' },
        { label: translate('Switzerland'), value: 'CH' },
        { label: translate('Syrian Arab Republic'), value: 'SY' },
        { label: translate('Taiwan (Province of China)'), value: 'TW' },
        { label: translate('Tajikistan'), value: 'TJ' },
        { label: translate('Tanzania, United Republic of'), value: 'TZ' },
        { label: translate('Thailand'), value: 'TH' },
        { label: translate('Timor-Leste'), value: 'TL' },
        { label: translate('Togo'), value: 'TG' },
        { label: translate('Tokelau'), value: 'TK' },
        { label: translate('Tonga'), value: 'TO' },
        { label: translate('Trinidad and Tobago'), value: 'TT' },
        { label: translate('Tunisia'), value: 'TN' },
        { label: translate('Turkey'), value: 'TR' },
        { label: translate('Turkmenistan'), value: 'TM' },
        { label: translate('Turks and Caicos Islands (the)'), value: 'TC' },
        { label: translate('Tuvalu'), value: 'TV' },
        { label: translate('Uganda'), value: 'UG' },
        { label: translate('Ukraine'), value: 'UA' },
        { label: translate('United Arab Emirates (the)'), value: 'AE' },
        {
            label: translate('United Kingdom of Great Britain and Northern Ireland (the)'),
            value: 'GB',
        },
        { label: translate('United States Minor Outlying Islands (the)'), value: 'UM' },
        { label: translate('United States of America (the)'), value: 'US' },
        { label: translate('Uruguay'), value: 'UY' },
        { label: translate('Uzbekistan'), value: 'UZ' },
        { label: translate('Vanuatu'), value: 'VU' },
        { label: translate('Venezuela (Bolivarian Republic of)'), value: 'VE' },
        { label: translate('Viet Nam'), value: 'VN' },
        { label: translate('Virgin Islands (British)'), value: 'VG' },
        { label: translate('Virgin Islands (U.S.)'), value: 'VI' },
        { label: translate('Wallis and Futuna'), value: 'WF' },
        { label: translate('Western Sahara'), value: 'EH' },
        { label: translate('Yemen'), value: 'YE' },
        { label: translate('Zambia'), value: 'ZM' },
        { label: translate('Zimbabwe'), value: 'ZW' },
        { label: translate('Åland Islands'), value: 'AX' },
    ];
};
