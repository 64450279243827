import { Children, isValidElement } from 'react';
// Base classes applied to all inputs
const baseClasses = [
    'box-border',
    'max-w-full',
    'border-solid',
    'bg-neutral-0',
    'font-sans',
    'text-sm',
    'font-medium',
    'not-italic',
    'shadow-sm',
    'rounded-md',
    'border',
    'p-4',
    'mt-2',
    'focus:outline-none',
    'focus:ring-1',
    'disabled:bg-neutral-200',
    'disabled:text-text-disabled',
    'disabled:border-neutral-200',
].join(' ');
export const getInputClassnames = (hasError, type) => {
    const typeClasses = type === 'textarea'
        ? 'overflow-auto resize-y min-h-40 list-disc list-numeric [&>ul]:ps-8 [&>ol]:ps-8'
        : 'overflow-hidden flex content-center h-10 items-center !text-nowrap';
    const errorClasses = hasError
        ? 'text-text-error border-red-500 hover:border-red-500 focus:ring-red-500 focus:border-red-500'
        : 'text-text-primary border-neutral-300 hover:border-neutral-500 focus:ring-purple-500 focus:border-purple-500';
    // Combine all classes
    return `${baseClasses} ${errorClasses} ${typeClasses}`;
};
export const linkClasses = 'box-border font-sans text-sm text-purple-500 underline focus:outline-none';
export const textClasses = 'box-border font-sans text-sm text-text-primary';
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const validateUrl = (url) => {
    return url === 'https://' || URL_REGEX.test(url);
};
/*
 * Extended the sanitization logic to support img tags and also content with only images.
 * Previously if there was no content besides an image the image was dropped and the value became
 * an empty string.
 *
 * TODO: Remove this logic from here and properly control how nodes export to dom by using the exportDOM
 * on each node. This causes 0.5ms - 1ms performance decrease when typing.
 */
export const transformValue = (input, retainTarget) => {
    const trimmedInput = input.trim();
    if (!trimmedInput) {
        return '';
    }
    const tempElement = document.createElement('div');
    tempElement.innerHTML = trimmedInput;
    const allowedTags = [
        'p',
        'br',
        'div',
        'span',
        'a',
        'b',
        'strong',
        'i',
        'em',
        'u',
        'img',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
    ];
    const allowedAttributes = {
        a: retainTarget ? ['href', 'target'] : ['href'],
        img: ['src', 'alt', 'title'],
    };
    const sanitizeElement = (element) => {
        if (!allowedTags.includes(element.tagName.toLowerCase())) {
            element.remove();
            return;
        }
        Array.from(element.attributes).forEach((attr) => {
            const tagName = element.tagName.toLowerCase();
            const allowedAttrs = allowedAttributes[tagName] || [];
            if (!allowedAttrs.includes(attr.name)) {
                element.removeAttribute(attr.name);
            }
        });
        Array.from(element.children).forEach((child) => sanitizeElement(child));
    };
    Array.from(tempElement.children).forEach((child) => sanitizeElement(child));
    const hasContent = tempElement.textContent.trim() !== '' || tempElement.querySelector('img') !== null;
    return hasContent ? tempElement.innerHTML.trim() : '';
};
/**
 * Recursively checks if a React component or its descendants contain a specific component type.
 *
 * @param {ReactNode} parentComponent - The parent component to search within.
 * @param {ElementType} targetComponentType - The type of component to search for.
 * @returns {boolean} True if the target component type is found, false otherwise.
 */
export const hasComponent = (parentComponent, targetComponentType) => {
    if (!isValidElement(parentComponent)) {
        return false;
    }
    if (parentComponent.type === targetComponentType) {
        return true;
    }
    const { children } = parentComponent.props;
    if (children) {
        // Convert to array if it's a single child
        const childrenArray = Children.toArray(children);
        for (const child of childrenArray) {
            if (isValidElement(child) && hasComponent(child, targetComponentType)) {
                return true;
            }
        }
    }
    // If we've reached here, the component wasn't found
    return false;
};
