import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'libs.react';
import { StudiesProvider } from './contexts/studies/studies.context';
const LayoutWrapper = lazy(() => import('./components/layout_wrapper/layout_wrapper.component'));
const StudiesDashboardPage = lazy(() => import('./pages/studies/studies_dashboard.page'));
const DataTransferPage = lazy(() => import('./pages/data_transfer/data_transfer.page'));
const DataReportsViewPage = lazy(() => import('./pages/data_reports/data_reports_view.page'));
const DataReportsBuildPage = lazy(() => import('./pages/data_reports/data_reports_build.page'));
const ProvidersWrapper = ({ children }) => {
    return _jsx(StudiesProvider, { children: children });
};
export const DataManagementApp = () => {
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { path: '/study/:studyId', element: _jsx(LayoutWrapper, {}), children: [_jsx(Route, { index: true, path: 'reports', element: _jsx(DataReportsViewPage, {}) }), _jsx(Route, { path: 'transfer', element: _jsx(DataTransferPage, {}) }), _jsx(Route, { path: 'reports/build', element: _jsx(DataReportsBuildPage, {}) })] }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { DATA_MANAGEMENT_APP_FLAGS } from './constants/developer_flags.constants';
