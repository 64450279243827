const isValidJson = (text) => {
    try {
        JSON.parse(text);
        return true;
    }
    catch (e) {
        return false;
    }
};
export const JsonUtils = {
    isValidJson,
};
