import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement } from 'react';
import { useBooleanState } from '../../hooks';
export const ModalNav = ({ actionButton, modalWindow, onSubmit }) => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const actionButtonWithOpenModal = cloneElement(actionButton, {
        onClick: openModal,
    });
    const modalWindowWithOpenAndCloseModal = cloneElement(modalWindow, {
        onClick: openModal,
        onSubmit: () => {
            closeModal();
            onSubmit();
        },
        onCancel: closeModal,
    });
    return (_jsxs(_Fragment, { children: [actionButtonWithOpenModal, isModalOpen && modalWindowWithOpenAndCloseModal] }));
};
