export class CookieFlagConnector {
    constructor(flagManager, cookieName = 'FLAGS') {
        this.flagManager = flagManager;
        this.cookieName = cookieName;
        this.flags = flagManager.getFlags();
        this.readFlagCookie();
    }
    notifyUpdate(_flag, _newValue) {
        this.writeFlagCookie();
    }
    writeFlagCookie() {
        const flagOverrides = [];
        for (const flagId in this.flags) {
            const flag = this.flags[flagId];
            const currentValue = this.flagManager.getValue(flag);
            if (flag.value === currentValue)
                continue;
            flagOverrides.push(`${flagId}=${encodeURIComponent(JSON.stringify(currentValue))}`);
        }
        document.cookie = `${this.cookieName}=${flagOverrides.join('&')};path=/`;
    }
    readFlagCookie() {
        const cookieFlagPrefix = `${this.cookieName}=`;
        document.cookie
            .split('; ')
            .filter((cookieNameValue) => cookieNameValue.startsWith(cookieFlagPrefix))
            .forEach((flagCookie) => {
            flagCookie
                .slice(cookieFlagPrefix.length)
                .split('&')
                .forEach((override) => {
                const [flagId, flagValue] = override.split('=');
                const flag = this.flags[flagId];
                if (flag === undefined)
                    return;
                this.flagManager.setValue(flag, JSON.parse(decodeURIComponent(flagValue)));
            });
        });
    }
}
