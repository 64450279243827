import { findIndex } from 'lodash';
export class Activity {
    c_steps = [];
    c_branches = [];
    orderedSteps = [];
    constructor(activity) {
        this.c_steps = activity.c_steps.data;
        // TODO: Implement this incase of form steps in the activity
        // if (this.c_steps) {
        //     const groupedSteps = groupBy(
        //       this.c_steps,
        //       (step) => step.c_parent_step && step.c_parent_step._id
        //     )
        // }
        this.setOrderedSteps();
        this.c_branches = activity.c_branches ? activity.c_branches.data.map((branch) => branch) : [];
    }
    setOrderedSteps() {
        this.orderedSteps = this.c_steps
            .filter((step) => !step.c_parent_step && !step.c_disabled)
            .sort((x, y) => x.c_order - y.c_order);
    }
    getBranch(triggerId) {
        return this.c_branches.find((b) => b.c_trigger._id === triggerId);
    }
    /**
     * @returns The first step in the task
     */
    getFirstStep() {
        return this.orderedSteps[0];
    }
    /**
     * Given a step _id it finds the next step
     * (according to c_order ordering)
     *
     * @param {string} stepId - The _id of the step whose successor
     * we want to find
     * @returns {*} The desired c_step instance or undefined
     */
    getNextStep(stepId) {
        const currentStepIndex = findIndex(this.orderedSteps, {
            _id: stepId,
        });
        return this.orderedSteps[currentStepIndex + 1];
    }
    /**
     * Given a step _id it finds the next step
     * (according to c_order ordering)
     *
     * @param {string} stepId - The _id of the step whose successor
     * we want to find
     * @returns {*} The desired c_step instance or undefined
     */
    getPreviousStep(stepId) {
        const currentStepIndex = findIndex(this.orderedSteps, {
            _id: stepId,
        });
        return this.orderedSteps[currentStepIndex - 1];
    }
}
