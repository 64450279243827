import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { EM_DASH } from 'libs.constants';
import { useLocalizeMessage } from 'libs.nucleus';
import { FormattedDateTime, ListInformation } from 'libs.react';
export const StudyTestPlanDetails = ({ testPlan }) => {
    const translate = useLocalizeMessage();
    const listItems = useMemo(() => {
        if (!testPlan) {
            return [];
        }
        return [
            { title: translate('Name'), description: testPlan.metadata.name },
            { title: translate('Description'), description: testPlan.data.description },
            {
                title: translate('Study version'),
                description: testPlan.metadata.version
                    ? `${testPlan.metadata.version.name} (${testPlan.metadata.version.externalVersion})`
                    : EM_DASH,
            },
            {
                title: translate('Date created'),
                description: _jsx(FormattedDateTime, { date: testPlan.createdAt }),
            },
            {
                title: translate('Last updated'),
                description: _jsx(FormattedDateTime, { date: testPlan.updatedAt }),
            },
        ];
    }, [testPlan]);
    return (_jsx("div", { className: 'flex-1 overflow-auto relative', children: _jsx(ListInformation, { items: listItems }) }));
};
