export const stepResponseTypes = {
    cBoolean: {
        objectType: 'c_boolean',
        type: 'boolean',
    },
    cNumeric: {
        objectType: 'c_numeric',
        type: 'number',
    },
    cText: {
        objectType: 'c_text',
        type: 'string',
    },
    cTextChoice: {
        objectType: 'c_text_choice',
        type: 'string',
    },
    cParticipantId: {
        objectType: 'c_participant_id',
        type: 'string',
    },
    cCompletion: {
        objectType: 'c_completion',
        type: 'boolean',
    },
    cInstruction: {
        objectType: 'c_instruction',
        type: 'boolean',
    },
    cDate: {
        objectType: 'c_date',
        type: 'date',
    },
    cDatetime: {
        objectType: 'c_datetime',
        type: 'datetime',
    },
    cImageCapture: {
        objectType: 'c_image_capture',
        type: 'file',
    },
};
