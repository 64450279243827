import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Pill, Spinner, Toggle, useLocalizeMessage } from 'libs.nucleus';
import { PageHeader } from 'libs.react';
import { TestPlanEditView } from '..';
import { StudyTestPlanContext } from '../../contexts';
import { SpreadsheetProvider } from '../../contexts/spreadsheet';
import { useWarnOnExit } from '../../hooks/use_warn_on_exit';
import { StudyTestPlanStatus } from '../../types';
const AUTOSAVE_INTERVAL = 20000;
export const StudyTestPlanEditor = ({ testPlan, setTestPlan, ecoaActivities, study }) => {
    const translate = useLocalizeMessage();
    const { updateTestPlan } = useContext(StudyTestPlanContext);
    const testPlanRef = useRef(null);
    const [isAutosaveEnabled, setIsAutosaveEnabled] = useState(false);
    const [isTestPlanSaving, setIsTestPlanSaving] = useState(false);
    const [isTestPlanDirty, setIsTestPlanDirty] = useState(false);
    useWarnOnExit(isTestPlanDirty, translate('You have unsaved changes. Are you sure you want to leave?'));
    const onSavePlan = () => {
        if (testPlanRef.current) {
            testPlanRef.current.savePlan();
        }
    };
    const resetPlan = () => {
        if (testPlanRef.current) {
            testPlanRef.current.resetPlan();
        }
    };
    const validatePlan = () => {
        if (testPlanRef.current) {
            testPlanRef.current.validatePlan();
        }
    };
    const onUpdatePlan = async (updatedPlan) => {
        setIsTestPlanSaving(true);
        const newTestPlan = await updateTestPlan({
            testPlan,
            csv: updatedPlan,
        });
        if (newTestPlan) {
            setTestPlan(newTestPlan);
        }
        setIsTestPlanSaving(false);
    };
    useEffect(() => {
        // TODO: Ask why NodeJS.Timeout is failing in build
        let autosaveTimer = null;
        if (isAutosaveEnabled && isTestPlanDirty && !isTestPlanSaving) {
            autosaveTimer = setInterval(() => {
                if (isTestPlanDirty) {
                    onSavePlan(); // Call the save function
                }
            }, AUTOSAVE_INTERVAL);
        }
        // Cleanup on unmount or if dependencies change
        return () => {
            if (autosaveTimer) {
                clearInterval(autosaveTimer);
            }
        };
    }, [isAutosaveEnabled, isTestPlanDirty, isTestPlanSaving]);
    const pillInfo = useMemo(() => {
        if (isTestPlanSaving) {
            return {
                variant: 'status',
                label: translate('Saving...'),
                iconBackgroundColor: 'primary',
            };
        }
        if (isTestPlanDirty) {
            return {
                variant: 'status',
                label: translate('Unsaved changes'),
                iconBackgroundColor: 'yellow',
            };
        }
        return {
            variant: 'status',
            label: translate('Saved'),
            iconBackgroundColor: 'green',
        };
    }, [isTestPlanDirty, isTestPlanSaving]);
    return (_jsxs("section", { className: 'relative h-full flex flex-col', children: [testPlan.status === StudyTestPlanStatus.DRAFT && (_jsx(PageHeader, { title: _jsx(Pill, { ...pillInfo }), customOptions: _jsxs("div", { className: 'flex gap-4 items-center', children: [_jsx(Toggle, { label: 'Autosave', checked: isAutosaveEnabled, onChange: setIsAutosaveEnabled }), _jsx(Button, { variant: 'secondary', onClick: validatePlan, disabled: !testPlan || !study || isTestPlanSaving, label: 'Validate' }), _jsx(Button, { variant: 'secondary', onClick: resetPlan, disabled: !isTestPlanDirty, label: 'Reset' }), _jsx(Button, { variant: 'primary', onClick: onSavePlan, disabled: !isTestPlanDirty || isTestPlanSaving, label: 'Save' })] }) })), _jsx("div", { className: 'flex-1 overflow-auto relative', children: !testPlan || !study ? (_jsx(Spinner, { wrapper: 'full' })) : (_jsx(SpreadsheetProvider, { children: _jsx(TestPlanEditView, { ref: testPlanRef, testPlanCsv: testPlan.data.csv, study: study, onUpdatePlan: onUpdatePlan, setIsTestPlanDirty: setIsTestPlanDirty, ecoaActivities: ecoaActivities, isEditable: testPlan.status === StudyTestPlanStatus.DRAFT }) })) })] }));
};
