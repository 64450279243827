export const getDeactivationInitialValues = () => {
    return {
        participantNumber: '',
        reason: { label: '', value: '' },
    };
};
export const getDeactivationReasons = (translate) => [
    {
        label: translate('Completed'),
        value: 'CMP',
    },
    {
        label: translate('Screen Failure'),
        value: 'SF',
    },
    {
        label: translate('Adverse Event'),
        value: 'AE',
    },
    {
        label: translate('Death'),
        value: 'DTH',
    },
    {
        label: translate('Disease Relapse'),
        value: 'DR',
    },
    {
        label: translate('Failure To Meet Randomization Criteria'),
        value: 'FTMRC',
    },
    {
        label: translate('Lack Of Efficacy'),
        value: 'LOE',
    },
    {
        label: translate('Lost To Follow-Up'),
        value: 'LTFU',
    },
    {
        label: translate('Non-Compliance With Study Drug'),
        value: 'NCWSD',
    },
    {
        label: translate('Physician Decision'),
        value: 'PD',
    },
    {
        label: translate('Pregnancy'),
        value: 'PREG',
    },
    {
        label: translate('Progressive Disease'),
        value: 'PRD',
    },
    {
        label: translate('Protocol Deviation'),
        value: 'PDV',
    },
    {
        label: translate('Recovery'),
        value: 'RCV',
    },
    {
        label: translate('Site Terminated By Sponsor'),
        value: 'STBS',
    },
    {
        label: translate('Study Terminated By Sponsor'),
        value: 'STDTBS',
    },
    {
        label: translate('Technical Problems'),
        value: 'TP',
    },
    {
        label: translate('Withdrawal By Parent/Guardian'),
        value: 'WBPG',
    },
    {
        label: translate('Withdrawal By Subject'),
        value: 'WBS',
    },
];
