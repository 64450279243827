import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, Link, useDialog, Spinner } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { stepDetermination } from './steps/step_determination';
import { useCurrentEnvironment } from '../../../../hooks/use_current_environment';
export const ActivityStandardView = ({ visitActivity, setIsActivityModalClosed, activityManager, activityResponse: currentActivityResponse, }) => {
    const standardActivity = activityManager.loadActivity(visitActivity.activity.c_group_task.c_assignment);
    const [currentStep, setCurrentStep] = useState(standardActivity.getFirstStep());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const queryClient = useQueryClient();
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { addNotification } = useToastNotification();
    const dialog = useDialog();
    const handleGoToNextStep = async () => {
        const isCompletionStep = activityManager.isCompletionStep(currentStep);
        if (isCompletionStep) {
            try {
                setIsSubmitting(true);
                await currentActivityResponse.upload({ environment });
                addNotification({
                    title: '',
                    subtitle: translate('Activity response uploaded successfully'),
                    type: 'success',
                });
                queryClient.invalidateQueries({
                    queryKey: ['onboardingActivities', visitActivity.visit.c_group],
                });
                queryClient.invalidateQueries({
                    queryKey: ['participantDetails', match?.params.participantId],
                });
                currentActivityResponse.clearStepResponses();
                activityManager.clearActivityResponse();
                setIsSubmitting(false);
                setIsActivityModalClosed();
            }
            catch (error) {
                setIsSubmitting(false);
                addNotification({
                    title: '',
                    subtitle: error?.response?.data?.message ?? translate('Error uploading activity response'),
                    type: 'error',
                });
            }
            return;
        }
        const nextStep = standardActivity.getNextStep(currentStep._id);
        if (nextStep) {
            setCurrentStep(nextStep);
        }
    };
    const handleGoToPreviousStep = () => {
        const previousStep = standardActivity.getPreviousStep(currentStep._id);
        if (previousStep) {
            setCurrentStep(previousStep);
        }
    };
    const clearActivityResponses = () => {
        currentActivityResponse.clearStepResponses();
        activityManager.clearActivityResponse();
    };
    const onClick = async () => {
        const cancelled = await dialog.open({
            title: translate('Are you sure you want to cancel this activity?'),
            body: translate('This activity will still be available to come back to, but your responses will not be saved.'),
            actionButtons: [{ label: translate('Yes, cancel activity') }, { label: translate('No, stay in activity') }],
        });
        if (cancelled) {
            clearActivityResponses();
            setIsActivityModalClosed();
        }
    };
    const StepToShow = stepDetermination(currentStep);
    return (_jsxs(_Fragment, { children: [isSubmitting && _jsx(Spinner, { wrapper: 'full' }), _jsx("div", { className: 'flex justify-end', style: { padding: '10px 40px' }, children: _jsx(Link, { to: '#', onClick: onClick, children: translate('Cancel') }) }), _jsx(StepToShow, { currentStep: currentStep, handleGoToNextStep: handleGoToNextStep, handleGoToPreviousStep: handleGoToPreviousStep, currentActivityResponse: currentActivityResponse, startDate: new Date() })] }));
};
