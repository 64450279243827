import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage, InlineNotification, Button } from 'libs.nucleus';
import { ListInformation, useBooleanState } from 'libs.react';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { StudySettingsEdit } from './study_settings_edit.component';
import { StudyConfigurationContext } from '../../../contexts';
export const StudySettings = () => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const { assignStudy, currentStudy, isReadOnlyMode, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [isEditModalOpen, openEditModal, closeEditModal] = useBooleanState(false);
    const consentedStatus = currentStudy.participantStatuses.find((status) => status.id === currentStudy.subjectEnrollmentStatusId);
    const handleSaveEdit = (newStudyMetadata) => {
        currentStudy.updateStudyMetadata(newStudyMetadata);
        assignStudy(currentStudy);
        logEvent('study_settings_updated', { study_id: studyId });
        closeEditModal();
    };
    const content = useMemo(() => {
        return [
            {
                title: translate('eConsent'),
                description: currentStudy.isEConsentEnabled ? translate('Enabled') : translate('Disabled'),
            },
            {
                title: translate('Consent status'),
                description: consentedStatus?.value,
                hidden: !currentStudy.isEConsentEnabled,
            },
            {
                title: translate('Adhoc televisits'),
                description: currentStudy.isTelevisitEnabled ? translate('Enabled') : translate('Disabled'),
            },
        ];
    }, [flagManager, currentStudy.isEConsentEnabled, currentStudy.isTelevisitEnabled]);
    return (_jsxs("section", { className: 'flex flex-col gap-5', children: [isReadOnlyMode && _jsx(TranslationStartedNotification, {}), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Edit settings'), onClick: openEditModal, disabled: isReadOnlyMode }) }), currentStudy.isNoPIIEnabled && !isReadOnlyMode && (_jsx(InlineNotification, { type: 'warning', role: 'status', title: translate('Warning'), subtitle: translate('Your participant registration method is set to "Username". To enable eConsent, please change your registration method to "Email"') })), _jsx(ListInformation, { items: content, descriptionColor: 'secondary' }), isEditModalOpen && _jsx(StudySettingsEdit, { onCancel: closeEditModal, onSave: handleSaveEdit })] }));
};
