export const USER_MANAGEMENT_FLAGS = {
    siteManagementRoles: {
        id: 'siteManagementRoles',
        value: false,
        description: 'Enables the ability to create users with site management roles',
        owner: 'Site experience team',
        name: 'Site management roles',
        extra: { section: 'User management' },
    },
};
