import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
const DEFAULT_VARIABLES = {
    openaiCompletionEndpoint: '',
    openaiModel: '',
    ultronKey: '',
    autobuildAssistantEndpoint: '',
};
const autobuildAssistantEndpoint = process.env.AUTOBUILD_ASSISTANT_ENDPOINT || 'https://assistant-latest.medable.com/autobuild/assistant';
const OPENAI_API_VERSION = '2023-09-01-preview';
const OPENAI_API_DEPLOYMENT = 'gpt35';
const openaiEndpoint = process.env.OPENAI_ENDPOINT;
export const VariablesContext = createContext(DEFAULT_VARIABLES);
export const VariablesProvider = ({ children }) => {
    const [variables, setVariables] = useState(DEFAULT_VARIABLES);
    useEffect(() => {
        setVariables({
            openaiCompletionEndpoint: `${openaiEndpoint}/openai/deployments/${OPENAI_API_DEPLOYMENT}/chat/completions?api-version=${OPENAI_API_VERSION}`,
            openaiModel: 'gpt-3.5-turbo',
            ultronKey: '',
            autobuildAssistantEndpoint,
        });
    }, []);
    return _jsx(VariablesContext.Provider, { value: { ...variables }, children: children });
};
