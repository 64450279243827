import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useContext } from 'react';
import { Locale, getLocaleMetaInfo } from 'libs.i18n-core';
import { LocaleContext, useLocalizeMessage, H6, Table } from 'libs.nucleus';
import { SelectLocalesModal } from 'libs.react';
const getLocalesTableArray = (currentLocale = Locale.en_US, locales) => {
    return locales.map((locale) => {
        const { region, language } = getLocaleMetaInfo({ appLocale: currentLocale, targetLocale: locale });
        return { code: locale, region, language };
    });
};
const ConfirmationStep = ({ selectedLocales }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const localesColumns = useMemo(() => {
        return [
            { header: translate('Code'), accessorKey: 'code' },
            { header: translate('Language/Script'), accessorKey: 'language' },
            { header: translate('Country/Region'), accessorKey: 'region' },
        ];
    }, []);
    const localesData = useMemo(() => getLocalesTableArray(currentLocale, selectedLocales), [currentLocale, selectedLocales]);
    return (_jsxs("div", { children: [_jsx("div", { className: 'mb-3', children: _jsx(H6, { className: 'text-neutral-600', children: translate('Review the locales you want to generate translations for.') }) }), _jsx(Table, { data: localesData, columns: localesColumns })] }));
};
export const GenTranslationModal = ({ hideLocales, onSubmit, onDismiss }) => {
    return (_jsx(SelectLocalesModal, { onAddLocales: (selectedLocales) => {
            onSubmit(selectedLocales.map(({ code }) => code));
        }, onClose: onDismiss, hideLocales: hideLocales, ConfirmationStep: ConfirmationStep, submitLabel: 'Generate Translation' }));
};
