import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { WorkspaceSiteModal, WorkspaceSitesTable } from '../../components';
export const WorkspaceSiteList = () => {
    const translate = useLocalizeMessage();
    const [isCreationModalOpen, setCreationModalOpen, setCreationModalClose] = useBooleanState();
    const headerOptions = [
        {
            name: translate('Bulk upload sites'),
            variant: 'secondary',
            disabled: true,
            onClick: () => { }, // todo: add bulk upload site functionality
        },
        {
            name: translate('Create site'),
            variant: 'primary',
            onClick: setCreationModalOpen,
        },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Sites'), subtitle: translate('This is your workspace sites library. A workspace library is the list of sites that are global applied to your entire entity instead of just individual studies.'), options: headerOptions }), _jsx(WorkspaceSitesTable, {}), _jsx(WorkspaceSiteModal, { isOpen: isCreationModalOpen, onClose: setCreationModalClose })] }));
};
