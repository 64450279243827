import { useEffect, useState } from 'react';
export const useDocumentVisibility = () => {
    const [isVisible, setIsVisible] = useState(!document.hidden);
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);
    };
    return isVisible;
};
