import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, useLocalizeMessage } from 'libs.nucleus';
import { useCountriesOptions } from 'libs.react';
import { useWorkspaceSites } from '../../hooks';
import { getSiteAddressLine } from '../../utils/site_address/site_address.utils';
export const SelectWorkspaceSiteStep = ({ value, onSelect }) => {
    const translate = useLocalizeMessage();
    const { workspaceSites, isLoading } = useWorkspaceSites();
    const countriesOptions = useCountriesOptions();
    const highlightedRowIds = value?.id ? [value.id.toString()] : [];
    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'id',
                cell: ({ row }) => (_jsx("input", { type: 'radio', checked: row.original.id === value?.id, value: row.original.id, onChange: () => onSelect(row.original) })),
                // todo: make ui compatible with the design - see discussion https://medable.slack.com/archives/C056G8HLWBZ/p1730229603567349
                size: 20,
            },
            {
                header: translate('Name'),
                accessorKey: 'name',
            },
            {
                header: translate('Site library ID'),
                accessorKey: 'number',
            },
            {
                header: translate('Location'),
                cell: ({ row }) => getSiteAddressLine(row.original.address, countriesOptions),
            },
        ];
    }, [value?.id]);
    return (_jsx(Table, { "data-testid": 'select-workspace-site-table', data: workspaceSites, columns: columns, isLoading: isLoading, getRowId: (row) => row.id.toString(), highlightedRowIds: highlightedRowIds }));
};
