import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect } from 'react';
import { useSpreadsheetSelector } from '../../../contexts/spreadsheet';
import { SpreadsheetStyles } from '../../spreadsheet';
const TestPlanContextEditor = ({ showContextModal, ...props }) => {
    const { cell, row } = props;
    const stepType = useSpreadsheetSelector((state) => state.data[row][0])?.value;
    useEffect(() => {
        if (stepType) {
            showContextModal({
                stepType,
                ...props,
            });
        }
    }, [stepType]);
    return (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth), children: cell?.value }));
};
export default TestPlanContextEditor;
