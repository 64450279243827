import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import SpreadsheetStyles from './spreadsheet_styles';
const FloatingRect = ({ dimensions, dragging, hidden, variant }) => {
    const { width, height, top, left } = dimensions || {};
    return (_jsx("div", { className: classnames(SpreadsheetStyles.floatingRect, {
            [SpreadsheetStyles.floatingRectCopied]: variant === 'copied',
            [SpreadsheetStyles.floatingRectSelected]: variant === 'selected',
            [SpreadsheetStyles.floatingRectDragging]: dragging,
            [SpreadsheetStyles.floatingRectHidden]: hidden,
        }), style: { width, height, top, left } }));
};
export default FloatingRect;
