import { Actions } from './spreadsheet.actions';
import { EmptySelection, EntireColumnsSelection, EntireRowsSelection, EntireWorksheetSelection, isActive, PointRange, RangeSelection, } from '../../utils/spreadsheet';
import * as Matrix from '../../utils/spreadsheet/spreadsheet_matrix.utils';
import * as Point from '../../utils/spreadsheet/spreadsheet_point.utils';
export const INITIAL_STATE = {
    active: null,
    mode: 'view',
    rowDimensions: {},
    columnDimensions: {},
    lastChanged: null,
    hasPasted: false,
    cut: false,
    dragging: false,
    selected: new EmptySelection(),
    copied: null,
    lastCommit: null,
    data: [],
    undoStack: [],
    redoStack: [],
    contextMenu: null,
    selectPopup: null,
};
export const reducer = (draft, action) => {
    const previousDraft = Object.assign({}, draft);
    switch (action.type) {
        case Actions.UPDATE_INITIAL_STATE: {
            const { state: newState } = action.payload;
            Object.assign(draft, newState);
            break;
        }
        case Actions.SET_DATA: {
            const { data } = action.payload;
            draft.active = draft.active && Matrix.has(draft.active, data) ? draft.active : null;
            draft.selected = draft.selected.normalizeTo(data);
            break;
        }
        case Actions.SELECT_ENTIRE_ROW: {
            const { row, extend } = action.payload;
            const { active } = draft;
            draft.selected = extend && active ? new EntireRowsSelection(active.row, row) : new EntireRowsSelection(row, row);
            draft.active = extend && active ? active : { ...Point.ORIGIN, row };
            draft.mode = 'view';
            break;
        }
        case Actions.SELECT_ENTIRE_COLUMN: {
            const { column, extend } = action.payload;
            const { active } = draft;
            draft.selected =
                extend && active
                    ? new EntireColumnsSelection(active.column, column)
                    : new EntireColumnsSelection(column, column);
            draft.active = extend && active ? active : { ...Point.ORIGIN, column };
            draft.mode = 'view';
            break;
        }
        case Actions.SELECT_ENTIRE_WORKSHEET: {
            draft.selected = new EntireWorksheetSelection();
            draft.active = Point.ORIGIN;
            draft.mode = 'view';
            break;
        }
        case Actions.SET_SELECTION: {
            const { selection } = action.payload;
            const range = selection.toRange(draft.data);
            draft.active = (draft.active && selection.has(draft.data, draft.active) ? draft.active : range?.start) || null;
            draft.selected = selection;
            draft.mode = 'view';
            break;
        }
        case Actions.SELECT: {
            const { point } = action.payload;
            if (draft.active && !isActive(draft.active, point)) {
                draft.selected = new RangeSelection(new PointRange(point, draft.active));
                draft.mode = 'view';
            }
            break;
        }
        case Actions.ACTIVATE: {
            const { point } = action.payload;
            draft.selected = new RangeSelection(new PointRange(point, point));
            draft.mode = isActive(draft.active, point) ? 'edit' : 'view';
            draft.active = point;
            break;
        }
        case Actions.SET_CELL_DATA: {
            const { active, data: cellData } = action.payload;
            if (isActiveReadOnly(draft)) {
                return;
            }
            const currentCell = Matrix.get(active, draft.data);
            const nextCell = { ...currentCell, ...cellData };
            draft.data = Matrix.set(active, nextCell, draft.data);
            draft.lastChanged = active;
            draft.undoStack.push(previousDraft);
            draft.redoStack = [];
            break;
        }
        case Actions.SET_CELL_DIMENSIONS: {
            const { point, dimensions } = action.payload;
            const prevRowDimensions = draft.rowDimensions[point.row];
            const prevColumnDimensions = draft.columnDimensions[point.column];
            if (prevRowDimensions?.top === dimensions.top &&
                prevRowDimensions?.height === dimensions.height &&
                prevColumnDimensions?.left === dimensions.left &&
                prevColumnDimensions?.width === dimensions.width) {
                return;
            }
            draft.rowDimensions[point.row] = { top: dimensions.top, height: dimensions.height };
            draft.columnDimensions[point.column] = { left: dimensions.left, width: dimensions.width };
            break;
        }
        case Actions.COPY:
        case Actions.CUT: {
            draft.copied = draft.selected.toRange(draft.data);
            draft.cut = action.type === Actions.CUT;
            draft.hasPasted = false;
            break;
        }
        case Actions.PASTE: {
            const { data: text } = action.payload;
            const { active } = draft;
            if (!active) {
                return;
            }
            const copied = Matrix.split(text, (value) => ({ value }));
            const copiedSize = Matrix.getSize(copied);
            const selectedRange = draft.selected.toRange(draft.data);
            if (selectedRange && copiedSize.rows === 1 && copiedSize.columns === 1) {
                const cell = Matrix.get({ row: 0, column: 0 }, copied);
                let newData = draft.cut && draft.copied ? Matrix.unset(draft.copied.start, draft.data) : draft.data;
                const commit = [];
                for (const point of selectedRange || []) {
                    const currentCell = Matrix.get(point, draft.data);
                    commit.push({
                        prevCell: currentCell || null,
                        nextCell: cell || null,
                    });
                    newData = Matrix.set(point, cell, newData);
                }
                draft.data = newData;
                draft.copied = null;
                draft.cut = false;
                draft.hasPasted = true;
                draft.mode = 'view';
                draft.lastCommit = commit;
                draft.undoStack.push(previousDraft);
                draft.redoStack = [];
            }
            else {
                const requiredSize = {
                    rows: active.row + copiedSize.rows,
                    columns: active.column + copiedSize.columns,
                };
                const paddedData = Matrix.pad(draft.data, requiredSize);
                let acc = { data: paddedData, commit: [] };
                for (const [point, cell] of Matrix.entries(copied)) {
                    let commit = acc.commit || [];
                    const nextPoint = {
                        row: point.row + active.row,
                        column: point.column + active.column,
                    };
                    let nextData = acc.data;
                    if (draft.cut) {
                        if (draft.copied) {
                            const prevPoint = {
                                row: point.row + draft.copied.start.row,
                                column: point.column + draft.copied.start.column,
                            };
                            nextData = Matrix.unset(prevPoint, acc.data);
                        }
                        commit = [...commit, { prevCell: cell || null, nextCell: null }];
                    }
                    if (!Matrix.has(nextPoint, paddedData)) {
                        acc = { data: nextData, commit };
                    }
                    const currentCell = Matrix.get(nextPoint, nextData) || null;
                    commit = [
                        ...commit,
                        {
                            prevCell: currentCell,
                            nextCell: cell || null,
                        },
                    ];
                    acc.data = Matrix.set(nextPoint, { value: undefined, ...currentCell, ...cell }, nextData);
                    acc.commit = commit;
                }
                draft.data = acc.data;
                draft.selected = new RangeSelection(new PointRange(active, {
                    row: active.row + copiedSize.rows - 1,
                    column: active.column + copiedSize.columns - 1,
                }));
                draft.copied = null;
                draft.cut = false;
                draft.hasPasted = true;
                draft.mode = 'view';
                draft.lastCommit = acc.commit;
                draft.undoStack.push(previousDraft);
                draft.redoStack = [];
            }
            break;
        }
        case Actions.EDIT:
            edit(draft);
            break;
        case Actions.VIEW:
            view(draft);
            break;
        case Actions.CLEAR:
            clear(draft);
            break;
        case Actions.BLUR:
            blur(draft);
            break;
        case Actions.KEY_PRESS: {
            const { event } = action.payload;
            if (isActiveReadOnly(draft) || event.metaKey) {
                return;
            }
            if (draft.mode === 'view' && draft.active) {
                const selectedRange = draft.selected.toRange(draft.data);
                if (selectedRange?.size() === 1) {
                    clear(draft);
                    edit(draft);
                }
                else {
                    edit(draft);
                }
            }
            break;
        }
        case Actions.KEY_DOWN: {
            const { event } = action.payload;
            const handler = getKeyDownHandler(draft, event);
            if (handler) {
                handler(draft, event);
            }
            break;
        }
        case Actions.DRAG_START:
            draft.dragging = true;
            break;
        case Actions.DRAG_END:
            draft.dragging = false;
            break;
        case Actions.COMMIT:
            commit(draft, action.payload.changes);
            break;
        case Actions.SHOW_CONTEXT_MENU: {
            const { e, type } = action.payload;
            draft.contextMenu = { position: { x: e.pageX, y: e.pageY }, type };
            break;
        }
        case Actions.HIDE_CONTEXT_MENU:
            draft.contextMenu = null;
            break;
        case Actions.MOVE_ROW: {
            const { from, to } = action.payload;
            draft.data = Matrix.moveRow(from, to, draft.data);
            draft.selected = new EntireRowsSelection(to, to);
            draft.active = { ...draft.active, row: to, column: 0 };
            draft.undoStack.push(previousDraft);
            draft.redoStack = [];
            break;
        }
        case Actions.DELETE_ROW: {
            const selectedRange = draft.selected.toRange(draft.data);
            if (!selectedRange) {
                return;
            }
            draft.data = Matrix.deleteRows(selectedRange, draft.data);
            draft.undoStack.push(previousDraft);
            draft.redoStack = [];
            break;
        }
        case Actions.INSERT_ROW: {
            const { position } = action.payload;
            const selectedRange = draft.selected.toRange(draft.data);
            if (!selectedRange) {
                return;
            }
            const startRow = selectedRange.start.row;
            const endRow = selectedRange.end.row;
            const startIndex = position < 0 ? startRow : endRow;
            const direction = position < 0 ? 'above' : 'below';
            draft.data = Matrix.insertRows(startIndex, direction, Math.abs(position), draft.data);
            draft.undoStack.push(previousDraft);
            draft.redoStack = [];
            break;
        }
        case Actions.SHOW_SELECT:
            draft.selectPopup = action.payload;
            break;
        case Actions.HIDE_SELECT:
            draft.selectPopup = null;
            break;
        case Actions.CLEAR_OTHER_CELLS: {
            const point = action.payload;
            draft.data = Matrix.clearRowExcept(draft.data, point);
            break;
        }
        case Actions.RESET_STATE:
            Object.assign(draft, INITIAL_STATE);
            draft.data = action.payload;
            break;
        default:
            throw new Error('Unknown action');
    }
};
// Shared reducers
function edit(draft) {
    if (!isActiveReadOnly(draft)) {
        draft.mode = 'edit';
    }
}
function clear(draft) {
    if (!draft.active) {
        return;
    }
    const canClearCell = (cell) => cell && !cell.readOnly;
    const clearCell = (cell) => {
        if (!canClearCell(cell)) {
            return cell;
        }
        return Object.assign({}, cell, { value: undefined });
    };
    const selectedRange = draft.selected.toRange(draft.data);
    const changes = [];
    let newData = draft.data;
    for (const point of selectedRange || []) {
        const cell = Matrix.get(point, draft.data);
        const clearedCell = clearCell(cell);
        changes.push({
            prevCell: cell || null,
            nextCell: clearedCell || null,
        });
        newData = Matrix.set(point, clearedCell, newData);
    }
    draft.data = newData;
    draft.lastCommit = changes;
}
function blur(draft) {
    draft.active = null;
    draft.selected = new EmptySelection();
    draft.copied = null;
}
function view(draft) {
    draft.mode = 'view';
}
function commit(draft, changes) {
    draft.lastCommit = changes;
}
// Utility
export const go = (rowDelta, columnDelta) => (draft) => {
    if (!draft.active) {
        return;
    }
    const size = Matrix.getSize(draft.data);
    const newColumn = draft.active.column + columnDelta;
    const shouldWrap = newColumn >= size.columns;
    const nextActive = {
        row: draft.active.row + rowDelta + (shouldWrap ? 1 : 0),
        column: (draft.active.column + columnDelta) % size.columns,
    };
    if (!Matrix.has(nextActive, draft.data)) {
        draft.mode = 'view';
    }
    else {
        draft.active = nextActive;
        draft.selected = new RangeSelection(new PointRange(nextActive, nextActive));
        draft.mode = 'view';
    }
};
const keyDownHandlers = {
    ArrowUp: go(-1, 0),
    ArrowDown: go(+1, 0),
    ArrowLeft: go(0, -1),
    ArrowRight: go(0, +1),
    Tab: go(0, +1),
    Enter: edit,
    Backspace: clear,
    Delete: clear,
    Escape: blur,
};
const editKeyDownHandlers = {
    Escape: view,
    Tab: keyDownHandlers.Tab,
    Enter: view,
};
const editShiftKeyDownHandlers = {
    Tab: go(0, -1),
};
export var Direction;
(function (Direction) {
    Direction["Left"] = "Left";
    Direction["Right"] = "Right";
    Direction["Top"] = "Top";
    Direction["Bottom"] = "Bottom";
})(Direction || (Direction = {}));
const shiftKeyDownHandlers = {
    ArrowUp: (draft) => {
        draft.selected = modifyEdge(draft.selected, draft.active, draft.data, Direction.Top);
    },
    ArrowDown: (draft) => {
        draft.selected = modifyEdge(draft.selected, draft.active, draft.data, Direction.Bottom);
    },
    ArrowLeft: (draft) => {
        draft.selected = modifyEdge(draft.selected, draft.active, draft.data, Direction.Left);
    },
    ArrowRight: (draft) => {
        draft.selected = modifyEdge(draft.selected, draft.active, draft.data, Direction.Right);
    },
    Tab: go(0, -1),
};
const shiftMetaKeyDownHandlers = {
    z: (draft) => {
        if (draft.redoStack.length > 0) {
            const nextState = draft.redoStack[0];
            Object.assign(draft, nextState);
            draft.undoStack.push(draft.redoStack[0]);
            draft.redoStack.shift();
        }
    },
};
const metaKeyDownHandlers = {
    z: (draft) => {
        if (draft.undoStack.length > 0) {
            const previousState = draft.undoStack[draft.undoStack.length - 1];
            Object.assign(draft, previousState);
            draft.undoStack.pop();
            draft.redoStack.unshift(draft);
        }
    },
};
export function getKeyDownHandler(draft, event) {
    const { key } = event;
    let handlers;
    // Order matters
    if (draft.mode === 'edit') {
        if (event.shiftKey) {
            handlers = editShiftKeyDownHandlers;
        }
        else {
            handlers = editKeyDownHandlers;
        }
    }
    else if (event.shiftKey && event.metaKey) {
        handlers = shiftMetaKeyDownHandlers;
    }
    else if (event.shiftKey) {
        handlers = shiftKeyDownHandlers;
    }
    else if (event.metaKey) {
        handlers = metaKeyDownHandlers;
    }
    else {
        handlers = keyDownHandlers;
    }
    return handlers[key];
}
/** Returns whether the reducer has a handler for the given keydown event */
export function hasKeyDownHandler(draft, event) {
    return getKeyDownHandler(draft, event) !== undefined;
}
/** Returns whether the active cell is read only */
export function isActiveReadOnly(draft) {
    const activeCell = getActive(draft);
    return Boolean(activeCell?.readOnly);
}
/** Gets active cell from given draft */
export function getActive(draft) {
    const activeCell = draft.active && Matrix.get(draft.active, draft.data);
    return activeCell || null;
}
/** Modify given edge according to given active point and data */
export function modifyEdge(selection, active, data, direction) {
    if (!active) {
        return selection;
    }
    if (selection instanceof RangeSelection) {
        const nextSelection = modifyRangeSelectionEdge(selection, active, data, direction);
        // @ts-expect-error - this is a valid operation
        return nextSelection;
    }
    if (selection instanceof EntireColumnsSelection) {
        // @ts-expect-error - this is a valid operation
        return modifyEntireColumnsSelection(selection, active, data, direction);
    }
    if (selection instanceof EntireRowsSelection) {
        // @ts-expect-error - this is a valid operation
        return modifyEntireRowsSelection(selection, active, data, direction);
    }
    return selection;
}
export function modifyRangeSelectionEdge(rangeSelection, active, data, edge) {
    const field = edge === Direction.Left || edge === Direction.Right ? 'column' : 'row';
    const key = edge === Direction.Left || edge === Direction.Top ? 'start' : 'end';
    const delta = key === 'start' ? -1 : 1;
    const edgeOffsets = rangeSelection.range.has({
        ...active,
        [field]: active[field] + delta * -1,
    });
    const keyToModify = edgeOffsets ? (key === 'start' ? 'end' : 'start') : key;
    const nextRange = new PointRange(rangeSelection.range.start, rangeSelection.range.end);
    nextRange[keyToModify][field] += delta;
    const nextSelection = new RangeSelection(nextRange).normalizeTo(data);
    return nextSelection;
}
export function modifyEntireRowsSelection(selection, active, data, edge) {
    if (edge === Direction.Left || edge === Direction.Right) {
        return selection;
    }
    const delta = edge === Direction.Top ? -1 : 1;
    const property = edge === Direction.Top ? 'start' : 'end';
    const oppositeProperty = property === 'start' ? 'end' : 'start';
    const newSelectionData = { ...selection };
    if (edge === Direction.Top ? selection.end > active.row : selection.start < active.row) {
        newSelectionData[oppositeProperty] = selection[oppositeProperty] + delta;
    }
    else {
        newSelectionData[property] = selection[property] + delta;
    }
    const nextSelection = new EntireRowsSelection(Math.max(newSelectionData.start, 0), Math.max(newSelectionData.end, 0));
    return nextSelection.normalizeTo(data);
}
export function modifyEntireColumnsSelection(selection, active, data, edge) {
    if (edge === Direction.Top || edge === Direction.Bottom) {
        return selection;
    }
    const delta = edge === Direction.Left ? -1 : 1;
    const property = edge === Direction.Left ? 'start' : 'end';
    const oppositeProperty = property === 'start' ? 'end' : 'start';
    const newSelectionData = { ...selection };
    if (edge === Direction.Left ? selection.end > active.row : selection.start < active.row) {
        newSelectionData[oppositeProperty] = selection[oppositeProperty] + delta;
    }
    else {
        newSelectionData[property] = selection[property] + delta;
    }
    const nextSelection = new EntireColumnsSelection(Math.max(newSelectionData.start, 0), Math.max(newSelectionData.end, 0));
    return nextSelection.normalizeTo(data);
}
