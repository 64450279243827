import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'libs.nucleus';
import { ApiClientService, useApiClient } from 'libs.react';
import { ParticipantStatusConditions } from './participant_status_condtions.component';
import { StudySummaryExtensions } from './study_summary_extensions.component';
import { StudySummaryMetadata } from './study_summary_metadata.component';
import { StudySummaryTaskDefinitions } from './study_summary_task_definitions.component';
import { StudySummaryTimelineConditions } from './study_summary_timeline_conditions.component';
import { StudySummaryVisits } from './study_summary_visits.component';
import { StudySummaryWorkflow } from './study_summary_workflow.component';
export const StudySummary = (props) => {
    const autobuildClient = useApiClient(ApiClientService.AUTOBUILD);
    const { data: studySummaryData, isLoading: isGeneratingSummary } = useQuery({
        queryKey: ['studySummary'],
        queryFn: async () => {
            try {
                const { data } = await autobuildClient.post('/study-summary/generate', {
                    ...(props.study && { rosetta: props.study.toRosetta() }),
                    ...(props.token && { token: props.token }),
                });
                return data;
            }
            catch (error) {
                console.error('Failed to generate study summary:', error);
                throw error;
            }
        },
        enabled: Boolean(props.token || props.study),
        retry: (failureCount, error) => {
            // The generate api takes a long time to respond, sometimes it times out.
            // Retry once only if it's a timeout error (504)
            return failureCount < 1 && error?.response?.status === 504;
        },
    });
    if (isGeneratingSummary || !studySummaryData) {
        return (_jsx("div", { className: 'flex items-center justify-center w-full mt-8', children: _jsx(Spinner, {}) }));
    }
    const { metadata, assignments, visitAvailability = [], taskDefinitions = [], timelineConditions = [], participantStatuses = {}, studyExtensions = [], } = studySummaryData.studySummary;
    return (_jsxs("div", { children: [_jsx(StudySummaryMetadata, { metadata: metadata }), _jsx(StudySummaryWorkflow, { studyData: assignments }), _jsx(StudySummaryTimelineConditions, { timelineConditions: timelineConditions }), _jsx(ParticipantStatusConditions, { participantStatuses: participantStatuses }), _jsx(StudySummaryVisits, { visitAvailability: visitAvailability }), _jsx(StudySummaryExtensions, { studyExtensions: studyExtensions }), _jsx(StudySummaryTaskDefinitions, { taskDefinitions: taskDefinitions })] }));
};
