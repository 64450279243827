export const onStartVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    // await getApiInstance(environment).post(`routes/visit/action`, {
    //   c_public_user: participantId,
    //   c_visit: visitId,
    //   action: 'start',
    // });
    function delay(t) {
        console.log({ environment, participantId, visitId });
        return new Promise((resolve) => {
            setTimeout(resolve, t);
            // throw new Error('Not implemented');
        });
    }
    await delay(2000);
};
export const onSkipVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    // await getApiInstance(environment).post(`routes/visit/action`, {
    //   c_public_user: participantId,
    //   c_visit: visitId,
    //   action: 'start',
    // });
    function delay(t) {
        console.log({ environment, participantId, visitId });
        return new Promise((resolve) => {
            setTimeout(resolve, t);
            // throw new Error('Not implemented');
        });
    }
    await delay(2000);
};
