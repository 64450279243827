import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { EM_DASH } from 'libs.constants';
import { Table, useLocalizeMessage } from 'libs.nucleus';
export const StudySummaryExtensions = ({ studyExtensions }) => {
    const translate = useLocalizeMessage();
    const columns = useMemo(() => [
        {
            header: translate('Name'),
            accessorKey: 'name',
        },
        {
            header: translate('Description'),
            accessorKey: 'description',
            cell: ({ row }) => row.original.description || EM_DASH,
        },
        {
            header: translate('Library reference key'),
            accessorKey: 'mappingKey',
            cell: ({ row }) => row.original.mappingKey || EM_DASH,
        },
    ], [translate]);
    if (!studyExtensions?.length) {
        return null;
    }
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Study extensions') }), _jsx(Table, { data: studyExtensions, columns: columns })] }));
};
