import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, useLocalizeMessage } from 'libs.nucleus';
const formatValue = (value) => {
    if (typeof value === 'object' && value !== null) {
        return (_jsx("ul", { children: Object.entries(value).map(([key, val]) => (_jsxs("li", { children: [key, ": ", String(val)] }, `${key}-${val}`))) }));
    }
    return value;
};
export const StudySummaryTaskDefinitions = ({ taskDefinitions }) => {
    const translate = useLocalizeMessage();
    const taskStepColumns = useMemo(() => [
        {
            header: translate('Step name'),
            accessorKey: 'name',
            cell: ({ row }) => formatValue(row.original.name),
        },
        {
            header: translate('Short question text'),
            accessorKey: 'shortQuestion',
            cell: ({ row }) => formatValue(row.original.shortQuestion),
        },
        {
            header: translate('Title'),
            accessorKey: 'title',
            cell: ({ row }) => formatValue(row.original.title),
            size: 200,
        },
        {
            header: translate('Screen text'),
            accessorKey: 'screenText',
            cell: ({ row }) => formatValue(row.original.screenText),
            size: 250,
        },
        {
            header: translate('Additional screen text'),
            accessorKey: 'additionalScreenText',
            cell: ({ row }) => formatValue(row.original.additionalScreenText),
        },
        {
            header: translate('Type'),
            accessorKey: 'type',
            cell: ({ row }) => formatValue(row.original.type),
        },
        {
            header: translate('Answer/Response value mapping'),
            accessorKey: 'answerValueMapping',
            cell: ({ row }) => {
                const step = row.original;
                if ('answerValueMapping' in step) {
                    return formatValue(step.answerValueMapping);
                }
                return null;
            },
        },
        {
            header: translate('Branching'),
            accessorKey: 'branching',
            cell: ({ row }) => formatValue(row.original.branching),
        },
    ], [translate]);
    if (!taskDefinitions?.length) {
        return null;
    }
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Activity definitions') }), taskDefinitions.map((taskDef) => (_jsxs("div", { className: 'mb-6', children: [_jsx("h4", { className: 'text-md font-medium text-gray-700 mb-2', children: taskDef.title }), _jsx(Table, { data: taskDef.c_steps, columns: taskStepColumns })] }, taskDef.title)))] }));
};
