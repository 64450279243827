import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Localization } from 'libs.localization.common';
import { useLocalizationExport } from 'libs.localization.react';
import { Locale, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { ButtonWithTooltip, useBooleanState } from 'libs.react';
import { ExportModal } from '../study/export_modal';
export const DashboardExportAction = ({ assets, locales, isDisabled, showTooltip = false, tooltipText = '', trackingContext = {}, studyId, baseLocale, }) => {
    const t = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const [isExportModalOpen, openExportModal, closeExportModal] = useBooleanState(false);
    const { isFetching: isExporting, exportLocalization } = useLocalizationExport();
    const handleExport = useCallback((state) => {
        closeExportModal();
        const _locales = state.locale?.selectedLocales || [Locale.en_US];
        const _assets = {};
        state.assets?.selectedAssets?.forEach((asset) => {
            const localeVersionTuple = [];
            const assetId = new Localization(asset).getId();
            const _asset = Object.values(assets || {}).find((a) => {
                return a?.asset?.id === asset.id && a?.asset?.type === asset.type;
            });
            if (_asset) {
                _locales.forEach((locale) => {
                    _asset.currentVersionStatus?.[locale]?.version &&
                        localeVersionTuple.push(`${locale}~${_asset.currentVersionStatus?.[locale].version}`);
                });
            }
            _assets[assetId] = localeVersionTuple;
        });
        exportLocalization({
            assets: _assets,
            resources: state.content?.resources || [],
            locales: state.locale?.selectedLocales || [Locale.en_US],
            scope: state.scope,
            ...(state.content?.viewport ? { viewport: state.content.viewport } : {}),
            ...(studyId ? { study: studyId } : {}),
        });
        logEvent('translations_localization_exported', {
            ...trackingContext,
            resources: (state.content?.resources || []).join(','),
            scope: state.scope,
            viewport: state.content?.viewport || 'default',
        });
    }, [assets]);
    return (_jsxs(_Fragment, { children: [_jsx(ButtonWithTooltip, { enableTooltip: showTooltip, tooltip: tooltipText, variant: 'secondary', onClick: openExportModal, label: t('Export'), disabled: isDisabled, content: isExporting ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm' }) })) : undefined }), isExportModalOpen && (_jsx(ExportModal, { onModalClose: closeExportModal, onHandleExport: handleExport, assets: assets, locales: locales, baseLocale: baseLocale }))] }));
};
