import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyL10nMap } from 'libs.localization.react';
export const useL10nMapSync = ({ l10SyncObjects, l10nMapResourceId, study, updateStudy, setL10nMap, }) => {
    const { studyId } = useParams();
    const { isPending, setStudyL10nMap } = useStudyL10nMap();
    const _syncObject = JSON.stringify(l10SyncObjects);
    useEffect(() => {
        if (l10SyncObjects && l10SyncObjects.length > 0 && !isPending) {
            setStudyL10nMap({
                studyId: Number(studyId),
                currentL10nMapId: l10nMapResourceId,
                assetLocaleVersionSet: l10SyncObjects,
            }, {
                onSuccess: (response) => {
                    if (response?.data?.id) {
                        study.l10nMapResourceId = response?.data?.id;
                        updateStudy?.(study);
                    }
                    if (response?.data?.data.data.mapping) {
                        setL10nMap?.(response?.data?.data.data.mapping);
                    }
                },
            });
        }
    }, [_syncObject, isPending]);
    return { isPending };
};
