import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage, ModalWindow, Select, Table, H5 } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { getResourceItem } from './helpers/resource_item.helpers';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
export const StudyResourcesList = () => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const [selectedResource, setSelectedResource] = useState(null);
    const [isResourceModalOpen, setResourceModalOpen, setResourceModalClose] = useBooleanState();
    const org = useOrgContext();
    const queryClient = useQueryClient();
    const [selectedLocale, setSelectedLocale] = useState({
        value: currentLocale,
        label: I18nUtils.getLanguageLabel(currentLocale, currentLocale, true),
    });
    const getLocaleRelatedResources = async () => {
        if (!environment) {
            throw new Error('Environment not found');
        }
        const response = await getApiInstance(environment).get(`c_study/${org._id}/c_study_resources`, {
            headers: {
                'Accept-Language': I18nUtils.cortexToI18nFormat(selectedLocale.value),
            },
        });
        return (response?.data?.data || [])
            .filter((resource) => ['both', 'site_only'].includes(resource.c_available_to))
            .sort((a, b) => a.c_order - b.c_order);
    };
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
        setResourceModalOpen();
    };
    const { data: resources, isFetching } = useQuery({
        queryKey: ['getStudyResources', org._id, selectedLocale.value],
        queryFn: getLocaleRelatedResources,
        enabled: true,
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey: ['getStudyResources', org._id, selectedLocale.value],
        });
    }, [selectedLocale]);
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(H5, { children: translate('Study resources') }), _jsx(ModalWindow, { title: selectedResource?.c_title ?? '', isOpen: isResourceModalOpen, closeWindow: setResourceModalClose, footerSecondaryActionButtons: [{ label: translate('Cancel'), onClick: setResourceModalClose }], width: 'lg', children: _jsx("div", { dangerouslySetInnerHTML: { __html: selectedResource?.c_html_content ?? '' } }) }), _jsx(Select, { dataTestId: 'resource-locale-select', label: translate('Resource language'), onChange: (value) => {
                    setSelectedLocale(value);
                }, options: org.c_supported_locales.map((locale) => ({
                    value: I18nUtils.cortexToI18nFormat(locale),
                    label: I18nUtils.getLanguageLabel(locale, currentLocale, true),
                })), value: selectedLocale, width: 'lg' }), _jsx(Table, { columns: [
                    {
                        header: translate('Resources'),
                        accessorKey: 'c_title',
                        cell: (value) => {
                            const resource = value.row.original;
                            return getResourceItem(resource, handleResourceClick);
                        },
                    },
                ], isLoading: isFetching, data: resources || [] })] }));
};
