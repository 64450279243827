import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationOrigin, LocalizationStatus } from 'libs.localization.common';
import { InlineNotification, useLocalizeMessage } from 'libs.nucleus';
export const AIGeneratedBanner = ({ status, origin, }) => {
    const t = useLocalizeMessage();
    const showAIBanner = status !== LocalizationStatus.READY &&
        (origin === LocalizationOrigin.AI_TRANSLATION || origin === LocalizationOrigin.AI_MIGRATION);
    if (!showAIBanner) {
        return _jsx("hr", { className: 'mb-6' });
    }
    return (_jsx("div", { className: 'mb-6', children: _jsx(InlineNotification, { title: t('AI generated'), subtitle: t('This content was generated via Medable AI. Please note that a human review is required prior to setting the translation to "Ready for use".') }) }));
};
