import { jsx as _jsx } from "react/jsx-runtime";
import { JobTypes } from 'libs.jobs.common';
import { AssetVersion, AssetVersionLoadingState } from '../asset_version';
export const AsssetVersionCell = ({ currentVersion, job }) => {
    let loadingState = AssetVersionLoadingState.LOADING;
    if (job && job.type === JobTypes.TRANSLATION__GENERATE_TRANSLATION) {
        loadingState = AssetVersionLoadingState.AI_TRANSLATING;
    }
    else if (currentVersion) {
        loadingState = AssetVersionLoadingState.DONE;
    }
    return (_jsx("td", { className: 'px-6 py-4 whitespace-nowrap text-center border-b border-r last:border-r-0', children: _jsx(AssetVersion, { currentVersion: currentVersion, loadingState: loadingState }) }));
};
