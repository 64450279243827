import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, useLocalizeMessage, useDialog, Table } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { ParticipantStatusModal } from './study_participant_status_modal.component';
import { useStudyParticipantStatusesColumns } from './study_participant_statuses_columns.hook';
import { StudyConfigurationContext } from '../../../../contexts';
import { GenericUtils, hasActivityStatusDependency } from '../../../../utils';
export const StudyParticipantStatuses = () => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { assignStudy, currentStudy, isReadOnlyMode, studyId, removeActivityStatusDependencies } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [participantStatusToEdit, setParticipantStatusToEdit] = useState();
    const [isCreateStatusModalOpen, openCreateStatusModal, closeCreateStatusModal] = useBooleanState(false);
    const participantStatuses = GenericUtils.sortBy(currentStudy.participantStatuses || [], 'value');
    /**
     * Opens the participant status modal on edit mode
     */
    const editParticipantStatus = (participantStatus) => {
        setParticipantStatusToEdit(participantStatus);
        openCreateStatusModal();
    };
    /**
     * Handles the save of the participant status
     * In order to save a new or existing status, it will decide if the status need to be replaced or added
     * it will also clean the list to have only one default status
     * @param participantStatus - The status to be saved
     */
    const handleOnSave = (participantStatus) => {
        const cleanedParticipantStatuses = cleanDefaultStatuses(participantStatus);
        const newParticipantStatuses = participantStatusToEdit
            ? cleanedParticipantStatuses.map((status) => (status.id === participantStatus.id ? participantStatus : status))
            : [...cleanedParticipantStatuses, participantStatus];
        logEvent(participantStatusToEdit ? 'study_status_edited' : 'study_status_added', {
            study_id: currentStudy.id,
            status_name: participantStatus.value,
        });
        setParticipantStatusToEdit(undefined);
        assignParticipantStatuses(newParticipantStatuses);
        closeCreateStatusModal();
    };
    /**
     * Returns a list of status where only one is set as default
     * @param participantStatus - The status taken as reference
     */
    const cleanDefaultStatuses = (participantStatus) => {
        return participantStatus.isDefault
            ? participantStatuses.map((status) => ({ ...status, isDefault: false }))
            : participantStatuses;
    };
    /**
     * Filters the statuses to delete the selected status from the list
     * @param participantStatus - The status to be deleted
     */
    const deleteParticipantStatus = async (participantStatus) => {
        const hasActivityStatusDependencies = hasActivityStatusDependency(participantStatus.id, currentStudy);
        const confirm = await dialog.open({
            title: translate('Delete status'),
            body: !hasActivityStatusDependencies
                ? translate('Are you sure you want to delete this status? This action cannot be undone.')
                : translate('You are about to delete a status that an activity or visit is dependent on. Please check your configured activity or visit status assignments before proceeding with deleting this status. Doing so will remove those dependencies.'),
            actionButtons: [
                { label: translate('Delete'), isDestructive: true },
                {
                    label: translate('Cancel'),
                },
            ],
        });
        if (confirm) {
            if (hasActivityStatusDependencies) {
                removeActivityStatusDependencies(participantStatus.id);
            }
            const newParticipantStatuses = participantStatuses.filter(({ id }) => id !== participantStatus.id);
            assignParticipantStatuses(newParticipantStatuses);
            logEvent('study_status_removed', { study_id: studyId, status_name: participantStatus.value });
        }
    };
    /**
     * Handles the assignation of a new list of statuses,
     * it will ensure the new list is sorted, and that the study information is updated
     * @param statuses - The new list of statuses
     */
    const assignParticipantStatuses = (statuses) => {
        currentStudy.updateStudyMetadata({ participantStatuses: statuses });
        assignStudy(currentStudy);
    };
    /**
     * Closes the participant status modal
     */
    const onClose = () => {
        setParticipantStatusToEdit(undefined);
        closeCreateStatusModal();
    };
    const tableColumns = useStudyParticipantStatusesColumns({
        deleteParticipantStatus,
        editParticipantStatus,
        disableEditing: isReadOnlyMode,
    });
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx("div", { className: 'flex justify-end', children: _jsx(Button, { label: translate('Create participant status'), onClick: openCreateStatusModal, variant: 'secondary', disabled: isReadOnlyMode }) }), _jsx(Table, { data: participantStatuses, columns: tableColumns, labelNoResults: translate('No participant statuses found') }), isCreateStatusModalOpen && (_jsx(ParticipantStatusModal, { initialParticipantStatus: participantStatusToEdit, onSave: handleOnSave, onClose: onClose }))] }));
};
