import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { useLocalizeMessage, Spinner, Text, ModalDialog } from 'libs.nucleus';
export const StudyTranslationStatusModal = ({ onModalClose, onHandleStatusChange, isLoading, currentStatus, }) => {
    const t = useLocalizeMessage();
    const isDraft = currentStatus === StudyLocalizationStatus.DRAFT || currentStatus === StudyLocalizationStatus.NOT_STARTED;
    const doneButton = {
        label: isDraft ? t('Lock study') : t('Unlock study'),
        onClick: onHandleStatusChange,
        content: isLoading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: 'alt' }) })) : undefined,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
        // disabled: isLoading, // temperory commented
    };
    return (_jsx(ModalDialog, { width: 'lg', title: isDraft ? t('Lock build for translations') : t('Unlock study build'), isOpen: true, actionButtons: [doneButton, closeButton], closeDialog: onModalClose, body: isDraft ? (_jsxs("div", { children: [_jsx(Text, { className: 'text-text-secondary mb-6', children: t('You are about to lock all assets to start translations. When your study is locked:') }), _jsxs("ul", { className: 'list-disc ps-7 leading-8 marker:text-text-secondary', children: [_jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('All edit screens will be read-only, which will be indicated with a banner.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Non-library assets will be set to v1.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Translation can get started.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('To edit the assets, you will have to Unlock the build.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Editing could invalidate related translations.') }) })] })] })) : (_jsxs("div", { children: [_jsx(Text, { className: 'text-text-secondary mb-6', children: t('You are about to unlock all assets for modification. When your study is unlocked:') }), _jsxs("ul", { className: 'list-disc ps-7 leading-8 marker:text-text-secondary', children: [_jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('All previously disabled edit sections will become editable.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Be aware that editing may invalidate your current translations progress.') }) })] })] })) }));
};
