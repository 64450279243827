import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Select, useLocalizeMessage } from 'libs.nucleus';
import { SOATimelineMatrix } from './soa_graph_timeline_matrix.component';
import { SOATestPlan } from './soa_testplan.component';
import { SOAGraphNodeType, StudyTestPlanExecutionModes, StudyTestPlanStepType, StudyTestPlanStepUserTypes, } from '../../types';
import { GenericUtils, getDisplayTime, getTimeToTravel, isUnscheduledVisit, isVisitConfirmation, SOAGraphUtils, } from '../../utils';
import TestPlanUserInputEditModal from '../study_test_plan_details/user_input_column/test_plan_user_input_edit_modal.component';
const DEFAULT_ROWS = [
    {
        'Step Type': StudyTestPlanStepType.CreateUser,
        'User': StudyTestPlanStepUserTypes.ADMIN,
        'Execution Mode': StudyTestPlanExecutionModes.BACKEND,
        'Context': `Role:Axon Site User\nUser:SiteUser1`,
    },
    {
        'Step Type': StudyTestPlanStepType.CreateParticipant,
        'User': 'SiteUser1',
        'Execution Mode': StudyTestPlanExecutionModes.BACKEND,
        'Context': 'User:Participant1',
    },
];
const DEFAULT_USERS = {
    [StudyTestPlanStepUserTypes.ADMIN]: ['Admin'],
    [StudyTestPlanStepUserTypes.SITE_USER]: ['SiteUser1'],
    [StudyTestPlanStepUserTypes.PARTICIPANT]: ['Participant1'],
};
export const SOAGraphPlayground = forwardRef(({ soaGraph, study, ecoaActivities }, ref) => {
    const translate = useLocalizeMessage();
    const soaGraphUtils = useRef(new SOAGraphUtils(soaGraph));
    const [state, setState] = useState({
        tasks: {},
        actions: [],
        trace: [],
        gates: [],
        timeTravelled: 0,
    });
    const [history, setHistory] = useState([]);
    const [isStarted, setIsStarted] = useState(false);
    const [activityToComplete, setActivityToComplete] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(undefined);
    // merge time travel nodes with same properties into one
    const mergeTimeTravelNodes = (actions) => {
        const timeTravelGroups = actions.reduce((groups, node) => {
            const key = JSON.stringify(node.properties);
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(node);
            return groups;
        }, {});
        // merge time travel nodes with same properties into one with a new property `siblings`
        const mergedTimeTravelNodes = Object.values(timeTravelGroups).map((group) => {
            if (group.length === 1) {
                return group[0];
            }
            const siblings = group.map((node) => node.nodeId);
            return {
                ...group[0],
                siblings,
            };
        });
        return [...mergedTimeTravelNodes];
    };
    const saveState = (newState) => {
        const prevState = structuredClone(state);
        setState({ ...newState, actions: mergeTimeTravelNodes(newState.actions) });
        setHistory((prevHistory) => [...prevHistory, prevState]);
    };
    const undo = () => {
        if (history.length === 0) {
            return;
        }
        const prevState = history.pop();
        setState(structuredClone(prevState));
    };
    const init = (tasks) => {
        const { startNodes } = soaGraph;
        const trace = [];
        const actions = [];
        const gates = [];
        for (const startNodeId of startNodes) {
            const startNode = soaGraph.nodes[startNodeId];
            if (startNode.nodeType === SOAGraphNodeType.CONDITION &&
                startNode.properties.conditionType === 'uponStudyCreation' &&
                startNode.properties.uponStudyCreation) {
                trace.push({ nodeId: startNode.nodeId });
                for (const child of startNode.children) {
                    soaGraphUtils.current.processNode({
                        node: soaGraph.nodes[child],
                        trace,
                        tasks,
                        actions,
                        gates,
                    });
                }
            }
            else {
                soaGraphUtils.current.processNode({
                    node: startNode,
                    trace,
                    tasks,
                    actions,
                    gates,
                });
            }
        }
        setState({
            tasks,
            actions,
            trace,
            gates,
            timeTravelled: 0,
        });
    };
    const start = () => {
        setIsStarted(true);
        init(state.tasks);
    };
    const findAndSetTasks = () => {
        const { nodes } = soaGraph;
        const tasks = {};
        Object.values(nodes).forEach((node) => {
            if (node.nodeType === SOAGraphNodeType.ASSIGNMENT_AVAILABLE ||
                node.nodeType === SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE) {
                const { id: assignmentId, activityName, visitName, type } = node.properties;
                if (node.properties.type === 'site') {
                    tasks[assignmentId] = {
                        id: node.nodeId,
                        activityName,
                        visitName,
                        status: SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE,
                        type,
                    };
                }
                else if (node.properties.type === 'participant') {
                    tasks[assignmentId] = {
                        id: node.nodeId,
                        activityName,
                        visitName,
                        status: SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE,
                        type,
                    };
                }
            }
        });
        setState({
            tasks,
            actions: [],
            trace: [],
            gates: [],
            timeTravelled: 0,
        });
    };
    const reset = () => {
        setIsStarted(false);
        setHistory([]);
        findAndSetTasks();
    };
    useEffect(() => {
        findAndSetTasks();
    }, []);
    const completeTask = (nodeId, userInput) => {
        const newState = soaGraphUtils.current.completeTask({
            nodeId,
            state,
            userInput,
        });
        if (newState) {
            saveState(newState);
        }
    };
    const completeAction = (actionNode) => {
        const newState = soaGraphUtils.current.completeTimeTravel({
            actionNode,
            state,
        });
        if (newState) {
            saveState(newState);
        }
    };
    const timelines = useMemo(() => {
        const { nodes } = soaGraph;
        const timelines = {
            Onboarding: {
                visits: new Set(),
                groupOrder: {},
                visitActivities: {},
                nonVisitActivities: {},
            },
        };
        Object.values(nodes).forEach((node) => {
            if (node.nodeType === SOAGraphNodeType.ASSIGNMENT_AVAILABLE ||
                node.nodeType === SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE) {
                let { activityName, visitName, timeline = 'Onboarding', type, id: assignmentId, order, groupOrder, activityId, } = node.properties;
                if (!timelines[timeline]) {
                    timelines[timeline] = {
                        visits: new Set(),
                        groupOrder: {},
                        visitActivities: {},
                        nonVisitActivities: {},
                    };
                }
                if (visitName) {
                    timelines[timeline].visits.add(visitName);
                    timelines[timeline].groupOrder[visitName] = groupOrder;
                }
                if (isVisitConfirmation(node.properties.activityName)) {
                    activityName = 'Visit Confirmation';
                    activityId = 'visit-confirmation';
                    order = -1;
                }
                if (visitName) {
                    timelines[timeline].visitActivities[activityId] = {
                        activityName,
                        type,
                        order,
                        assignmentIds: [...(timelines[timeline].visitActivities[activityId]?.assignmentIds || []), assignmentId],
                    };
                }
                else {
                    timelines[timeline].nonVisitActivities[assignmentId] = {
                        activityName,
                        type,
                        order,
                        assignmentIds: [
                            ...(timelines[timeline].nonVisitActivities[assignmentId]?.assignmentIds || []),
                            assignmentId,
                        ],
                    };
                }
            }
        });
        const sortedTimelines = {};
        Object.entries(timelines).forEach(([timeline, data]) => {
            const visits = Array.from(data.visits).sort((a, b) => data.groupOrder[a] - data.groupOrder[b]);
            const visitActivities = Object.values(data.visitActivities)
                .sort((a, b) => a.order - b.order)
                .map((activity) => ({
                ...activity,
                assignmentIds: Array.from(new Set(activity.assignmentIds)),
            }));
            const nonVisitActivities = Object.values(data.nonVisitActivities)
                .sort((a, b) => a.order - b.order)
                .map((activity) => ({
                ...activity,
                assignmentIds: Array.from(new Set(activity.assignmentIds)),
            }));
            sortedTimelines[timeline] = {
                visits,
                visitActivities,
                nonVisitActivities,
            };
        });
        const { Onboarding, ...rest } = sortedTimelines;
        return {
            Onboarding,
            Schedule: rest,
        };
    }, [soaGraph]);
    useEffect(() => {
        const timelineKeys = Object.keys(timelines.Schedule);
        if (timelineKeys.length > 0) {
            const timeline = timelineKeys[0];
            setSelectedTimeline({
                label: timeline,
                value: timeline,
            });
        }
    }, [timelines.Schedule]);
    const taskToComplete = useMemo(() => {
        if (activityToComplete) {
            const node = soaGraph.nodes[activityToComplete];
            if (node.nodeType !== SOAGraphNodeType.ASSIGNMENT_AVAILABLE) {
                return null;
            }
            const activities = study.getAllActivities();
            const activity = activities.find((activity) => {
                if (node.properties.type === 'site' &&
                    isVisitConfirmation(node.properties.activityName) &&
                    activity.isVisitConfirmation) {
                    return true;
                }
                return (activity.libraryId === node.properties.activityId ||
                    GenericUtils.isSameString(activity.name, node.properties.activityName));
            });
            if (!activity) {
                console.error('Activity not found', node.properties, activities);
                return null;
            }
            const ecoaActivity = ecoaActivities.find((activity) => activity.data.identifier === node.properties.activityCode);
            return {
                node,
                activity: activity,
                ecoaActivity: ecoaActivity?.data,
                stepType: node.properties.type === 'site'
                    ? StudyTestPlanStepType.CompleteSiteTask
                    : StudyTestPlanStepType.CompleteParticipantTask,
            };
        }
        return null;
    }, [activityToComplete, soaGraph, study, ecoaActivities]);
    const testPlan = useMemo(() => {
        const plan = [...DEFAULT_ROWS];
        const { trace } = state;
        const { nodes } = soaGraph;
        const getActivityName = (node) => {
            if (node.nodeType === SOAGraphNodeType.ASSIGNMENT_AVAILABLE ||
                node.nodeType === SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE ||
                node.nodeType === SOAGraphNodeType.ASSIGNMENT_COMPLETE) {
                const activities = study.getAllActivities();
                const activity = activities.find((activity) => {
                    if (node.properties.type === 'site' &&
                        isVisitConfirmation(node.properties.activityName) &&
                        activity.isVisitConfirmation) {
                        return true;
                    }
                    return (activity.libraryId === node.properties.activityId ||
                        GenericUtils.isSameString(activity.name, node.properties.activityName));
                });
                const ecoaActivity = ecoaActivities.find((activity) => activity.data.identifier === node.properties.activityCode);
                return ecoaActivity?.data.full_name || activity?.name || node.properties.activityName;
            }
            return '';
        };
        for (const traceItem of trace) {
            const node = nodes[traceItem.nodeId];
            switch (node.nodeType) {
                case SOAGraphNodeType.ASSIGNMENT_COMPLETE: {
                    const activityName = getActivityName(node);
                    if (node.properties.type === 'site') {
                        const { groupName = 'Onboarding' } = node.properties;
                        plan.push({
                            'Step Type': StudyTestPlanStepType.CompleteSiteTask,
                            'User': 'SiteUser1',
                            'Execution Mode': StudyTestPlanExecutionModes.SITE_WEB,
                            'User Input': JSON.stringify(traceItem.meta?.userInput),
                            'Context': `User:Participant1\nGroupName:${groupName}\nTaskName:${activityName}`,
                        });
                    }
                    else if (node.properties.type === 'participant') {
                        const { groupName = 'Onboarding' } = node.properties;
                        plan.push({
                            'Step Type': StudyTestPlanStepType.CompleteParticipantTask,
                            'User': 'Participant1',
                            'Execution Mode': StudyTestPlanExecutionModes.PATIENT_WEB,
                            'User Input': JSON.stringify(traceItem.meta?.userInput),
                            'Context': `GroupName:${groupName}\nTaskName:${activityName}`,
                        });
                    }
                    break;
                }
                case SOAGraphNodeType.TIME_TRAVEL: {
                    if (traceItem.meta && traceItem.meta.timeTravelled) {
                        const { timeTravelled } = traceItem.meta;
                        plan.push({
                            'Step Type': StudyTestPlanStepType.TimeTravel,
                            'User': 'Admin',
                            'Context': `Duration:${timeTravelled}m`,
                            'Execution Mode': StudyTestPlanExecutionModes.BACKEND,
                        });
                    }
                    break;
                }
            }
        }
        return plan;
    }, [state.trace]);
    useImperativeHandle(ref, () => ({
        getTestPlan: () => testPlan,
    }));
    const timeTravelOptions = useMemo(() => {
        const items = [];
        for (const actionNode of state.actions) {
            const { eod } = actionNode.properties;
            const time = getTimeToTravel(actionNode);
            const label = eod
                ? translate('Time Travel to end of day')
                : translate('Time Travel {to}', {
                    to: getDisplayTime(time),
                });
            const nodeIdsToProcess = actionNode.siblings ? actionNode.siblings : [actionNode.nodeId];
            const children = nodeIdsToProcess
                .map((nodeId) => soaGraph.nodes[nodeId])
                .map((node) => node.children.map((childId) => soaGraph.nodes[childId]))
                .flat();
            const trace = [
                ...state.trace,
                ...nodeIdsToProcess.map((nodeId) => ({
                    nodeId,
                })),
            ];
            const gateNodes = children.filter((node) => node.nodeType === SOAGraphNodeType.GATE);
            gateNodes.forEach((gate) => {
                const isGateOpened = soaGraphUtils.current.checkIfGateOpened({
                    gate,
                    trace,
                });
                if (isGateOpened) {
                    trace.push({ nodeId: gate.nodeId });
                    gate.children.forEach((childId) => {
                        const child = soaGraph.nodes[childId];
                        children.push(child);
                    });
                }
            });
            const availableTasks = children.filter((node) => node.nodeType === SOAGraphNodeType.ASSIGNMENT_AVAILABLE);
            const unavailableTasks = children.filter((node) => node.nodeType === SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE);
            if (availableTasks.length === 0 && unavailableTasks.length === 0) {
                continue;
            }
            items.push({
                id: nodeIdsToProcess[0].toString(),
                offset: time,
                content: (_jsxs("div", { className: 'max-w-[400px]', children: [_jsx("span", { className: 'font-medium text-md text-gray-800', children: label }), _jsxs("div", { className: 'text-sm text-gray-600', children: [availableTasks.length > 0 ? (_jsxs("p", { children: [_jsxs("span", { className: 'text-green-600', children: [translate('Available tasks'), ": "] }), availableTasks.map((node) => node.properties.activityName).join(', ')] })) : null, unavailableTasks.length > 0 ? (_jsxs("p", { children: [_jsxs("span", { className: 'text-red-600', children: [translate('Unavailable tasks'), ": "] }), unavailableTasks.map((node) => node.properties.activityName).join(', ')] })) : null] })] })),
                onClick: () => {
                    completeAction(actionNode);
                },
            });
        }
        items.sort((a, b) => a.offset - b.offset);
        return items;
    }, [state.actions, state.trace]);
    const selectedTimelineData = useMemo(() => {
        if (!selectedTimeline) {
            return null;
        }
        const timeline = timelines.Schedule[selectedTimeline.value];
        // divide tasks into visitNames
        const visits = Object.values(state.tasks).reduce((acc, task) => {
            if (task.visitName) {
                acc[task.visitName] = [...(acc[task.visitName] || []), task];
            }
            return acc;
        }, {});
        const filteredHeaders = timeline.visits.filter((visit) => {
            if (isUnscheduledVisit(visit)) {
                const tasks = visits[visit];
                const hasAvailableOrCompleteTask = tasks.some((task) => {
                    return (task.status === SOAGraphNodeType.ASSIGNMENT_AVAILABLE ||
                        task.status === SOAGraphNodeType.ASSIGNMENT_COMPLETE);
                });
                return hasAvailableOrCompleteTask;
            }
            return true;
        });
        return (_jsxs("div", { className: 'bg-white', children: [_jsx("h4", { className: 'text-x font-semibold text-gray-800 mb-4', children: selectedTimeline.value }), _jsx(SOATimelineMatrix, { headerLabels: filteredHeaders, tasks: state.tasks, sections: [
                        {
                            header: translate('VISIT ACTIVITIES'),
                            items: timeline.visitActivities,
                            minRowsDisplayed: 1,
                            dataTestId: 'schedule-timeline-visit-activities',
                        },
                        {
                            header: translate('NON-VISIT ACTIVITIES'),
                            items: timeline.nonVisitActivities,
                            minRowsDisplayed: 1,
                            dataTestId: 'schedule-timeline-remote-activities',
                        },
                    ], completeTask: setActivityToComplete })] }, selectedTimeline.value));
    }, [selectedTimeline, state.tasks]);
    return (_jsxs("div", { children: [_jsx("div", { className: 'flex justify-end', children: _jsxs("div", { className: 'flex items-center gap-2', children: [_jsxs("span", { children: [translate('Time travelled'), ": ", getDisplayTime(state.timeTravelled)] }), _jsx(Dropdown, { items: timeTravelOptions, label: translate('Time Travel ({options})', {
                                options: state.actions.length,
                            }), variant: 'default' }), _jsx(Button, { onClick: undo, disabled: history.length === 0, label: translate('Undo ({steps})', {
                                steps: history.length,
                            }) }), isStarted ? (_jsx(Button, { onClick: reset, label: translate('Reset') })) : (_jsx(Button, { onClick: start, label: translate('Start') }))] }) }), _jsxs("div", { children: [_jsx("h4", { className: 'text-x font-semibold text-gray-800 mt-8 mb-4', children: "Onboarding" }), _jsx(SOATimelineMatrix, { isOnboarding: true, headerLabels: [translate('Onboarding')], tasks: state.tasks, sections: [
                            {
                                dataTestId: 'schedule-timeline-visit-activities',
                                header: 'onboarding',
                                isStatic: true,
                                items: [...timelines.Onboarding.visitActivities, ...timelines.Onboarding.nonVisitActivities].sort((a, b) => a.order - b.order),
                                minRowsDisplayed: 1,
                            },
                        ], completeTask: setActivityToComplete }), timelines.Schedule ? (_jsxs(_Fragment, { children: [_jsx("div", { className: 'mt-8 mb-4', children: _jsx(Select, { options: Object.keys(timelines.Schedule).map((timeline) => ({
                                        label: timeline,
                                        value: timeline,
                                    })), label: translate('Select Timeline'), value: selectedTimeline, onChange: (value) => setSelectedTimeline(value) }) }), selectedTimelineData] })) : null] }), taskToComplete ? (_jsx(TestPlanUserInputEditModal, { onClose: () => setActivityToComplete(null), study: study, stepType: taskToComplete.stepType, activity: taskToComplete.activity, ecoaActivity: taskToComplete.ecoaActivity, taskName: taskToComplete.node.properties.activityName, cell: {
                    value: '',
                }, useDefaultConfig: true, column: 0, row: 0, onChange: (cell) => {
                    completeTask(taskToComplete.node.nodeId, cell.value ? JSON.parse(cell.value) : null);
                    setActivityToComplete(null);
                }, exitEditMode: () => setActivityToComplete(null) })) : null, _jsx("div", { className: 'flex flex-col mt-8', children: _jsx(SOATestPlan, { study: study, ecoaActivities: ecoaActivities, testPlanCsv: testPlan, users: DEFAULT_USERS }) })] }));
});
SOAGraphPlayground.displayName = 'SOAGraphPlayground';
