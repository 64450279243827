import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useContext, useEffect } from 'react';
import { matchPath } from 'react-router';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Spinner } from 'libs.nucleus';
import { AuthContext, AuthRoute, ForgotPasswordPage, LoginPage, NotFoundPage, PublicRoute, ResetPasswordPage, } from 'libs.react';
import { useSelfServiceNavigation } from '../../hooks';
import { BuildInfo } from '../build_info';
import { PrivateRoute } from '../routes';
// Whitelist of routes that are accessible without authentication
const routeWhitelist = [AuthRoute.LOGIN, AuthRoute.FORGOT_PASSWORD, AuthRoute.RESET_PASSWORD, PublicRoute.VERSION];
export const Router = () => {
    const { appRoutes, defaultAppSection } = useSelfServiceNavigation();
    const { isLoggedIn, navigateToLogin, user } = useContext(AuthContext);
    const location = useLocation();
    const { logEvent, setAnalyticsUserId } = useContext(FirebaseAppContext);
    useEffect(() => {
        setAnalyticsUserId(user ? user.path : null);
        if (!isLoggedIn) {
            for (const route of routeWhitelist) {
                if (matchPath({ path: route, caseSensitive: false, end: false }, location.pathname)) {
                    return;
                }
            }
            navigateToLogin();
        }
    }, [isLoggedIn]);
    useEffect(() => {
        logEvent('page_view', { page_location: location.pathname });
    }, [location]);
    return (_jsx(Suspense, { fallback: _jsx(Spinner, { size: 'full' }), children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Navigate, { to: defaultAppSection.path }) }), _jsx(Route, { path: AuthRoute.LOGIN, element: _jsx(LoginPage, {}) }), _jsx(Route, { path: AuthRoute.FORGOT_PASSWORD, element: _jsx(ForgotPasswordPage, {}) }), _jsx(Route, { path: AuthRoute.RESET_PASSWORD, element: _jsx(ResetPasswordPage, {}) }), _jsx(Route, { path: PublicRoute.VERSION, element: _jsx(BuildInfo, {}) }), Object.values(appRoutes).map((appsSection) => (_jsxs(Route, { path: appsSection.path, element: _jsx(PrivateRoute, { sectionPath: appsSection.path, navItems: !appsSection.hideSideNav && appsSection.appRoutes }), children: [_jsx(Route, { path: appsSection.path, element: _jsx(Navigate, { to: appsSection.redirectTo || appsSection.appRoutes[0].path }) }), appsSection.appRoutes.map(({ path, component }) => component && _jsx(Route, { path: `${path}/*`, Component: component }, path))] }, appsSection.name))), _jsx(Route, { path: '*', element: _jsx(PrivateRoute, { sectionPath: '*' }), children: _jsx(Route, { path: '*', Component: NotFoundPage }) })] }) }));
};
