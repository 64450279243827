import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useLocalizeMessage, Select, Text } from 'libs.nucleus';
import { StudyConfigurationContext } from '../../../../contexts';
export const SelectActivityResponse = ({ screenType = 'vrs', options, selectedOption, onSelectedOptionChange, }) => {
    const translate = useLocalizeMessage();
    const { isReadOnlyMode } = useContext(StudyConfigurationContext);
    const handleOptionChange = (option, type) => {
        if (option.value === selectedOption[type].value) {
            return;
        }
        if (type === 'activity') {
            const screen = options.screenOptions[option.value].screens[0];
            onSelectedOptionChange({
                activity: option,
                screen,
                response: options.screenOptions[option.value].responses[screen.value][0],
            });
        }
        else if (type === 'screen') {
            const instanceId = selectedOption.activity.value;
            onSelectedOptionChange({
                ...selectedOption,
                screen: option,
                response: options.screenOptions[instanceId].responses[option.value][0],
            });
        }
        else {
            onSelectedOptionChange({ ...selectedOption, response: option });
        }
    };
    return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Activity') }), _jsx(Select, { width: 'full', value: selectedOption.activity, options: options.activities, onChange: (option) => handleOptionChange(option, 'activity'), disabled: isReadOnlyMode })] }), _jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Screen') }), _jsx(Select, { width: 'full', value: selectedOption.screen, options: options.screenOptions[selectedOption.activity.value].screens, onChange: (option) => handleOptionChange(option, 'screen'), disabled: !selectedOption.activity.value || isReadOnlyMode })] }), screenType === 'vrs' && (_jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Response') }), _jsx(Select, { width: 'full', value: selectedOption.response, options: options.screenOptions[selectedOption.activity.value].responses[selectedOption.screen.value], onChange: (option) => handleOptionChange(option, 'response'), disabled: !selectedOption.screen.value || isReadOnlyMode })] }))] }));
};
