import { jsx as _jsx } from "react/jsx-runtime";
import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
const RowIndicator = ({ row, label, selected, onSelect, showContextMenu }) => {
    const { listeners, active } = useSortable({ id: row });
    const handleClick = React.useCallback((event) => {
        onSelect(row, event.shiftKey);
    }, [onSelect, row]);
    if (selected) {
        return (_jsx("th", { className: classNames(SpreadsheetStyles.header, SpreadsheetStyles.headerSelected), style: {
                cursor: active ? 'grab' : 'pointer',
            }, tabIndex: 0, ...listeners, onContextMenu: (e) => {
                e.preventDefault();
                showContextMenu(e);
            }, children: label !== undefined ? label : row + 1 }));
    }
    return (_jsx("th", { className: classNames(SpreadsheetStyles.header), onClick: handleClick, tabIndex: 0, onContextMenu: (e) => {
            e.preventDefault();
            onSelect(row, false);
            showContextMenu(e);
        }, children: label !== undefined ? label : row + 1 }));
};
export default RowIndicator;
export const enhance = (RowIndicatorComponent) => {
    return function RowIndicatorWrapper(props) {
        const { selectEntireRow, showContextMenu } = useSpreadsheetDispatch();
        const selected = useSpreadsheetSelector((state) => state.selected.hasEntireRow(props.row));
        const showIndicatorContextMenu = React.useCallback((e) => {
            showContextMenu(e, 'indicator');
        }, [showContextMenu]);
        return (_jsx(RowIndicatorComponent, { ...props, selected: selected, onSelect: selectEntireRow, showContextMenu: showIndicatorContextMenu }));
    };
};
