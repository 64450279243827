import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { selectedDeviceAtom, deviceZoomLevelAtom } from 'libs.preview';
export function useDeviceScale(wrapperRef, isPair) {
    const [device] = useAtom(selectedDeviceAtom);
    const [, setZoomLevel] = useAtom(deviceZoomLevelAtom);
    useEffect(() => {
        if (wrapperRef.current) {
            const rect = wrapperRef.current.getBoundingClientRect();
            // accomodating 1rem gap for compare mode
            const viewportWidth = isPair ? rect.width - 16 : rect.width;
            const scale = calculateScale(device.width, viewportWidth, isPair);
            setZoomLevel(scale);
        }
    }, [device]);
}
// @TODO To check if resize event is needed?
function calculateScale(deviceWidth, viewportWidth, isPair) {
    // Calculate scale based on viewport width
    const scale = viewportWidth / (deviceWidth * (isPair ? 2 : 1));
    return Math.max(0.2, Math.min(1, scale));
}
