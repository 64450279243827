export const getResourceAvailabilityStringFromKey = {
    site_only: 'Site app',
    patient_only: 'Patient app',
    site_patient: 'Site and patient apps',
};
export const getResourceContentTypeString = (resource) => {
    if (resource.type === 'link') {
        return 'URL';
    }
    if (resource.type === 'file') {
        return 'PDF';
    }
    return 'Text';
};
