import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { EntireWorksheetSelection } from '../../utils/spreadsheet';
const CornerIndicator = ({ selected, onSelect }) => {
    const handleClick = React.useCallback(() => {
        onSelect();
    }, [onSelect]);
    return (_jsx("th", { className: classNames(SpreadsheetStyles.header, {
            [SpreadsheetStyles.headerSelected]: selected,
        }), onClick: handleClick, tabIndex: 0 }));
};
export default CornerIndicator;
export const enhance = (CornerIndicatorComponent) => {
    return function CornerIndicatorWrapper(props) {
        const { selectEntireWorksheet } = useSpreadsheetDispatch();
        const selected = useSpreadsheetSelector((state) => state.selected instanceof EntireWorksheetSelection);
        return _jsx(CornerIndicatorComponent, { ...props, selected: selected, onSelect: selectEntireWorksheet });
    };
};
