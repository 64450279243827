import { useMutation } from '@tanstack/react-query';
import { ApiClientService, useApiClient } from 'libs.react';
export const useLocalizedBundle = (isCustomActivity = false) => {
    const { post } = useApiClient(ApiClientService.WEB);
    const url = isCustomActivity ? '/api/custom_ecoa/localized' : '/api/instrument_bundler/localized';
    return useMutation({
        mutationKey: ['instrument_bundler', 'localized'],
        mutationFn: ({ resourceId, locales }) => {
            return post(url, { resourceId, localesWithVersion: locales });
        },
        retry: false,
    });
};
