import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useLocalizeMessage, useDialog } from 'libs.nucleus';
import { ApiClientService, useApiClient, useToastNotification, LibraryEndpoint, LibraryResourceStatus, } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useApproveVersion = () => {
    const dialog = useDialog();
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { studyId } = useContext(StudyConfigurationContext);
    const queryClient = useQueryClient();
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const changeVersion = async (version) => {
        const newStatus = version.status === LibraryResourceStatus.APPROVED
            ? LibraryResourceStatus.PUBLISHED
            : LibraryResourceStatus.APPROVED;
        try {
            await libraryClient.put(`${LibraryEndpoint.GET_STUDY_CONFIGS}/${version.id}`, { status: newStatus });
            await queryClient?.invalidateQueries({ queryKey: ['study-configs', studyId] });
            return true;
        }
        catch (error) {
            showErrorMessage(newStatus);
            return false;
        }
    };
    const showApproveModal = async (version) => {
        const isApproved = version.status === LibraryResourceStatus.APPROVED;
        const ok = await dialog.open({
            title: isApproved ? translate('Disapprove study version?') : translate('Approve study version?'),
            body: isApproved
                ? translate('Disapproving this study version will change the status from Approved back to Published. It cannot be deployed to production until it is Approved again.')
                : translate('Approving your validated study build version from Published to Approved status will allow it to be deployed to a production environment. Once it is marked as Approved, only workspace administrators can move it back to a published status.'),
            hasOverlay: true,
            willCloseOnOutsideClickAndEscapeKeyPress: false,
            actionButtons: [
                {
                    label: isApproved ? translate('Disapprove') : translate('Approve'),
                    onClick: () => changeVersion(version),
                },
                { label: translate('Cancel') },
            ],
        });
        console.log(ok);
    };
    const showErrorMessage = (status) => {
        addNotification({
            title: translate('Error'),
            subtitle: status === LibraryResourceStatus.PUBLISHED
                ? translate('There was an error when approving the version. Please try again.')
                : translate('There was an error when disapproving the version. Please try again.'),
            type: 'error',
        });
    };
    return {
        showApproveModal,
    };
};
