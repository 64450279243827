import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Pill } from 'libs.nucleus';
export const DeploymentStatus = ({ workflow }) => {
    const translate = useLocalizeMessage();
    const statusMap = {
        active: { iconColor: 'yellow', label: translate('Running') },
        completed: { iconColor: 'green', label: translate('Success') },
        failed: { iconColor: 'red', label: translate('Failed') },
    };
    const { label, iconColor } = statusMap[workflow.status] || statusMap.active;
    return _jsx(Pill, { label: label, iconBackgroundColor: iconColor, variant: 'status' });
};
