import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import Papaparse from 'papaparse';
import { useContext, useMemo, useState } from 'react';
import { FormSections, useLocalizeMessage, ModalWindow, Select, Spinner, } from 'libs.nucleus';
import { ApiClientService, AuthContext, LibraryEndpoint, useApiClient, useToastNotification, } from 'libs.react';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
import { sanitizeStudy, validateTestPlanCSV } from '../../utils';
import { DeploymentUtils } from '../../utils/deployment';
export const StudyTestPlanExecuteModal = ({ testPlan, onClose, versionId }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const momClient = useApiClient(ApiClientService.MOM);
    const { entityId } = useContext(AuthContext);
    const { executeTestPlan } = useContext(StudyTestPlanContext);
    const { currentStudy, studyId } = useContext(StudyConfigurationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState();
    const retrieveTests = async () => {
        const endpoint = `/v1/entities/${entityId}/studies/${studyId}/environments?withWorkflows=true&clusterTypes=dev,ephemeral,sandbox`;
        const { data: response } = await momClient.get(endpoint);
        // We sort the tests by updated date to show newer first
        return response.data
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .filter((test) => {
            const status = DeploymentUtils.getEnvironmentStatus(test);
            return status === 'completed';
        });
    };
    const { data: studyTests = [], isLoading: isLoadingTests } = useQuery({
        queryKey: ['entities', entityId, 'studies', studyId, 'environments', 'completed'],
        queryFn: retrieveTests,
        enabled: !!entityId,
    });
    const saveDisabled = useMemo(() => {
        return !studyTestForm || !studyTestForm.sandbox.value || isLoading || isLoadingTests;
    }, [studyTestForm, isLoading, isLoadingTests]);
    const testOptions = useMemo(() => studyTests
        .filter((test) => test.type === 'ephemeral')
        .map((test) => ({
        label: translate(`${test.metadata.name}`),
        value: test.id.toString(),
        meta: {
            package: test.metadata.packages.find((pkg) => pkg.name === currentStudy.name),
        },
    }))
        .filter((test) => {
        if (versionId) {
            return test.meta.package?.resourceId === versionId;
        }
        return Boolean(test.meta.package);
    }), [studyTests, versionId]);
    const saveTest = async () => {
        if (!saveDisabled && studyTestForm) {
            try {
                setIsLoading(true);
                const { package: currentPackage } = studyTestForm.sandbox.meta;
                if (!currentPackage) {
                    addNotification({
                        type: 'error',
                        title: translate('Error executing study'),
                        subtitle: translate('The selected sandbox does not have a package'),
                    });
                    return;
                }
                const { resourceId } = currentPackage;
                if (!resourceId) {
                    addNotification({
                        type: 'error',
                        title: translate('Error executing study'),
                        subtitle: translate('The selected sandbox does not have a resource ID'),
                    });
                    return;
                }
                const { data: studyConfigResponse } = await libClient.get(LibraryEndpoint.GET_STUDY_CONFIGS, {
                    params: { id: resourceId },
                });
                const study = sanitizeStudy(studyConfigResponse.data[0].data);
                const activities = study.getAllActivities().filter((activity) => activity.libraryId);
                const { data: activitiesResponse } = await libClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, {
                    params: { id: activities.map((activity) => activity.libraryId) },
                });
                if (!testPlan) {
                    addNotification({
                        type: 'error',
                        title: translate('Test plan not found'),
                    });
                    return;
                }
                const validations = validateTestPlanCSV(testPlan.data.csv, study, activitiesResponse.data);
                if (!validations.valid) {
                    addNotification({
                        type: 'error',
                        title: translate('The test plan has errors, please fix them before executing the test plan'),
                    });
                    return;
                }
                // add a Test Case No column to the CSV at the beginning
                const testPlanWithTestCase = testPlan.data.csv.map((row, index) => {
                    delete row['Test Case No'];
                    return { 'Test Case No': index + 1, ...row };
                });
                const csv = Papaparse.unparse(testPlanWithTestCase, {
                    header: true,
                    skipEmptyLines: true,
                    columns: ['Test Case No', 'Step Type', 'User', 'Context', 'Execution Mode', 'User Input'],
                });
                executeTestPlan({
                    testPlanId: testPlan.id,
                    csv,
                    sandboxId: Number(studyTestForm.sandbox.value),
                    package: {
                        name: currentPackage.name,
                        version: currentPackage.version,
                        resourceId: currentPackage.resourceId,
                        resourceKey: currentPackage.resourceKey,
                    },
                });
                onClose();
            }
            catch (error) {
                console.error('Error executing study configuration', error);
                onDeployError();
            }
            finally {
                setIsLoading(false);
            }
        }
    };
    const onDeployError = () => {
        addNotification({ title: translate('Error executing study'), type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Execute'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleTestChange = (selectedTest) => {
        setStudyTestForm({
            sandbox: selectedTest,
        });
    };
    const formSections = [
        {
            title: translate('Sandbox'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-sandbox', label: translate('Select sandbox'), description: translate('Select a sandbox to execute this test plan'), onChange: handleTestChange, options: testOptions, required: true, value: studyTestForm?.sandbox, width: 'lg' }) })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Execute test plan'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'lg', children: [isLoading || isLoadingTests ? _jsx(Spinner, { wrapper: 'full' }) : null, _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
