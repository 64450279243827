import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useEnvironmentSite = (environmentId, environmentSiteId) => {
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchEnvironmentSites = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments/${environmentId}/sites/${environmentSiteId}`);
        return response?.data || [];
    };
    const { isFetching, isLoading, isError, error, data } = useQuery({
        queryKey: [
            'entities',
            entityId.toString(),
            'studies',
            studyId.toString(),
            'environments',
            environmentId.toString(),
            'sites',
            environmentSiteId.toString(),
        ],
        queryFn: fetchEnvironmentSites,
        placeholderData: keepPreviousData,
        enabled: !!environmentSiteId,
    });
    return {
        isFetching,
        isLoading,
        isError,
        error,
        environmentSite: data,
    };
};
