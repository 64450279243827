import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, RadioGroup, RadioGroupOption, Text, useLocalizeMessage } from 'libs.nucleus';
import { DeviceSelector, selectedDeviceAtom } from 'libs.preview';
import { deviceList } from '../../../utils/device_list';
var STRING_FILE_FORMAT;
(function (STRING_FILE_FORMAT) {
    STRING_FILE_FORMAT["SINGLE"] = "strings_single_json";
    STRING_FILE_FORMAT["MULTIPLE"] = "strings";
})(STRING_FILE_FORMAT || (STRING_FILE_FORMAT = {}));
export const SectionContent = ({ onChange }) => {
    const t = useLocalizeMessage();
    const [isStringsChecked, setIsStringsChecked] = useState(true);
    const [isReportsChecked, setIsReportsChecked] = useState(true);
    const [stringFileFormat, setStringFileFormat] = useState(STRING_FILE_FORMAT.MULTIPLE);
    const selectedDeviceValue = useAtomValue(selectedDeviceAtom);
    const defaultViewport = useMemo(() => ({
        device: 'Settings default',
        width: 412,
        height: 915,
        scale: 1,
        hasLandscape: false,
    }), []);
    const devices = useMemo(() => ({ settingsDefault: defaultViewport, ...deviceList }), [defaultViewport]);
    const selectedDevice = Object.keys(devices).find((key) => devices[key] === selectedDeviceValue) || 'settingsDefault';
    useEffect(() => {
        const resources = [];
        if (isReportsChecked) {
            resources.push('screens');
        }
        if (isStringsChecked) {
            resources.push(stringFileFormat);
        }
        onChange({
            resources,
            viewport: selectedDevice === 'settingsDefault' ? undefined : selectedDevice,
        });
    }, [isStringsChecked, stringFileFormat, isReportsChecked, selectedDevice]);
    return (_jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs("div", { className: 'flex flex-col gap-5', children: [_jsx(Checkbox, { label: t('Strings'), description: t('Strings in json files'), checked: isStringsChecked, onChange: setIsStringsChecked, dataTestId: 'strings-checkbox' }), isStringsChecked && (_jsx("div", { className: 'ps-8', children: _jsxs(RadioGroup, { label: t('String file format'), value: stringFileFormat, onChange: setStringFileFormat, children: [_jsx(RadioGroupOption, { option: {
                                        value: STRING_FILE_FORMAT.MULTIPLE,
                                        label: t('Assets in separate json files'),
                                        description: t('Multiple json files, one for every library activity and every study created asset, for every locale selected.'),
                                    } }), _jsx(RadioGroupOption, { option: {
                                        value: STRING_FILE_FORMAT.SINGLE,
                                        label: t('Assets in a single json'),
                                        description: t('A single combined json that includes library activities and study assets. One json per locale selected.'),
                                    } })] }) }))] }), _jsx(Checkbox, { label: t('Screen review reports'), description: t('Screen reports in pdf'), checked: isReportsChecked, onChange: setIsReportsChecked, dataTestId: 'screens-checkbox' }), isReportsChecked && (_jsxs("div", { className: 'ps-8', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: t('Device Size') }), _jsx(Text, { className: 'text-text-secondary mb-2', size: 'sm', children: t('Settings default is iPhone small for participant app and Desktop small for site app.') }), _jsx(DeviceSelector, { devices: devices, defaultDevice: defaultViewport, showDimensions: true })] }))] }));
};
