import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/**
 * Custom hook that manages the active tab based on the URL hash.
 * @param fragments - An array of strings that represent the hash fragment for the corresponding tab index.
 * @returns An array containing the active tab and a function to set the active tab.
 */
export const useTabNavigation = (fragments = []) => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(() => {
        const idx = hash ? fragments.indexOf(hash) : 0;
        return idx !== -1 ? idx : 0;
    });
    useEffect(() => {
        const hash = fragments[activeTab];
        navigate(hash || fragments[0]);
    }, [activeTab]);
    return [activeTab, setActiveTab];
};
