import { faker } from '@faker-js/faker';
import { SDKStudy } from '@axon/rosetta-sdk';
export const generateRandomOrgName = (environment) => {
    const adjective = faker.word.adjective({ length: { min: 3, max: 8 } });
    const adverb = faker.word.adverb({ length: { min: 3, max: 8 } });
    const noun = faker.word.noun({ length: { min: 3, max: 8 } });
    return `${environment}.${adjective}-${adverb}-${noun}`;
};
export const sanitizeStudy = (study) => {
    return typeof study === 'string' ? SDKStudy.fromRosetta(JSON.parse(study)) : SDKStudy.fromRosetta(study);
};
export const timelineNameToHash = (name = '') => {
    return `#${encodeURIComponent(name)}`;
};
export const hashToTimelineName = (hash = '') => {
    if (!hash) {
        return '';
    }
    const nameCheck = hash.startsWith('#') ? hash.slice(1) : hash;
    return decodeURIComponent(nameCheck);
};
export const getActivityWarningMessage = (hasDependentActivities, hasDependentNotifications, hasTimelineConditions = false) => {
    // Count the number of true conditions
    const trueConditionsCount = [hasDependentActivities, hasDependentNotifications, hasTimelineConditions].filter(Boolean).length;
    // Check if at least two conditions are true
    if (trueConditionsCount >= 2) {
        return "You are about to delete an activity that has several dependencies configured. Please check your activity's dependencies before proceeding with deleting this activity. Doing so will remove those dependencies.";
    }
    if (hasDependentActivities) {
        return "You are about to delete an activity that another activity is dependent on. Please check other activities' start and end availability before proceeding with deleting this activity. Doing so will remove those dependencies.";
    }
    if (hasDependentNotifications) {
        return 'You are about to delete an activity that a notification is dependent on. Please check your configured notifications before proceeding with deleting this activity. Doing so will remove those dependencies.';
    }
    if (hasTimelineConditions) {
        return 'You are about to delete an activity that a schedule assignment is dependent on. Please check your configured scheduled assignments before proceeding with deleting this activity. Doing so will remove those dependencies.';
    }
    return 'Are you sure you want to delete this activity? This action cannot be undone.';
};
