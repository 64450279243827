import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Dropdown } from 'libs.nucleus';
import { FormatterWrapper } from './formatter_wrapper';
var FontSizeOption;
(function (FontSizeOption) {
    FontSizeOption["SMALL"] = "small";
    FontSizeOption["REGULAR"] = "regular";
    FontSizeOption["LARGE"] = "large";
})(FontSizeOption || (FontSizeOption = {}));
const fontSizeActions = [
    { type: FontSizeOption.LARGE, label: 'Large' },
    { type: FontSizeOption.REGULAR, label: 'Regular' },
    { type: FontSizeOption.SMALL, label: 'Small' },
];
export const FontSize = ({ onClick }) => {
    const items = useMemo(() => fontSizeActions.map((action) => ({
        id: action.type,
        content: action.label,
        onClick: () => onClick && onClick(action.type),
        ariaLabel: action.label,
    })), [onClick]);
    return (_jsx(FormatterWrapper, { children: _jsx(Dropdown, { variant: 'icon', iconName: 'fontSize', iconSize: 20, label: 'Format font size', items: items, "aria-label": `Format font size` }, 'size') }));
};
