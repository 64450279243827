import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { isEmail, isMobilePhone } from 'validator';
export const validateFormFieldOnBlur = (fieldName, value) => {
    switch (fieldName) {
        case 'name': {
            // eslint-disable-next-line no-control-regex
            const pattern = /^[0-9]|[\u0007\u0008\u0009\u000A\u000B\u000C\u000D]/;
            if (!value) {
                return 'Name is required';
            }
            if (value.length > 100) {
                return 'Maximum 100 characters';
            }
            if (pattern.test(value)) {
                return "Can't start with a number or have non-printable characters";
            }
            break;
        }
        case 'email': {
            if (!isEmpty(value) && !isEmail(value)) {
                return 'Invalid email address';
            }
            break;
        }
        case 'phone': {
            if (isEmpty(value)) {
                return 'Phone is required';
            }
            if (!isMobilePhone(value)) {
                return 'Invalid phone number';
            }
            break;
        }
        case 'addressLine': {
            if (isEmpty(value)) {
                return 'Address line is required';
            }
            break;
        }
        case 'city': {
            if (isEmpty(value)) {
                return 'City is required';
            }
            break;
        }
        case 'countryCode': {
            if (isEmpty(value)) {
                return 'Country is required';
            }
            break;
        }
        case 'region': {
            if (isEmpty(value)) {
                return 'Region is required';
            }
            break;
        }
        default:
            return undefined;
    }
    return undefined;
};
export const validateFormFieldOnChange = (fieldName, value) => {
    switch (fieldName) {
        case 'name': {
            // eslint-disable-next-line no-control-regex
            const pattern = /^[0-9]|[\u0007\u0008\u0009\u000A\u000B\u000C\u000D]/;
            if (value && value.length > 100) {
                return 'Maximum 100 characters';
            }
            if (pattern.test(value)) {
                return "Can't start with a number or have non-printable characters";
            }
            break;
        }
        default:
            return undefined;
    }
};
export const buildTimezoneOptions = () => {
    return moment.tz.names().map((item) => ({ value: item, label: item }));
};
