import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { ApiClientService, useApiClient } from 'libs.react';
export function useStudyTranslationTransition(id) {
    const { post } = useApiClient(ApiClientService.WEB);
    const { firebaseToken } = useContext(FirebaseAuthContext);
    return useMutation({
        mutationKey: ['study', 'translations', 'transition', id],
        mutationFn: (body) => {
            return post('/api/translation/study_translation_transition', body, {
                headers: {
                    Authorization: `Bearer ${firebaseToken}`,
                },
                withCredentials: true,
            });
        },
        retry: false,
    });
}
