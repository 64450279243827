import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, useLocalizeMessage, Icon } from 'libs.nucleus';
import { getOnboardingConfig } from '../../helpers/study.helpers';
import { useOrgContext } from '../../hooks/use_org_context';
export const OnboardingSchedule = ({ onboardingScheduleActivities, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const onboardingGroupId = getOnboardingConfig(org)?.c_group_id;
    const onOnboardingScheduleClick = () => {
        setCurrentVisit({
            c_visit: '',
            c_name: translate('Onboarding'),
            c_group: onboardingGroupId ?? '',
            c_window_start: Date.now(),
            c_window_end: Date.now(),
            c_open_queries: 0,
            c_completed_assignments: 0,
            c_completed_assignments_for_site: 0,
            c_sequence: 0,
            assignments: onboardingScheduleActivities.length,
        });
        setIsActivityListModalOpen();
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'grid grid-cols-2 grid-rows-1 gap-4 w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm', children: [_jsxs("div", { children: [_jsx("div", { children: translate('Onboarding') }), _jsx("div", { "data-testid": 'onboarding-assessments', children: translate('{assignments} site activities', { assignments: onboardingScheduleActivities.length }) })] }), _jsx("div", { className: 'ml-auto', style: { marginTop: '3px' }, children: _jsx(Button, { label: translate('View onboarding'), "aria-label": 'view-onboarding-schedule', variant: 'transparent', content: _jsx(Icon, { name: 'chevronRight', size: 24, color: 'primary' }), onClick: onOnboardingScheduleClick }) })] }) }));
};
