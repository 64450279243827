import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueries } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, InlineNotification, Spinner, Icon } from 'libs.nucleus';
import { AvailableActivities } from './activities_list_components/available_activities.component';
import { CompletedActivities } from './activities_list_components/completed_activities.component';
import { isActivityAvailableForCompletion } from './helpers/activity_scheduling.helpers';
import { fetchParticipantActivities, fetchSiteActivities } from './helpers/fetch_activities.helpers';
import { SiteContext } from '../../contexts/site';
import { isOutsideVisitWindowRange } from '../../helpers/visit.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { VisitConfirmation } from '../visit_confirmation';
export const SiteTasksList = ({ isVisitConfirmationComplete, visitConfirmActivity, activities, hasError, visit, setVisitActivity, setIsActivityModalOpen, }) => {
    const translate = useLocalizeMessage();
    const disableActivities = visitConfirmActivity && !isVisitConfirmationComplete;
    if (hasError) {
        return (_jsx(InlineNotification, { subtitle: translate('An error occurred while fetching site activities.'), title: '', type: 'error' }));
    }
    if (activities.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('No site participants available') }) }));
    }
    return activities
        .filter((activity) => !activity.c_group_task.c_assignment?.c_visit_confirmation)
        .map((activity) => {
        const isAvailable = isActivityAvailableForCompletion(activity, activity?.last_response);
        if (!isAvailable && activity.completed_count > 0) {
            return (_jsx(CompletedActivities, { siteActivity: activity, isSiteActivity: true }, activity.c_group_task.c_assignment._id));
        }
        if (isAvailable && activity.completed_count > 0) {
            return (_jsxs("div", { children: [_jsx(AvailableActivities, { siteActivity: activity, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit, isActivityDisabled: !!disableActivities, isSiteActivity: true }), _jsx(CompletedActivities, { siteActivity: activity, isSiteActivity: true })] }, `${activity.c_group_task.c_assignment._id}-${activity.last_response?._id}`));
        }
        return (_jsx(AvailableActivities, { siteActivity: activity, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit, isActivityDisabled: !!disableActivities, isSiteActivity: true }, activity.c_group_task.c_assignment._id));
    });
};
export const ParticipantTasksList = ({ activities, hasError, visit, }) => {
    const translate = useLocalizeMessage();
    if (hasError) {
        return (_jsx(InlineNotification, { subtitle: translate('An error occurred while fetching participant activities.'), title: '', type: 'error' }));
    }
    if (activities.length === 0) {
        return _jsx("div", {});
    }
    return activities.map((activity) => {
        if (activity.c_completed) {
            return _jsx(CompletedActivities, { participantActivity: activity, isSiteActivity: false }, activity._id);
        }
        return (_jsx(AvailableActivities, { participantActivity: activity, setVisitActivity: () => { }, setIsActivityModalOpen: () => { }, visit: visit, isActivityDisabled: true, isSiteActivity: false }, activity._id));
    });
};
export const ActivitiesList = ({ visit, setVisitActivity, setIsActivityModalOpen, onActionComplete, isOnboarding = false, onboardingSchedule, }) => {
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const translate = useLocalizeMessage();
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const participantId = match?.params.participantId;
    const visitId = visit.c_visit;
    const results = useQueries({
        queries: [
            {
                queryKey: ['siteActivities', participantId, visitId, site?._id],
                queryFn: () => fetchSiteActivities({ environment, participantId, visit, site }),
                enabled: !isOnboarding && !!visitId,
            },
            {
                queryKey: ['participantActivities', participantId, visitId],
                queryFn: () => fetchParticipantActivities({ environment, participantId, visit }),
                enabled: !isOnboarding && !!visitId,
            },
        ],
    });
    const isLoading = results.some((query) => query.isLoading);
    const isFetching = results.some((query) => query.isFetching);
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (onboardingSchedule?.length === 0 || (results[0].data?.length === 0 && results[1].data?.length === 0)) {
        return (_jsx("div", { className: 'flex py-4', children: _jsxs("div", { className: 'my-0 mx-auto', children: [_jsx("div", { className: 'text-center', children: _jsx(Icon, { name: 'navProtocol', size: 32 }) }), _jsx("div", { className: 'py-4', children: translate('No activities in this visit.') })] }) }));
    }
    const visitConfirmActivity = isOnboarding
        ? null
        : results[0].data?.find((task) => task.c_group_task.c_assignment.c_visit_confirmation);
    const isVisitConfirmationComplete = visitConfirmActivity && visitConfirmActivity?.last_response !== null && visitConfirmActivity?.completed_count > 0;
    const visitIsOutsideVisitWindowRange = isOutsideVisitWindowRange(new Date(), visit);
    return (_jsxs("div", { className: 'bg-neutral-100 p-4 h-full', children: [isFetching && _jsx(Spinner, { wrapper: 'full' }), !isOnboarding && visit.c_group && visitIsOutsideVisitWindowRange && (_jsx("section", { className: 'flex items-center pb-4', children: _jsx(InlineNotification, { subtitle: translate('This visit is outside the visit window'), title: '', type: 'warning' }) })), visitConfirmActivity && !isVisitConfirmationComplete && (_jsx(VisitConfirmation, { visitIsOutsideVisitWindowRange: visitIsOutsideVisitWindowRange, participantId: participantId ?? '', visit: visit, siteId: site?._id ?? '', environment: environment, onActionComplete: onActionComplete })), _jsx(SiteTasksList, { isVisitConfirmationComplete: isVisitConfirmationComplete, visitConfirmActivity: visitConfirmActivity, activities: isOnboarding ? onboardingSchedule : results[0].data || [], hasError: results[0].isError, visit: visit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen }), !isOnboarding && (_jsx(ParticipantTasksList, { hasError: results[1].isError, activities: results[1].data || [], visit: visit }))] }));
};
