"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlagManager = void 0;
var DEFAULT_LOGGER = function (message) { return console.log(message); };
var FlagManager = (function () {
    function FlagManager(flags, logger) {
        if (logger === void 0) { logger = DEFAULT_LOGGER; }
        this.flags = flags;
        this.logger = logger;
        this.flagValues = new Map();
        this.connectors = [];
        for (var flagId in flags) {
            var flag = flags[flagId];
            this.flagValues.set(flag, flag.value);
        }
    }
    FlagManager.prototype.getValue = function (flag) {
        return this.flagValues.get(flag);
    };
    FlagManager.prototype.setValue = function (flag, newValue) {
        if (!this.flagValues.has(flag)) {
            this.logger("Flag '".concat(flag.description, "' is not controlled by this FlagManager"));
            return;
        }
        var currentValue = this.flagValues.get(flag);
        if (typeof currentValue !== typeof newValue) {
            this.logger("Flag '".concat(flag.description, "' cannot be updated since the provided value type is incorrect. Provided value type ").concat(typeof newValue, ", expected value type ").concat(typeof currentValue));
            return;
        }
        this.flagValues.set(flag, newValue);
        this.connectors.forEach(function (connector) { return connector.notifyUpdate(flag, newValue); });
    };
    FlagManager.prototype.registerConnector = function (connectorProvider) {
        this.connectors.push(connectorProvider(this));
    };
    FlagManager.prototype.getFlags = function () {
        return this.flags;
    };
    return FlagManager;
}());
exports.FlagManager = FlagManager;
