import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { ResponderRole } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Dropdown, useLocalizeMessage, Icon, useDialog } from 'libs.nucleus';
import { useBooleanState, useToastNotification } from 'libs.react';
import { ActivityDependencyUtils } from './activity_dependencies';
import { RemoteActivityEditModal } from './remote_activity_edit_modal';
import { StudyConfigurationContext } from '../../../contexts';
import { useActivityUtils } from '../../../hooks';
import { getActivityWarningMessage, hasDependentActivities, hasDependentNotifications } from '../../../utils';
import { EnhancedMatrixRow } from '../../enhanced_matrix';
import { ScheduleTimelineEditActivityModal } from '../schedule_timeline_activity_modal';
const ACTIVITY_STYLES_MAP = {
    [ResponderRole.PARTICIPANT]: {
        background: 'bg-gradient-to-r from-purple-400 via-purple-400 to-purple-400/70 hover:from-purple-300 hover:via-purple-400 hover:to-purple-300/70',
    },
    [ResponderRole.SITE]: {
        background: `bg-gradient-to-r from-[#47B670] via-[#47B670] to-[#47B670]/70 hover:from-[#6BC08A] hover:via-[#6BC08A] hover:to-[#6BC08A]/70`,
    },
};
export const ScheduleTimelineRemoteRow = ({ item: remoteActivity, dataTestId, headers, getCellStyles, rowIndex, defaultCellWidth, }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { assignStudy, currentStudy, isReadOnlyMode, visits, currentTimelineId, studyId } = useContext(StudyConfigurationContext);
    const [isRemoteActivityModalOpen, openRemoteActivityModal, closeRemoteActivityModal] = useBooleanState(false);
    const [isEditActivityModalOpen, openEditActivityModal, closeEditActivityModal] = useBooleanState(false);
    const { getActivityIcon } = useActivityUtils();
    const { logEvent } = useContext(FirebaseAppContext);
    const activity = currentStudy.getActivityById(remoteActivity.scheduledActivity.activityId);
    const onDeleteRemoteActivity = async (scheduledActivityId) => {
        const dependentActivities = hasDependentActivities(remoteActivity.scheduledActivity.activityId, currentStudy, currentTimelineId);
        const hasNotificationsDependency = hasDependentNotifications(remoteActivity.scheduledActivity.activityId, currentStudy, currentTimelineId);
        const ok = await dialog.open({
            title: translate('Delete non-visit activity'),
            body: translate(getActivityWarningMessage(dependentActivities, hasNotificationsDependency)),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok) {
            currentStudy.removeScheduledActivity(currentTimelineId, scheduledActivityId);
            assignStudy(currentStudy);
            addNotification({
                title: translate('Non-visit activity removed'),
                subtitle: translate('The non-visit activity "{name}" has been removed from the timeline.', {
                    name: remoteActivity.name,
                }),
                type: 'success',
            });
            logEvent('remote_activity_deleted', {
                study_id: studyId,
                remote_activity_id: remoteActivity.id,
                remote_activity_name: remoteActivity.name,
                timeline_id: currentTimelineId,
            });
        }
    };
    const activityOptions = [
        {
            id: 'Edit',
            content: isReadOnlyMode ? translate('View settings') : translate('Edit settings'),
            onClick: openEditActivityModal,
            hidden: remoteActivity.type !== ResponderRole.PARTICIPANT,
        },
        {
            id: 'Delete',
            content: translate('Delete'),
            destructive: true,
            onClick: async () => onDeleteRemoteActivity(remoteActivity.id),
            disabled: isReadOnlyMode,
        },
    ];
    if (remoteActivity.libraryId) {
        activityOptions.unshift({
            id: 'View-Activity',
            content: translate('View activity'),
            onClick: () => onViewActivityClick(remoteActivity.libraryId),
        });
    }
    const onViewActivityClick = (activityId) => {
        logEvent('timeline_activity_viewed_in_library', {
            study_id: studyId,
            activity_id: activityId,
            timeline_id: currentTimelineId,
        });
        window.open(`${window.location.origin}/library/activities/${activityId}`, '_blank', 'noopener,noreferrer');
    };
    const onActivitySave = (activities) => {
        addNotification({
            title: translate('Activity updated'),
            subtitle: translate('The activity "{message}" has been updated.', { message: activities[0].name }),
            type: 'success',
        }, 5000);
        logEvent('activity_updated', {
            study_id: studyId,
            activity_id: activities[0].id,
            activity_name: activities[0].name,
            timeline_id: currentTimelineId,
        });
        assignStudy(currentStudy);
        closeEditActivityModal();
    };
    const onActivityScheduleSave = (activitySchedule) => {
        const baseCondition = {
            activityId: remoteActivity.scheduledActivity.activityId,
            scheduleTimelineId: currentTimelineId,
        };
        const endConditions = ActivityDependencyUtils.convertEndConditions({
            conditions: activitySchedule.endConditions,
            baseCondition,
            isRemoteActivity: true,
        });
        const startConditions = ActivityDependencyUtils.convertStartConditions({
            conditions: activitySchedule.startConditions,
            baseCondition,
            isRemoteActivity: true,
        });
        currentStudy.editScheduledActivityTiming({
            timelineId: currentTimelineId,
            scheduledActivityId: remoteActivity.scheduledActivity.id,
            timing: {
                ...remoteActivity.scheduledActivity.timing,
                startConditions,
                endConditions,
                timingDetails: { specificTime: activitySchedule.specificStartTime },
                ...(activitySchedule.type === 'recurring' &&
                    activitySchedule.repetition?.repetitionEvery && {
                    recurrenceRule: {
                        frequency: activitySchedule.repetition.repetitionEveryPeriod.value,
                        interval: Number(activitySchedule.repetition.repetitionEvery),
                        ...(activitySchedule.repetition.count && { count: Number(activitySchedule.repetition.count) }),
                    },
                }),
            },
        });
        assignStudy(currentStudy);
        closeRemoteActivityModal();
        addNotification({
            title: translate('Non-visit activity updated'),
            subtitle: translate('The configuration for the non-visit activity "{name}" has been updated successfully', {
                name: remoteActivity.name,
            }),
            type: 'success',
        });
        logEvent('remote_activity_updated', {
            study_id: studyId,
            activity_id: remoteActivity.scheduledActivity.id,
            activity_name: remoteActivity.name,
            timeline_id: currentTimelineId,
        });
    };
    // Find the index of the start and end triggers for the non-visit activity in the visits array
    let startIndex = visits.findIndex((visit) => remoteActivity.startTrigger === visit.id);
    startIndex = startIndex === -1 ? 0 : startIndex;
    let endIndex = visits.findIndex((visit) => remoteActivity.endTrigger === visit.id);
    endIndex = endIndex === -1 ? visits.length : endIndex;
    // If the end trigger is set, calculate the width of the cell based on the start and end triggers
    const remoteActivityWidth = endIndex > startIndex ? defaultCellWidth * (endIndex - startIndex) : defaultCellWidth;
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedMatrixRow, { dataTestId: dataTestId, rowIndex: rowIndex, children: headers.map((header, index) => {
                    // We hide as many extra cells as the cells consumed by non-visit activities happening in multiple visits to ensure the row end is aligned
                    if ((index - 1 > startIndex && endIndex === -1) || (index - 1 > startIndex && index <= endIndex)) {
                        return null;
                    }
                    // we subtract 1 from the index to account for the activity name cell when calculating the width of the cell and the background gradient
                    return (_jsxs("div", { className: getCellStyles(index, { isEmpty: header.isEmpty }), style: {
                            width: header.width || (index - 1 === startIndex ? remoteActivityWidth : undefined),
                            padding: '0.25rem',
                        }, children: [header.key === 'activityName' && (_jsxs("div", { className: 'flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', children: [getActivityIcon(remoteActivity.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-remote-row-name', title: remoteActivity.name, children: remoteActivity.name }), _jsx("div", { className: 'opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100 has-[button[aria-expanded="true"]]:opacity-100', children: _jsx(Dropdown, { items: activityOptions, variant: 'kebab' }) })] })), header.key !== 'activityName' && (_jsx("div", { className: `group flex h-full w-full items-center justify-center rounded-[0.25rem] ${index - 1 === startIndex ? ACTIVITY_STYLES_MAP[remoteActivity.type].background : ''}`, children: index - 1 === startIndex && (_jsx("div", { className: 'absolute left-2 rounded-full p-[0.1875rem] bg-white text-neutral-400 flex items-center justify-center opacity-0 cursor-pointer group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100', "data-testid": `remote-activity-cog-${remoteActivity.name}`, onClick: openRemoteActivityModal, children: _jsx(Icon, { name: 'cog', color: 'current', size: 'xs' }) })) }))] }, `timeline-${remoteActivity.id}-remote-cell-${header.key}`));
                }) }), isRemoteActivityModalOpen && (_jsx(RemoteActivityEditModal, { initialActivity: remoteActivity.scheduledActivity, activityName: remoteActivity.name, onCancel: closeRemoteActivityModal, onSave: onActivityScheduleSave, activityType: remoteActivity.type })), isEditActivityModalOpen && activity && (_jsx(ScheduleTimelineEditActivityModal, { initialActivity: activity, onCancel: closeEditActivityModal, onSave: onActivitySave }))] }));
};
