import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { QueryClientContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, useLocalizeMessage, useDialog, Pill, Spinner, Table, H3, Text } from 'libs.nucleus';
import { ApiClientService, AuthContext, FormattedDateTime, ListInformation, PageHeader, useApiClient, useBooleanState, useDateTime, } from 'libs.react';
import { ExtendTimeDialog } from '../extend_time_dialog';
import { useStudyDeploymentsColumns } from './study_deployments_columns.hook';
import { StudyConfigurationContext } from '../../contexts';
import { useDeploymentUtils } from '../../hooks';
import { GenericUtils } from '../../utils';
import { DeploymentUtils } from '../../utils/deployment';
import { EnvironmentStatus } from '../environment_status';
import { ServerTimeModal } from '../server_time_modal';
import { getUTCOffset } from '../server_time_modal/server_time_modal.utils';
import { StudySummary } from '../study_summary';
export const StudyDeploymentDetails = ({ envId, originPage }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { parseDateTimeShort } = useDateTime();
    const utcOffset = getUTCOffset();
    const { getRegionName } = useDeploymentUtils();
    const dialog = useDialog();
    const { entityId, isInternalUser } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [isExtendTimeDialogOpen, openExtendTimeDialog, closeExtendTimeDialog] = useBooleanState(false);
    const [isChangeServerTimeModalOpen, openChangeServerTimeModal, closeChangeServerTimeModal] = useBooleanState(false);
    const momClient = useApiClient(ApiClientService.MOM);
    const retrieveStudyEnv = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}?withWorkflows=true`);
        return response.data;
    };
    const { data: environment, isLoading, isError, } = useQuery({
        enabled: !!envId,
        queryFn: retrieveStudyEnv,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
    });
    const isTimeTravel = environment && DeploymentUtils.isTimeTravelEnvironment(environment);
    const deploymentStatus = environment && DeploymentUtils.getEnvironmentStatus(environment);
    const showErrorDetails = async (workflow) => {
        const error = workflow.result?.faults?.[0];
        if (error) {
            await dialog.open({
                title: translate('Deployment details'),
                body: _jsx("pre", { className: 'text-text-secondary text-wrap', children: JSON.stringify(error, null, 2) }),
                width: 'lg',
                actionButtons: [{ label: translate('Close') }],
            });
        }
    };
    const deploymentsColumns = useStudyDeploymentsColumns(showErrorDetails);
    const retrieveServerTime = async () => {
        if (!environment || !DeploymentUtils.isTimeTravelEnvironment(environment)) {
            return null;
        }
        const cortexClusterUrl = environment.cortexURL;
        const { data: serverTime } = await axios.get(`${cortexClusterUrl}/settime/`);
        return serverTime.date;
    };
    const { data: currentServerDateTime } = useQuery({
        enabled: !!environment,
        queryFn: retrieveServerTime,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId, 'serverTime'],
    });
    const getToken = async () => {
        try {
            const { data } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
            return data.data;
        }
        catch (error) {
            console.error('Failed to get cortex token:', error);
            throw error;
        }
    };
    const { data: cortexToken } = useQuery({
        queryKey: ['cortexToken', envId],
        queryFn: getToken,
        enabled: isInternalUser && !!envId && environment && deploymentStatus === 'completed',
        retry: false,
        refetchOnWindowFocus: false,
    });
    const remainingTime = useMemo(() => {
        if (environment && environment.metadata.expiresAt) {
            const { hour, minute, day } = GenericUtils.getDatesDiff(new Date(environment.metadata.expiresAt), new Date());
            return day === 1
                ? translate('{hour}h {minute}m', { hour, minute })
                : translate('{day}d {hour}h {minute}m', { day: day - 1, hour, minute });
        }
        return '';
    }, [environment]);
    const listItems = useMemo(() => {
        if (!environment) {
            return [];
        }
        const isActiveTimeTravel = isTimeTravel && !DeploymentUtils.hasExpired(environment);
        const workflow = DeploymentUtils.getEnvironmentWorkflow(environment);
        const shouldShowServerTime = isTimeTravel && currentServerDateTime && deploymentStatus === 'completed';
        return [
            { title: translate('Description'), description: environment.metadata.description },
            {
                title: translate('Status'),
                description: _jsx(EnvironmentStatus, { environment: environment }),
                actionContent: workflow.result?.faults && (_jsx(Button, { type: 'button', variant: 'transparent', label: translate('View details'), onClick: () => showErrorDetails(workflow) })),
            },
            {
                title: translate('Type'),
                description: isTimeTravel ? translate('Time travel') : translate('Standard'),
            },
            {
                title: translate('Expiration date'),
                description: !!environment.metadata.expiresAt && (_jsxs("div", { className: 'flex flex-col', children: [_jsx(Text, { size: 'sm', children: parseDateTimeShort(environment.metadata.expiresAt) }), _jsx(Text, { size: 'xs', className: 'text-text-secondary', children: translate('Expires in {date}', { date: remainingTime }) })] })),
                actionContent: deploymentStatus === 'completed' ? (_jsx(Button, { variant: 'transparent', label: translate('Extend time'), onClick: openExtendTimeDialog })) : undefined,
                hidden: !isActiveTimeTravel,
            },
            {
                title: translate('Server time'),
                description: (_jsxs(Text, { size: 'sm', children: [_jsx(FormattedDateTime, { date: currentServerDateTime }), " ", translate('(UTC{offset})', { offset: utcOffset })] })),
                actionContent: (_jsx(Button, { variant: 'transparent', label: translate('Update server time'), onClick: openChangeServerTimeModal })),
                hidden: !shouldShowServerTime,
            },
            { title: translate('Build version'), description: workflow.studyConfigVersion },
            { title: translate('Owner'), description: environment.owner.email },
            { title: translate('Date created'), description: parseDateTimeShort(environment.createdAt) },
            { title: translate('Last updated'), description: parseDateTimeShort(workflow.updatedAt) },
            { title: translate('Internal name'), description: environment.code },
        ];
    }, [environment, currentServerDateTime, deploymentStatus]);
    const launchEnv = () => {
        if (environment) {
            const url = DeploymentUtils.buildEnvironmentUrl(environment);
            logEvent('launch_env_sandbox', { study_id: studyId, env_name: environment.name });
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    const onTimeExtended = async () => {
        closeExtendTimeDialog();
        await queryClient?.invalidateQueries({
            queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
        });
    };
    const breadcrumbsPages = [originPage, { name: environment?.metadata.name || '', path: '' }];
    const headerOptions = deploymentStatus === 'completed' ? [{ name: translate('Launch'), onClick: launchEnv, variant: 'primary' }] : [];
    if (isError) {
        navigate(`/studies/${studyId}/tests`);
    }
    const TitleElement = (_jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(H3, { className: 'text-4xl font-light text-text-primary', children: environment?.name || '' }), environment && (_jsx("div", { className: 'pt-1', children: _jsx(Pill, { label: getRegionName(environment.region), backgroundColor: 'cyan' }) }))] }));
    return (_jsxs("section", { className: 'relative p-6 h-full flex flex-col', children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: TitleElement, options: headerOptions }), _jsxs("div", { className: 'flex flex-col flex-1 overflow-auto relative gap-4', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(ListInformation, { items: listItems }), !isLoading && (_jsxs("div", { children: [_jsx(Text, { children: translate('Deployment history') }), _jsx(Text, { size: 'sm', className: 'mb-3 text-text-secondary', children: translate('This is the record of all times you have deployed versions of your study build to this sandbox.') }), _jsx(Table, { columns: deploymentsColumns, data: environment?.workflows || [], hasPagination: true, initialPageSize: 10, paginationNavigationScreenReaderLabel: translate('Deployments table navigation') })] })), isInternalUser && cortexToken && deploymentStatus === 'completed' && (_jsxs(_Fragment, { children: [_jsxs("h2", { className: 'text-2xl font-semibold text-gray-800 mt-10 mb-3', children: [translate('Study summary'), " ", _jsx(Pill, { label: translate('Internal') })] }), _jsx(StudySummary, { token: cortexToken })] }))] }), isTimeTravel && isChangeServerTimeModalOpen && environment && (_jsx(ServerTimeModal, { serverDateTime: currentServerDateTime, studyEnv: environment, onClose: closeChangeServerTimeModal })), isExtendTimeDialogOpen && environment && (_jsx(ExtendTimeDialog, { environment: environment, onClose: closeExtendTimeDialog, onTimeExtended: onTimeExtended }))] }));
};
