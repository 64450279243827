import { SOAGraphNodeType, } from '../../types';
/**
 * SOA Graph Utils
 * @class
 * @classdesc Utility class for SOA Graph
 * @param {SOAGraph} soaGraph The SOA Graph
 * @returns {SOAGraphUtils} SOA Graph Utils instance
 * @example
 * const soaGraphUtils = new SOAGraphUtils(soaGraph);
 * soaGraphUtils.completeTask({ nodeId, state, userInput });
 * soaGraphUtils.completeTimeTravel({ actionNode, state });
 * soaGraphUtils.checkIfGateOpened({ gate, trace });
 * soaGraphUtils.processGates({ gates, trace, tasks, actions });
 * soaGraphUtils.processNode({ node, trace, tasks, actions, gates, userInput });
 * soaGraphUtils.selectConditionsForTask(conditions, userInput);
 */
class SOAGraphUtils {
    soaGraph;
    constructor(soaGraph) {
        this.soaGraph = soaGraph;
    }
    checkIfGateOpened = ({ gate, trace }) => {
        const { parents } = gate;
        let isOpened = true;
        for (const parentId of parents) {
            const parent = this.soaGraph.nodes[parentId];
            const isParentEnabled = parent.nodeType === SOAGraphNodeType.CONDITION &&
                parent.properties.enable &&
                parent.properties.isMatched !== false;
            if (isParentEnabled && !trace.find((t) => t.nodeId === parentId)) {
                isOpened = false;
                break;
            }
            else if (!isParentEnabled && trace.find((t) => t.nodeId === parentId)) {
                isOpened = false;
                break;
            }
        }
        return isOpened;
    };
    processGates = ({ gates, trace, tasks, actions, }) => {
        let newGates = [...gates];
        for (const gate of gates) {
            const isGateOpened = this.checkIfGateOpened({ gate, trace });
            if (isGateOpened) {
                trace.push({ nodeId: gate.nodeId });
                newGates = newGates.filter((g) => g.nodeId !== gate.nodeId);
                for (const child of gate.children) {
                    this.processNode({
                        node: this.soaGraph.nodes[child],
                        trace,
                        tasks,
                        actions,
                        gates,
                    });
                }
            }
        }
        return newGates;
    };
    selectConditionsForTask = (conditions, userInput) => {
        const conditionsToSelect = [];
        const activityCompleted = conditions.find((condition) => condition.properties.conditionType === 'activityCompleted');
        const matchResponses = conditions.filter((condition) => condition.properties.conditionType === 'stepResponseMatch');
        if (activityCompleted) {
            conditionsToSelect.push(activityCompleted);
        }
        if (matchResponses.length > 0 && userInput?.screens) {
            const { screens } = userInput;
            for (const matchResponse of matchResponses) {
                if (matchResponse.properties.conditionType !== 'stepResponseMatch') {
                    continue;
                }
                const { stepResponseMatch } = matchResponse.properties;
                const { comparisonValue, stepName } = stepResponseMatch;
                const value = screens.find((screen) => screen.name === stepName)?.value;
                if (value === comparisonValue) {
                    conditionsToSelect.push(matchResponse);
                }
            }
        }
        return conditionsToSelect;
    };
    processNode = ({ node, trace, tasks, actions, gates, userInput, }) => {
        switch (node.nodeType) {
            case SOAGraphNodeType.ASSIGNMENT_AVAILABLE: {
                const { id: assignmentId } = node.properties;
                trace.push({ nodeId: node.nodeId });
                if (tasks[assignmentId].status !== SOAGraphNodeType.ASSIGNMENT_COMPLETE) {
                    tasks[assignmentId].id = node.nodeId;
                    tasks[assignmentId].status = SOAGraphNodeType.ASSIGNMENT_AVAILABLE;
                }
                break;
            }
            case SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE: {
                const { id: assignmentId } = node.properties;
                trace.push({ nodeId: node.nodeId });
                if (tasks[assignmentId].status !== SOAGraphNodeType.ASSIGNMENT_COMPLETE) {
                    tasks[assignmentId].status = SOAGraphNodeType.ASSIGNMENT_UNAVAILABLE;
                }
                break;
            }
            case SOAGraphNodeType.ASSIGNMENT_COMPLETE: {
                const { id: assignmentId } = node.properties;
                trace.push({
                    nodeId: node.nodeId,
                    meta: {
                        userInput,
                    },
                });
                tasks[assignmentId].id = node.nodeId;
                tasks[assignmentId].status = SOAGraphNodeType.ASSIGNMENT_COMPLETE;
                // children of completed node will be conditions
                const conditions = node.children
                    .map((child) => this.soaGraph.nodes[child])
                    .filter((child) => child.nodeType === SOAGraphNodeType.CONDITION)
                    .filter((child) => {
                    const isEnabled = child.nodeType === SOAGraphNodeType.CONDITION && child.properties.enable;
                    if (isEnabled) {
                        trace.push({ nodeId: child.nodeId });
                    }
                    return child.children.length > 0 && isEnabled;
                });
                if (conditions.length > 0) {
                    const selectedConditions = this.selectConditionsForTask(conditions, userInput);
                    conditions.forEach((condition) => {
                        const isConditionSelected = selectedConditions.find((selectedCondition) => selectedCondition.nodeId === condition.nodeId);
                        // @ts-expect-error Forcefully set value
                        this.soaGraph.nodes[condition.nodeId].properties.isMatched = Boolean(isConditionSelected);
                    });
                    for (const selectedCondition of selectedConditions) {
                        trace.push({ nodeId: selectedCondition.nodeId });
                        for (const child of selectedCondition.children) {
                            this.processNode({
                                node: this.soaGraph.nodes[child],
                                trace,
                                tasks,
                                actions,
                                gates,
                                userInput,
                            });
                        }
                    }
                }
                break;
            }
            case SOAGraphNodeType.CONDITION:
                throw new Error('God please fix this graph!!! @praneeth.gayem');
            case SOAGraphNodeType.GATE: {
                const isGateOpened = this.checkIfGateOpened({ gate: node, trace });
                if (isGateOpened) {
                    trace.push({ nodeId: node.nodeId });
                    // eslint-disable-next-line no-param-reassign
                    gates = gates.filter((g) => g.nodeId !== node.nodeId);
                    for (const child of node.children) {
                        this.processNode({
                            node: this.soaGraph.nodes[child],
                            trace,
                            tasks,
                            actions,
                            gates,
                            userInput,
                        });
                    }
                }
                else {
                    gates.push(node);
                }
                break;
            }
            case SOAGraphNodeType.TIME_TRAVEL: {
                if (node.properties.offset <= 0 && !node.properties.eod) {
                    trace.push({ nodeId: node.nodeId });
                    for (const child of node.children) {
                        this.processNode({
                            node: this.soaGraph.nodes[child],
                            trace,
                            tasks,
                            actions,
                            gates,
                            userInput,
                        });
                    }
                }
                else {
                    actions.push(node);
                }
                break;
            }
            default:
                break;
        }
    };
    completeTask = ({ nodeId, state, userInput, }) => {
        const trace = [...state.trace];
        const tasks = structuredClone(state.tasks);
        const actions = [...state.actions];
        const gates = [...state.gates];
        // go to completed node of this available node
        const availabilityNode = this.soaGraph.nodes[nodeId];
        if (availabilityNode.nodeType !== SOAGraphNodeType.ASSIGNMENT_AVAILABLE) {
            return null;
        }
        const node = this.soaGraph.nodes[availabilityNode.children[0]];
        this.processNode({
            node,
            trace,
            tasks,
            actions,
            gates,
            userInput,
        });
        const newGates = this.processGates({ gates, trace, tasks, actions });
        return {
            tasks,
            actions,
            trace,
            gates: newGates,
            timeTravelled: state.timeTravelled,
        };
    };
    completeTimeTravel = ({ actionNode, state, }) => {
        const trace = [...state.trace];
        const tasks = structuredClone(state.tasks);
        let actions = [...state.actions];
        const gates = [...state.gates];
        let { timeTravelled } = state;
        const nodeIdsToProcess = actionNode.siblings ? [...actionNode.siblings, actionNode.nodeId] : [actionNode.nodeId];
        let timeToTravel = 0;
        if (actionNode.properties.eod) {
            timeToTravel = nodeIdsToProcess.length * 1440;
        }
        else {
            timeToTravel += getTimeToTravel(actionNode);
        }
        timeTravelled += timeToTravel;
        for (const nodeId of nodeIdsToProcess) {
            actions = actions.filter((action) => action.nodeId !== nodeId);
        }
        // find other time travel nodes to process from actions
        const otherTimeTravelNodes = actions.filter((action) => getTimeToTravel(action) <= timeTravelled);
        nodeIdsToProcess.push(...otherTimeTravelNodes.map((action) => action.nodeId));
        for (const nodeId of nodeIdsToProcess) {
            const node = this.soaGraph.nodes[nodeId];
            if (nodeId === actionNode.nodeId) {
                trace.push({
                    nodeId,
                    meta: {
                        timeTravelled: timeToTravel,
                    },
                });
            }
            else {
                trace.push({
                    nodeId,
                });
            }
            actions = actions.filter((action) => action.nodeId !== nodeId);
            for (const child of node.children) {
                this.processNode({
                    node: this.soaGraph.nodes[child],
                    trace,
                    tasks,
                    actions,
                    gates,
                });
            }
        }
        // reduce offset of other time travel nodes
        actions = actions.map((action) => {
            return {
                ...action,
                properties: {
                    ...action.properties,
                    offset: getTimeToTravel(action) - timeTravelled,
                    offsetUnit: 'minute',
                },
            };
        });
        const newGates = this.processGates({ gates, trace, tasks, actions });
        return {
            tasks,
            actions,
            trace,
            gates: newGates,
            timeTravelled,
        };
    };
}
/**
 * Check if the visit is a confirmation visit
 * @param node The name of the activity
 * @returns boolean
 */
const isVisitConfirmation = (activityName) => {
    return activityName.toLowerCase().includes('confirmation');
};
/**
 * Check if the visit is unscheduled
 * @param name The name of the visit
 * @returns boolean
 */
const isUnscheduledVisit = (name) => {
    return name.toLowerCase().includes('unscheduled');
};
/**
 * Get the display time for the duration in minutes
 * @param durationMinutes The duration in minutes
 * @returns string
 */
const getDisplayTime = (durationMinutes) => {
    const days = Math.floor(durationMinutes / 1440);
    const hours = Math.floor((durationMinutes % 1440) / 60);
    const minutes = durationMinutes % 60;
    if (durationMinutes === 0) {
        return '0 minutes';
    }
    const daysDisplay = days > 0 ? days + (days > 1 ? ' days ' : ' day ') : '';
    const hoursDisplay = hours > 0 ? hours + (hours > 1 ? ' hours ' : ' hour ') : '';
    const minutesDisplay = minutes > 0 ? minutes + (minutes > 1 ? ' minutes' : ' minute') : '';
    return daysDisplay + hoursDisplay + minutesDisplay;
};
/**
 * Get the time to travel for the action node
 * @param node The action node
 * @returns number
 */
const getTimeToTravel = (node) => {
    const { offset, eod, offsetUnit } = node.properties;
    if (eod) {
        return 1440;
    }
    switch (offsetUnit) {
        case 'minute':
            return offset;
        case 'day':
            return offset * 1440;
        default:
            return offset;
    }
};
export { SOAGraphUtils, isVisitConfirmation, isUnscheduledVisit, getDisplayTime, getTimeToTravel };
