import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
const SelectPopup = ({ position, onClose, options, onSelect, popupContainer }) => {
    const menuRef = useRef(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [search, setSearch] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    padding: 10,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 35],
                },
            },
        ],
    });
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);
    const filteredOptions = useMemo(() => {
        const searchTrimmed = search.trim();
        if (!searchTrimmed) {
            return options;
        }
        return options.filter((option) => option.label.toLowerCase().includes(searchTrimmed.toLowerCase()) ||
            option.value.toLowerCase().includes(searchTrimmed.toLowerCase()));
    }, [options, search]);
    const handleKeyDown = React.useCallback((event) => {
        if (!filteredOptions.length) {
            return;
        }
        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % filteredOptions.length;
                scrollToOption(newIndex);
                return newIndex;
            });
            event.preventDefault();
            event.stopPropagation();
        }
        else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + filteredOptions.length) % filteredOptions.length;
                scrollToOption(newIndex);
                return newIndex;
            });
            event.preventDefault();
            event.stopPropagation();
        }
        else if (event.key === 'Enter') {
            if (filteredOptions.length && highlightedIndex >= 0 && highlightedIndex < filteredOptions.length) {
                onSelect(filteredOptions[highlightedIndex].value);
            }
            onClose();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (event.key === 'Escape') {
            onClose();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (event.key === 'Tab') {
            onClose();
        }
    }, [filteredOptions, highlightedIndex, onSelect, onClose]);
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    const scrollToOption = (index) => {
        const optionElement = menuRef.current?.querySelectorAll('div[data-option]')[index];
        if (optionElement) {
            optionElement.scrollIntoView({ block: 'nearest' });
        }
    };
    useEffect(() => {
        popupContainer?.parentElement?.style.setProperty('overflow', 'hidden');
        return () => {
            popupContainer?.parentElement?.style.removeProperty('overflow');
        };
    }, [popupContainer]);
    return createPortal(_jsxs(_Fragment, { children: [_jsx("div", { ref: setReferenceElement, style: {
                    position: 'fixed',
                    top: position.y,
                    left: position.x,
                    width: 0,
                    height: 0,
                } }), _jsx("div", { ref: (node) => {
                    menuRef.current = node;
                    setPopperElement(node);
                }, className: '!fixed', style: { ...styles.popper }, ...attributes.popper, children: _jsxs("div", { className: 'bg-white border border-gray-200 rounded shadow-md min-w-[200px]', style: {
                        width: position.width + 60,
                    }, children: [_jsx("input", { type: 'text', className: 'w-full px-4 py-2 border-b border-gray-200 outline-none', placeholder: 'Search...', autoFocus: true, value: search, onChange: (e) => setSearch(e.target.value) }), _jsx("div", { className: `py-1 max-h-[300px] overflow-scroll`, ref: menuRef, children: filteredOptions.map((option, index) => (_jsx("div", { "data-option": true, className: `px-4 py-2 cursor-pointer ${highlightedIndex === index ? 'bg-gray-100' : ''}`, onClick: (e) => {
                                    e.stopPropagation();
                                    onSelect(option.value);
                                    onClose();
                                }, onMouseEnter: () => setHighlightedIndex(index), children: option.label }, option.value))) })] }) })] }), popupContainer || document.body);
};
export default SelectPopup;
