import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useLocalizeMessage, ModalWindow } from 'libs.nucleus';
const noop = () => { };
export const MultiStepsModalWindow = ({ title, steps, stepStates, submitLabel, onCancel = noop, onSubmit = noop, }) => {
    const translate = useLocalizeMessage();
    const lastStepN = steps.length - 1;
    const [stepN, setStepN] = useState(0);
    const disabled = !stepStates[stepN]?.isReady;
    const goBack = useCallback(() => setStepN(Math.max(0, stepN - 1)), [stepN]);
    const goForward = useCallback(() => setStepN(Math.min(stepN + 1, lastStepN)), [stepN, lastStepN]);
    const callOnSubmit = useCallback(() => onSubmit(), [onSubmit]);
    const backButton = { label: translate('Back'), onClick: goBack };
    const continueButton = {
        label: translate('Continue'),
        onClick: goForward,
        disabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const doneButton = {
        label: submitLabel ?? translate('Save'),
        onClick: callOnSubmit,
        disabled,
    };
    return (_jsx(ModalWindow, { title: title, isOpen: true, closeWindow: onCancel, footerSecondaryActionButtons: stepN === 0 ? [cancelButton] : [cancelButton, backButton], footerPrimaryActionButton: stepN === lastStepN ? doneButton : continueButton, width: 'full', children: steps.map((step, i) => (
        // Similar usage of index in keys as we find in nucleus.tabs
        // eslint-disable-next-line react/no-array-index-key
        _jsx("div", { style: stepN === i ? {} : { display: 'none' }, children: step }, i))) }));
};
