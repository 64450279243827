import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Instant, Now } from 'temporal-luxon';
import { DateTimeShort } from '@medable/i18n-core';
import { useLocalizeMessage, LocaleContext, Pill, Icon } from 'libs.nucleus';
import { SiteIcon } from '../../svgIcons/site.icon';
import { UserIcon } from '../../svgIcons/user.icon';
export const CompletedActivities = ({ siteActivity, participantActivity, isSiteActivity }) => {
    const currentLocale = useContext(LocaleContext);
    const translate = useLocalizeMessage();
    const completionTimeToUse = isSiteActivity
        ? siteActivity?.last_response?.created
        : participantActivity?.c_task_response?.created;
    const completionTime = new DateTimeShort(currentLocale).format(Instant.fromEpochMilliseconds(completionTimeToUse ? new Date(completionTimeToUse).getTime() : 0).toZonedDateTimeISO(Now.timeZone()));
    return (_jsxs("section", { className: 'grid grid-cols-3 grid-rows-1w-full bg-white border border-gray-200 mb-2.5 rounded-md px-6 py-4', "data-testid": isSiteActivity
            ? `site-activity-completed-${siteActivity?.c_group_task.c_assignment._id}`
            : `participant-activity-completed-${participantActivity?.c_task._id}`, children: [_jsxs("div", { className: 'col-span-2', children: [_jsx("span", { className: 'pr-2.5', children: isSiteActivity ? siteActivity?.c_group_task.c_assignment.c_name : participantActivity?.c_task.c_name }), _jsx(Pill, { label: translate('Completed'), backgroundColor: 'gray' }), _jsxs("div", { className: 'flex items-center pt-1.5', children: [isSiteActivity ? (_jsxs(_Fragment, { children: [_jsx(SiteIcon, { className: 'flex-1 text-green-500' }), _jsx("span", { className: 'px-1.5 text-xs text-green-500', children: translate('Site') })] })) : (_jsxs(_Fragment, { children: [_jsx(UserIcon, { className: 'flex-1 text-purple-500' }), _jsx("span", { className: 'px-1.5 text-xs text-purple-500', children: translate('Participant') })] })), _jsx(Icon, { name: 'circle', color: 'gray', size: 5 }), _jsx("span", { className: 'px-1.5 text-xs', "data-testid": isSiteActivity
                                    ? `site-activity-completion-time-${siteActivity?.c_group_task.c_assignment._id}`
                                    : `participant-activity-completion-time-${participantActivity?.c_task._id}`, children: completionTime })] })] }), _jsx("div", { className: 'ml-auto mt-2.5 col-span-1', children: _jsx(Icon, { name: 'confirm', color: 'primary', size: 24 }) })] }));
};
