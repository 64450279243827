import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { useLocalizeMessage, Text, Table, Dropdown, Link, useDialog } from 'libs.nucleus';
import { FormattedDateTime } from 'libs.react';
import { EditTestPlanDetailsModal } from './study_edit_testplan_modal.component';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
import { StudyTestPlanStatus } from '../../types';
export const StudyTestPlansTable = ({ versions, showSelect = false, hideActions = false, selected = null, onSelect = () => { }, }) => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { studyId } = useContext(StudyConfigurationContext);
    const { testPlans, updateTestPlan, deleteTestPlan } = useContext(StudyTestPlanContext);
    const [isEditModalOpen, setIsEditModalOpen] = useState(null);
    const onCloseEditModal = () => {
        setIsEditModalOpen(null);
    };
    const onDelete = async (id) => {
        deleteTestPlan(id);
        setIsEditModalOpen(null);
    };
    const publishAndToggleRequired = async (testPlan) => {
        updateTestPlan({
            testPlan,
            status: testPlan.status === StudyTestPlanStatus.REQUIRED ? StudyTestPlanStatus.LOCKED : StudyTestPlanStatus.REQUIRED,
            errorSubtitle: translate('Unable to update test plan status'),
        });
    };
    const toggleRequired = async (id) => {
        const testPlan = testPlans.find((testPlan) => testPlan.id === id);
        if (testPlan) {
            const isNotRequired = testPlan.status === StudyTestPlanStatus.REQUIRED;
            await dialog.open({
                title: isNotRequired ? translate('Mark as Not Required') : translate('Mark as Required'),
                body: isNotRequired
                    ? translate('Are you sure you want to mark this test plan as not required? This test plan will still be locked and cannot be edited.')
                    : translate('Are you sure you want to mark this test plan as required? This test plan will be mandatory for all study versions. The test plan will be locked and cannot be edited.'),
                hasOverlay: true,
                willCloseOnOutsideClickAndEscapeKeyPress: false,
                actionButtons: [
                    {
                        label: translate('Yes'),
                        onClick: () => publishAndToggleRequired(testPlan),
                    },
                    { label: translate('No') },
                ],
            });
        }
        else {
            console.error('Test plan not found');
        }
    };
    const onRowSelectionChange = (selectedIds) => {
        if (selectedIds.length > 0) {
            onSelect(selectedIds[selectedIds.length - 1]);
        }
        else {
            onSelect(null);
        }
    };
    const testPlanColumns = useMemo(() => {
        const columns = [
            {
                header: translate('Name'),
                accessorKey: 'name',
                cell: ({ row }) => (_jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/test-plans/${row.original.id}`, disabled: showSelect, children: row.original.name })),
            },
            {
                header: translate('Description'),
                cell: (props) => props.row.original.data.description ? (_jsx(Text, { size: 'sm', className: 'text-text-primary line-clamp-2', children: props.row.original.data.description })) : (EM_DASH),
            },
            {
                header: translate('Created At'),
                accessorKey: 'createdAt',
                cell: (props) => _jsx(FormattedDateTime, { date: props.row.original.createdAt }),
            },
            {
                header: translate('Required'),
                size: 100,
                accessorKey: 'metadata.required',
                cell: ({ row }) => (row.original.status === StudyTestPlanStatus.REQUIRED ? translate('Yes') : translate('No')),
            },
        ];
        if (!hideActions) {
            columns.push({
                header: translate('Actions'),
                size: 100,
                cell: ({ row }) => {
                    const options = [];
                    options.push({
                        id: 'mark-as-required',
                        content: row.original.status === StudyTestPlanStatus.REQUIRED
                            ? translate('Mark as Not Required')
                            : translate('Mark as Required'),
                        onClick: () => {
                            toggleRequired(row.original.id);
                        },
                    });
                    if (row.original.status === StudyTestPlanStatus.DRAFT) {
                        options.push({
                            id: 'edit',
                            content: translate('Edit'),
                            onClick: () => {
                                setIsEditModalOpen(row.original.id);
                            },
                        });
                    }
                    options.push({
                        id: 'delete',
                        content: translate('Delete'),
                        onClick: () => {
                            dialog.open({
                                title: translate('Delete Test Plan'),
                                body: translate('Are you sure you want to delete this test plan?'),
                                hasOverlay: true,
                                willCloseOnOutsideClickAndEscapeKeyPress: false,
                                actionButtons: [
                                    {
                                        label: translate('Yes'),
                                        onClick: () => onDelete(row.original.id),
                                    },
                                    { label: translate('No') },
                                ],
                            });
                        },
                        destructive: true,
                    });
                    return _jsx(Dropdown, { items: options, variant: 'kebab' });
                },
            });
        }
        return columns;
    }, [studyId, showSelect, versions, testPlans, hideActions]);
    const testPlanToEdit = useMemo(() => testPlans.find((testPlan) => testPlan.id === isEditModalOpen), [testPlans, isEditModalOpen]);
    return (_jsxs(_Fragment, { children: [showSelect ? (_jsx(Table, { columns: testPlanColumns, data: testPlans, isSelectable: true, selectedIds: selected ? [selected] : [], onRowSelectionChange: onRowSelectionChange, getRowId: 'id', disableActionBar: true })) : (_jsx(Table, { columns: testPlanColumns, data: testPlans })), isEditModalOpen && testPlanToEdit ? (_jsx(EditTestPlanDetailsModal, { type: 'edit', initialTestPlan: testPlanToEdit, versions: versions, onClose: onCloseEditModal, onSave: onCloseEditModal })) : null] }));
};
