import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useLocalizeMessage, Table, Text } from 'libs.nucleus';
const SectionAssets = ({ onChange, assets, initialSelectedIds = [] }) => {
    const t = useLocalizeMessage();
    const data = Object.keys(assets).map((key) => {
        return {
            name: key,
            asset: assets[key].asset,
        };
    });
    const columns = [
        {
            header: t('Name'),
            accessorKey: 'name',
            cell: (value) => {
                return value.getValue();
            },
        },
    ];
    const onRowSelectionChange = useCallback((selectedRows) => {
        const selectedAssets = selectedRows.map((row) => data[row].asset);
        onChange({
            selectedAssets,
        });
    }, []);
    return (_jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs(Text, { size: 'sm', children: [t('Assets selection'), _jsx("span", { className: 'text-red-500', children: "*" })] }), _jsx(Table, { data: data, columns: columns, labelNoResults: t('No Assets'), useInternalSearch: true, hasPagination: true, initialPageSize: 10, labelSearchField: t('Search'), isSelectable: true, onRowSelectionChange: onRowSelectionChange, paginationNavigationScreenReaderLabel: t('Assets table navigation'), initialSelectedIds: initialSelectedIds })] }));
};
export default React.memo(SectionAssets);
