export const SITE_MANAGEMENT_FLAGS = {
    site_management: {
        id: 'site_management_app',
        value: false,
        description: 'Enables the Site Management experience',
        owner: 'Site Management Experience',
        name: 'General',
        extra: { section: 'Site Management', isFirst: true, dataTestId: 'site-management-flag' },
    },
};
