import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FormSections, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { ListInformation, SectionCard, useDateTime } from 'libs.react';
import StepResponseValue from './step_response_value.component';
import { getStepQuestion } from './visit_activity_responses.utils';
import { stepResponseTypes } from '../../constants/step_response_type.constants';
export const VisitActivityResponses = ({ visitActivityResponses, isLoading }) => {
    const translate = useLocalizeMessage();
    const dateFormat = useDateTime();
    if (isLoading) {
        return _jsx(Spinner, {});
    }
    const formSectionTransform = visitActivityResponses.map((response) => {
        let detailsItems = (response?.c_step_responses?.data || [])
            //  Filter out completion step responses
            .filter((stepResponse) => {
            return stepResponse.type !== stepResponseTypes.cCompletion.objectType && !stepResponse.c_step.c_disabled;
        })
            .sort((x, y) => x.c_step.c_order - y.c_step.c_order)
            .flatMap((stepResponse) => {
            return [
                {
                    title: translate('Question'),
                    description: (_jsx("div", { dangerouslySetInnerHTML: {
                            __html: getStepQuestion(stepResponse.c_step),
                        }, "data-testid": 'step-question' })),
                },
                {
                    title: translate('Response'),
                    description: _jsx(StepResponseValue, { stepResponse: stepResponse }),
                },
            ];
        });
        if (detailsItems.length === 0) {
            detailsItems = [
                {
                    title: translate('No responses'),
                    description: translate('No responses recorded for this activity'),
                },
            ];
        }
        return {
            title: response.c_task?.c_name,
            description: dateFormat.parseDateTimeShort(response.created),
            content: (_jsx("section", { className: 'flex flex-col gap-6', children: _jsx(ListInformation, { dataTestId: `activity-response-${response._id}`, items: detailsItems, useDefaultEmptyDescription: true }) })),
        };
    });
    if (formSectionTransform.length === 0) {
        return (_jsx(SectionCard, { children: _jsx("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: _jsx("p", { className: 'text-sm text-secondary', children: translate('No visit activity responses') }) }) }));
    }
    return (_jsx(_Fragment, { children: _jsx(FormSections, { sections: formSectionTransform }) }));
};
