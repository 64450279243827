import { ListItemNode } from '@lexical/list';
import { isHTMLElement, } from 'lexical';
export class CustomListItemNode extends ListItemNode {
    static getType() {
        return 'custom_list_item_node';
    }
    static clone(node) {
        return new CustomListItemNode(node.getValue(), node.getChecked(), node.getKey());
    }
    createDOM(config) {
        return super.createDOM(config);
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'custom_list_item_node',
            version: 1,
        };
    }
    static importDOM() {
        const importers = super.importDOM();
        return {
            li: () => ({
                conversion: patchStyleConversion(importers?.li),
                priority: 1,
            }),
        };
    }
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            element.removeAttribute('value');
            element.getAttribute('style');
            element.removeAttribute('style');
        }
        return { element };
    }
}
const patchStyleConversion = (originalDOMConverter) => (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
        return null;
    }
    const originalOutput = original.conversion(node);
    if (!originalOutput) {
        return originalOutput;
    }
    const { textAlign } = node.style;
    if (originalOutput.node instanceof CustomListItemNode && textAlign) {
        originalOutput.node.setFormat(textAlign);
    }
    return originalOutput;
};
