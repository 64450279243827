import { useContext } from 'react';
import { Now } from 'temporal-luxon';
import { LocaleContext } from 'libs.nucleus';
export const useDateTime = () => {
    const currentLocale = useContext(LocaleContext);
    const { id: timeZoneID } = Now.timeZone();
    const parseDateShort = (date, options = {}) => {
        if (!date) {
            return '';
        }
        const formatter = new Intl.DateTimeFormat(currentLocale, {
            ...(!options.ignoreTimeZone && { timeZone: options.timeZone || timeZoneID }),
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        if (typeof date === 'string') {
            return formatter.format(new Date(date));
        }
        return formatter.format(date);
    };
    const parseDateTimeShort = (date, options = {}) => {
        if (!date) {
            return '';
        }
        const formatter = new Intl.DateTimeFormat(currentLocale, {
            ...(!options.ignoreTimeZone && { timeZone: options.timeZone || timeZoneID }),
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
        if (typeof date === 'string') {
            return formatter.format(new Date(date));
        }
        return formatter.format(date);
    };
    return { parseDateShort, parseDateTimeShort };
};
