import { Locale } from 'libs.i18n-core';
export const sortLocales = (localeArray = []) => {
    return localeArray.sort((a, b) => {
        if (a === Locale.en_US) {
            return -1;
        }
        if (b === Locale.en_US) {
            return 1;
        }
        return a.localeCompare(b, Locale.en_US);
    });
};
// type Sortable = string | { [key: string]: string };
/**
 *  Sorts an array of strings or objects by locale, with 'en-US' always at the top.
 *  If the array contains strings, pass the array as the first argument.
 *  If the array contains objects, pass the array as the first argument and the key as the second argument.
 * @example
 * sortLocaleSensitive(['en-US', 'es-ES', 'fr-FR', 'de-DE', 'it-IT']);
 * // ['en-US', 'de-DE', 'es-ES', 'fr-FR', 'it-IT']
 * @example
 * sortLocaleSensitive([{ locale: 'en-US' }, { locale: 'es-ES' }, { locale: 'fr-FR' }, { locale: 'de-DE' }, { locale: 'it-IT' }], 'locale');
 * // [{ locale: 'en-US' }, { locale: 'de-DE' }, { locale: 'es-ES' }, { locale: 'fr-FR' }, { locale: 'it-IT' }]
 *
 */
export const sortLocaleSensitive = (array, key) => {
    return [...array].sort((a, b) => {
        let aValue;
        let bValue;
        if (typeof a === 'string' && typeof b === 'string') {
            aValue = a;
            bValue = b;
        }
        else if (key && typeof a === 'object' && typeof b === 'object' && a && b) {
            aValue = a[key];
            bValue = b[key];
        }
        else {
            throw new Error('Invalid arguments. Expected either strings or objects with a key.');
        }
        if (aValue === Locale.en_US) {
            return -1;
        }
        if (bValue === Locale.en_US) {
            return 1;
        }
        return aValue.localeCompare(bValue, Locale.en_US);
    });
};
