export const SITE_FLAGS = {
    site: {
        id: 'site_app',
        value: false,
        description: 'Enables the Site user experience',
        owner: 'Site Experience',
        name: 'General',
        extra: { section: 'Site', isFirst: true, dataTestId: 'site-flag' },
    },
};
