import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { FormSections, useLocalizeMessage, Input, ModalWindow, Spinner, TextArea, Select, } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { StudyTestPlanContext } from '../../contexts';
export const EditTestPlanDetailsModal = ({ type, onClose, onSave, initialTestPlan, versions, }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const { updateTestPlan, createTestPlan, testPlans } = useContext(StudyTestPlanContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState({
        name: initialTestPlan?.metadata.name || '',
        description: initialTestPlan?.data.description || '',
        version: initialTestPlan && initialTestPlan.metadata.version
            ? {
                label: `${initialTestPlan.metadata.version.name} (${initialTestPlan.metadata.version.externalVersion})`,
                value: initialTestPlan.metadata.version.id.toString(),
                meta: {
                    libraryId: initialTestPlan.metadata.version.libraryId,
                    externalVersion: initialTestPlan.metadata.version.externalVersion,
                    name: initialTestPlan.metadata.version.name,
                },
            }
            : { label: '-select-', value: '', meta: {} },
    });
    const versionOptions = useMemo(() => versions.map((version) => ({
        label: `${version.metadata.name} (${version.externalVersion})`,
        value: version.id.toString(),
        meta: {
            libraryId: version.libraryId,
            externalVersion: version.externalVersion,
            name: version.metadata.name,
        },
    })), [versions]);
    const saveDisabled = useMemo(() => {
        return !studyTestForm.name || isLoading || !studyTestForm.version.value;
    }, [studyTestForm, isLoading]);
    const saveTest = async () => {
        if (!saveDisabled) {
            if (testPlans
                .filter((testPlan) => testPlan.id !== initialTestPlan?.id)
                .some((testPlan) => testPlan.metadata.name === studyTestForm.name)) {
                addNotification({
                    title: translate('Error'),
                    subtitle: translate('Test plan with this name already exists'),
                    type: 'error',
                });
                return;
            }
            setIsLoading(true);
            let testPlan;
            if (type === 'create') {
                testPlan = await createTestPlan({
                    name: studyTestForm.name,
                    version: {
                        id: studyTestForm.version.value,
                        externalVersion: studyTestForm.version.meta.externalVersion,
                        name: studyTestForm.version.meta.name,
                        libraryId: studyTestForm.version.meta.libraryId,
                    },
                    description: studyTestForm.description,
                    csv: initialTestPlan?.data.csv || [
                        {
                            'Step Type': '',
                            'User': '',
                            'Context': '',
                            'Execution Mode': '',
                            'User Input': '',
                        },
                    ],
                });
            }
            else {
                testPlan = await updateTestPlan({
                    testPlan: initialTestPlan,
                    name: studyTestForm.name,
                    version: {
                        id: studyTestForm.version.value,
                        externalVersion: studyTestForm.version.meta.externalVersion,
                        name: studyTestForm.version.meta.name,
                        libraryId: studyTestForm.version.meta.libraryId,
                    },
                    description: studyTestForm.description,
                });
            }
            setIsLoading(false);
            if (testPlan) {
                onSave(testPlan);
            }
        }
    };
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
    };
    const handleVersionChange = (selectedVersion) => {
        setStudyTestForm((oldForm) => ({
            ...oldForm,
            version: {
                ...selectedVersion,
                meta: selectedVersion.meta || { libraryId: undefined },
            },
        }));
    };
    const formSections = [
        {
            title: translate('Test plan details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Version'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-version', label: translate('Select version'), description: translate('Select the version to validate this test plan'), onChange: handleVersionChange, options: versionOptions, required: true, value: studyTestForm.version, width: 'lg' }) })),
        },
    ];
    return (_jsxs(ModalWindow, { title: type === 'create' ? translate('Create test plan') : translate('Edit test plan'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, children: _jsx(FormSections, { sections: formSections }) })] }));
};
