import { AssetTypes } from 'libs.localization.common';
export const getSupportInformationAsset = (study) => ({
    type: AssetTypes.SIGN_IN_SUPPORT_INFORMATION,
    id: study.id,
    version: '1',
    messages: [
        {
            namespace: 'support_information',
            messages: Object.entries(study.configuration.studyMetadata.selfServiceInformation).reduce((acc, [id, value]) => ({ ...acc, [id]: value }), {}),
        },
    ],
    preview: {
        engine: 'react',
        id: AssetTypes.SIGN_IN_SUPPORT_INFORMATION,
        default_viewport: 'iosSmall',
        info: {
            display_name: 'Support Information',
            list: [],
        },
    },
});
