import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage, Link, Pill } from 'libs.nucleus';
import { useDateTime } from 'libs.react';
import { buildDocumentUrl } from './participant_documents.utils';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
const statusMap = {
    cancelled: 'red',
    complete: 'green',
    sent: 'yellow',
    partial: 'gray',
    voided: 'gray',
};
export const useParticipantDocumentsTableColumns = () => {
    const translate = useLocalizeMessage();
    const { parseDateTimeShort } = useDateTime();
    const { environment } = useCurrentEnvironment();
    const participantDocumentsColumns = useMemo(() => {
        return [
            {
                header: translate('Title'),
                accessorKey: 'ec__document_template.ec__title',
                cell: (context) => {
                    const title = context.getValue();
                    const link = buildDocumentUrl(context.row.original._id, environment);
                    return (_jsx(Link, { href: link, target: '_blank', children: title }));
                },
            },
            {
                header: translate('Required signers'),
                accessorKey: 'ec__required_signers',
            },
            {
                header: translate('Created'),
                accessorKey: 'created',
                cell: (context) => parseDateTimeShort(context.getValue()),
            },
            {
                header: translate('Status'),
                accessorKey: 'ec__status',
                cell: (context) => {
                    const status = context.getValue();
                    return _jsx(Pill, { label: status, variant: 'status', iconBackgroundColor: statusMap[status] });
                },
            },
        ];
    }, []);
    return { participantDocumentsColumns };
};
