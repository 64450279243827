var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getStorage, ref as storageRef, uploadBytesResumable, connectStorageEmulator, getDownloadURL, } from 'firebase/storage';
import { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FileUploader } from 'libs.nucleus';
import { FirebaseAppContext } from '.';
var Status;
(function (Status) {
    Status["UPLOADING"] = "uploading";
    Status["ERROR"] = "error";
    Status["SUCCESS"] = "success";
})(Status || (Status = {}));
export const FileUploaderViaFirebaseStorage = (props) => {
    const { onFileChange, storageBaseUrl } = props, restOfProps = __rest(props, ["onFileChange", "storageBaseUrl"]);
    const [files, setFiles] = useState({});
    const [statusMap, setStatusMap] = useState({});
    const firebaseApp = useContext(FirebaseAppContext);
    const storage = useMemo(() => getStorage(firebaseApp.app, `${firebaseApp.app.options.projectId}.appspot.com`), [firebaseApp]);
    useEffect(() => {
        if (firebaseApp.app.options.projectId === 'demo-local') {
            connectStorageEmulator(storage, 'localhost', 9199);
        }
    }, [firebaseApp, storage]);
    useEffect(() => {
        const filesToUpload = Object.entries(files).filter(([id]) => !statusMap[id]);
        if (!filesToUpload.length) {
            return;
        }
        function uploadFile(id, file) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const fileExtension = file.name.split('.').pop();
                    const randomFileName = `${uuidv4()}.${fileExtension}`;
                    const firebaseUrl = `${storageBaseUrl}/${randomFileName}`;
                    const fileRef = storageRef(storage, firebaseUrl);
                    const uploadTask = uploadBytesResumable(fileRef, file);
                    uploadTask.on('state_changed', (snapshot) => {
                        const progress = snapshot.bytesTransferred;
                        setStatusMap((prevStatusMap) => (Object.assign(Object.assign({}, prevStatusMap), { [id]: { status: Status.UPLOADING, progress } })));
                    }, (error) => {
                        console.error('Error uploading file: ', error.message);
                        setStatusMap((prevStatusMap) => (Object.assign(Object.assign({}, prevStatusMap), { [id]: { status: Status.ERROR } })));
                    }, () => __awaiter(this, void 0, void 0, function* () {
                        const fileDownloadUrl = yield getDownloadURL(uploadTask.snapshot.ref);
                        setStatusMap((prevStatusMap) => (Object.assign(Object.assign({}, prevStatusMap), { [id]: { status: Status.SUCCESS, firebaseUrl, firebaseDownloadUrl: fileDownloadUrl } })));
                    }));
                }
                catch (error) {
                    console.error('Error uploading file: ', error.message);
                    setStatusMap((prevStatusMap) => (Object.assign(Object.assign({}, prevStatusMap), { [id]: { status: Status.ERROR } })));
                }
            });
        }
        function uploadFiles() {
            return __awaiter(this, void 0, void 0, function* () {
                for (const [id, file] of filesToUpload) {
                    yield uploadFile(id, file);
                }
            });
        }
        const initialStatusMapUpdate = {};
        for (const [id] of filesToUpload) {
            initialStatusMapUpdate[id] = { status: Status.UPLOADING, progress: 0 };
        }
        setStatusMap((prevStatusMap) => (Object.assign(Object.assign({}, prevStatusMap), initialStatusMapUpdate)));
        uploadFiles();
    }, [files, storage, storageBaseUrl]);
    const fileStates = useMemo(() => {
        const states = {};
        Object.entries(statusMap).forEach(([id, { status, progress }]) => {
            let type = 'pending';
            let loadingIndicator;
            switch (status) {
                case Status.UPLOADING:
                    type = 'inProgress';
                    loadingIndicator = progress !== null && progress !== void 0 ? progress : 0;
                    break;
                case Status.ERROR:
                    type = 'error';
                    break;
                case Status.SUCCESS:
                    type = 'success';
                    break;
                default:
                    type = 'pending';
            }
            states[id] = {
                type,
                message: status === Status.ERROR ? 'Upload failed' : undefined,
                loadingIndicator: loadingIndicator !== null && loadingIndicator !== void 0 ? loadingIndicator : 0,
            };
        });
        return states;
    }, [statusMap]);
    useEffect(() => {
        onFileChange(Object.fromEntries(Object.entries(files).map(([id, file]) => [id, Object.assign({ file }, statusMap[id])])));
    }, [files, statusMap]);
    const handleFileChange = useCallback((fileObjects) => {
        setFiles(fileObjects);
    }, []);
    const handleFileDelete = useCallback((filesObject, id, onSuccess) => {
        setFiles(filesObject);
        setStatusMap((prevStatusMap) => {
            const newStatusMap = Object.assign({}, prevStatusMap);
            delete newStatusMap[id];
            return newStatusMap;
        });
        onSuccess();
    }, []);
    return (_jsx(FileUploader, Object.assign({}, restOfProps, { fileStates: fileStates, onFileChange: handleFileChange, onFileDelete: handleFileDelete })));
};
