import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClientContext, useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { SDKStudy, Error as SDKError, DefaultTimelineId, StudyLocalizationStatus, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage, useDialog } from 'libs.nucleus';
import { ApiClientService, AuthContext, LibraryEndpoint, LibraryResourceStatus, useApiClient, useToastNotification, } from 'libs.react';
import { useDocumentVisibility } from '../../hooks';
import { hasDependentActivities, hashToTimelineName, sanitizeStudy, timelineNameToHash } from '../../utils';
import { StudiesContext } from '../studies';
const mockedStudy = new SDKStudy({ name: '' });
const sortActivities = (study, timelineId) => {
    return study
        .mapActivities(study.visitActivities[timelineId])
        .sort((activityA, activityB) => (activityA.order || 0) - (activityB.order || 0));
};
const getActivityLibraryIds = (study) => {
    return study.getAllActivities().reduce((acc, activity) => {
        return activity.libraryId ? [...acc, activity.libraryId] : acc;
    }, []);
};
const missingProvider = () => {
    throw Error('Missing the study configuration provider');
};
export const DEFAULT_STUDY_CONFIGURATION_VALUE = {
    activities: [],
    activityScreens: {},
    assignStudy: missingProvider,
    changeCurrentTimelineId: missingProvider,
    cloneStudy: missingProvider,
    configId: '',
    createNewStudy: missingProvider,
    currentStudy: mockedStudy,
    currentTimelineId: '',
    getStudy: missingProvider,
    getStudyConfiguration: missingProvider,
    isActivityOnVisit: missingProvider,
    isInitialized: false,
    isLoading: true,
    isReadOnlyMode: false,
    isValidVisit: missingProvider,
    persistedTimelineId: '',
    removeActivityStatusDependencies: missingProvider,
    scheduledActivities: [],
    setOnboarding: missingProvider,
    studyId: '',
    toggleActivityOnVisit: missingProvider,
    visits: [],
};
export const StudyConfigurationContext = createContext(DEFAULT_STUDY_CONFIGURATION_VALUE);
const getInitialTimelineId = (study, hash) => {
    const timelineName = hashToTimelineName(hash);
    const timeline = Object.values(study.getScheduleTimelines()).find((timeline) => timeline.name.toLocaleLowerCase() === timelineName.toLocaleLowerCase());
    return timeline?.id ?? study.timelineInfo.onboarding.id;
};
export const StudyConfigurationProvider = ({ children }) => {
    const { createStudy, showStudyUpdatedWarning, updateStudy, studies } = useContext(StudiesContext);
    const { entityId } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const dialog = useDialog();
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const momClient = useApiClient(ApiClientService.MOM);
    const { logEvent } = useContext(FirebaseAppContext);
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const match = useMatch('/studies/:studyId/:section/*');
    const { hash } = useLocation();
    const navigate = useNavigate();
    const isDocumentVisisble = useDocumentVisibility();
    const [activities, setActivities] = useState([]);
    const [currentStudy, setCurrentStudy] = useState(mockedStudy);
    const [visits, setVisits] = useState([]);
    const [scheduledActivities, setScheduledActivities] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [studyId, setStudyId] = useState('');
    const [configId, setConfigId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentTimelineId, setCurrentTimelineId] = useState('');
    const [persistedTimelineId, setPersistedTimelineId] = useState(currentStudy.timelineInfo.main.id);
    const isReadOnlyMode = useMemo(() => currentStudy.localizationStatus === StudyLocalizationStatus.READY, [currentStudy.localizationStatus]);
    useEffect(() => {
        if (isInitialized && isDocumentVisisble) {
            verifyConfiguration();
        }
    }, [isInitialized, isDocumentVisisble]);
    useEffect(() => {
        if (match && match.params.studyId && match.params.section && match.params.studyId !== studyId) {
            setStudyId(match.params.studyId);
            getStudy(match.params.studyId);
        }
    }, [match?.params]);
    /**
     * Verifies if the current study configuration is still the one in Draft
     * as it might have changed to Published in a different tab, or by a different user
     */
    const verifyConfiguration = async () => {
        if (studyId && configId) {
            setIsLoading(true);
            const studyConfiguration = await getStudyConfiguration(studyId);
            setIsLoading(false);
            if (studyConfiguration) {
                if (studyConfiguration.id !== configId) {
                    showStudyUpdatedWarning(true);
                }
                else {
                    const study = sanitizeStudy(studyConfiguration.data);
                    assignStudy(study, false);
                }
            }
        }
    };
    const getStudy = async (id = studyId) => {
        setIsLoading(true);
        if (!studies.find((study) => study.id === parseInt(id))) {
            // we need to fetch the study to see if it exists. if it doesnt, reroute back to studies dashboard
            try {
                await momClient.get(`/v1/entities/${entityId}/studies/${id}`);
            }
            catch (error) {
                if (error.response?.status === 404) {
                    setIsLoading(false);
                    navigate('/studies/dashboard');
                    return;
                }
            }
        }
        const studyConfiguration = await getStudyConfiguration(id);
        if (studyConfiguration) {
            try {
                setConfigId(studyConfiguration.id);
                const study = sanitizeStudy(studyConfiguration.data);
                const timelineId = getInitialTimelineId(study, hash);
                assignStudy(study, false, timelineId);
                setCurrentTimelineId(timelineId);
                if (timelineId !== currentStudy.timelineInfo.onboarding.id) {
                    setPersistedTimelineId(timelineId);
                }
            }
            catch (error) {
                addNotification({
                    title: translate('Error parsing the study'),
                    subtitle: error instanceof SDKError && error.message
                        ? error.message
                        : translate('Something went wrong when parsing the study.'),
                    type: 'error',
                });
            }
        }
        setIsLoading(false);
        setIsInitialized(true);
    };
    /**
     * Fetches all activities in use in the current study to have the screens available
     */
    const getActivityScreens = async () => {
        const activityIds = getActivityLibraryIds(currentStudy);
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, { params: { entityId, limit: 200, id: activityIds, status: LibraryResourceStatus.PUBLISHED } });
        return response.data?.reduce((acc, activity) => {
            acc[activity.id] = activity.data?.screens ?? [];
            return acc;
        }, {});
    };
    /**
     * Verifies if the stored activity screens are sufficient for the activities included in the current study
     */
    const validateActivityScreens = async (study) => {
        const activityIds = getActivityLibraryIds(study);
        const missingScreens = activityIds.filter((id) => !activityScreens[id]);
        if (queryClient && missingScreens.length > 0) {
            await queryClient.invalidateQueries({ queryKey: ['activity', 'screens', currentStudy.activities] });
        }
    };
    const { data: activityScreens = {} } = useQuery({
        queryKey: ['activity', 'screens', currentStudy.activities],
        queryFn: getActivityScreens,
        enabled: !!currentStudy.name,
    });
    const getStudyConfiguration = async (id) => {
        setIsLoading(true);
        const searchParams = new URLSearchParams({ studyId: id, status: LibraryResourceStatus.DRAFT });
        const { data: response } = await libraryClient.get(`${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`);
        setIsLoading(false);
        return response.data && response.data.length > 0 ? response.data[0] : undefined;
    };
    const createNewStudy = async (study) => {
        setIsLoading(true);
        try {
            const newStudyId = await createStudy(study);
            assignStudy(study, false, study.timelineInfo.onboarding.id);
            return newStudyId;
        }
        finally {
            setIsLoading(false);
        }
    };
    const changeCurrentTimelineId = useCallback((timelineId, doNavigate = true) => {
        if (timelineId !== currentTimelineId) {
            setCurrentTimelineId(timelineId);
            if (timelineId !== currentStudy.timelineInfo.onboarding.id) {
                setPersistedTimelineId(timelineId);
            }
            setActivities(sortActivities(currentStudy, timelineId));
            const studyVisitSchedule = currentStudy.visitSchedule[timelineId];
            setVisits(studyVisitSchedule.map((visitId) => currentStudy.visits[timelineId][visitId]));
            setScheduledActivities(currentStudy.scheduledActivities[timelineId]);
            if (doNavigate) {
                navigate(timelineNameToHash(currentStudy.getScheduleTimelines()[timelineId].name));
            }
        }
    }, [currentStudy, currentTimelineId]);
    /**
     * Updates the information of the current Study with the new changes made
     */
    const assignStudy = (newStudy, updateLibraryConfig = true, timelineId = currentTimelineId) => {
        setCurrentStudy(newStudy);
        setActivities(sortActivities(newStudy, timelineId));
        const studyVisitSchedule = newStudy.visitSchedule[timelineId];
        setVisits(studyVisitSchedule.map((visitId) => newStudy.visits[timelineId][visitId]));
        setScheduledActivities(newStudy.scheduledActivities[timelineId]);
        if (updateLibraryConfig) {
            updateStudy(configId, newStudy);
        }
        validateActivityScreens(newStudy);
    };
    /**
     * Checks if the given activity is part of the given visit schedule
     * @param activity - Activity
     * @param visitId - string
     */
    const isActivityOnVisit = (activity, visitId) => {
        const visit = currentStudy.getVisitById(currentTimelineId, visitId);
        return !!visit && !!visit.activities[activity.id];
    };
    /**
     * Used to persist the previous value of the schedule timeline id (if there is any)
     * so that we can provide a better UX when the user navigates through the Onboarding and Schedule tabs
     * @param isOnboardingEnabled
     */
    const setOnboarding = (isOnboardingEnabled) => {
        if (isOnboardingEnabled) {
            changeCurrentTimelineId(currentStudy.timelineInfo.onboarding.id, false);
            if (currentTimelineId !== currentStudy.timelineInfo.onboarding.id) {
                setPersistedTimelineId(currentTimelineId);
            }
        }
        else {
            changeCurrentTimelineId(persistedTimelineId, false);
        }
    };
    /**
     * Removes all dependent activity status dependencies for the given status id
     */
    const removeActivityStatusDependencies = (statusId) => {
        currentStudy.participantStatusConditions = currentStudy.participantStatusConditions.filter((statusCondition) => statusCondition.updatesParticipantStatusTo !== statusId);
        assignStudy(currentStudy);
    };
    /**
     * Given an activity and a visit,
     * it will include or exclude the activity on/from the visit depending on the previous visit schedule
     * @param activity
     * @param visitId
     */
    const toggleActivityOnVisit = async (activity, visitId) => {
        const visit = currentStudy.getVisitById(currentTimelineId, visitId);
        if (visit) {
            if (visit.activities[activity.id]) {
                const instance = visit.getActivitySequenceByActivityId(activity.id);
                const dependencyFilter = (condition) => 'dependencyInstanceId' in condition && condition.dependencyInstanceId === instance.instanceId;
                const visitHasActivityDependencies = currentTimelineId !== DefaultTimelineId.ONBOARDING
                    ? visit.activitySequence.some((activityInstance) => {
                        return (activityInstance.instanceId !== instance.instanceId &&
                            (activityInstance.timing.startConditions.some(dependencyFilter) ||
                                activityInstance.timing.endConditions.some(dependencyFilter)));
                    })
                    : hasDependentActivities(activity.id, currentStudy, currentTimelineId);
                if (visitHasActivityDependencies) {
                    const ok = await dialog.open({
                        title: translate('Unschedule activity'),
                        body: translate("You are about to unschedule an activity that other activities depend on. Please check your visits' activity dependencies before proceeding with unscheduling this activity. Doing so will remove those dependencies."),
                        actionButtons: [{ isDestructive: true, label: translate('Unschedule') }, { label: translate('Cancel') }],
                    });
                    if (ok) {
                        currentStudy.removeActivityDependencies(currentTimelineId, activity.id);
                        visit.removeActivity(activity);
                    }
                }
                else {
                    visit.removeActivity(activity);
                }
            }
            else {
                visit.addActivity(activity);
            }
            logEvent('study_activity_scheduled', {
                activity_name: activity.name,
                assigned: !!visit.activities[activity.id],
                study_id: currentStudy.id,
                visit_name: visit.name,
            });
        }
        assignStudy(currentStudy);
    };
    const value = useMemo(() => ({
        activities,
        activityScreens,
        assignStudy,
        changeCurrentTimelineId,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        isReadOnlyMode,
        persistedTimelineId,
        removeActivityStatusDependencies,
        scheduledActivities,
        setOnboarding,
        studyId,
        toggleActivityOnVisit,
        visits,
    }), [
        activities,
        activityScreens,
        assignStudy,
        changeCurrentTimelineId,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        isReadOnlyMode,
        persistedTimelineId,
        removeActivityStatusDependencies,
        scheduledActivities,
        setOnboarding,
        studyId,
        toggleActivityOnVisit,
        visits,
    ]);
    return _jsx(StudyConfigurationContext.Provider, { value: value, children: children });
};
