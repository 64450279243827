const downloadJsonFile = (fileContent, fileName) => {
    const studyJson = 'data:text/json;charset=utf-8,' + encodeURIComponent(fileContent);
    const downloadNode = document.createElement('a');
    downloadNode.setAttribute('href', studyJson);
    downloadNode.setAttribute('download', fileName);
    document.body.appendChild(downloadNode);
    downloadNode.click();
    downloadNode.remove();
};
const readFile = (file, callback) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => callback(reader.result);
};
export const FileUtils = {
    downloadJsonFile,
    readFile,
};
