import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, Icon, Link, H5, Text } from 'libs.nucleus';
import { IconWrapper } from './icon_wrapper.component';
import { GrayCard } from '../card/card.component';
import { FeedbackIcon } from '../svgIcons/feedback.icon';
import { HelpCenterIcon } from '../svgIcons/help_center.icon';
export const HelpSupportLinks = () => {
    const translate = useLocalizeMessage();
    return (_jsxs("div", { className: 'flex flex-row gap-6 justify-between', children: [_jsx(GrayCard, { children: _jsxs("div", { className: 'flex flex-row gap-4', children: [_jsx(IconWrapper, { children: _jsx(HelpCenterIcon, {}) }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(H5, { children: translate('Help center') }), _jsx(Text, { children: translate('Find answers to common questions') }), _jsxs(Link, { target: '_blank', href: 'https://www.medable.com/support', children: [translate('Go to Medable Support'), " ", _jsx(Icon, { size: 'sm', color: 'primary', name: 'externalLink' })] })] })] }) }), _jsx(GrayCard, { children: _jsxs("div", { className: 'flex flex-row gap-4', children: [_jsx(IconWrapper, { children: _jsx(FeedbackIcon, {}) }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(H5, { children: translate('Feedback') }), _jsx(Text, { children: translate('Help shape our product') }), _jsx(Link, { to: '#', children: translate('Give feedback') })] })] }) })] }));
};
