export class UrlFlagConnector {
    constructor(flagManager, flagParameter = 'flag') {
        this.flagManager = flagManager;
        this.flagParameter = flagParameter;
        this.flags = flagManager.getFlags();
        this.readOverridesFromUrl();
        window.addEventListener('locationchange', () => this.readOverridesFromUrl());
    }
    notifyUpdate(_flag, _newValue) {
    }
    readOverridesFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.getAll(this.flagParameter).forEach((flagOverride) => {
            const [flagId, valueOverrideString = 'true'] = flagOverride.split(':');
            const flag = this.flags[flagId];
            if (flag === undefined) {
                console.warn(`Flag with id '${flagId}' doesn't exist.`);
                return;
            }
            const valueOverride = this.parseValueString(valueOverrideString, typeof flag.value);
            if (valueOverride === undefined)
                return;
            this.flagManager.setValue(flag, valueOverride);
        });
    }
    parseValueString(valueString, flagType) {
        switch (flagType) {
            case 'boolean': {
                const lowerCaseValueString = valueString.toLowerCase();
                switch (lowerCaseValueString) {
                    case 'true':
                        return true;
                    case 'false':
                        return false;
                    default:
                        console.warn(`Cannot convert '${lowerCaseValueString}' to a boolean.`);
                        return undefined;
                }
            }
            case 'string':
                return valueString;
            case 'number': {
                const valueNumber = parseFloat(valueString);
                if (isNaN(valueNumber)) {
                    console.warn(`Cannot convert '${valueString}' to a number.`);
                    return undefined;
                }
                return valueNumber;
            }
            default:
                console.warn(`Flag type '${flagType}' is not supported.`);
                return undefined;
        }
    }
}
