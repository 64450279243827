/**
 * Creates an empty matrix with given rows and columns
 * @param rows - integer, the amount of rows the matrix should have
 * @param columns - integer, the amount of columns the matrix should have
 * @returns an empty matrix with given rows and columns
 */
export function createEmpty(rows, columns) {
    const matrix = Array(rows);
    for (let i = 0; i < rows; i++) {
        matrix[i] = Array(columns);
    }
    return matrix;
}
/** Gets the value at row and column of matrix. */
export function get(point, matrix) {
    const columns = matrix[point.row];
    if (columns === undefined) {
        return undefined;
    }
    return columns[point.column];
}
/** Creates a slice of matrix from startPoint up to, but not including, endPoint. */
export function slice(startPoint, endPoint, matrix) {
    const sliced = [];
    const columns = endPoint.column - startPoint.column;
    for (let { row } = startPoint; row <= endPoint.row; row++) {
        const slicedRow = row - startPoint.row;
        sliced[slicedRow] = sliced[slicedRow] || Array(columns);
        for (let { column } = startPoint; column <= endPoint.column; column++) {
            sliced[slicedRow][column - startPoint.column] = get({ row, column }, matrix);
        }
    }
    return sliced;
}
/** Sets the value at row and column of matrix. If a row doesn't exist, it's created. */
export function set(point, value, matrix) {
    const nextMatrix = [...matrix];
    // Synchronize first row length
    const firstRow = matrix[0];
    const nextFirstRow = firstRow ? [...firstRow] : [];
    if (nextFirstRow.length - 1 < point.column) {
        nextFirstRow[point.column] = undefined;
        nextMatrix[0] = nextFirstRow;
    }
    const nextRow = matrix[point.row] ? [...matrix[point.row]] : [];
    nextRow[point.column] = value;
    nextMatrix[point.row] = nextRow;
    return nextMatrix;
}
/** Like Matrix.set() but mutates the matrix */
export function mutableSet(point, value, matrix) {
    let firstRow = matrix[0];
    if (!firstRow) {
        firstRow = [];
        matrix[0] = firstRow;
    }
    if (!(point.row in matrix)) {
        matrix[point.row] = [];
    }
    // Synchronize first row length
    if (!(point.column in firstRow)) {
        firstRow[point.column] = undefined;
    }
    matrix[point.row][point.column] = value;
}
/** Removes the coordinate of matrix */
export function unset(point, matrix) {
    if (!has(point, matrix)) {
        return matrix;
    }
    const nextMatrix = [...matrix];
    const nextRow = [...matrix[point.row]];
    // Avoid deleting to preserve first row length
    nextRow[point.column] = undefined;
    nextMatrix[point.row] = nextRow;
    return nextMatrix;
}
/** Creates an array of values by running each element in collection thru iteratee. */
export function map(func, matrix) {
    const newMatrix = [];
    for (const [point, value] of entries(matrix)) {
        mutableSet(point, func(value, point), newMatrix);
    }
    return newMatrix;
}
/** Create an iterator over the cells in the matrix */
export function* entries(matrix) {
    for (const [row, values] of matrix.entries()) {
        for (const [column, value] of values.entries()) {
            const point = { row, column };
            yield [point, value];
        }
    }
}
export function join(matrix, horizontalSeparator = '\t', verticalSeparator = '\n') {
    let joined = '';
    const { rows, columns } = getSize(matrix);
    for (let row = 0; row < rows; row++) {
        if (row) {
            joined += verticalSeparator;
        }
        for (let column = 0; column < columns; column++) {
            if (column) {
                joined += horizontalSeparator;
            }
            if (matrix[row] && column in matrix[row]) {
                // Encode newline characters within cell values
                const cellValue = String(matrix[row][column]).replace(/\n/g, '\\n').replace(/\r/g, '\\r');
                joined += cellValue;
            }
        }
    }
    return joined;
}
/**
 * Parses a CSV separated by a horizontalSeparator and verticalSeparator into a
 * Matrix using a transform function
 */
export function split(csv, transform, horizontalSeparator = '\t', verticalSeparator = /\r\n|\n|\r/) {
    // Replace line breaks inside quotes and decode encoded newlines
    return csv.split(verticalSeparator).map((row) => row
        .split(horizontalSeparator)
        .map((line) => {
        // Decode original line breaks in each line
        return line.replace(/\\n/g, '\n').replace(/\\r/g, '\r');
    })
        .map(transform));
}
/** Returns whether the point exists in the matrix or not. */
export function has(point, matrix) {
    const firstRow = matrix[0];
    return (firstRow &&
        // validation
        point.row >= 0 &&
        point.column >= 0 &&
        Number.isInteger(point.row) &&
        Number.isInteger(point.column) &&
        // first row length is in sync with other rows
        point.column < firstRow.length &&
        point.row < matrix.length);
}
/** Gets the count of rows and columns of given matrix */
export function getSize(matrix) {
    return {
        columns: getColumnsCount(matrix),
        rows: getRowsCount(matrix),
    };
}
/** Gets the count of rows of given matrix */
export function getRowsCount(matrix) {
    return matrix.length;
}
/** Gets the count of columns of given matrix */
export function getColumnsCount(matrix) {
    const firstRow = matrix[0];
    return firstRow ? firstRow.length : 0;
}
/**
 * Pads matrix with empty rows to match given total rows
 * @param matrix - matrix to pad
 * @param totalRows - number of rows the matrix should have
 * @returns the updated matrix
 */
export function padRows(matrix, totalRows) {
    const { rows, columns } = getSize(matrix);
    if (rows >= totalRows) {
        return matrix;
    }
    const missingRows = totalRows - rows;
    const emptyRow = Array(columns).fill(undefined);
    const emptyRows = Array(missingRows).fill(emptyRow);
    return [...matrix, ...emptyRows];
}
/**
 * Pads matrix with empty columns to match given total columns
 * @param matrix - matrix to pad
 * @param size - minimum size of the matrix after padding.
 * @returns the updated matrix
 */
export function pad(matrix, size) {
    const { rows, columns } = getSize(matrix);
    if (rows >= size.rows && columns >= size.columns) {
        // Optimization, no padding required.
        return matrix;
    }
    const resultSize = {
        rows: size.rows > rows ? size.rows : rows,
        columns: size.columns > columns ? size.columns : columns,
    };
    let padded = [...matrix];
    if (resultSize.columns > columns) {
        const padColumns = resultSize.columns - columns;
        padded = padded.map((row) => [...row, ...Array(padColumns).fill(undefined)]);
    }
    if (resultSize.rows > rows) {
        const padRows = resultSize.rows - rows;
        const emptyRow = Array(resultSize.columns).fill(undefined);
        padded = [...padded, ...Array(padRows).fill(emptyRow)];
    }
    return padded;
}
/**
 * Flattens a matrix values to an array
 * @param matrix - the matrix to flatten values from
 * @param transform - optional transform function to apply to each value in the
 * matrix
 * @returns an array of the values from matrix, transformed if a transform
 * function is passed
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toArray(matrix, transform) {
    const array = [];
    for (let row = 0; row < matrix.length; row++) {
        for (let column = 0; column < matrix[row].length; column++) {
            const value = matrix[row][column];
            array.push(transform ? transform(value, { row, column }) : value);
        }
    }
    return array;
}
/** Returns the maximum point in the matrix */
export function maxPoint(matrix) {
    const size = getSize(matrix);
    return { row: size.rows - 1, column: size.columns - 1 };
}
/** Move a row from one index to other */
export function moveRow(from, to, matrix) {
    const nextMatrix = [...matrix];
    const [removed] = nextMatrix.splice(from, 1);
    nextMatrix.splice(to, 0, removed);
    return nextMatrix;
}
/** Insert rows above or below a given row */
export function insertRows(row, direction, count, matrix) {
    const nextMatrix = [...matrix];
    const emptyRow = Array(getColumnsCount(matrix)).fill(undefined);
    for (let i = 0; i < count; i++) {
        nextMatrix.splice(row + (direction === 'above' ? 0 : 1), 0, emptyRow);
    }
    return nextMatrix;
}
/** Delete rows based on a PointRange */
export function deleteRows(range, matrix) {
    const nextMatrix = [...matrix];
    nextMatrix.splice(range.start.row, range.end.row - range.start.row + 1);
    return nextMatrix;
}
/** Clear a row except a point */
export function clearRowExcept(matrix, point) {
    const nextMatrix = [...matrix];
    const row = nextMatrix[point.row];
    nextMatrix[point.row] = row.map((_, column) => (column === point.column ? row[column] : undefined));
    return nextMatrix;
}
