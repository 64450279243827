import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AuthContext, useApiClient, ApiClientService } from 'libs.react';
export const useWorkspaceSite = (workspaceSiteId) => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchSite = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/sites/${workspaceSiteId}`);
        return response?.data;
    };
    const { data: workspaceSite, isLoading } = useQuery({
        queryKey: ['entities', entityId.toString(), 'sites', workspaceSiteId?.toString()],
        queryFn: fetchSite,
        enabled: !!workspaceSiteId,
    });
    return {
        isLoading,
        workspaceSite,
    };
};
