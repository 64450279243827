import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { ActivityType } from '@axon/rosetta-sdk';
import { SpreadsheetProvider } from '../../contexts';
import { StudyTestPlanStepValidUser, StudyTestPlan_StepValidExecutionModes, StudyTestPlan_ExecutionModeOptions, StudyTestPlan_StepOptions, } from '../../types';
import { GenericUtils, getContextFieldOptionsInCSV } from '../../utils';
import { Spreadsheet } from '../spreadsheet';
import DefaultDataEditor from '../spreadsheet/spreadsheet_data_editor';
import DefaultDataViewer from '../spreadsheet/spreadsheet_data_viewer';
import SelectDataEditor from '../spreadsheet/spreadsheet_select_data_editor';
import SelectDataViewer from '../spreadsheet/spreadsheet_select_data_viewer';
import TestPlanContextEditModal from '../study_test_plan_details/context_column/test_plan_context_edit_modal.component';
import TestPlanContextEditor from '../study_test_plan_details/context_column/test_plan_context_editor.component';
import TestPlanUserInputEditModal from '../study_test_plan_details/user_input_column/test_plan_user_input_edit_modal.component';
import TestPlanUserInputEditor from '../study_test_plan_details/user_input_column/test_plan_user_input_editor.component';
import TestPlanUserInputViewer from '../study_test_plan_details/user_input_column/test_plan_user_input_viewer.component';
export const SOATestPlan = ({ study, ecoaActivities, testPlanCsv, users }) => {
    const [contextModal, setContextModal] = useState(null);
    const [userInputModal, setUserInputModal] = useState(null);
    const { visits, activities, notifications, statuses, allActivities } = useMemo(() => {
        const visits = Object.values(study.getAllVisits());
        const allActivities = study.getAllActivities();
        const activities = study.getAllActivities().filter((activity) => activity.type === ActivityType.PARTICIPANT);
        const { notifications } = study;
        const statuses = study.participantStatuses;
        return { visits, activities, notifications, statuses, allActivities };
    }, [study]);
    const selectedActivity = useMemo(() => {
        if (!userInputModal) {
            return undefined;
        }
        const { taskName } = userInputModal;
        const ecoaActivity = ecoaActivities.find((a) => GenericUtils.isSameString(a.name, taskName) ||
            GenericUtils.isSameString(a.data.short_name, taskName) ||
            GenericUtils.isSameString(a.data.full_name, taskName));
        if (ecoaActivity) {
            const activity = allActivities.find((a) => a.libraryId === ecoaActivity.libraryId);
            return {
                activity: activity,
                ecoaActivity: ecoaActivity.data,
            };
        }
        const activity = allActivities.find((a) => GenericUtils.isSameString(a.name, taskName));
        if (!activity) {
            return undefined;
        }
        return {
            activity,
            ecoaActivity: ecoaActivity,
        };
    }, [ecoaActivities, userInputModal?.taskName, allActivities]);
    const getUsers = useCallback((rowCells) => {
        const value = rowCells[0]?.value;
        if (!value) {
            return [];
        }
        const validUsersTypes = StudyTestPlanStepValidUser[value];
        const usersToSelect = [];
        if (validUsersTypes && validUsersTypes.length) {
            validUsersTypes.forEach((type) => {
                const usersOfType = users[type];
                usersToSelect.push(...usersOfType.map((user) => {
                    return {
                        value: user,
                        label: user,
                    };
                }));
            });
        }
        return usersToSelect;
    }, [users]);
    const getExecutionModes = useCallback((rowCells) => {
        const value = rowCells[0]?.value;
        if (!value) {
            return [];
        }
        const validExecutionModes = StudyTestPlan_StepValidExecutionModes[value];
        if (!validExecutionModes) {
            return [];
        }
        const executionModes = StudyTestPlan_ExecutionModeOptions.filter((mode) => validExecutionModes.includes(mode.value));
        return executionModes;
    }, []);
    const getFieldOptions = useCallback((field, context) => {
        return getContextFieldOptionsInCSV(field, context, {
            visits,
            activities,
            notifications,
            statuses,
            users,
            ecoaActivities,
        });
    }, [visits, activities, notifications, statuses, users]);
    const columnConfig = useMemo(() => [
        {
            label: 'Step Type',
            DataViewer: (props) => _jsx(SelectDataViewer, { ...props, getOptions: () => StudyTestPlan_StepOptions }),
            DataEditor: (props) => (_jsx(SelectDataEditor, { ...props, getOptions: () => StudyTestPlan_StepOptions, clearOtherCells: true })),
        },
        {
            label: 'User',
            DataViewer: (props) => _jsx(SelectDataViewer, { ...props, getOptions: getUsers }),
            DataEditor: (props) => _jsx(SelectDataEditor, { ...props, getOptions: getUsers }),
        },
        {
            label: 'Context',
            DataEditor: (props) => _jsx(TestPlanContextEditor, { ...props, showContextModal: setContextModal }),
        },
        {
            label: 'Execution Mode',
            DataViewer: (props) => _jsx(SelectDataViewer, { ...props, getOptions: getExecutionModes }),
            DataEditor: (props) => _jsx(SelectDataEditor, { ...props, getOptions: getExecutionModes }),
        },
        {
            label: 'User Input',
            DataViewer: (props) => _jsx(TestPlanUserInputViewer, { ...props }),
            DataEditor: (props) => _jsx(TestPlanUserInputEditor, { ...props, showUserInputModal: setUserInputModal }),
        },
    ], [getUsers, getExecutionModes]);
    const columnsLabels = useMemo(() => columnConfig.map((col) => col.label), [columnConfig]);
    const data = useMemo(() => {
        const matrix = [];
        for (const row of testPlanCsv) {
            const cellValues = [];
            for (const col of columnsLabels) {
                cellValues.push({
                    value: row[col],
                });
            }
            matrix.push([...cellValues]);
        }
        return matrix;
    }, [testPlanCsv]);
    const DataEditor = useCallback((props) => {
        const { column } = props;
        const config = columnConfig[column];
        const CustomDataEditor = config.DataEditor || DefaultDataEditor;
        return _jsx(CustomDataEditor, { ...props });
    }, [columnConfig]);
    const DataViewer = useCallback((props) => {
        const { column } = props;
        const config = columnConfig[column];
        const CustomDataViewer = config.DataViewer || DefaultDataViewer;
        return _jsx(CustomDataViewer, { ...props });
    }, [columnConfig]);
    return (_jsxs(_Fragment, { children: [_jsx(SpreadsheetProvider, { children: _jsx(Spreadsheet, { data: data, columnLabels: columnsLabels, DataEditor: DataEditor, DataViewer: DataViewer, isHeaderSticky: true, isEditEnabled: false }) }), contextModal ? (_jsx(TestPlanContextEditModal, { ...contextModal, onClose: () => {
                    setContextModal(null);
                }, getFieldOptions: getFieldOptions, addNewUser: () => undefined })) : null, userInputModal && selectedActivity ? (_jsx(TestPlanUserInputEditModal, { ...userInputModal, onClose: () => {
                    setUserInputModal(null);
                }, study: study, ...selectedActivity })) : null] }));
};
