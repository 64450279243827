import { DateTime } from 'luxon';
export const calculateServerTimeDiff = (dateTime) => {
    const serverTime = new Date(dateTime);
    const now = new Date(Date.now());
    const diff = serverTime.getTime() - now.getTime();
    const diffInDays = Math.max(0, Math.floor(diff / (1000 * 60 * 60 * 24)));
    const diffInHours = Math.max(0, Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const diffInMinutes = Math.max(0, Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
    const sign = diffInDays > 0 ? '+' : diffInDays < 0 ? '-' : '';
    return `${sign}${diffInDays}d ${diffInHours}h ${diffInMinutes}m`;
};
export const convertAbsoluteTimeToISO = (date, time) => {
    const dateTime = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: time.time?.hour ?? 0,
        minute: time.time?.minute ?? 0,
    }, {
        zone: time.timeZone?.toString() ?? 'UTC',
    });
    return dateTime.toISO();
};
export const convertRelativeTimeToISO = (serverDateTime, days, hours, minutes) => {
    const dateTime = DateTime.fromISO(serverDateTime);
    if (!dateTime.isValid) {
        throw new Error('Invalid serverDateTime');
    }
    return dateTime.plus({ days, hours, minutes }).toISO();
};
export const getUTCOffset = () => {
    const offset = -new Date().getTimezoneOffset() / 60;
    const sign = offset >= 0 ? '+' : '-';
    const absOffset = Math.abs(offset);
    return `${sign}${absOffset}`;
};
