import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { LocalizationStatus, } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { Locale, useLocalizeMessage, InlineNotification } from 'libs.nucleus';
import { PageHeader } from 'libs.react';
import { SectionExport, VersionedTableImport } from '../../components';
import { ImportPdfAction } from '../../components/import_pdf_action';
import { TranslationWorkbenchContext } from '../../contexts';
import { useAssetContext } from '../../hooks';
import { getExportTableStatus } from '../../utils';
import { toSentenceCase, toSnakeCase } from '../../utils/string_utils';
export const ResourcePdfView = ({ assetId, studyId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const assetType = queryParams.get('assetType');
    const asset = {
        type: assetType,
        id: assetId,
        version: '1',
    };
    const { study, updateStudy, studyLocalizationStatus, resourcesAssets, isStudyEnglishOnly } = useContext(TranslationWorkbenchContext);
    const isStudyinDraft = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const _resource = Object.keys(resourcesAssets).find((key) => resourcesAssets[key].id === assetId);
    const _resourceData = _resource ? resourcesAssets[_resource] : {};
    const resource = study.studyResources.find((r) => r.id === assetId);
    const { getReviewUrl } = useAssetContext();
    const { localizationEntry } = useLocalization(asset);
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        { name: toSentenceCase(_resource) || '', path: `/studies/${studyId}/build/translations/${assetId}` },
    ], [assetId, assetType]);
    const handleUpdateStudyConfig = useCallback((files) => {
        const file = files?.[0];
        if (!file || !resource) {
            return;
        }
        resource?.files?.push(file);
        const resources = [...study.studyResources.filter((r) => r.id !== assetId), resource];
        study.updateStudyMetadata({ studyResources: resources });
        updateStudy?.(study);
    }, [study, updateStudy, resource]);
    const filteredLocales = _resource && _resourceData?.currentVersionStatus
        ? Object.keys(_resourceData.currentVersionStatus).filter((locale) => locale !== Locale.en_US)
        : [];
    const importTableAuxData = filteredLocales.map((locale) => {
        return {
            locale: locale,
            status: LocalizationStatus.NOT_STARTED, // Deprecated: will be removed in the future
            last_updated: localizationEntry?.supported_locales?.[locale]?.last_updated
                ?.toDate()
                .toISOString(),
            version: '0', // Deprecated: will be removed in the future
            reviewPath: `${getReviewUrl()}/${locale}?assetType=${asset?.type}`,
            fileName: `${toSnakeCase(resource?.title)}_${locale}_v${localizationEntry?.supported_locales?.[locale]?.version || '1'}.pdf`,
            currentVersionStatus: _resourceData?.currentVersionStatus?.[locale],
        };
    });
    const importTableAuxDataKey = JSON.stringify(importTableAuxData);
    const data = useMemo(() => importTableAuxData.map((allTheStuff) => {
        const { locale, fileName } = allTheStuff;
        return {
            ...allTheStuff,
            action: (_jsx(ImportPdfAction, { locale: locale, asset: asset, updateStudyConfig: handleUpdateStudyConfig, disabled: isStudyinDraft, fileName: fileName })),
        };
    }), [importTableAuxDataKey]);
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: toSentenceCase(resource?.title) || t('PDF Resources'), breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' }, status: getExportTableStatus(studyLocalizationStatus) }), _jsx("hr", {}), !filteredLocales.length && (_jsx(InlineNotification, { title: t('Preview unavailable'), subtitle: t('Preview links will be enabled after the first import') })), !isStudyEnglishOnly && _jsx(VersionedTableImport, { data: data, hasAction: true, checklink: true })] })] }));
};
