var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getAuth, connectAuthEmulator, signInWithCustomToken } from 'firebase/auth';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'libs.react';
import { FirebaseAppContext } from './firebase_app_context';
import { FirebaseAuthContext } from './firebase_auth_context';
const oneHour = 60 * 60 * 1000;
const useFirebaseToken = (medableUser) => {
    const [firebaseCustomToken, setFirebaseCustomToken] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchToken = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch('/api/auth', { method: 'POST' });
                const data = yield response.json();
                setFirebaseCustomToken(data.token);
            }
            catch (err) {
                console.error('Error fetching firebase token:', err);
                setError(err);
            }
        });
        if (medableUser) {
            fetchToken();
            const interval = setInterval(fetchToken, oneHour);
            return () => clearInterval(interval);
        }
    }, [medableUser]);
    return { firebaseCustomToken, error };
};
const useFirebaseAuth = (app, firebaseCustomToken) => {
    const [user, setUser] = useState(null);
    const [userClaims, setUserClaims] = useState(null);
    const [firebaseToken, setFirebaseToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const signIn = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!app || !firebaseCustomToken) {
                return;
            }
            const auth = getAuth(app);
            try {
                setLoading(true);
                if (app.options.projectId === 'demo-local') {
                    if (!process.env.FIREBASE_AUTH_EMULATOR_HOST) {
                        throw new Error('Please setup FIREBASE_AUTH_EMULATOR_HOST in your .env.local');
                    }
                    const authUrl = `http://${process.env.FIREBASE_AUTH_EMULATOR_HOST}`;
                    console.log(`Attempt auth with firebase emulator at ${authUrl}`);
                    connectAuthEmulator(auth, authUrl, { disableWarnings: true });
                }
                const userCredential = yield signInWithCustomToken(auth, firebaseCustomToken);
                setUser(userCredential.user);
                const token = yield userCredential.user.getIdToken();
                setFirebaseToken(token);
                const tokenResult = yield userCredential.user.getIdTokenResult();
                setUserClaims(tokenResult.claims);
            }
            catch (err) {
                console.error('Error signing in:', err.message);
                setError(err);
            }
            finally {
                setLoading(false);
            }
        });
        signIn();
    }, [app, firebaseCustomToken]);
    return { user, userClaims, firebaseToken, loading, error };
};
export const FirebaseAuthProvider = ({ children }) => {
    const { app } = useContext(FirebaseAppContext);
    const { user: medableUser } = useContext(AuthContext);
    if (!app) {
        throw new Error('FirebaseAuthProvider must be used within a FirebaseAppProvider');
    }
    const { firebaseCustomToken, error: tokenError } = useFirebaseToken(medableUser);
    const { user, userClaims, firebaseToken, loading, error: authError } = useFirebaseAuth(app, firebaseCustomToken);
    return (_jsx(FirebaseAuthContext.Provider, { value: { user, userClaims, firebaseToken, loading, error: tokenError || authError }, children: children }));
};
