import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalization } from 'libs.localization.react';
import { Tooltip } from 'libs.nucleus';
export const LocalesCount = ({ asset }) => {
    const { isLoading, localizationEntry } = useLocalization(asset);
    if (isLoading) {
        return _jsx("span", { children: "-" });
    }
    const locales = Object.keys(localizationEntry?.supported_locales || {});
    if (locales.length > 0) {
        return (_jsx(Tooltip, { title: `${locales.join(', ')}`, placement: 'top', children: _jsx("span", { className: 'cursor-text text-sm', children: locales.length }) }));
    }
    return _jsx("span", { children: "0" });
};
