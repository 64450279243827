import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
const getEmptyArrayOf = (length) => Array.from(Array(length).keys());
export const useTimeframes = ({ emptyValue = '--', maxDays = 31 } = {}) => {
    const translate = useLocalizeMessage();
    /**
     * Given a number, it creates a SelectOption for that value following the standards used for our UX
     * That means that the value will contain the numeric value of the option and the label will be always a positive
     * value
     */
    const getOption = ({ value, emptyValue }) => {
        return value === 0 ? { label: emptyValue, value: '' } : { label: String(Math.abs(value)), value: String(value) };
    };
    const dayOptions = useMemo(() => {
        return getEmptyArrayOf(maxDays).map((value) => getOption({ value, emptyValue }));
    }, []);
    const periodOptions = useMemo(() => {
        return [
            { value: 'hours', label: translate('Hour(s)') },
            { value: 'days', label: translate('Calendar day(s)') },
            { value: 'weeks', label: translate('Week(s)') },
        ];
    }, []);
    return {
        dayOptions,
        periodOptions,
    };
};
