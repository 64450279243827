import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Tooltip, useLocalizeMessage } from 'libs.nucleus';
import { isUnscheduledVisit } from '../../utils';
import { EnhancedMatrixRow, EnhancedMatrix, } from '../enhanced_matrix';
import { UserIcon, BuildingIcon } from '../svg';
const TASK_BG_MAP = {
    participant: 'bg-gradient-to-r from-purple-400 via-purple-400 to-purple-400/70 hover:from-purple-300 hover:via-purple-300 hover:to-purple-300/70',
    site: 'bg-gradient-to-r from-[#47B670] via-[#47B670] to-[#47B670]/70 hover:from-[#6BC08A] hover:via-[#6BC08A] hover:to-[#6BC08A]/70',
};
export const StudySummaryWorkflowMatrix = ({ headerLabels, sections, activities, isOnboarding }) => {
    const translate = useLocalizeMessage();
    const initialCell = { content: '', key: 'activityName', width: 280 };
    const headers = useMemo(() => {
        const headers = [initialCell];
        const unscheduledVisitIndex = headerLabels.findIndex(isUnscheduledVisit);
        const unscheduledVisitCount = headerLabels.map(isUnscheduledVisit).filter(Boolean).length;
        headerLabels
            .filter((label) => !isUnscheduledVisit(label))
            .forEach((visit, index) => {
            headers.push({ content: visit, key: visit });
            if (unscheduledVisitCount > 0 && index === unscheduledVisitIndex) {
                const visit = headerLabels[unscheduledVisitIndex];
                headers.push({
                    content: visit,
                    key: visit,
                    subtitle: `${unscheduledVisitCount} ${translate('visits')}`,
                });
            }
        });
        return headers;
    }, [activities]);
    const getActivityIcon = (type) => {
        switch (type) {
            case 'participant':
                return _jsx(UserIcon, { className: 'text-purple-500' });
            case 'site':
            default:
                return _jsx(BuildingIcon, { className: 'text-green-500' });
        }
    };
    const MatrixRow = ({ item: activity, dataTestId, rowIndex, headers, getCellStyles, defaultCellWidth, }) => {
        const activityNodes = activity.assignmentIds
            .map((id) => activities[id])
            .filter((activity) => activity)
            .reduce((acc, curr) => {
            if (curr.visitName) {
                acc[curr.visitName] = [...(acc[curr.visitName] || []), curr];
            }
            else if (isOnboarding) {
                acc.Onboarding = [...(acc.Onboarding || []), curr];
            }
            return acc;
        }, {});
        const isNonVisitActivity = Object.keys(activityNodes).length === 0;
        const totalWidth = headers.slice(1).reduce((acc, curr) => acc + (curr.width || defaultCellWidth), 0);
        const getTaskCell = (index, activity, header) => {
            const { type } = activity;
            return (_jsx("div", { className: getCellStyles(index, { isEmpty: header.isEmpty }), style: { width: header.width }, children: _jsx(Tooltip, { className: 'max-w-[40vw]', wrapperClassName: 'w-full', as: 'div', title: _jsxs("div", { className: 'flex flex-row gap-2 relative z-10', children: [_jsxs("div", { className: 'flex-1', children: [_jsx("div", { className: 'font-bold mb-1', children: translate('Start Conditions') }), _jsx("ul", { className: 'list-disc list-outside ml-4', children: activity.availabilityRules.map((rule) => (_jsx("li", { children: rule }, rule))) })] }), _jsx("div", { className: 'w-[1px] nucleus-bg-neutral-300 min-w-[1px]' }), _jsxs("div", { className: 'flex-1', children: [_jsx("div", { className: 'font-bold mb-1', children: translate('End Conditions') }), _jsx("ul", { className: 'list-disc list-outside ml-4', children: activity.availabilityWindow.map((rule) => (_jsx("li", { children: rule }, rule))) })] })] }), children: _jsx("div", { className: `flex flex-row w-full items-center justify-start rounded m-1 p-4 relative group ${TASK_BG_MAP[type]}` }) }) }, `timeline-${activity.assignmentId}-cell-${header.key}`));
        };
        const getNonVisitActivityCells = () => {
            const activityNodes = activity.assignmentIds.map((id) => activities[id]).filter((activity) => activity);
            if (activityNodes.length === 0) {
                return (_jsx("div", { className: getCellStyles(1), style: { width: totalWidth } }, `timeline-empty-cell-nonVisitActivity`));
            }
            return getTaskCell(1, activityNodes[0], {
                key: 'nonVisitActivity',
                width: totalWidth,
            });
        };
        return (_jsxs(EnhancedMatrixRow, { dataTestId: dataTestId, rowIndex: rowIndex, children: [_jsx("div", { className: getCellStyles(0, { isEmpty: headers[0].isEmpty }), style: {
                        width: headers[0].width,
                    }, children: _jsxs("div", { className: 'sticky left-0 flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', "data-testid": 'schedule-timeline-activity-name-cell-container', children: [getActivityIcon(activity.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-row-name', title: activity.activityName, children: activity.activityName })] }) }, `timeline-activityName`), isNonVisitActivity
                    ? getNonVisitActivityCells()
                    : headers.slice(1).map((header, index) => {
                        const tasks = activityNodes[header.key];
                        if (!tasks || tasks.length === 0) {
                            return (_jsx("div", { className: getCellStyles(index + 1, { isEmpty: header.isEmpty }), style: { width: header.width } }, `timeline-empty-cell-${header.key}`));
                        }
                        const task = tasks[0];
                        return getTaskCell(index + 1, task, header);
                    })] }));
    };
    return (_jsx(EnhancedMatrix, { headers: headers, sections: sections.map((section) => ({
            ...section,
            RowComponent: MatrixRow,
        })), stickyColumn: true }));
};
