import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { FormSections, useLocalizeMessage, ModalWindow, Select } from 'libs.nucleus';
import { StudyConfigurationContext } from '../../../contexts';
export const ScheduleTimelineMoveVisitModal = ({ onCancel, onMoveVisit, visitToMove, }) => {
    const { currentStudy, currentTimelineId, visits } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    /**
     * Generates the possible options to reschedule the visit
     * It will exclude the current visit and will add a option to add it at the end of the schedule
     */
    const moveOptions = useMemo(() => {
        const visitSchedule = currentStudy.visitSchedule[currentTimelineId];
        const options = [{ label: translate('-select-'), value: '-1' }];
        let count = 0;
        const standardVisitSchedule = visits.filter((visit) => visit.visitType === 'standard');
        standardVisitSchedule.forEach((visit, index) => {
            if (visit && visit.id !== visitToMove.id) {
                if (index === 0 || visitToMove.id !== standardVisitSchedule[index - 1].id) {
                    // we avoid adding an option to move the visit to before the current next visit (which will have no effect)
                    options.push({ label: visit.name, value: String(count++) });
                }
                else {
                    count++;
                }
            }
        });
        // We add the ability to move the visit to the end of the schedule only if it is not already the last visit
        if (visitToMove.id !== standardVisitSchedule[standardVisitSchedule.length - 1].id) {
            options.push({ label: translate('Move to the end'), value: String(visitSchedule.length - 1) });
        }
        return options;
    }, []);
    const [isPristine, setIsPristine] = useState(true);
    const [selectedOption, setSelectedOption] = useState(moveOptions[0]);
    const isEnabled = selectedOption.value !== '-1' && !isPristine;
    const handleMoveVisit = () => {
        onMoveVisit(visitToMove, Number(selectedOption.value));
    };
    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setIsPristine(false);
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isEnabled) {
            handleMoveVisit();
        }
    };
    const saveButton = {
        label: translate('Move'),
        onClick: handleMoveVisit,
        disabled: !isEnabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const formSections = [
        {
            title: translate('Move visit'),
            description: translate('Choose to move this visit to a different place in your schedule of activities timeline'),
            content: (_jsx("section", { className: 'max-w-input-lg', children: _jsx(Select, { dataTestId: 'move-visit-select', label: translate('Move before'), onChange: handleSelectChange, options: moveOptions, value: selectedOption, width: 'full' }) })),
        },
    ];
    return (_jsx(ModalWindow, { title: translate('Move visit'), isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], width: 'full', children: _jsx("section", { className: 'min-h-[30rem]', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
