export const getCountryNameFromCode = (code, countriesOptions = []) => {
    return countriesOptions.find((country) => country.value === code)?.label || 'Unknown';
};
export const getSiteAddressLine = (address, countriesOptions = []) => {
    let fullAddress = `${address.city}, ${address.region}, ${getCountryNameFromCode(address.countryCode, countriesOptions)}`;
    if (address.postalCode) {
        fullAddress = `${fullAddress}, ${address.postalCode}`;
    }
    return fullAddress;
};
