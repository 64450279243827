import { useContext } from 'react';
import { ApiClientContext } from 'libs.react';
import { StudyConfigurationContext, VariablesContext } from '../../contexts';
export class OpenAIError extends Error {
    type;
    param;
    code;
    constructor(message, type, param, code) {
        super(message);
        this.name = 'OpenAIError';
        this.type = type;
        this.param = param;
        this.code = code;
    }
}
export const useServerUtils = () => {
    const { apiClient: { post }, } = useContext(ApiClientContext);
    const { autobuildAssistantEndpoint } = useContext(VariablesContext);
    const { currentStudy } = useContext(StudyConfigurationContext);
    const openAIStream = async (messageProps) => {
        try {
            const { data } = await post(autobuildAssistantEndpoint, {
                message: messageProps.message.content,
                study: JSON.stringify(currentStudy.toRosetta()),
            });
            return { response: data };
        }
        catch (e) {
            throw new Error('Error getting response from Medable AI Assistant');
        }
    };
    return { openAIStream };
};
