import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { useSpreadsheetSelector } from '../../../contexts/spreadsheet';
import { StudyTestPlanExecutionModes, StudyTestPlanStepType } from '../../../types';
import { convertTestPlanContextToObject } from '../../../utils';
import { SpreadsheetStyles } from '../../spreadsheet';
const TestPlanUserInputViewer = ({ row: rowNumber, cell, disabled }) => {
    const translate = useLocalizeMessage();
    const row = useSpreadsheetSelector((state) => state.data[rowNumber]);
    const showConfigure = useMemo(() => {
        const stepType = row[0]?.value;
        const executionMode = row[3]?.value;
        if (!stepType) {
            return undefined;
        }
        if (![StudyTestPlanStepType.CompleteParticipantTask, StudyTestPlanStepType.CompleteSiteTask].includes(stepType)) {
            return undefined;
        }
        const context = row[2]?.value;
        if (!context) {
            return undefined;
        }
        const obj = convertTestPlanContextToObject(context);
        const taskName = obj.TaskName;
        if (!taskName) {
            return undefined;
        }
        if (!executionMode || executionMode === StudyTestPlanExecutionModes.BACKEND) {
            return undefined;
        }
        return true;
    }, [row]);
    return (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth, {
            [SpreadsheetStyles.cellReadOnly]: !showConfigure,
        }), children: showConfigure ? (disabled ? (cell?.value ? (_jsx("span", { className: `font-bold text-purple-500 cursor-pointer`, children: translate('View') })) : null) : (_jsx("span", { className: `font-bold ${cell?.value ? 'text-yellow-500' : 'text-purple-500'} cursor-pointer`, children: cell?.value ? translate('Edit') : translate('Configure') }))) : null }));
};
export default TestPlanUserInputViewer;
