import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { SDKStudy } from '@axon/rosetta-sdk';
import { ChatPanel, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { ChatContext, StudyConfigurationContext } from '../../contexts';
import { OpenAIError, useServerUtils } from '../../utils';
import { ChatFileUpload } from '../chat_file_upload';
import { MedableIconGraphic } from '../svg';
export var ConversationKey;
(function (ConversationKey) {
    ConversationKey["STUDY_CONFIGURATION"] = "study-configuration";
    ConversationKey["SCHEDULE_TIMELINE"] = "schedule-timeline";
    ConversationKey["STUDY_DETAILS"] = "study-details";
    ConversationKey["STUDY_SETTINGS"] = "study-settings";
})(ConversationKey || (ConversationKey = {}));
export const Chat = memo(({ conversationKey, systemPrompt }) => {
    const translate = useLocalizeMessage();
    const ref = useRef(null);
    const { openAIStream } = useServerUtils();
    const { addNotification } = useToastNotification();
    const [isLoading, setIsLoading] = useState(false);
    const { assignStudy } = useContext(StudyConfigurationContext);
    const { changeConversation, conversations, isChatOpen, isInitialized, selectedConversation, toggleChat, updateConversations, updateSelectedConversation, } = useContext(ChatContext);
    useEffect(() => {
        if (isInitialized && isChatOpen) {
            changeConversation(conversationKey);
        }
    }, [conversationKey, isInitialized, isChatOpen]);
    useEffect(() => {
        if (systemPrompt && selectedConversation && !selectedConversation.initialized) {
            handleSend({ role: 'system', content: systemPrompt });
        }
    }, [selectedConversation]);
    const handleSend = useCallback(async (message) => {
        if (selectedConversation) {
            const messageProps = {
                message,
                history: selectedConversation.messages,
            };
            let updatedConversation = {
                ...selectedConversation,
                initialized: true,
                ...(message.role !== 'system' && { messages: [...selectedConversation.messages, message] }),
            };
            updateSelectedConversation(updatedConversation);
            setIsLoading(true);
            try {
                const { response } = await openAIStream(messageProps);
                const { study, content } = response;
                if (study !== undefined) {
                    const restoredStudy = SDKStudy.fromRosetta(study);
                    assignStudy(restoredStudy);
                }
                setIsLoading(false);
                const updatedMessages = [
                    ...updatedConversation.messages,
                    {
                        role: 'assistant',
                        content: content ||
                            translate('An error occured while fetching the message. Please try again later. If the problem persists, please reach out to our support team.'),
                    },
                ];
                updatedConversation = { ...updatedConversation, messages: updatedMessages };
                let updatedConversations = [];
                if (conversations.length === 0) {
                    updatedConversations.push(updatedConversation);
                }
                else {
                    updatedConversations = conversations.map((conversation) => conversation.key === selectedConversation.key ? updatedConversation : conversation);
                }
                updateSelectedConversation(updatedConversation);
                updateConversations(updatedConversations);
            }
            catch (error) {
                console.error(error);
                setIsLoading(false);
                const title = error instanceof OpenAIError ? error.message : translate('An error occurred while sending the message');
                addNotification({ title, type: 'error' });
            }
        }
    }, [conversations, selectedConversation, systemPrompt]);
    const onSend = (message) => {
        handleSend({ role: 'user', content: message });
    };
    const messages = selectedConversation?.messages.map((msg, index) => ({
        id: index,
        message: _jsx("p", { children: msg.content }),
        type: msg.role === 'user' ? 'user' : 'bot',
    })) ?? [];
    const onUploadFile = () => {
        ref.current?.click();
    };
    const onUpload = ({ downloadUrl }) => {
        handleSend({ content: `File uploaded # ${downloadUrl}`, role: 'user' });
    };
    return (_jsxs(_Fragment, { children: [!isChatOpen && (_jsx("section", { className: 'fixed bottom-10 right-10 cursor-pointer rounded-full border-l border-neutral-200 bg-white p-2 shadow-xl duration-300 ease-in-out', onClick: toggleChat, children: _jsx(MedableIconGraphic, {}) })), _jsxs("section", { className: `relative flex h-full flex-col overflow-hidden border-r border-neutral-200 bg-white font-sans duration-300 ease-in-out dark:bg-[#343541] ${isChatOpen ? 'w-full max-w-[25.5rem]' : 'w-0 max-w-0'}`, children: [isLoading && _jsx(Spinner, { size: 'full' }), _jsx(ChatPanel, { title: translate('Medable AI Assistant'), onSubmit: onSend, onClose: toggleChat, messages: messages, customBorderProperties: { borderTop: 'unset' }, onUploadFile: onUploadFile })] }), _jsx(ChatFileUpload, { ref: ref, onUpload: onUpload })] }));
});
Chat.displayName = 'Chat';
