import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useSpreadsheetSelector } from '../../contexts/spreadsheet';
const ContextMenu = ({ position, items, onClose, popupContainer }) => {
    const selectionCount = useSpreadsheetSelector((state) => state.selected.count(state.data));
    const menuRef = useRef(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    padding: 10,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);
    useEffect(() => {
        popupContainer?.parentElement?.style.setProperty('overflow', 'hidden');
        return () => {
            popupContainer?.parentElement?.style.removeProperty('overflow');
        };
    }, [popupContainer]);
    return createPortal(_jsxs(_Fragment, { children: [_jsx("div", { ref: setReferenceElement, style: {
                    position: 'fixed',
                    top: position.y,
                    left: position.x,
                    width: 0,
                    height: 0,
                } }), _jsx("div", { ref: (node) => {
                    menuRef.current = node;
                    setPopperElement(node);
                }, className: '!fixed', style: styles.popper, ...attributes.popper, children: _jsxs("div", { className: 'bg-white border border-gray-200 rounded shadow-md', children: [_jsx("p", { className: ' px-4 py-2 text-sm font-medium', children: `${selectionCount} cell${selectionCount === 1 ? '' : 's'}` }), items.map((item) => (_jsx("div", { className: 'flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-gray-100', onClick: (event) => {
                                item.onClick(event);
                                onClose();
                            }, children: item.label }, item.label)))] }) })] }), popupContainer || document.body);
};
export default ContextMenu;
