import { useContext } from 'react';
import { checkScopes } from './check_scopes';
import { AuthContext } from '../../contexts';
export const useScopeValidator = (permissions, options = {}) => {
    const { user, scopes, entityId } = useContext(AuthContext);
    if (!user) {
        return false;
    }
    const { anyResource, resource = `entity/${entityId}`, validationRule = 'some' } = options;
    const permissionList = Array.isArray(permissions) ? permissions : [permissions];
    const checkOptions = anyResource ? { anyResource } : { resource };
    return validationRule === 'all'
        ? permissionList.every((permission) => checkScopes({ scopes, permission, ...checkOptions }))
        : permissionList.some((permission) => checkScopes({ scopes, permission, ...checkOptions }));
};
