import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { ActivityType, ResponderRole, SDKActivity } from '@axon/rosetta-sdk';
import { useLocalizeMessage } from 'libs.nucleus';
import { LibraryActivityInstrumentType } from 'libs.react';
import { BuildingIcon, UserIcon } from '../../components/svg';
import { StudyConfigurationContext } from '../../contexts';
export const useActivityUtils = () => {
    const { currentStudy } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const getActivityTypeName = (activity) => {
        switch (activity.data.activity_metadata?.instrument_type) {
            case LibraryActivityInstrumentType.SITE_WORKFLOW:
                return translate('Site Workflow');
            case LibraryActivityInstrumentType.CLINRO:
                return translate('Site');
            case LibraryActivityInstrumentType.OBSRO:
                return translate('ObsRo');
            case LibraryActivityInstrumentType.PRO:
            default:
                return translate('Participant');
        }
    };
    const getActivityType = (activity) => {
        switch (activity.data.activity_metadata?.instrument_type) {
            case LibraryActivityInstrumentType.SITE_WORKFLOW:
                return ActivityType.SITE_WORKFLOW;
            case LibraryActivityInstrumentType.CLINRO:
                return ActivityType.SITE;
            case LibraryActivityInstrumentType.OBSRO:
                return ActivityType.OBSERVER;
            case LibraryActivityInstrumentType.PRO:
            default:
                return ActivityType.PARTICIPANT;
        }
    };
    const getActivityIcon = (role) => {
        switch (role) {
            case ResponderRole.PARTICIPANT:
                return _jsx(UserIcon, { className: 'text-purple-500' });
            case ResponderRole.SITE:
            default:
                return _jsx(BuildingIcon, { className: 'text-green-500' });
        }
    };
    const findActivityByLibraryId = (libraryId) => {
        return currentStudy.getAllActivities().find((activity) => activity.libraryId === libraryId);
    };
    const getActivityFromResource = (resource, order) => {
        const existingActivity = findActivityByLibraryId(resource.id);
        if (existingActivity) {
            return existingActivity;
        }
        const type = getActivityType(resource);
        const details = SDKActivity.createDetails({
            buildVersion: String(resource.version),
            code: resource.data.identifier,
            libraryId: resource.id,
            mappingKey: resource.data.key,
            order,
            type,
        });
        return new SDKActivity({
            details,
            name: resource.data.full_name,
            responderRole: type === ActivityType.PARTICIPANT ? ResponderRole.PARTICIPANT : ResponderRole.SITE,
        });
    };
    return { getActivityFromResource, getActivityIcon, getActivityTypeName, getActivityType };
};
