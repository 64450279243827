import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalizeMessage, Spinner, Text } from 'libs.nucleus';
import { DevicePreview } from 'libs.preview';
import { sanitizeStringName } from 'libs.utils.sanitize';
import { useDeviceScale } from '../../hooks/device_scale.hook';
export const TranslationScreen = ({ screen, basePreview, localePreview, isBasePreviewLoading, isLocalePreviewLoading, isBaseBundleError, isLocaleBundleError, }) => {
    const baseLocale = 'en-US';
    const { locale = baseLocale } = useParams();
    const [basePreviewURL, setBasePreviewURL] = useState();
    const [localePreviewURL, setLocalePreviewURL] = useState();
    const wrapperRef = useRef(null);
    const isComparisonMode = baseLocale !== locale;
    useDeviceScale(wrapperRef, isComparisonMode);
    useEffect(() => {
        if (basePreview) {
            const url = basePreview.getScreenURL(sanitizeStringName(screen.identifier));
            setBasePreviewURL(url);
        }
    }, [basePreview, screen.identifier]);
    useEffect(() => {
        if (localePreview) {
            const url = localePreview.getScreenURL(sanitizeStringName(screen.identifier));
            setLocalePreviewURL(url);
        }
    }, [localePreview, screen.identifier]);
    return (_jsx("div", { ref: wrapperRef, children: _jsxs("div", { className: 'mb-8 flex gap-4 justify-center w-fit', children: [_jsx(Preview, { previewURL: basePreviewURL, isPreviewLoading: isBasePreviewLoading, isError: isBaseBundleError }, `base_${screen.identifier}`), isComparisonMode && (_jsx(Preview, { previewURL: localePreviewURL, isPreviewLoading: isLocalePreviewLoading, isError: isLocaleBundleError }, `locale_${screen.identifier}`))] }) }));
};
const Preview = ({ previewURL, isPreviewLoading = false, isError = false, }) => {
    const translate = useLocalizeMessage();
    if (isPreviewLoading || isError) {
        return (_jsx(DevicePreview, { children: _jsxs("div", { className: 'grid place-items-center h-full', children: [isPreviewLoading && _jsx(Spinner, {}), isError && _jsx(Text, { children: translate('Something went wrong while loading bundle') })] }) }));
    }
    return previewURL ? _jsx(DevicePreview, { src: previewURL, disableInteractions: true }) : _jsx(DevicePreview, {});
};
