import { useMutation } from '@tanstack/react-query';
import { ApiClientService, useApiClient } from 'libs.react';
export const usePdfUpload = () => {
    const { post } = useApiClient(ApiClientService.LIBRARY);
    return useMutation({
        mutationKey: ['uploadPdf'],
        mutationFn: async ({ payload, resourceId }) => {
            return post(`/v1/asset/${resourceId}/translations`, payload, {
                headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
            });
        },
        retry: 1,
    });
};
