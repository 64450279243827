import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocalizeMessage, Link } from 'libs.nucleus';
export const useWorkspaceSiteColumns = () => {
    const translate = useLocalizeMessage();
    const workspaceSiteColumns = useMemo(() => {
        return [
            {
                header: translate('Name'),
                accessorKey: 'name',
                cell: (context) => {
                    const { id, name } = context.row.original;
                    return (_jsx(Link, { LinkComponent: RouterLink, to: `/site-management/workspace-site/${id}`, children: name }));
                },
            },
            {
                header: translate('Site library ID'),
                accessorKey: 'number',
                cell: (context) => {
                    return _jsx("p", { className: 'nucleus-text-neutral-600', children: context.getValue() });
                },
            },
            {
                header: translate('Location'),
                accessorKey: 'address',
                cell: (context) => {
                    const { address } = context.row.original;
                    // todo: we need to display country converted from country code to country name
                    let fullAddressLine = `${address.city}, ${address.region}, ${address.countryCode}`;
                    if (address.postalCode) {
                        fullAddressLine += `, ${address.postalCode}`;
                    }
                    return fullAddressLine;
                },
            },
            {
                header: translate('Users'),
                accessorKey: 'userCount',
            },
            {
                header: translate('Studies'),
                accessorKey: 'studyCount',
            },
        ];
    }, []);
    return {
        workspaceSiteColumns,
    };
};
