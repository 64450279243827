import { ActivityType } from '@axon/rosetta-sdk';
export var StudyTestPlanStatus;
(function (StudyTestPlanStatus) {
    StudyTestPlanStatus["DRAFT"] = "DRAFT";
    StudyTestPlanStatus["LOCKED"] = "LOCKED";
    StudyTestPlanStatus["REQUIRED"] = "REQUIRED";
})(StudyTestPlanStatus || (StudyTestPlanStatus = {}));
export var StudyTestPlanStepType;
(function (StudyTestPlanStepType) {
    StudyTestPlanStepType["CreateUser"] = "CreateUser";
    StudyTestPlanStepType["CreateParticipant"] = "CreateParticipant";
    StudyTestPlanStepType["CompleteSiteTask"] = "CompleteSiteTask";
    StudyTestPlanStepType["CompleteParticipantTask"] = "CompleteParticipantTask";
    StudyTestPlanStepType["TimeTravel"] = "TimeTravel";
    StudyTestPlanStepType["UpdateParticipantTimezone"] = "UpdateParticipantTimezone";
    StudyTestPlanStepType["AssertSiteTaskAvailability"] = "AssertSiteTaskAvailability";
    StudyTestPlanStepType["AssertSiteTaskNonAvailability"] = "AssertSiteTaskNonAvailability";
    StudyTestPlanStepType["AssertParticipantTaskAvailability"] = "AssertParticipantTaskAvailability";
    StudyTestPlanStepType["AssertParticipantTaskNonAvailability"] = "AssertParticipantTaskNonAvailability";
    StudyTestPlanStepType["AssertParticipantStatus"] = "AssertParticipantStatus";
    StudyTestPlanStepType["AssertNotification"] = "AssertNotification";
})(StudyTestPlanStepType || (StudyTestPlanStepType = {}));
export const StudyTestPlan_StepOptions = [
    {
        label: 'Create User',
        value: StudyTestPlanStepType.CreateUser,
    },
    {
        label: 'Create Participant',
        value: StudyTestPlanStepType.CreateParticipant,
    },
    {
        label: 'Complete Site Task',
        value: StudyTestPlanStepType.CompleteSiteTask,
    },
    {
        label: 'Complete Participant Task',
        value: StudyTestPlanStepType.CompleteParticipantTask,
    },
    {
        label: 'Time Travel',
        value: StudyTestPlanStepType.TimeTravel,
    },
    {
        label: 'Update Participant Timezone',
        value: StudyTestPlanStepType.UpdateParticipantTimezone,
    },
    {
        label: 'Assert Site Task Availability',
        value: StudyTestPlanStepType.AssertSiteTaskAvailability,
    },
    {
        label: 'Assert Site Task Non Availability',
        value: StudyTestPlanStepType.AssertSiteTaskNonAvailability,
    },
    {
        label: 'Assert Participant Task Availability',
        value: StudyTestPlanStepType.AssertParticipantTaskAvailability,
    },
    {
        label: 'Assert Participant Task Non Availability',
        value: StudyTestPlanStepType.AssertParticipantTaskNonAvailability,
    },
    {
        label: 'Assert Participant Status',
        value: StudyTestPlanStepType.AssertParticipantStatus,
    },
    {
        label: 'Assert Notification',
        value: StudyTestPlanStepType.AssertNotification,
    },
];
export var StudyTestPlanExecutionModes;
(function (StudyTestPlanExecutionModes) {
    StudyTestPlanExecutionModes["BACKEND"] = "backend";
    StudyTestPlanExecutionModes["SITE_WEB"] = "site-web";
    StudyTestPlanExecutionModes["PATIENT_WEB"] = "patient-web";
})(StudyTestPlanExecutionModes || (StudyTestPlanExecutionModes = {}));
export const StudyTestPlan_ExecutionModeOptions = [
    {
        label: 'Backend',
        value: StudyTestPlanExecutionModes.BACKEND,
    },
    {
        label: 'Site Web',
        value: StudyTestPlanExecutionModes.SITE_WEB,
    },
    {
        label: 'Patient Web',
        value: StudyTestPlanExecutionModes.PATIENT_WEB,
    },
];
export const StudyTestPlan_StepValidExecutionModes = {
    [StudyTestPlanStepType.CreateUser]: [StudyTestPlanExecutionModes.BACKEND],
    [StudyTestPlanStepType.CreateParticipant]: [StudyTestPlanExecutionModes.BACKEND],
    [StudyTestPlanStepType.CompleteSiteTask]: [StudyTestPlanExecutionModes.SITE_WEB, StudyTestPlanExecutionModes.BACKEND],
    [StudyTestPlanStepType.CompleteParticipantTask]: [
        StudyTestPlanExecutionModes.PATIENT_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.TimeTravel]: [StudyTestPlanExecutionModes.BACKEND],
    [StudyTestPlanStepType.UpdateParticipantTimezone]: [StudyTestPlanExecutionModes.BACKEND],
    [StudyTestPlanStepType.AssertSiteTaskAvailability]: [
        StudyTestPlanExecutionModes.SITE_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.AssertSiteTaskNonAvailability]: [
        StudyTestPlanExecutionModes.SITE_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.AssertParticipantTaskAvailability]: [
        StudyTestPlanExecutionModes.PATIENT_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.AssertParticipantTaskNonAvailability]: [
        StudyTestPlanExecutionModes.PATIENT_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.AssertParticipantStatus]: [
        StudyTestPlanExecutionModes.SITE_WEB,
        StudyTestPlanExecutionModes.BACKEND,
    ],
    [StudyTestPlanStepType.AssertNotification]: [StudyTestPlanExecutionModes.BACKEND],
};
export var StudyTestPlanStepUserTypes;
(function (StudyTestPlanStepUserTypes) {
    StudyTestPlanStepUserTypes["ADMIN"] = "Admin";
    StudyTestPlanStepUserTypes["SITE_USER"] = "SiteUser";
    StudyTestPlanStepUserTypes["PARTICIPANT"] = "Participant";
})(StudyTestPlanStepUserTypes || (StudyTestPlanStepUserTypes = {}));
export const StudyTestPlanStepValidUser = {
    [StudyTestPlanStepType.CreateUser]: [StudyTestPlanStepUserTypes.ADMIN],
    [StudyTestPlanStepType.CreateParticipant]: [StudyTestPlanStepUserTypes.SITE_USER],
    [StudyTestPlanStepType.CompleteSiteTask]: [StudyTestPlanStepUserTypes.SITE_USER],
    [StudyTestPlanStepType.CompleteParticipantTask]: [StudyTestPlanStepUserTypes.PARTICIPANT],
    [StudyTestPlanStepType.TimeTravel]: [StudyTestPlanStepUserTypes.ADMIN],
    [StudyTestPlanStepType.UpdateParticipantTimezone]: [StudyTestPlanStepUserTypes.PARTICIPANT],
    [StudyTestPlanStepType.AssertSiteTaskAvailability]: [StudyTestPlanStepUserTypes.SITE_USER],
    [StudyTestPlanStepType.AssertSiteTaskNonAvailability]: [StudyTestPlanStepUserTypes.SITE_USER],
    [StudyTestPlanStepType.AssertParticipantTaskAvailability]: [StudyTestPlanStepUserTypes.PARTICIPANT],
    [StudyTestPlanStepType.AssertParticipantTaskNonAvailability]: [StudyTestPlanStepUserTypes.PARTICIPANT],
    [StudyTestPlanStepType.AssertParticipantStatus]: [StudyTestPlanStepUserTypes.SITE_USER],
    [StudyTestPlanStepType.AssertNotification]: [
        StudyTestPlanStepUserTypes.PARTICIPANT,
        StudyTestPlanStepUserTypes.SITE_USER,
    ],
};
export const StudyTestPlan_StepActivityType = {
    [StudyTestPlanStepType.CompleteParticipantTask]: ActivityType.PARTICIPANT,
    [StudyTestPlanStepType.AssertParticipantTaskAvailability]: ActivityType.PARTICIPANT,
    [StudyTestPlanStepType.AssertParticipantTaskNonAvailability]: ActivityType.PARTICIPANT,
    [StudyTestPlanStepType.CompleteSiteTask]: ActivityType.SITE,
    [StudyTestPlanStepType.AssertSiteTaskAvailability]: ActivityType.SITE,
    [StudyTestPlanStepType.AssertSiteTaskNonAvailability]: ActivityType.SITE,
};
export var StudyTestPlanStepContextValues;
(function (StudyTestPlanStepContextValues) {
    StudyTestPlanStepContextValues["USER_ROLE"] = "role";
    StudyTestPlanStepContextValues["PARTICIPANT_TASKS"] = "participantTasks";
    StudyTestPlanStepContextValues["GROUPS"] = "siteGroups";
    StudyTestPlanStepContextValues["SITE_TASKS"] = "siteTasks";
    StudyTestPlanStepContextValues["USER"] = "user";
    StudyTestPlanStepContextValues["SITE_USER"] = "siteUser";
    StudyTestPlanStepContextValues["PARTICIPANT"] = "participant";
    StudyTestPlanStepContextValues["DURATION"] = "duration";
    StudyTestPlanStepContextValues["TIME_OF_DAY"] = "timeOfDay";
    StudyTestPlanStepContextValues["END_OF_DAY"] = "endOfDay";
    StudyTestPlanStepContextValues["DATE"] = "date";
    StudyTestPlanStepContextValues["TIMEZONE"] = "timezone";
    StudyTestPlanStepContextValues["NOTIFICATION"] = "notification";
    StudyTestPlanStepContextValues["STATUS"] = "status";
    StudyTestPlanStepContextValues["PLAIN_TEXT"] = "plainText";
})(StudyTestPlanStepContextValues || (StudyTestPlanStepContextValues = {}));
export const StudyTestPlan_StepValidContexts = {
    [StudyTestPlanStepType.CreateUser]: {
        type: 'form',
        fields: [
            { key: 'Role', label: 'User role', required: true, value: StudyTestPlanStepContextValues.USER_ROLE },
            { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.SITE_USER },
            // { key: 'Email', autofill: 'User' },
            { key: 'FirstName', label: 'FirstName', value: StudyTestPlanStepContextValues.PLAIN_TEXT },
            { key: 'LastName', label: 'LastName', value: StudyTestPlanStepContextValues.PLAIN_TEXT },
        ],
    },
    [StudyTestPlanStepType.CreateParticipant]: {
        type: 'form',
        fields: [
            { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
            // { key: 'Email', autofill: 'User' },
            {
                key: 'TimeZone',
                label: 'Timezone',
                value: StudyTestPlanStepContextValues.TIMEZONE,
            },
        ],
    },
    [StudyTestPlanStepType.CompleteSiteTask]: {
        type: 'form',
        fields: [
            { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
            { key: 'GroupName', label: 'Group Name', required: true, value: StudyTestPlanStepContextValues.GROUPS },
            { key: 'TaskName', label: 'Task', required: true, value: StudyTestPlanStepContextValues.SITE_TASKS },
        ],
    },
    [StudyTestPlanStepType.CompleteParticipantTask]: {
        type: 'form',
        fields: [
            { key: 'GroupName', label: 'Group Name', required: true, value: StudyTestPlanStepContextValues.GROUPS },
            {
                key: 'TaskName',
                label: 'Task',
                required: true,
                value: StudyTestPlanStepContextValues.PARTICIPANT_TASKS,
            },
        ],
    },
    [StudyTestPlanStepType.TimeTravel]: {
        type: 'selector',
        options: [
            {
                key: 'Duration',
                label: 'Duration',
                fields: [
                    { key: 'Duration', label: 'Duration', required: true, value: StudyTestPlanStepContextValues.DURATION },
                    { key: 'TimeOfDay', label: 'Time of day', value: StudyTestPlanStepContextValues.TIME_OF_DAY },
                ],
            },
            {
                key: 'Absolute',
                label: 'Absolute',
                fields: [{ key: 'Date', label: 'Date', required: true, value: StudyTestPlanStepContextValues.DATE }],
            },
            {
                key: 'EndofDay',
                label: 'End of Day',
                fields: [
                    {
                        key: 'User',
                        label: 'User',
                        required: true,
                        value: StudyTestPlanStepContextValues.PARTICIPANT,
                    },
                    {
                        key: 'EndOfDay',
                        label: 'End of Day',
                        required: true,
                        value: StudyTestPlanStepContextValues.END_OF_DAY,
                    },
                ],
            },
        ],
    },
    [StudyTestPlanStepType.UpdateParticipantTimezone]: {
        type: 'form',
        fields: [{ key: 'TimeZone', label: 'Timezone', required: true, value: StudyTestPlanStepContextValues.TIMEZONE }],
    },
    [StudyTestPlanStepType.AssertSiteTaskAvailability]: {
        type: 'selector',
        options: [
            {
                key: 'Group',
                label: 'Group',
                fields: [
                    { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
                    { key: 'GroupName', label: 'Group Name', required: true, value: StudyTestPlanStepContextValues.GROUPS },
                    {
                        key: 'TaskName',
                        label: 'Task',
                        required: true,
                        value: StudyTestPlanStepContextValues.SITE_TASKS,
                    },
                ],
            },
            {
                key: 'Tasks',
                label: 'Tasks',
                fields: [
                    { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
                    {
                        key: 'TaskNames',
                        label: 'Tasks',
                        required: true,
                        multiple: true,
                        value: StudyTestPlanStepContextValues.GROUPS,
                    },
                ],
            },
        ],
    },
    [StudyTestPlanStepType.AssertSiteTaskNonAvailability]: {
        type: 'selector',
        options: [
            {
                key: 'Group',
                label: 'Group',
                fields: [
                    { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
                    { key: 'GroupName', label: 'Group Name', required: true, value: StudyTestPlanStepContextValues.GROUPS },
                    {
                        key: 'TaskName',
                        label: 'Task',
                        required: true,
                        value: StudyTestPlanStepContextValues.SITE_TASKS,
                    },
                ],
            },
            {
                key: 'Tasks',
                label: 'Tasks',
                fields: [
                    { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
                    {
                        key: 'TaskNames',
                        label: 'Tasks',
                        required: true,
                        multiple: true,
                        value: StudyTestPlanStepContextValues.GROUPS,
                    },
                ],
            },
        ],
    },
    [StudyTestPlanStepType.AssertParticipantTaskAvailability]: {
        type: 'form',
        fields: [
            {
                key: 'TaskNames',
                label: 'Tasks',
                required: true,
                multiple: true,
                value: StudyTestPlanStepContextValues.PARTICIPANT_TASKS,
            },
            { key: 'StartDate', label: 'Start Date', value: StudyTestPlanStepContextValues.DATE },
            { key: 'EndDate', label: 'End Date', value: StudyTestPlanStepContextValues.DATE },
        ],
    },
    [StudyTestPlanStepType.AssertParticipantTaskNonAvailability]: {
        type: 'form',
        fields: [
            {
                key: 'TaskNames',
                label: 'Tasks',
                required: true,
                multiple: true,
                value: StudyTestPlanStepContextValues.PARTICIPANT_TASKS,
            },
            { key: 'StartDate', label: 'Start Date', value: StudyTestPlanStepContextValues.DATE },
            { key: 'EndDate', label: 'End Date', value: StudyTestPlanStepContextValues.DATE },
        ],
    },
    [StudyTestPlanStepType.AssertParticipantStatus]: {
        type: 'form',
        fields: [
            { key: 'User', label: 'User', required: true, value: StudyTestPlanStepContextValues.PARTICIPANT },
            { key: 'Status', label: 'Status', required: true, value: StudyTestPlanStepContextValues.STATUS },
        ],
    },
    [StudyTestPlanStepType.AssertNotification]: {
        type: 'form',
        fields: [
            { key: 'Recipient', label: 'Recipient', required: true, value: StudyTestPlanStepContextValues.USER },
            {
                key: 'NotificationName',
                label: 'Notification',
                required: true,
                value: StudyTestPlanStepContextValues.NOTIFICATION,
            },
        ],
    },
};
export const StudyTestPlan_Timezones = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis',
    'UTC',
];
