"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Now = void 0;
const templates_temporal_plain_date_time_1_0_1 = require("templates.temporal.plain_date_time~1_0");
const templates_temporal_plain_date_1_0_1 = require("templates.temporal.plain_date~1_0");
class Now {
    static plainDateISO() {
        const now = new Date();
        // Subtract the timezone offset so that the ISO string (which uses UTC) has the same date+time as the current
        // date+time in the user's timezone.
        // Use UTC functions to avoid daylight savings time changes.
        now.setUTCMinutes(now.getUTCMinutes() - now.getTimezoneOffset());
        return templates_temporal_plain_date_1_0_1.PlainDate.from(now.toISOString().split('T')[0]);
    }
    static plainDateTimeISO() {
        const now = new Date();
        // Subtract the timezone offset so that the ISO string (which uses UTC) has the same date+time as the current
        // date+time in the user's timezone.
        // Use UTC functions to avoid daylight savings time changes.
        now.setUTCMinutes(now.getUTCMinutes() - now.getTimezoneOffset());
        // Remove seconds and timezone.
        return templates_temporal_plain_date_time_1_0_1.PlainDateTime.from(now.toISOString().replace(/(T\d\d:\d\d).*Z/, '$1'));
    }
}
exports.Now = Now;
