import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { AxonAccountContext } from '../axon_account';
export const SiteContext = createContext({
    isLoading: true,
    site: null,
});
export const SiteProvider = ({ children }) => {
    const match = useMatch('/site/study/:studyId/*');
    const { account } = useContext(AxonAccountContext);
    const { environment } = useCurrentEnvironment(match?.params?.studyId);
    const getSite = async () => {
        if (!environment || !account) {
            return;
        }
        const url = `/v2/account/${account._id}/c_sites`;
        const { data: response } = await getApiInstance(environment).get(url);
        return response?.data?.[0] || null;
    };
    const { data: site, isLoading } = useQuery({
        queryKey: ['getSite', environment?.id?.toString()],
        queryFn: getSite,
        enabled: !!environment && !!account,
    });
    const value = useMemo(() => ({
        isLoading,
        site,
    }), [site]);
    return _jsx(SiteContext.Provider, { value: value, children: children });
};
