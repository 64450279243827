import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { H5, ModalWindow, Pill, RadioGroup, RadioGroupOption, useLocalizeMessage } from 'libs.nucleus';
import { CloneTestPlanModal } from './clone_testplan_modal.component';
import { ImportTestPlanModal } from './import_testplan_modal.component';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
import { EditTestPlanDetailsModal } from '../study_test_plan_details';
var CreateTestPlanType;
(function (CreateTestPlanType) {
    CreateTestPlanType["CREATE_USING_PLAYGROUND"] = "create-using-playground";
    CreateTestPlanType["DUPLICATE_PLAN"] = "duplicate-plan";
    CreateTestPlanType["IMPORT_PLAN"] = "import-plan";
    CreateTestPlanType["CREATE_NEW_PLAN"] = "create-new-plan";
})(CreateTestPlanType || (CreateTestPlanType = {}));
var CreateModals;
(function (CreateModals) {
    CreateModals["IMPORT"] = "import";
    CreateModals["DUPLICATE"] = "duplicate";
    CreateModals["CREATE"] = "create";
})(CreateModals || (CreateModals = {}));
var StepPosition;
(function (StepPosition) {
    StepPosition[StepPosition["start"] = 0] = "start";
    StepPosition[StepPosition["end"] = 1] = "end";
})(StepPosition || (StepPosition = {}));
export const CreateTestPlanModal = ({ onClose, versions }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { studyId } = useContext(StudyConfigurationContext);
    const { testPlans } = useContext(StudyTestPlanContext);
    const [selectedCreateTestPlanType, setSelectedCreateTestPlanType] = useState(CreateTestPlanType.CREATE_USING_PLAYGROUND);
    const [initialTestPlanId, setInitialTestPlanId] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [stepIndex, setStepIndex] = useState(0);
    const options = useMemo(() => [
        {
            value: CreateTestPlanType.CREATE_USING_PLAYGROUND,
            title: translate('Create using playground'),
            description: translate('Create a new test plan using the playground against a version.'),
            pill: {
                label: translate('New'),
                backgroundColor: 'forestGreen',
            },
            onSelect: () => {
                setModalType(CreateModals.CREATE);
            },
        },
        {
            value: CreateTestPlanType.IMPORT_PLAN,
            title: translate('Import a CSV'),
            description: translate('Import a test plan from a CSV and validate it against a version.'),
            onSelect: () => {
                setModalType(CreateModals.IMPORT);
            },
        },
        {
            value: CreateTestPlanType.DUPLICATE_PLAN,
            title: translate('Clone a plan'),
            description: translate('Duplicate and customize an existing test plan.'),
            onSelect: () => {
                setModalType(CreateModals.DUPLICATE);
            },
        },
        {
            value: CreateTestPlanType.CREATE_NEW_PLAN,
            title: translate('Create an empty plan'),
            description: translate('Create a new test plan from scratch against a version.'),
            onSelect: () => {
                setModalType(CreateModals.CREATE);
            },
        },
    ], []);
    const onCreateTestPlanSelection = (value) => {
        setSelectedCreateTestPlanType(value);
    };
    const cancelButton = { label: translate('Cancel'), onClick: onClose };
    const createButton = {
        label: translate('Continue'),
        onClick: () => {
            const selectedOption = options.find((option) => option.value === selectedCreateTestPlanType);
            selectedOption?.onSelect?.();
        },
    };
    const backButton = {
        label: translate('Back'),
        onClick: () => setStepIndex((p) => p - 1),
    };
    const onCloseModal = () => {
        setModalType(null);
        onClose();
    };
    const onSave = (testPlan) => {
        if (selectedCreateTestPlanType === CreateTestPlanType.CREATE_USING_PLAYGROUND) {
            navigate(`/studies/${studyId}/test-plans/${testPlan.id}/playground`);
        }
        onCloseModal();
    };
    const onDuplicateContinue = (testPlanId) => {
        setInitialTestPlanId(testPlanId);
        setStepIndex(StepPosition.end);
        setModalType(CreateModals.CREATE);
    };
    const initialTestPlan = useMemo(() => testPlans.find((testPlan) => testPlan.id === initialTestPlanId), [initialTestPlanId, testPlans]);
    return (_jsxs(ModalWindow, { title: translate('Create test plan'), isOpen: true, hideCloseIcon: false, closeWindow: onClose, width: 'full', footerSecondaryActionButtons: stepIndex !== StepPosition.start ? [cancelButton, backButton] : [cancelButton], footerPrimaryActionButton: stepIndex !== StepPosition.end ? createButton : undefined, children: [stepIndex === StepPosition.start && (_jsx("div", { className: 'flex justify-center w-full h-full', children: _jsxs("div", { className: 'flex flex-col w-full max-w-2xl h-full justify-center gap-y-3', children: [_jsx(H5, { children: translate('Select how you would like to get started') }), _jsx(RadioGroup, { onChange: onCreateTestPlanSelection, value: selectedCreateTestPlanType, children: options.map(({ value, title, description, pill }) => (_jsxs("div", { className: `relative w-full p-6 cursor-pointer border border-solid rounded-lg w-1/2 ${selectedCreateTestPlanType === value ? 'bg-purple-50 border-purple-600 border-[1.5px]' : 'hover:bg-neutral-50'}`, children: [pill && (_jsx("div", { className: 'absolute -top-2 -right-2', children: _jsx(Pill, { label: pill.label, backgroundColor: pill?.backgroundColor }) })), _jsx(RadioGroupOption, { option: {
                                            label: title,
                                            description,
                                            value,
                                        } })] }, value))) })] }) })), modalType === CreateModals.IMPORT && _jsx(ImportTestPlanModal, { onClose: onCloseModal, versions: versions }), modalType === CreateModals.DUPLICATE && (_jsx(CloneTestPlanModal, { onClose: onCloseModal, onContinue: onDuplicateContinue, versions: versions })), modalType === CreateModals.CREATE && (_jsx(EditTestPlanDetailsModal, { type: 'create', initialTestPlan: initialTestPlan, onClose: onCloseModal, onSave: onSave, versions: versions }))] }));
};
