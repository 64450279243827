import { useMemo } from 'react';
import { AssetRole, AssetTypes, LocalizationStatus, } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { Locale } from 'libs.nucleus';
import { useNotificationsLoader } from './use_notifications_loader.hook';
import { StudyAssetType } from '../types';
import { getVersionStatus } from '../utils/data_utils';
import { toSnakeCase } from '../utils/string_utils';
function getType(type) {
    switch (type) {
        case 'push':
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
        case 'sms':
            return AssetTypes.NOTIFICATION_SMS;
        case 'email':
            return AssetTypes.NOTIFICATION_EMAIL;
        default:
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
    }
}
export const useNotificationsData = (studyId, participantLocales = [], siteLocales = []) => {
    const templates = useNotificationsLoader(studyId);
    const assets = templates?.map(({ id, data: { type } }) => ({
        type: getType(type),
        id,
        version: '1',
    }));
    const locales = Array.from(new Set([...participantLocales, ...siteLocales]));
    const { localizationsMap, isLoading } = useLocalizationCollection(assets);
    const _data = useMemo(() => {
        if (!templates?.length) {
            return {};
        }
        const data = {};
        templates.forEach((template) => {
            const localeData = {};
            for (const locale of locales) {
                localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
            }
            const _asset = assets.find((a) => a.id === template.id);
            const { localizationEntry, localization, } = localizationsMap?.get(_asset) || {};
            const translationWorkbenchId = localization?.getId();
            const role = AssetRole.PARTICIPANT;
            const versionStatus = getVersionStatus({
                localizationEntry,
                participantLocales,
                siteLocales,
                role,
                translationWorkbenchId,
                assetType: getType(template.data.type),
                isLoading,
            });
            data[template.name] = {
                translationWorkbenchId: localization.getId(),
                id: template.id,
                type: StudyAssetType.NOTIFICATIONS,
                path: template.id,
                statuses: localeData,
                role: AssetRole.PARTICIPANT,
                assetType: getType(template.data.type),
                ...versionStatus,
                asset: {
                    type: getType(template.data.type),
                    id: template.id,
                    version: '1',
                    human_id: toSnakeCase(template.name),
                    resource_id: template.id,
                    messages: [
                        {
                            namespace: template.id,
                            messages: ['plain', 'subject', 'html'].reduce((acc, key) => {
                                if (template.data[key]) {
                                    acc[key] = template.data[key];
                                }
                                return acc;
                            }, {}),
                        },
                    ],
                    preview: {
                        engine: 'react',
                        id: getType(template.data.type),
                        default_viewport: 'iosSmall',
                        info: {
                            display_name: template.name,
                            list: [],
                        },
                    },
                },
            };
        });
        return data;
    }, [templates, localizationsMap, isLoading]);
    return { data: _data, isLoading };
};
