import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { PlainDate } from 'temporal-luxon';
import { RosettaSDKUtils } from '@axon/rosetta-sdk';
import { DatePicker, FormSections, useLocalizeMessage, Input, ModalWindow, Spinner, TextArea, } from 'libs.nucleus';
import { StudiesContext, StudyConfigurationContext } from '../../../contexts';
import { GenericUtils } from '../../../utils';
export const StudyDetailsEdit = ({ initialStudy, isCloning, onCancel, onSave, }) => {
    const { isLoading } = useContext(StudyConfigurationContext);
    const { studies } = useContext(StudiesContext);
    const translate = useLocalizeMessage();
    const [isPristine, setIsPristine] = useState(true);
    const [studyDetails, setStudyDetails] = useState(() => ({
        description: initialStudy?.description || '',
        name: !isCloning && initialStudy ? initialStudy.name : '',
        displayName: initialStudy?.displayName || '',
        protocolNumber: initialStudy?.protocolNumber || '',
        protocolVersion: initialStudy?.protocolVersion || '',
        protocolVersionDate: initialStudy?.protocolVersionDate || '',
        sponsor: initialStudy?.sponsor || '',
        metadata: initialStudy?.studyMetadata || RosettaSDKUtils.getDefaultStudyMetadata(),
    }));
    const nameInput = useRef(null);
    const hasSameName = !initialStudy &&
        studies?.length > 0 &&
        studies.some((study) => GenericUtils.isSameString(study.name, studyDetails.name));
    const isEditEnabled = studyDetails.name &&
        !hasSameName &&
        studyDetails.name.length <= 100 &&
        studyDetails.metadata.sponsor &&
        studyDetails.metadata.isNoPIIEnabled !== undefined &&
        !isLoading &&
        !isPristine;
    useEffect(() => {
        if (nameInput.current) {
            nameInput.current.focus();
        }
    }, []);
    const handleGeneralInputChange = useCallback((event) => {
        const { id, value } = event.target;
        setStudyDetails((prevStudyDetails) => ({ ...prevStudyDetails, [id]: value }));
        setIsPristine(false);
    }, []);
    const handleDatePickerChange = useCallback((date, id) => {
        if (!date) {
            return null;
        }
        setStudyDetails((prevStudyDetails) => ({ ...prevStudyDetails, [id]: date?.toString() }));
        setIsPristine(false);
    }, []);
    const onSponsorChange = useCallback((event) => {
        setStudyDetails((prevStudyDetails) => ({
            ...prevStudyDetails,
            metadata: { ...prevStudyDetails.metadata, sponsor: event.target.value },
        }));
        setIsPristine(false);
    }, []);
    const savePrimaryButton = {
        label: isCloning ? translate('Clone') : initialStudy?.name ? translate('Save') : translate('Create'),
        onClick: () => onSave(studyDetails),
        disabled: !isEditEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onCancel,
        disabled: false,
    };
    const handleSave = (event) => {
        event.preventDefault();
        if (isEditEnabled) {
            onSave(studyDetails);
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isEditEnabled) {
            onSave(studyDetails);
        }
    };
    const formSections = useMemo(() => {
        const sections = [
            {
                title: translate('Details'),
                content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(Input, { ref: nameInput, id: 'name', label: translate('Name'), value: studyDetails.name, onChange: handleGeneralInputChange, required: true, hasError: hasSameName, errorMessage: translate('A study with this name already exists. Please choose a different name.'), width: 'full', containLabels: true, maxLength: 100 }), _jsx(Input, { id: 'sponsor', label: translate('Sponsor name'), value: studyDetails.metadata.sponsor, onChange: onSponsorChange, required: true, width: 'full', containLabels: true }), _jsx(Input, { id: 'displayName', label: translate('Display name'), description: translate('This is an optional consumer friendly version of the study name that participants see.'), value: studyDetails.displayName, onChange: handleGeneralInputChange, width: 'full', containLabels: true }), _jsx(Input, { id: 'protocolNumber', label: translate('Protocol number'), value: studyDetails.protocolNumber, onChange: handleGeneralInputChange, width: 'full', containLabels: true }), _jsx(Input, { id: 'protocolVersion', label: translate('Protocol version'), value: studyDetails.protocolVersion, onChange: handleGeneralInputChange, width: 'full', containLabels: true }), _jsx(DatePicker, { label: translate('Protocol date'), id: 'protocolVersionDate', onChange: (date) => {
                                handleDatePickerChange(date, 'protocolVersionDate');
                            }, value: studyDetails.protocolVersionDate ? PlainDate.from(studyDetails.protocolVersionDate) : undefined, width: 'full', endDate: PlainDate.from(DateTime.now().toISODate()), position: 'top' }), _jsx(TextArea, { id: 'description', label: translate('Description'), value: studyDetails.description, onChange: handleGeneralInputChange, width: 'full' })] })),
            },
        ];
        return sections;
    }, [studyDetails]);
    return (_jsxs(ModalWindow, { title: isCloning
            ? translate('Clone study')
            : initialStudy?.name
                ? translate('Edit study details')
                : translate('Create study'), subtitle: initialStudy?.name, isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { onSubmit: handleSave, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
