import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Dropdown, useLocalizeMessage, Link, Table } from 'libs.nucleus';
import { getResourceAvailabilityStringFromKey, getResourceContentTypeString } from '../../utils/resources';
export const ResourcesTable = ({ resources = [], onEditResource, onDeleteResource, disableEdit = false, }) => {
    const translate = useLocalizeMessage();
    const resourceColumns = useMemo(() => {
        return [
            {
                header: translate('Name'),
                accessorKey: 'title',
                cell: (context) => (_jsx(Link, { LinkComponent: RouterLink, to: context.row.original.id, children: context.getValue() })),
            },
            {
                header: translate('Format'),
                accessorKey: 'format',
                cell: (context) => translate(getResourceContentTypeString(context.row.original)),
            },
            {
                header: translate('Location'),
                accessorKey: 'availableTo',
                cell: (context) => translate(getResourceAvailabilityStringFromKey[context.getValue()]),
            },
            {
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    const dropdownItems = [
                        {
                            id: 'edit',
                            content: translate('Edit'),
                            onClick: () => onEditResource(row.original),
                            disabled: disableEdit,
                        },
                        {
                            id: 'delete',
                            content: translate('Delete'),
                            onClick: () => onDeleteResource(row.original),
                            destructive: true,
                            disabled: disableEdit,
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            },
        ];
    }, []);
    return _jsx(Table, { data: resources, columns: resourceColumns, labelNoResults: translate('No resources') });
};
