import { I18nUtils } from '@medable/i18n-core';
export const formatLanguageOptions = (locales, currentLocale) => {
    const showOwnLocale = true;
    return locales.length
        ? locales.map((locale) => ({
            value: locale,
            label: I18nUtils.getLanguageLabel(locale, currentLocale, showOwnLocale),
        }))
        : [];
};
