import { AssetTypes } from 'libs.localization.common';
export const NO_RESULTS = 'No Results';
export const AUTOMATED_CANDIDATE_TYPES = [
    //  Participant content
    AssetTypes.SIGN_IN_STUDY_NAME,
    AssetTypes.SIGN_IN_STUDY_NAME_PII,
    AssetTypes.RESOURCE_TITLES,
    AssetTypes.RESOURCES,
    //  Site content
    AssetTypes.PARTICIPANT_STATUSES,
    AssetTypes.PROTOCOL_SCHEDULE,
    AssetTypes.SITE_VISIT_SCHEDULE,
];
