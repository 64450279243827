"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlainDateTime = void 0;
const templates_temporal_plain_time_1_0_1 = require("templates.temporal.plain_time~1_0");
/**
 * This implementation is based on the Temporal PlainDateTime proposal, but strips the seconds part of the time value,
 * as they are not used in eCOAs and it simplifies the implementation.
 */
class PlainDateTime {
    constructor(dateTimeIsoString) {
        // In order to properly compare values that have 00 seconds with values that don't include seconds, normalize the
        // value by removing seconds.
        this.dateTimeIsoString = dateTimeIsoString.replace(/(T\d\d:\d\d):\d\d/, '$1');
        // If we ever decide to include seconds, the correct code to normalize the value is:
        // this.dateTimeIsoString = dateTimeIsoString.replace(/(T\d\d:\d\d(?!:))/, '$1:00');
    }
    static from(dateTimeIsoString) {
        return new PlainDateTime(dateTimeIsoString);
    }
    toString() {
        return this.dateTimeIsoString;
    }
    add({ days, hours }) {
        // Add 'Z' to pretend the time is in UTC so Date() doesn't adjust it to the local timezone. We'll remove it later.
        const date = new Date(`${this.dateTimeIsoString}Z`);
        // Use UTC functions to avoid daylight savings time changes.
        date.setUTCDate(date.getUTCDate() + (days || 0));
        date.setUTCHours(date.getUTCHours() + (hours || 0));
        // Because we're creating the date from an ISO string and converting it back after adding, timezone doesn't matter.
        return new PlainDateTime(date.toISOString().replace(/(T\d\d:\d\d).*Z/, '$1'));
    }
    withPlainTime(plainTime) {
        if (typeof plainTime === 'object') {
            throw new Error('withPlainTime() with object argument is not supported');
        }
        return new PlainDateTime(`${this.dateTimeIsoString.replace(/T\d\d:\d\d/, `T${plainTime !== null && plainTime !== void 0 ? plainTime : '00:00'}`)}`);
    }
    toPlainTime() {
        return templates_temporal_plain_time_1_0_1.PlainTime.from(this.dateTimeIsoString.replace(/.*T(\d\d:\d\d).*/, '$1'));
    }
    static compare(one, two) {
        return one.dateTimeIsoString < two.dateTimeIsoString ? -1 : one.dateTimeIsoString > two.dateTimeIsoString ? 1 : 0;
    }
}
exports.PlainDateTime = PlainDateTime;
