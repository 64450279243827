import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { Button, useLocalizeMessage, LocaleContext, Link, Icon } from 'libs.nucleus';
import { getRouteLink } from '../../helpers/links.helpers';
import { formatVisitWindow, isParticipantDeactivated } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const VisitSchedule = ({ participant, participantVisits, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const onVisitScheduleClick = (visit) => {
        setCurrentVisit(visit);
        setIsActivityListModalOpen();
    };
    return (_jsx(_Fragment, { children: participantVisits.map((visit) => {
            return (_jsxs("div", { className: 'grid grid-cols-3 grid-rows-1 gap-4 w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm', children: [_jsxs("div", { className: 'col-span-2', children: [_jsx("div", { children: _jsx(Link, { LinkComponent: RouterLink, to: getRouteLink(`participants/${participantId}/visit/${visit.c_visit}`, environment), "data-testid": 'visit-name', children: visit.c_name }) }), _jsxs("div", { children: [_jsx("span", { className: 'text-gray-500', "data-testid": `visit-period-${visit.c_visit}`, children: formatVisitWindow({
                                            windowStartDate: visit.c_window_start_date,
                                            windowEndDate: visit.c_window_end_date,
                                            currentLocale,
                                        }) }), _jsx("span", { className: 'px-1.5', children: "\u2022" }), _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${visit.c_visit}`, children: translate('{assignments} site activities', { assignments: visit.assignments }) })] })] }), _jsx("div", { className: 'ml-auto col-span-1', style: { marginTop: '3px' }, children: !isParticipantDeactivated(participant) && (_jsx(Button, { label: translate('View visit'), "aria-label": 'view-visit-schedule', variant: 'transparent', content: _jsx(Icon, { name: 'chevronRight', size: 24, color: 'primary' }), onClick: () => onVisitScheduleClick(visit) })) })] }, visit.c_visit));
        }) }));
};
