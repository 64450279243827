import { jsx as _jsx } from "react/jsx-runtime";
import { FormSections, useLocalizeMessage, RadioGroup, RadioGroupOption } from 'libs.nucleus';
const options = [
    { value: 'participant', label: 'Participant' },
    { value: 'both', label: 'Site and Participant' },
];
export const SelectLocaleTypeStep = ({ localeType = 'participant', onLocaleTypeChange }) => {
    const translate = useLocalizeMessage();
    const section = [
        {
            title: translate('Locale availability'),
            description: translate('Select whether a locale is available to participants or both site users and participants.'),
            content: (_jsx("div", { children: _jsx(RadioGroup, { label: translate('Locale availability'), value: localeType, onChange: onLocaleTypeChange, required: true, children: options.map((option) => (_jsx(RadioGroupOption, { option: option }, option.value))) }) })),
        },
    ];
    return _jsx(FormSections, { sections: section });
};
