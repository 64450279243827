import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, RadioGroup, RadioGroupOption } from 'libs.nucleus';
export const RadioGroupSection = ({ options, label, selectedValue, dataTestId, orientation = 'vertical', onSelectionChange, }) => {
    const orientationClass = {
        horizontal: 'flex-row justify-between',
        vertical: 'flex-col',
    };
    return (_jsxs("div", { className: 'flex flex-col gap-2', children: [label && _jsx("label", { className: `order-none flex-none grow-0 text-sm font-medium text-text-primary`, children: label }), _jsx("div", { "data-testid": dataTestId, className: `flex ${orientationClass[orientation]} gap-3`, children: options.map((option) => {
                    return (_jsx(Card, { "data-testid": option.dataTestId, onClick: () => onSelectionChange(option.value), className: `flex-1 radio-group-card p-6 cursor-pointer ${selectedValue === option.value ? 'bg-purple-50 border-purple-600 border-[1.5px]' : 'hover:bg-neutral-50'}`, children: _jsx(RadioGroup, { onChange: onSelectionChange, value: selectedValue, children: _jsx(RadioGroupOption, { option: {
                                    label: option.label,
                                    description: option.description,
                                    value: option.value,
                                } }) }) }, option.value));
                }) })] }));
};
