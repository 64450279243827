import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, useLocalizeMessage } from 'libs.nucleus';
export const StudySummaryTimelineConditions = ({ timelineConditions }) => {
    const translate = useLocalizeMessage();
    const sortByVisitSchedule = useMemo(() => {
        return (a, b) => {
            if (!a.visitSchedule) {
                return -1;
            }
            if (!b.visitSchedule) {
                return 1;
            }
            return a.visitSchedule.localeCompare(b.visitSchedule);
        };
    }, []);
    const filteredAndSortedConditions = useMemo(() => {
        if (!timelineConditions?.length) {
            return [];
        }
        return timelineConditions.filter((condition) => condition.type === 'site').sort(sortByVisitSchedule);
    }, [timelineConditions, sortByVisitSchedule]);
    const columns = useMemo(() => [
        {
            header: translate('Target visit schedule'),
            accessorFn: (row) => row.visitScheduleName || row.visitScheduleKey,
        },
        {
            header: translate('Activity'),
            accessorFn: (row) => row.activityName || row.activityKey,
        },
        {
            header: translate('Screen condition'),
            cell: ({ row }) => {
                const condition = row.original;
                return `${condition.stepName || condition.stepKey || ''} ${condition.comparisonOperator || ''} ${condition.comparisonValue || ''}`;
            },
        },
        {
            header: translate('Visit'),
            accessorKey: 'visit',
        },
        {
            header: translate('Config type'),
            accessorKey: 'configType',
        },
    ], [translate]);
    if (!timelineConditions?.length) {
        return null;
    }
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Timeline conditions') }), _jsx(Table, { data: filteredAndSortedConditions, columns: columns })] }));
};
