import { useQuery } from '@tanstack/react-query';
import { ApiClientService, useApiClient } from 'libs.react';
export function useTranslationDetails(id, translationId, enabled = true) {
    const { get } = useApiClient(ApiClientService.LIBRARY);
    return useQuery({
        queryKey: ['asset', id, 'translations', translationId],
        queryFn: () => {
            return get(`v1/asset/${id}/translations/${translationId}`, { params: { withUrl: true } });
        },
        enabled: !!id && !!translationId && enabled,
        retry: false,
    });
}
