import { useMemo, useEffect, useState } from 'react';
import BundlePreview from './bundle_preview';
export const useBundlePreview = (bundle) => {
    const bundlePreview = useMemo(() => new BundlePreview(), []);
    const [isBundleLoaded, setIsBundleLoaded] = useState(false);
    useEffect(() => {
        (async () => {
            if (bundle) {
                await bundlePreview.loadBundle(bundle);
                setIsBundleLoaded(true);
            }
        })();
    }, [bundle]);
    return { bundlePreview: bundle && isBundleLoaded ? bundlePreview : null };
};
export default useBundlePreview;
