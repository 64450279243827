export const convertFormatSpecSubjectIdIntoArray = (specPattern) => {
    const pattern = specPattern?.toString();
    if (!pattern) {
        return [];
    }
    return pattern.match(/({SITE}|{PROTOCOL}|{COUNTRY}|[a-zA-Z0-9]+|[@#]+|[._/-]+)/g);
};
export function siteNumber(site, translate) {
    return site?.c_number || translate('Missing site number');
}
export function protocol(study, translate) {
    return study?.c_protocol_number || translate('Missing protocol number');
}
export function countryCode(site, translate) {
    return site?.c_country || translate('Missing country code');
}
export const setupInitialValues = (fields) => {
    const fieldsThatRequireInput = {};
    const initialValues = fields?.reduce((acc, { format, value }, index) => {
        if (format.includes('#') || format.includes('@')) {
            acc[`pid-${index}`] = value;
            fieldsThatRequireInput[`pid-${index}`] = { format, value };
        }
        return acc;
    }, {}) || {};
    return {
        initialValues,
        fieldsThatRequireInput,
    };
};
// packages/axon/src/components/steps/ParticipantIDStep/Step.js setupResponseValue function
export const setupResponseValue = (fields, responseValue) => {
    const fieldsThatRequireInput = {};
    const stringWithPlaceHoldersOnly = fields
        .map((field) => {
        if (field.format.includes('#') || field.format.includes('@')) {
            return field.format;
        }
        return field.value;
    })
        .join('');
    let nextIndex = 0;
    const initialValues = fields.reduce((acc, field, index) => {
        if (field.format.includes('#') || field.format.includes('@')) {
            const indexOfCurrentString = stringWithPlaceHoldersOnly.indexOf(field.format[0], nextIndex);
            const stringLength = field.format.length;
            nextIndex = indexOfCurrentString + stringLength;
            acc[`pid-${index}`] = responseValue.slice(indexOfCurrentString, indexOfCurrentString + stringLength);
            fieldsThatRequireInput[`pid-${index}`] = { format: field.format, value: field.value };
        }
        return acc;
    }, {});
    return {
        fieldsThatRequireInput,
        initialValues,
    };
};
