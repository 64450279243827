export var StartingConditionType;
(function (StartingConditionType) {
    StartingConditionType["VISIT_CONFIRMATION"] = "visitConfirmation";
    StartingConditionType["AFTER_ACTIVITY_COMPLETION"] = "afterActivityCompletion";
    StartingConditionType["ACTIVITY_RESPONSE"] = "activityResponse";
    StartingConditionType["DATE_RESPONSE"] = "dateResponse";
    StartingConditionType["STUDY_CREATION"] = "studyCreation";
})(StartingConditionType || (StartingConditionType = {}));
export var EndingConditionType;
(function (EndingConditionType) {
    EndingConditionType["AFTER_ACTIVITY_COMPLETION"] = "afterActivityCompletion";
    EndingConditionType["AFTER_DURATION"] = "afterDuration";
    EndingConditionType["END_OF_DAY"] = "endOfDay";
    EndingConditionType["UPON_COMPLETION"] = "uponCompletion";
    EndingConditionType["ACTIVITY_RESPONSE"] = "activityResponse";
    EndingConditionType["DATE_RESPONSE"] = "dateResponse";
    EndingConditionType["VISIT_CONFIRMATION"] = "visitConfirmation";
    EndingConditionType["ENDLESS"] = "endless";
    EndingConditionType["NUM_OF_OCURRENCES"] = "numOcurrences";
})(EndingConditionType || (EndingConditionType = {}));
