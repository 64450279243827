import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { LocalizationStatus } from 'libs.localization.common';
export const getExportTableStatus = (studyLocalizationStatus) => {
    switch (studyLocalizationStatus) {
        case StudyLocalizationStatus.READY:
            return LocalizationStatus.READY;
        default:
            return LocalizationStatus.IN_PROGRESS;
    }
};
