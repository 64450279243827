import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { Tooltip, Link, Dropdown, Table, useLocalizeMessage } from 'libs.nucleus';
import { FormattedDateTime } from 'libs.react';
import { StudyTestPlanHistoryStatus } from './study_testplan_history_status.component';
import { StudyTestPlanContext } from '../../contexts';
export const StudyTestPlanHistory = ({ testPlan }) => {
    const translate = useLocalizeMessage();
    const { getTestPlanHistory, fetchReportUrl, deleteExecutionHistory, testPlanHistory } = useContext(StudyTestPlanContext);
    const testPlanHistoryList = useMemo(() => {
        if (!testPlan) {
            return [];
        }
        return testPlanHistory[testPlan.id] || [];
    }, [testPlan, testPlanHistory]);
    const { isLoading } = useQuery({
        queryKey: [],
        queryFn: () => getTestPlanHistory(testPlan.id),
        enabled: !!testPlan,
    });
    const executionHistoryColumns = useMemo(() => {
        return [
            {
                header: translate('Status'),
                size: 50,
                cell: (value) => _jsx(StudyTestPlanHistoryStatus, { status: value.row.original.status }),
            },
            {
                header: translate('Build version'),
                cell: ({ row }) => {
                    return (_jsx(Tooltip, { title: row.original.metadata.package?.version || '', children: row.original.metadata.package?.version || EM_DASH }));
                },
            },
            {
                header: translate('Executed on'),
                cell: ({ row }) => {
                    return _jsx(FormattedDateTime, { date: row.original.createdAt });
                },
            },
            {
                header: translate('Execution time'),
                cell: ({ row }) => {
                    if (row.original.data?.duration) {
                        return row.original.data.duration;
                    }
                    return EM_DASH;
                },
            },
            {
                header: translate('Report'),
                cell: ({ row }) => {
                    if (row.original.blobPath) {
                        return (_jsx(Link, { LinkComponent: RouterLink, to: row.original.blobPath, target: '_blank', onClick: async (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const url = await fetchReportUrl(row.original.id);
                                window.open(url, '_blank');
                            }, children: translate('View report') }));
                    }
                    return EM_DASH;
                },
            },
            {
                header: '',
                id: 'options',
                cell: ({ row }) => {
                    const dropdownOptions = [];
                    if (row.original.status === 'RUNNING') {
                        return null;
                    }
                    dropdownOptions.push({
                        id: 'delete',
                        content: translate('Delete'),
                        onClick: () => {
                            deleteExecutionHistory(row.original.id);
                        },
                        destructive: true,
                    });
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownOptions, variant: 'kebab' }) }));
                },
            },
        ];
    }, []);
    return (_jsx(Table, { columns: executionHistoryColumns, data: testPlanHistoryList, hasPagination: true, initialPageSize: 10, isLoading: isLoading, paginationNavigationScreenReaderLabel: translate('Test plan table navigation') }));
};
