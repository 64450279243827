import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { INITIAL_STATE, reducer } from './spreadsheet.reducer';
import { SpreadsheetDispatchProvider } from './spreadsheet_dispatch.context';
export const SpreadsheetContext = createContext(INITIAL_STATE);
export const SpreadsheetProvider = ({ children }) => {
    const reducerElements = useImmerReducer(reducer, INITIAL_STATE);
    const [state, dispatch] = reducerElements;
    return (_jsx(SpreadsheetContext.Provider, { value: state, children: _jsx(SpreadsheetDispatchProvider, { dispatch: dispatch, children: children }) }));
};
export const useSpreadsheetState = () => {
    const context = useContext(SpreadsheetContext);
    if (!context) {
        throw new Error('useSpreadsheetState must be used within an SpreadsheetProvider');
    }
    return context;
};
export const useSpreadsheetSelector = (selector) => {
    const state = useSpreadsheetState();
    return selector(state);
};
