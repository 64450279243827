import { useQueries } from '@tanstack/react-query';
import { ApiClientService, useApiClient } from 'libs.react';
export const useResourcesLoader = (resources) => {
    const { get } = useApiClient(ApiClientService.LIBRARY);
    return useQueries({
        queries: resources.map((resource) => ({
            queryKey: ['resource', resource],
            queryFn: () => get(`/v1/asset/${resource}`),
            refetchOnWindowFocus: false,
            retry: false,
            enabled: !!resource,
        })),
        combine: (results) => {
            return {
                data: results.map((result) => result.data?.data.data),
                pending: results.some((result) => result.isPending),
            };
        },
    });
};
