import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocalizeMessage, Input, ModalWindow } from 'libs.nucleus';
import { validateUrl } from '../utils';
export const FormatLinkModal = ({ initialLink, onSave, onCancel, onRemove }) => {
    const [link, setLink] = useState(initialLink || { text: '', url: '' });
    const [isPristine, setIsPristine] = useState(true);
    const [isURLFieldTouched, setIsURLFieldTouched] = useState(false);
    const translate = useLocalizeMessage();
    const saveEnabled = !isPristine && validateUrl(link.url);
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: () => onSave(link),
        disabled: !saveEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const removeSecondaryButton = {
        label: translate('Remove'),
        onClick: onRemove,
        variant: 'destructive',
    };
    const onInputChange = (event) => {
        const { id, value } = event.target;
        setLink((prevLink) => ({ ...prevLink, [id]: value }));
        setIsPristine(false);
    };
    const handleSave = (event) => {
        event.preventDefault();
        if (saveEnabled) {
            onSave(link);
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && saveEnabled) {
            onSave(link);
        }
    };
    const isUrlValid = !isURLFieldTouched || validateUrl(link.url);
    const footerSecondaryActionButtons = initialLink?.url
        ? [cancelSecondaryButton, removeSecondaryButton]
        : [cancelSecondaryButton];
    return (_jsx(ModalWindow, { title: initialLink?.url || initialLink?.text ? translate('Edit link') : translate('Add link'), isOpen: true, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: footerSecondaryActionButtons, closeWindow: onCancel, width: 'sm', dataTestId: 'format-link-modal', children: _jsxs("form", { autoComplete: 'off', className: 'flex flex-col gap-6 mb-6', onSubmit: handleSave, onKeyUp: handleEnterPress, children: [_jsx(Input, { id: 'text', label: translate('Text'), value: link.text, onChange: onInputChange }), _jsx(Input, { id: 'url', label: translate('Link'), required: true, value: link.url, onChange: onInputChange, hasError: !isUrlValid, errorMessage: translate('Invalid URL'), onBlur: () => setIsURLFieldTouched(true) })] }) }));
};
