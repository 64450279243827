import { CompletionStep } from './completion_step.component';
import { ParticipantIdStep } from './participant_id_step.component';
export const stepDetermination = (step) => {
    const { c_type } = step;
    switch (c_type) {
        case 'participant_id':
            return ParticipantIdStep;
        case 'completion':
            return CompletionStep;
        default:
            throw new Error(`Unknown step type: ${c_type}`);
    }
};
