import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, ModalWindow } from 'libs.nucleus';
const TestPlanValidation = ({ validations, onSelectCell, onClose }) => {
    const translate = useLocalizeMessage();
    return (_jsx(ModalWindow, { isOpen: true, title: translate('Errors'), closeWindow: onClose, hideCloseIcon: false, children: _jsx("ul", { className: 'flex flex-col gap-2', children: validations.map((validation) => {
                const { column, row, error, columnName } = validation;
                return (_jsxs("li", { className: 'cursor-pointer p-2 bg-red-100 hover:bg-red-200', onClick: () => {
                        onClose();
                        onSelectCell({
                            column,
                            row,
                        });
                    }, children: ["Row: ", row + 1, ", Column: ", columnName, ", Error:", error] }, `${row}-${column}-${error}`));
            }) }) }));
};
export default TestPlanValidation;
