import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useEffect } from 'react';
import { AlignmentOptions } from '../contexts/alignment_context';
/**
 * This hook is used to deserialise the initial value of the editor.
 */
export const useLexicalDeserializer = (value) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor) {
            console.error('Editor instance is undefined');
            return;
        }
        editor.update(() => {
            const parser = new DOMParser();
            let content = '';
            let alignment = AlignmentOptions.LEFT;
            if (typeof value === 'string') {
                content = value;
            }
            else if (typeof value === 'object' && typeof value?.content === 'string') {
                // eslint-disable-next-line prefer-destructuring
                content = value?.content;
                alignment = value?.alignment;
            }
            const dom = parser.parseFromString(content, 'text/html');
            const imgs = dom.querySelectorAll('img');
            const p = dom.querySelectorAll('p');
            const li = dom.querySelectorAll('li');
            [...p, ...li].forEach((el) => {
                el.style.textAlign = alignment;
            });
            imgs.forEach((img) => {
                const src = img.getAttribute('src');
                const pattern = /\{([^}]+)\}/;
                const match = src?.match(pattern);
                const imageKey = match && match[1] && match[1];
                if (imageKey && value?.images) {
                    img.dataset.imageKey = imageKey;
                    img.setAttribute('src', value.images[imageKey]);
                }
            });
            const nodes = $generateNodesFromDOM(editor, dom);
            if (nodes && nodes.length) {
                $insertNodes(nodes);
            }
        });
    }, []);
};
