import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { getOffsetRect, isActive } from '../../utils/spreadsheet';
import * as Matrix from '../../utils/spreadsheet/spreadsheet_matrix.utils';
export const Cell = ({ row, column, DataViewer, selected, active, dragging, mode, data, select, error, activate, setCellDimensions, setCellData, showContextMenu, disabled, }) => {
    const rootRef = React.useRef(null);
    const point = React.useMemo(() => ({
        row,
        column,
    }), [row, column]);
    const handleMouseDown = React.useCallback((event) => {
        if (mode === 'view') {
            setCellDimensions(point, getOffsetRect(event.currentTarget));
            if (event.button === 2 && selected) {
                showContextMenu(event, 'selection');
            }
            else if (event.shiftKey) {
                select(point);
            }
            else {
                activate(point);
            }
        }
    }, [mode, setCellDimensions, point, select, activate, selected]);
    const handleMouseOver = React.useCallback((event) => {
        if (dragging) {
            setCellDimensions(point, getOffsetRect(event.currentTarget));
            select(point);
        }
    }, [setCellDimensions, select, dragging, point]);
    React.useEffect(() => {
        const root = rootRef.current;
        if (selected && root) {
            setCellDimensions(point, getOffsetRect(root));
        }
        if (root && active && mode === 'view') {
            root.focus();
        }
    }, [setCellDimensions, selected, active, mode, point, data]);
    if (data && data.DataViewer) {
        // eslint-disable-next-line no-param-reassign
        DataViewer = data.DataViewer;
    }
    return (_jsx("td", { ref: rootRef, className: classnames(SpreadsheetStyles.cellBase, data?.className, {
            [SpreadsheetStyles.cellReadOnly]: data?.readOnly,
            [SpreadsheetStyles.cellError]: error,
        }), onMouseOver: handleMouseOver, onMouseDown: handleMouseDown, tabIndex: 0, onContextMenu: (e) => {
            e.preventDefault();
        }, children: _jsx(DataViewer, { row: row, column: column, cell: data, setCellData: setCellData, disabled: disabled }) }));
};
export const enhance = (CellComponent) => {
    return function CellWrapper(props) {
        const { row, column } = props;
        const { select, activate, setCellDimensions, setCellData, showContextMenu } = useSpreadsheetDispatch();
        const point = React.useMemo(() => ({
            row,
            column,
        }), [row, column]);
        const setCurrentCellData = React.useCallback((cell) => setCellData(point, cell), [setCellData, point]);
        const active = useSpreadsheetSelector((state) => isActive(state.active, point));
        const mode = useSpreadsheetSelector((state) => (active ? state.mode : 'view'));
        const data = useSpreadsheetSelector((state) => Matrix.get(point, state.data));
        const selected = useSpreadsheetSelector((state) => state.selected.has(state.data, point));
        const dragging = useSpreadsheetSelector((state) => state.dragging);
        const copied = useSpreadsheetSelector((state) => state.copied?.has(point) || false);
        return (_jsx(CellComponent, { ...props, selected: selected, active: active, copied: copied, dragging: dragging, mode: mode, data: data, select: select, activate: activate, setCellDimensions: setCellDimensions, setCellData: setCurrentCellData, showContextMenu: showContextMenu }));
    };
};
