"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
/**
 * @param fn function to call.
 * @param t time in milliseconds to wait before calling fn.
 * @returns A function that calls the given function after t milliseconds. If the returned function is called a second
 *     time before the first call triggers the given function, starts counting the time again and only results in one
 *     total call of the given function.
 */
function debounce(fn, t) {
    let timeout;
    const debouncedFn = () => {
        clearTimeout(timeout);
        timeout = setTimeout(fn, t);
    };
    debouncedFn.cancel = () => clearTimeout(timeout);
    return debouncedFn;
}
exports.debounce = debounce;
