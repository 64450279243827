import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, useLocalizeMessage } from 'libs.nucleus';
export const StudySummaryVisits = ({ visitAvailability }) => {
    const translate = useLocalizeMessage();
    const visitAvailabilityColumns = useMemo(() => [
        {
            header: translate('Visit name'),
            accessorKey: 'visit_name',
            size: 200,
        },
        {
            header: translate('Group name'),
            accessorKey: 'group_name',
            size: 200,
        },
        {
            header: translate('Visit schedule'),
            accessorKey: 'visit_schedule',
        },
        {
            header: translate('Sequence'),
            accessorKey: 'sequence',
        },
        {
            header: translate('Anchor name'),
            accessorKey: 'anchor_name',
            size: 200,
        },
        {
            header: translate('Days from anchor'),
            accessorKey: 'days_from_anchor',
        },
        {
            header: translate('Offset minus'),
            accessorKey: 'offset_minus',
        },
        {
            header: translate('Offset plus'),
            accessorKey: 'offset_plus',
        },
        {
            header: translate('Televisit'),
            accessorKey: 'Televisit',
        },
    ], [translate]);
    if (!visitAvailability?.length) {
        return null;
    }
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Visits') }), _jsx(Table, { data: visitAvailability, columns: visitAvailabilityColumns })] }));
};
