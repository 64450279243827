import { useEffect } from 'react';
export const useWarnOnExit = (shouldWarn, message) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (shouldWarn) {
                event.preventDefault();
                event.returnValue = message || ''; // INFO: This message is not shown in modern browsers
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [shouldWarn]);
};
