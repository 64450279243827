import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { ECOA_BUILDER_FLAGS } from './app';
import { UrlScreenModalActions } from './constants/screen.constants';
const ActivitiesDashboard = lazy(() => import('./components/activities_dashboard'));
const ViewActivity = lazy(() => import('./components/view_activity'));
const ViewTranslation = lazy(() => import('./components/view_translation'));
const Generative = lazy(() => import('./components/generative/page'));
export const ECOABuilderApp = () => {
    const flagManager = useContext(FlagContext);
    return (_jsxs(Routes, { children: [flagManager.getValue(ECOA_BUILDER_FLAGS.generativeAI) && _jsx(Route, { path: '/generative', element: _jsx(Generative, {}) }), _jsx(Route, { path: '/new', element: _jsx(ViewActivity, { newActivity: true }) }), _jsx(Route, { path: '/new/:screen?', element: _jsx(ViewActivity, { newActivity: true }) }), Object.values(UrlScreenModalActions).map((action) => (_jsx(Route, { path: '/:id/:screen?/' + action, element: _jsx(ViewActivity, { screenAction: action }) }, action))), _jsx(Route, { path: '/:id/:screen?', element: _jsx(ViewActivity, {}) }), _jsx(Route, { path: '/:id/translation/:locale?', element: _jsx(ViewTranslation, {}) }), _jsx(Route, { index: true, path: '/', element: _jsx(ActivitiesDashboard, {}) })] }));
};
export { ECOA_BUILDER_FLAGS } from './constants/developer_flags.constants';
