import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, } from 'react';
import ActiveCell from './spreadsheet_active_cell';
import { Cell as DefaultCell, enhance as enhanceCell } from './spreadsheet_cell';
import DefaultColumnIndicator, { enhance as enhanceColumnIndicator } from './spreadsheet_column_indicator';
import ContextMenu from './spreadsheet_context_menu';
import Copied from './spreadsheet_copied';
import DefaultCornerIndicator, { enhance as enhanceCornerIndicator } from './spreadsheet_corner_indicator';
import DefaultDataEditor from './spreadsheet_data_editor';
import DefaultDataViewer from './spreadsheet_data_viewer';
import DefaultHeaderRow from './spreadsheet_header_row';
import DefaultRow, { enhance as enhanceRow } from './spreadsheet_row';
import DefaultRowIndicator, { enhance as enhanceRowIndicator } from './spreadsheet_row_indicator';
import SelectPopup from './spreadsheet_select_popup';
import Selected from './spreadsheet_selected';
import SpreadsheetStyles from './spreadsheet_styles';
import DefaultTable from './spreadsheet_table';
import { hasKeyDownHandler, INITIAL_STATE, useSpreadsheetDispatch, useSpreadsheetState, } from '../../contexts/spreadsheet';
import { calculateSpreadsheetSize, getCSV, isFocusedWithin, range, readTextFromClipboard, shouldHandleClipboardEvent, writeTextToClipboard, } from '../../utils/spreadsheet';
import * as Matrix from '../../utils/spreadsheet/spreadsheet_matrix.utils';
const noOp = () => { };
/**
 * The Spreadsheet component
 */
export const Spreadsheet = forwardRef((props, ref) => {
    const { className, errors, columnLabels, rowLabels, hideColumnIndicators, hideRowIndicators, onKeyDown, isHeaderSticky, isEditEnabled = true, Table = DefaultTable, HeaderRow = DefaultHeaderRow, DataEditor = DefaultDataEditor, DataViewer = DefaultDataViewer, onChange = noOp, onModeChange = noOp, onSelect = noOp, onActivate = noOp, onBlur = noOp, onCellCommit = noOp, } = props;
    const state = useSpreadsheetState();
    const { updateInitialState, cut, copy, paste, keyDown: onKeyDownAction, keyPress: onKeyPress, dragStart: onDragStart, dragEnd: onDragEnd, setData, blur, setSelection, moveRow, hideContextMenu, insertRow, deleteRow, hideSelect: hideSelectPopup, resetState, activate: activateCell, } = useSpreadsheetDispatch();
    useEffect(() => {
        updateInitialState({
            data: props.data,
            selected: props.selected || INITIAL_STATE.selected,
        });
    }, [props.data, props.selected]);
    const size = useMemo(() => {
        return calculateSpreadsheetSize(state.data, rowLabels, columnLabels);
    }, [state.data, rowLabels, columnLabels]);
    const { mode } = state;
    const rootRef = useRef(null);
    useImperativeHandle(ref, () => ({
        fetchData: () => state.data,
        reset: () => resetState(props.data),
        selectCell: activateCell,
    }));
    // Track active
    const prevActiveRef = useRef(state.active);
    useEffect(() => {
        if (state.active !== prevActiveRef.current) {
            if (state.active) {
                onActivate(state.active);
            }
            else {
                const root = rootRef.current;
                if (root && isFocusedWithin(root) && document.activeElement) {
                    document.activeElement.blur();
                }
                onBlur();
            }
        }
        prevActiveRef.current = state.active;
    }, [onActivate, onBlur, state.active]);
    // Listen to data changes
    const prevDataRef = useRef(state.data);
    useEffect(() => {
        if (state.data !== prevDataRef.current) {
            // Call on change only if the data change internal
            if (state.data !== props.data) {
                onChange(state.data);
            }
        }
        prevDataRef.current = state.data;
    }, [state.data, onChange, props.data]);
    // Listen to selection changes
    const prevSelectedRef = useRef(state.selected);
    useEffect(() => {
        if (!state.selected.equals(prevSelectedRef.current)) {
            // Call on select only if the selection change internal
            if (!props.selected || !state.selected.equals(props.selected)) {
                onSelect(state.selected);
            }
        }
        prevSelectedRef.current = state.selected;
    }, [state.selected, onSelect, props.selected]);
    // Listen to mode changes
    const prevModeRef = useRef(state.mode);
    useEffect(() => {
        if (state.mode !== prevModeRef.current) {
            onModeChange(state.mode);
        }
        prevModeRef.current = state.mode;
    }, [state.mode, onModeChange]);
    // Listen to last commit changes
    const prevLastCommitRef = useRef(state.lastCommit);
    useEffect(() => {
        if (state.lastCommit && state.lastCommit !== prevLastCommitRef.current) {
            for (const change of state.lastCommit) {
                onCellCommit(change.prevCell, change.nextCell, state.lastChanged);
            }
        }
    }, [onCellCommit, state.lastChanged, state.lastCommit]);
    // Update selection when props.selected changes
    const prevSelectedPropRef = useRef(props.selected);
    useEffect(() => {
        if (props.selected && prevSelectedPropRef.current && !props.selected.equals(prevSelectedPropRef.current)) {
            setSelection(props.selected);
        }
        prevSelectedPropRef.current = props.selected;
    }, [props.selected, setSelection]);
    // Update data when props.data changes
    const prevDataPropRef = useRef(props.data);
    useEffect(() => {
        if (props.data !== prevDataPropRef.current) {
            setData(props.data);
        }
        prevDataPropRef.current = props.data;
    }, [props.data, setData]);
    const writeDataToClipboard = useCallback(() => {
        const { data, selected } = state;
        const range = selected.toRange(data);
        if (range) {
            const selectedData = Matrix.slice(range.start, range.end, data);
            const csv = getCSV(selectedData);
            writeTextToClipboard(csv);
        }
    }, [state]);
    const handleCut = useCallback((event) => {
        if (shouldHandleClipboardEvent(rootRef.current, mode) && isEditEnabled) {
            event.preventDefault();
            event.stopPropagation();
            writeDataToClipboard();
            cut();
        }
    }, [mode, writeDataToClipboard, cut, isEditEnabled]);
    const handleCopy = useCallback((event) => {
        if (shouldHandleClipboardEvent(rootRef.current, mode) && isEditEnabled) {
            event.preventDefault();
            event.stopPropagation();
            writeDataToClipboard();
            copy();
        }
    }, [mode, writeDataToClipboard, copy, isEditEnabled]);
    const handlePaste = useCallback(async (event) => {
        if (shouldHandleClipboardEvent(rootRef.current, mode) && isEditEnabled) {
            event.preventDefault();
            event.stopPropagation();
            if (event.clipboardData) {
                const text = await readTextFromClipboard();
                paste(text);
            }
        }
    }, [mode, paste, isEditEnabled]);
    const handleKeyDown = useCallback((event) => {
        event.persist();
        if (onKeyDown) {
            onKeyDown(event);
        }
        // Do not use event in case preventDefault() was called inside onKeyDown
        if (!event.defaultPrevented) {
            // Only disable default behavior if an handler exist
            if (hasKeyDownHandler(state, event)) {
                event.nativeEvent.preventDefault();
            }
            onKeyDownAction(event);
        }
    }, [state, onKeyDown, onKeyDownAction]);
    const handleMouseUp = useCallback(() => {
        onDragEnd();
        document.removeEventListener('mouseup', handleMouseUp);
    }, [onDragEnd]);
    const handleMouseMove = useCallback((event) => {
        if (!state.dragging && event.buttons === 1) {
            onDragStart();
            document.addEventListener('mouseup', handleMouseUp);
        }
    }, [state, onDragStart, handleMouseUp]);
    const handleBlur = useCallback(() => {
        /**
         * Focus left self, Not triggered when swapping focus between children
         * @see https://reactjs.org/docs/events.html#detecting-focus-entering-and-leaving
         */
        // TODO: not handling blur cause not needed as of now
        // if (
        //   event.relatedTarget &&
        //   !event.currentTarget.contains(event.relatedTarget as Node)
        // ) {
        //   blur();
        // }
    }, [blur]);
    const Cell = useMemo(() => {
        // @ts-expect-error - CellType is not assignable to CellBase
        return enhanceCell(props.Cell || DefaultCell);
    }, [props.Cell]);
    const CornerIndicator = useMemo(() => enhanceCornerIndicator(props.CornerIndicator || DefaultCornerIndicator), [props.CornerIndicator]);
    const RowIndicator = useMemo(() => enhanceRowIndicator(props.RowIndicator || DefaultRowIndicator), [props.RowIndicator]);
    const ColumnIndicator = useMemo(() => enhanceColumnIndicator(props.ColumnIndicator || DefaultColumnIndicator), [props.ColumnIndicator]);
    const Row = useMemo(() => enhanceRow(props.Row || DefaultRow), [props.Row]);
    useEffect(() => {
        document.addEventListener('cut', handleCut);
        document.addEventListener('copy', handleCopy);
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('cut', handleCut);
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('paste', handlePaste);
        };
    }, [handleCut, handleCopy, handlePaste]);
    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over?.id) {
            moveRow(active.id, over.id);
        }
    };
    const tableNode = useMemo(() => (_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd, children: _jsxs(Table, { columns: size.columns, hideColumnIndicators: hideColumnIndicators, children: [_jsxs(HeaderRow, { isHeaderSticky: isHeaderSticky, children: [!hideRowIndicators && !hideColumnIndicators && _jsx(CornerIndicator, {}), !hideColumnIndicators &&
                            range(size.columns).map((columnNumber) => columnLabels ? (_jsx(ColumnIndicator, { column: columnNumber, label: columnNumber in columnLabels ? columnLabels[columnNumber] : null }, columnNumber)) : (_jsx(ColumnIndicator, { column: columnNumber }, columnNumber)))] }), _jsx(SortableContext, { items: range(size.rows), strategy: verticalListSortingStrategy, children: range(size.rows).map((rowNumber) => (_jsxs(Row, { row: rowNumber, children: [!hideRowIndicators &&
                                (rowLabels ? (_jsx(RowIndicator, { row: rowNumber, label: rowNumber in rowLabels ? rowLabels[rowNumber] : null }, rowNumber)) : (_jsx(RowIndicator, { row: rowNumber }, rowNumber))), range(size.columns).map((columnNumber) => (_jsx(Cell, { row: rowNumber, column: columnNumber, 
                                // @ts-expect-error - CellType is not assignable to CellBase
                                DataViewer: DataViewer, columnLabels: columnLabels, error: errors ? errors[rowNumber]?.[columnNumber] : undefined, disabled: !isEditEnabled }, columnNumber)))] }, rowNumber))) })] }) })), [
        Table,
        size.rows,
        size.columns,
        hideColumnIndicators,
        Row,
        HeaderRow,
        hideRowIndicators,
        columnLabels,
        ColumnIndicator,
        rowLabels,
        RowIndicator,
        Cell,
        DataViewer,
        errors,
        isEditEnabled,
    ]);
    const activeCellNode = useMemo(() => (_jsx(ActiveCell
    // @ts-expect-error - CellType is not assignable to CellBase
    , { 
        // @ts-expect-error - CellType is not assignable to CellBase
        DataEditor: DataEditor, columnLabels: columnLabels, disabled: !isEditEnabled })), [DataEditor, columnLabels, isEditEnabled]);
    const contextMenu = useMemo(() => {
        if (!state.contextMenu || !isEditEnabled) {
            return null;
        }
        const { position } = state.contextMenu;
        // show a context menu in react portal
        return (_jsx(ContextMenu, { position: position, items: [
                {
                    label: 'Cut',
                    onClick: () => {
                        writeDataToClipboard();
                        cut();
                    },
                },
                {
                    label: 'Copy',
                    onClick: () => {
                        writeDataToClipboard();
                        copy();
                    },
                },
                {
                    label: 'Paste',
                    onClick: async () => {
                        const text = await readTextFromClipboard();
                        paste(text);
                    },
                },
                {
                    label: 'Insert a row above',
                    onClick: () => insertRow(-1),
                },
                {
                    label: 'Insert a row below',
                    onClick: () => insertRow(+1),
                },
                {
                    label: 'Delete this row',
                    onClick: deleteRow,
                },
            ], onClose: hideContextMenu, popupContainer: rootRef.current }));
    }, [state.contextMenu, hideContextMenu, isEditEnabled]);
    const rootNode = useMemo(() => (_jsxs("div", { ref: rootRef, className: classNames(SpreadsheetStyles.spreadsheet, className), onKeyPress: onKeyPress, onKeyDown: handleKeyDown, onMouseMove: handleMouseMove, onBlur: handleBlur, children: [tableNode, activeCellNode, _jsx(Selected, {}), _jsx(Copied, {}), contextMenu, state.selectPopup && (_jsx(SelectPopup, { position: state.selectPopup.position, options: state.selectPopup.options, value: state.selectPopup.value, onSelect: state.selectPopup.onChange, onClose: hideSelectPopup, popupContainer: rootRef.current }))] })), [className, onKeyPress, handleKeyDown, handleMouseMove, handleBlur, tableNode, activeCellNode]);
    return rootNode;
});
Spreadsheet.displayName = 'Spreadsheet';
