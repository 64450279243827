import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
const ImagesContext = createContext({
    images: {},
});
const ImagesApiContext = createContext({
    addImage: () => { },
    addImages: () => { },
    removeImage: () => { },
});
export const ImagesContextProvider = ({ children, initialValue, }) => {
    const [images, setImages] = useState(typeof initialValue === 'object' && initialValue?.images ? initialValue.images : {});
    const value = useMemo(() => ({ images }), [images]);
    const api = useMemo(() => ({
        addImage: (key, image) => {
            setImages((prevState) => ({ ...prevState, [key]: image.src }));
        },
        addImages: (newImages) => {
            setImages((prevState) => ({ ...prevState, ...newImages }));
        },
        removeImage: (imageKey) => {
            setImages((prevState) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [imageKey]: _, ...rest } = prevState;
                return rest;
            });
        },
    }), []);
    return (_jsx(ImagesContext.Provider, { value: value, children: _jsx(ImagesApiContext.Provider, { value: api, children: children }) }));
};
export const useImagesContext = () => useContext(ImagesContext);
export const useImagesApi = () => useContext(ImagesApiContext);
