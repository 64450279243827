import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, Icon } from 'libs.nucleus';
const statusIconMap = {
    ERROR: {
        color: 'red',
        icon: 'error',
        label: 'Error',
    },
    RUNNING: {
        color: 'orangeLight',
        icon: 'clockSolid',
        label: 'Running',
    },
    PASSED: {
        color: 'green',
        icon: 'successSolid',
        label: 'Passed',
    },
    FAILED: {
        color: 'red',
        icon: 'infoCircleSolid',
        label: 'Failed',
    },
    PROCESSING: {
        color: 'blue',
        icon: 'infoCircleSolid',
        label: 'Processing',
    },
};
export const StudyTestPlanHistoryStatus = ({ status = 'ERROR' }) => {
    const translate = useLocalizeMessage();
    const statusIcon = statusIconMap[status];
    return (_jsxs("div", { className: 'flex flex-row gap-2 items-center', children: [_jsx(Icon, { color: statusIcon.color, name: statusIcon.icon }), _jsx("span", { children: translate(statusIcon.label) })] }));
};
