import { useContext } from 'react';
import { ApiClientContext } from '../contexts/api_client.context';
import { ApiClientEndpointsByService } from '../types/api_client.constants';
/**
 * The useApiClient is a hook that works together with the ApiClientContext to provide a simple way to make requests
 * In any general case, consumers should make calls using this hook and not the context directly
 * This is to ensure that the baseURL is set correctly
 *
 * @param {ApiClientService} service - The service that the client will be used for, needed to define the baseURL (aka
 *   endpoint)
 * @param {ApiClientRequestConfig} clientConfig - The client config object that will be merged with the default config
 */
export const useApiClient = (service, clientConfig = {}) => {
    const { apiClient } = useContext(ApiClientContext);
    const getConfig = (config = {}) => {
        const baseURL = ApiClientEndpointsByService[service];
        return {
            ...clientConfig,
            baseURL,
            ...config,
        };
    };
    const get = (path, config) => {
        return apiClient.get(path, getConfig(config));
    };
    const post = async (path, payload, config) => {
        return await apiClient.post(path, payload, getConfig(config));
    };
    const put = async (path, payload, config) => {
        return await apiClient.put(path, payload, getConfig(config));
    };
    const patch = async (path, payload, config) => {
        return await apiClient.patch(path, payload, getConfig(config));
    };
    const deleteFn = async (path, config) => {
        return await apiClient.delete(path, getConfig(config));
    };
    return {
        get,
        post,
        put,
        patch,
        delete: deleteFn,
    };
};
