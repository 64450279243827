import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Papaparse from 'papaparse';
import { useContext, useMemo, useState } from 'react';
import { FormSections, Input, ModalWindow, Select, Spinner, TextArea, useLocalizeMessage, } from 'libs.nucleus';
import { StudyTestPlanContext } from '../../contexts';
export const ImportTestPlanModal = ({ onClose, versions }) => {
    const translate = useLocalizeMessage();
    const { createTestPlan } = useContext(StudyTestPlanContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState({
        name: '',
        description: '',
        version: { label: '-select-', value: '', meta: {} },
    });
    const [testPlan, setTestPlan] = useState([]);
    const saveDisabled = useMemo(() => {
        return !studyTestForm.name || !studyTestForm.version.value || isLoading || !testPlan.length;
    }, [studyTestForm, isLoading, testPlan]);
    const versionOptions = useMemo(() => versions.map((version) => ({
        label: `${version.metadata.name} (${version.externalVersion})`,
        value: version.id.toString(),
        meta: {
            libraryId: version.libraryId,
            externalVersion: version.externalVersion,
            name: version.metadata.name,
        },
    })), [versions]);
    const saveTest = async () => {
        if (!saveDisabled) {
            setIsLoading(true);
            const newTestPlan = await createTestPlan({
                name: studyTestForm.name,
                description: studyTestForm.description,
                version: {
                    id: studyTestForm.version.value,
                    externalVersion: studyTestForm.version.meta.externalVersion,
                    name: studyTestForm.version.meta.name,
                    libraryId: studyTestForm.version.meta.libraryId,
                },
                csv: testPlan,
            });
            setIsLoading(false);
            if (newTestPlan) {
                onClose();
            }
        }
    };
    const savePrimaryButton = {
        label: translate('Generate'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
    };
    const handleVersionChange = (selectedVersion) => {
        setStudyTestForm((oldForm) => ({
            ...oldForm,
            version: {
                ...selectedVersion,
                meta: selectedVersion.meta || { libraryId: undefined },
            },
        }));
    };
    const onFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = e.target?.result;
                const { data } = Papaparse.parse(csv, { header: true });
                const columns = ['Step Type', 'User', 'Context', 'Execution Mode', 'User Input'];
                // keep only the columns that are present in the csv
                const filteredData = data.map((row) => {
                    const newRow = {};
                    columns.forEach((column) => {
                        newRow[column] = row[column];
                    });
                    return newRow;
                });
                setTestPlan(filteredData);
            };
            reader.readAsText(file);
        }
    };
    const formSections = [
        {
            title: translate('Test plan details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Version'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-version', label: translate('Select version'), description: translate('Select the version to validate this test plan'), onChange: handleVersionChange, options: versionOptions, required: true, value: studyTestForm.version, width: 'lg' }) })),
        },
        {
            title: translate('CSV Import'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: [_jsx("p", { className: 'text-sm text-gray-600', children: translate('Please select the CSV file that contains the test plan you want to import. The CSV file must have the following columns: Step Type, User, Context. Optional fields are Execution Mode, User Input') }), _jsx("input", { type: 'file', id: 'csv', accept: '.csv', onChange: onFileChange, required: true })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Import CSV'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
