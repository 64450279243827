import { jsx as _jsx } from "react/jsx-runtime";
import { getAnalytics, logEvent as firebaseLogEvent, setUserId, getGoogleAnalyticsClientId as fbGetGoogleAnalyticsClientId, isSupported, setAnalyticsCollectionEnabled as fbSetAnalyticsCollectionEnabled, setConsent, setDefaultEventParameters, setUserProperties, } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getPerformance } from 'firebase/performance';
import { useMemo } from 'react';
import { FirebaseAppContext, } from './firebase_app_context';
export const FirebaseAppProvider = ({ firebaseConfig, children }) => {
    const firebaseApp = useMemo(() => {
        if (!firebaseConfig) {
            throw new Error('No firebaseConfig provided');
        }
        const app = initializeApp(firebaseConfig);
        const db = getFirestore();
        if (firebaseConfig.projectId === 'demo-local') {
            connectFirestoreEmulator(db, '127.0.0.1', 8080);
            return {
                app,
                db,
                logEvent: (name, ...args) => {
                    console.groupCollapsed(`Event: ${name}`);
                    console.table(args);
                    console.groupEnd();
                },
                setAnalyticsUserId: () => { },
                getGoogleAnalyticsClientId: () => Promise.resolve(''),
                setAnalyticsCollectionEnabled: () => { },
                isAnalyticsSupported: () => Promise.resolve(false),
                setAnalyticsConsent: () => { },
                setAnalyticsDefaultEventParameters: () => { },
                setAnalyticsUserProperties: () => { },
            };
        }
        const analytics = getAnalytics(app);
        const performance = getPerformance(app);
        const logEvent = (...args) => {
            firebaseLogEvent(analytics, ...args);
        };
        const setAnalyticsUserId = (userId) => {
            setUserId(analytics, userId);
        };
        const getGoogleAnalyticsClientId = () => {
            return fbGetGoogleAnalyticsClientId(analytics);
        };
        const setAnalyticsCollectionEnabled = (enabled = true) => {
            fbSetAnalyticsCollectionEnabled(analytics, enabled);
        };
        const isAnalyticsSupported = () => {
            return isSupported();
        };
        const setAnalyticsConsent = (consentSettings) => {
            setConsent(consentSettings);
        };
        const setAnalyticsDefaultEventParameters = (customParams) => {
            setDefaultEventParameters(customParams);
        };
        const setAnalyticsUserProperties = (...args) => {
            setUserProperties(analytics, ...args);
        };
        return {
            app,
            db,
            analytics,
            logEvent,
            performance,
            setAnalyticsUserId,
            getGoogleAnalyticsClientId,
            setAnalyticsCollectionEnabled,
            isAnalyticsSupported,
            setAnalyticsConsent,
            setAnalyticsDefaultEventParameters,
            setAnalyticsUserProperties,
        };
    }, [firebaseConfig]);
    return _jsx(FirebaseAppContext.Provider, { value: firebaseApp, children: children });
};
