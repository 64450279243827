// Nucleus & Tailwind CSS classes are supported for the rich content editor
export default {
    list: {
        ol: 'list-decimal',
        ul: 'list-disc',
    },
    text: {
        italic: 'italic',
        underline: 'underline',
    },
};
