"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlainDate = void 0;
class PlainDate {
    constructor(dateIsoString) {
        this.dateIsoString = dateIsoString;
    }
    static from(dateIsoString) {
        return new PlainDate(dateIsoString);
    }
    toString() {
        return this.dateIsoString;
    }
    add({ days }) {
        const date = new Date(this.dateIsoString);
        // Use UTC functions to avoid daylight savings time changes.
        date.setUTCDate(date.getUTCDate() + (days || 0));
        // Because we're creating the date from an ISO string and converting it back after adding, timezone doesn't matter.
        return new PlainDate(date.toISOString().split('T')[0]);
    }
    static compare(one, two) {
        return one.dateIsoString < two.dateIsoString ? -1 : one.dateIsoString > two.dateIsoString ? 1 : 0;
    }
}
exports.PlainDate = PlainDate;
