import { normalizeDate } from './date.helpers';
export const isOutsideVisitWindowRange = (date, visit) => {
    if (!visit.c_window_start_date || !visit.c_window_end_date) {
        return false;
    }
    const today = normalizeDate(date);
    const windowStartDate = normalizeDate(visit.c_window_start_date);
    const windowEndDate = normalizeDate(visit.c_window_end_date);
    return today < windowStartDate || today > windowEndDate;
};
