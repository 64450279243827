/**
 * AuthRoute is intended to be used with react-router-dom library.
 * It defines the different user-facing routes that are available in the application.
 */
export var AuthRoute;
(function (AuthRoute) {
    AuthRoute["FORGOT_PASSWORD"] = "/forgot-password";
    AuthRoute["LOGIN"] = "/login";
    AuthRoute["RESET_PASSWORD"] = "/reset-password/:token";
})(AuthRoute || (AuthRoute = {}));
/**
 * AuthEndpoint is intended to contain the different endpoints that are available in the IAM Service.
 */
export var AuthEndpoint;
(function (AuthEndpoint) {
    AuthEndpoint["FORGOT_PASSWORD"] = "/v1/request-password-reset";
    AuthEndpoint["IDENTITIES"] = "/v1/identities";
    AuthEndpoint["INTROSPECT"] = "/v1/introspect";
    AuthEndpoint["LOGIN"] = "/v1/login";
    AuthEndpoint["LOGOUT"] = "/v1/logout";
    AuthEndpoint["ME"] = "/v1/identities/me";
    AuthEndpoint["RESET_PASSWORD"] = "/v1/reset-password/";
    AuthEndpoint["SSO_LOGIN"] = "/v1/sso/login";
})(AuthEndpoint || (AuthEndpoint = {}));
