import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState, useCallback } from 'react';
/**
 * This hook is used to determine if the format bar should be visible or not.
 * The format bar should be visible if the mouse is inside the editor or the format bar itself.
 * Accepts an `alwaysVisible` prop which will always keep the format bar visible.
 * @returns {boolean} isFormatBarVisible
 */
export const useFormatBarVisibility = (ref, alwaysVisible = false) => {
    const [editor] = useLexicalComposerContext();
    const [isFormatBarVisible, setIsFormatBarVisible] = useState(false);
    const handleClick = useCallback((mouseEvent) => {
        if (alwaysVisible) {
            return;
        }
        const editorRoot = editor.getRootElement();
        if (editorRoot) {
            const targetElement = document.elementFromPoint(mouseEvent.clientX, mouseEvent.clientY);
            // If mouse is inside the root element of the editor, we don't want to hide the format bar
            if (editorRoot.contains(targetElement)) {
                return setIsFormatBarVisible(true);
            }
            // If text is selected, we don't want to hide the format bar
            // Make sure selection is in the active editor otherwise all format bars on the page will be visible
            const selection = window.getSelection();
            if (selection && selection.toString().length > 0 && editorRoot.contains(selection.anchorNode)) {
                return setIsFormatBarVisible((prev) => prev);
            }
            // If mouse is inside the format bar, we don't want to hide it
            if (ref.current?.contains(targetElement)) {
                return setIsFormatBarVisible((prev) => prev);
            }
            // If mouse is inside a menu item, we don't want to hide the format bar
            if (targetElement?.getAttribute('role') === 'menuitem' || targetElement?.closest('div[role="menu"]')) {
                return setIsFormatBarVisible((prev) => prev);
            }
            // If mouse is inside the format link modal, we don't want to hide the format bar
            if (targetElement?.closest('div[data-testid="format-link-modal"]')) {
                return setIsFormatBarVisible((prev) => prev);
            }
            if (targetElement?.closest('div[data-testid="upload-image-modal"]')) {
                return setIsFormatBarVisible((prev) => prev);
            }
            setIsFormatBarVisible(false);
        }
    }, [editor, alwaysVisible]);
    useEffect(() => {
        if (alwaysVisible) {
            return;
        }
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [handleClick, alwaysVisible]);
    return alwaysVisible || isFormatBarVisible;
};
