import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Pill } from 'libs.nucleus';
import { DeploymentUtils } from '../../utils/deployment';
export const EnvironmentStatus = ({ environment }) => {
    const translate = useLocalizeMessage();
    const deploymentStatus = DeploymentUtils.getEnvironmentStatus(environment) || 'expired';
    const statusMap = {
        active: { iconColor: 'yellow', label: translate('Running') },
        completed: { iconColor: 'green', label: translate('Success') },
        expired: { iconColor: 'gray', label: translate('Expired') },
        failed: { iconColor: 'red', label: translate('Failed') },
    };
    const { label, iconColor } = statusMap[deploymentStatus];
    return _jsx(Pill, { label: label, iconBackgroundColor: iconColor, variant: 'status' });
};
