import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { ParticipantFormModal } from '../../components/participant_form/participant_form_modal.component';
import { ParticipantsTable } from '../../components/participants_table/participants_table.component';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const ParticipantsList = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const [isParticipantFormOpen, setParticipantFormOpen, setParticipantFormClose] = useBooleanState();
    const getParticipantsData = async () => {
        if (!environment) {
            return [];
        }
        const response = await getApiInstance(environment).get(`/v2/routes/site_subjects/${site?._id}`, {
            params: {
                sort: JSON.stringify({ created: -1 }),
            },
        });
        return response?.data?.data || [];
    };
    const headerOptions = [
        { name: translate('New Participant'), onClick: setParticipantFormOpen, variant: 'primary' },
    ];
    const { data: participants, isLoading } = useQuery({
        queryKey: ['participantsList', environment?.id],
        queryFn: getParticipantsData,
        enabled: true,
    });
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Participants'), options: headerOptions }), _jsx(ParticipantFormModal, { isModalOpen: isParticipantFormOpen, onClose: setParticipantFormClose }), _jsx(ParticipantsTable, { participants: participants, isLoading: isLoading })] }));
};
