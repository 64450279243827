import { createContext } from 'react';
export const FirebaseAppContext = createContext({
    app: {},
    db: undefined,
    analytics: undefined,
    logEvent: () => {
        throw Error('Missing the firebase app provider');
    },
    setAnalyticsUserId: () => {
        throw Error('Missing the firebase app provider');
    },
    performance: undefined,
    getGoogleAnalyticsClientId: () => {
        throw Error('Missing the firebase app provider');
    },
    setAnalyticsCollectionEnabled: () => {
        throw Error('Missing the firebase app provider');
    },
    isAnalyticsSupported: () => {
        throw Error('Missing the firebase app provider');
    },
    setAnalyticsConsent: () => {
        throw Error('Missing the firebase app provider');
    },
    setAnalyticsDefaultEventParameters: () => {
        throw Error('Missing the firebase app provider');
    },
    setAnalyticsUserProperties: () => {
        throw Error('Missing the firebase app provider');
    },
});
