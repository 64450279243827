import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocalizeMessage, ModalWindow } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { StudyTestPlansTable } from '../study_test_plans';
export const CloneTestPlanModal = ({ onClose, onContinue, versions }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const [selectedTestPlan, setSelectedTestPlan] = useState(null);
    const onSelect = (id) => {
        setSelectedTestPlan(id);
    };
    const onSave = () => {
        if (selectedTestPlan) {
            onContinue(selectedTestPlan);
        }
        else {
            addNotification({
                type: 'error',
                title: translate('No test plan selected'),
                subtitle: translate('Please select a test plan to continue.'),
            });
        }
    };
    const savePrimaryButton = {
        label: translate('Continue'),
        onClick: onSave,
        disabled: !selectedTestPlan,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    return (_jsx(ModalWindow, { title: translate('Select test plan'), subtitle: translate('Select a test plan to clone'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: _jsx(StudyTestPlansTable, { versions: versions, showSelect: true, hideActions: true, selected: selectedTestPlan, onSelect: onSelect }) }));
};
