import { useContext, useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useLocalizeMessage } from 'libs.nucleus';
import { AuthContext } from '../../contexts';
/**
 * Hook to encapsulate the logic of the email field
 * Allowing us to reuse it in different components and provide a seamless experience when validating the email
 */
export const useEmailField = (initialState = '') => {
    const { setError } = useContext(AuthContext);
    const translate = useLocalizeMessage();
    const [email, setEmail] = useState(initialState);
    const [emailTouched, setEmailTouched] = useState(false);
    const [emailError, setEmailError] = useState();
    useEffect(() => {
        if (!email || isEmail(email)) {
            setEmailError(undefined);
        }
        else {
            setEmailError(translate('Invalid email address'));
        }
    }, [email]);
    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const onEmailBlur = () => {
        setEmailTouched(true);
        setError(undefined);
    };
    return {
        email,
        emailError,
        emailHasError: !!emailError,
        emailTouched,
        onEmailBlur,
        onEmailChange,
    };
};
