import { useLocation, useSearchParams } from 'react-router-dom';
/**
 * Hook to check whether this asset is being used under library or studies
 */
export function useAssetContext() {
    const { pathname } = useLocation();
    const [params] = useSearchParams();
    const assetType = params.get('assetType');
    const isLibrary = pathname.startsWith('/library/');
    const isStudy = pathname.startsWith('/studies/');
    const getReviewUrl = () => {
        return isStudy ? 'review' : 'translation';
    };
    return { isLibrary, isStudy, getReviewUrl, assetType };
}
