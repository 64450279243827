import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { AuthContext, ApiClientService, useApiClient } from 'libs.react';
export const EnvironmentsContext = createContext({
    isLoading: true,
    environments: [],
});
export const EnvironmentsProvider = ({ children }) => {
    const { entityId, user } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchEnvironmentsWithStudy = async () => {
        const response = await Promise.all([
            momClient.get(`/v1/entities/${entityId}/studies`),
            momClient.get(`/v1/entities/${entityId}/environments`),
        ]);
        const studies = response?.[0]?.data?.data || [];
        const environments = response?.[1]?.data?.data || [];
        return environments.map((environment) => {
            const study = studies.find((s) => s.id === environment.studyId);
            return { ...environment, study };
        });
    };
    const { data: environments = [], isLoading } = useQuery({
        queryKey: ['entities', entityId, 'environments'],
        queryFn: fetchEnvironmentsWithStudy,
        enabled: !!user && !!entityId,
    });
    const value = useMemo(() => ({
        isLoading,
        environments,
    }), [isLoading, environments]);
    return _jsx(EnvironmentsContext.Provider, { value: value, children: children });
};
