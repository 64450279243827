import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { FormSections, useLocalizeMessage, ModalWindow, Select, Toggle, Checkbox, } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
import { StudyConfigurationContext } from '../../../contexts';
export const StudySettingsEdit = ({ onCancel, onSave }) => {
    const { currentStudy, isLoading } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const [isPristine, setIsPristine] = useState(true);
    const [studyMetadata, setStudyMetadata] = useState(currentStudy.studyMetadata);
    const participantStatusOptions = useMemo(() => {
        const statuses = currentStudy.participantStatuses.map(({ value, id }) => ({ label: value, value: id }));
        return [{ value: '', label: translate('-select-') }, ...statuses];
    }, []);
    const [selectedConsentStatus, setSelectedConsentStatus] = useState(() => participantStatusOptions.find((status) => status.value === currentStudy.subjectEnrollmentStatusId) ||
        participantStatusOptions[0]);
    const isEditEnabled = !isLoading && !isPristine && (!studyMetadata.isEConsentEnabled || studyMetadata.subjectEnrollmentStatusId);
    const onConsentStatusChange = (enrollmentStatus) => {
        setSelectedConsentStatus(enrollmentStatus);
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            subjectEnrollmentStatusId: enrollmentStatus.value || undefined,
        }));
        setIsPristine(false);
    };
    const onIsEConsentEnabledChange = (isEConsentEnabled) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, isEConsentEnabled }));
        setIsPristine(false);
    };
    const onTelevisitChange = (isTelevisitEnabled) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, isTelevisitEnabled }));
        setIsPristine(false);
    };
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: () => onSave(studyMetadata),
        disabled: !isEditEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const handleSave = (event) => {
        event.preventDefault();
        if (isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const formSections = useMemo(() => {
        const listItems = [
            {
                title: translate('Enable eConsent'),
                description: translate('Use Medable to facilitate the informed consent process'),
                actionContent: (_jsx(Toggle, { checked: studyMetadata.isEConsentEnabled, dataTestId: 'edit-settings-enable-econsent', disabled: currentStudy.isNoPIIEnabled, onChange: onIsEConsentEnabledChange })),
            },
        ];
        return [
            {
                title: translate('Settings'),
                content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(ListInformation, { items: listItems, descriptionColor: 'secondary' }), _jsx(Select, { dataTestId: 'edit-settings-consent-status', description: translate('Select the status that will trigger upon completion of an enrolling consent'), disabled: !studyMetadata.isEConsentEnabled, label: translate('Consent status'), onChange: onConsentStatusChange, options: participantStatusOptions, required: studyMetadata.isEConsentEnabled, value: selectedConsentStatus, width: 'full' }), _jsx(Checkbox, { label: translate('Adhoc televisits'), description: translate('Enabling this will allow televisit to be used adhoc at any time for sites and participants throughout the study'), checked: studyMetadata.isTelevisitEnabled, onChange: onTelevisitChange, dataTestId: 'edit-settings-adhoc-televisits' })] })),
            },
        ];
    }, [studyMetadata]);
    return (_jsx(ModalWindow, { title: translate('Edit settings'), subtitle: currentStudy.name, isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: _jsx("form", { onSubmit: handleSave, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
