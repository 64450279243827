import { QueryClientContext, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { AuthContext, ApiClientService, useApiClient } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useEnvironments = () => {
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const queryClient = useContext(QueryClientContext);
    const fetchEnvironments = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments`);
        return response.data;
    };
    const { isFetching, isLoading, isError, error, data: environments = [], } = useQuery({
        queryKey: ['entities', entityId, 'studies', studyId, 'environments'],
        queryFn: fetchEnvironments,
        enabled: !!entityId,
    });
    const invalidateEnvironments = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'studies', studyId, 'environments'] });
    };
    const environmentOptions = useMemo(() => {
        if (isEmpty(environments)) {
            return [];
        }
        return environments.map((environment) => ({
            value: environment?.id?.toString(),
            label: environment?.name,
        }));
    }, [environments]);
    return {
        isFetching,
        isLoading,
        isError,
        error,
        environments,
        environmentOptions,
        invalidateEnvironments,
    };
};
