import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useRef, useCallback } from 'react';
import { useImagesContext } from '../contexts/images_context';
import { useLinkTargetContext } from '../contexts/link_target_context';
import { transformValue } from '../utils';
export const CustomOnChange = ({ onChange, id, }) => {
    const [editor] = useLexicalComposerContext();
    const { images } = useImagesContext();
    const { retainTarget } = useLinkTargetContext();
    const raf = useRef(null);
    const handleChange = useCallback((editorState) => {
        if (!onChange) {
            return;
        }
        editorState.read(() => {
            try {
                const value = transformValue($generateHtmlFromNodes(editor, null), retainTarget);
                onChange({ id, value, images });
            }
            catch (error) {
                console.error('Error processing editor state:', error);
            }
        });
    }, [editor, id, onChange, images]);
    useEffect(() => {
        const unregisterListener = editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves }) => {
            if (raf.current) {
                cancelAnimationFrame(raf.current);
            }
            raf.current = requestAnimationFrame(() => {
                if (dirtyElements.size || dirtyLeaves.size) {
                    handleChange(editorState);
                }
            });
        });
        return () => {
            unregisterListener();
            if (raf.current) {
                cancelAnimationFrame(raf.current);
                raf.current = null;
            }
        };
    }, [editor, handleChange]);
    return null;
};
