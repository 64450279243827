import { useContext, useEffect, useRef } from 'react';
import { AuthContext, ApiClientService, useApiClient } from 'libs.react';
import { DEPLOYMENT_STATUS } from '../../constants';
export const useJobChecker = () => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const intervalId = useRef();
    /**
     * Clean up the interval when the component is unmounted
     */
    useEffect(() => {
        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, []);
    const checkJobStatus = async (jobId, { interval = 1000, onInterval } = {}) => {
        const initialJobResponse = await getJobStatus(jobId);
        if (initialJobResponse.status === DEPLOYMENT_STATUS.CREATED) {
            return await new Promise((resolve) => {
                const endPolling = (response) => {
                    clearInterval(intervalId.current);
                    if (response) {
                        resolve(response);
                    }
                };
                intervalId.current = setInterval(async () => {
                    const jobResponse = await getJobStatus(jobId);
                    onInterval && onInterval(jobId, jobResponse, endPolling);
                    if (jobResponse.status === DEPLOYMENT_STATUS.COMPLETED || jobResponse.status === DEPLOYMENT_STATUS.FAILED) {
                        endPolling(jobResponse);
                    }
                }, interval);
            });
        }
        return initialJobResponse;
    };
    const getJobStatus = async (jobId) => {
        const { data: statusResponse } = await momClient.get(`/v1/entities/${entityId}/workflows/${jobId}`);
        return statusResponse.data;
    };
    return { checkJobStatus, getJobStatus };
};
