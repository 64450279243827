import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, useLocalizeMessage, Text } from 'libs.nucleus';
const MULTI_VALUE_KEYS = ['Package Versions', 'Self Service Information'];
export const StudySummaryMetadata = ({ metadata }) => {
    const translate = useLocalizeMessage();
    const formatCellValue = (value, key) => {
        if (MULTI_VALUE_KEYS.includes(key) && typeof value === 'string') {
            return value.split(';').map((v) => (_jsx(Text, { size: 'sm', children: v }, `${key}-${v}`)));
        }
        if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }
        return String(value);
    };
    const columns = [
        {
            header: translate('Key'),
            accessorKey: 'key',
        },
        {
            header: translate('Value'),
            accessorKey: 'value',
            cell: ({ row }) => formatCellValue(row.original.value, row.original.key),
        },
    ];
    const metadataArray = Object.entries(metadata).map(([key, value]) => ({
        key,
        value: value?.toString() ?? '',
    }));
    return (_jsxs("div", { className: 'mb-10', children: [_jsx("h3", { className: 'text-xl font-semibold text-gray-800 mb-3', children: translate('Metadata') }), _jsx(Table, { data: metadataArray, columns: columns })] }));
};
