import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Locale } from 'libs.i18n-core';
import { DirContainer, usePreviewComponent } from 'libs.localization.react';
import { DevicePreview } from 'libs.preview';
import { useDeviceScale } from '../../hooks/device_scale.hook';
export const AssetTranslationScreen = ({ previewOptions, basePreview, localePreview, baseProductMessages, localeProductMessages, }) => {
    const baseLocale = Locale.en_US;
    const { locale = baseLocale } = useParams();
    const wrapperRef = useRef(null);
    const isComparisonMode = baseLocale !== locale;
    const PreviewComponent = usePreviewComponent(previewOptions);
    useDeviceScale(wrapperRef, isComparisonMode);
    return (_jsx("div", { ref: wrapperRef, children: _jsxs("div", { className: 'mb-8 flex gap-4 justify-center w-fit', children: [basePreview && (_jsx(DevicePreview, { showTopbar: false, children: _jsx(DirContainer, { locale: baseLocale, children: _jsx(PreviewComponent, { productMessages: baseProductMessages, messages: basePreview }) }) })), isComparisonMode && localePreview && (_jsx(DevicePreview, { showTopbar: false, children: _jsx(DirContainer, { locale: locale, children: _jsx(PreviewComponent, { productMessages: localeProductMessages, messages: localePreview }) }) }))] }) }));
};
