import { jsx as _jsx } from "react/jsx-runtime";
export const Card = ({ children }) => {
    return _jsx("div", { className: `w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm`, children: children });
};
export const GrayCard = ({ children }) => {
    return _jsx("div", { className: `w-full p-4 bg-neutral-100 rounded-lg sm:p-8`, children: children });
};
export const ActivitiesCard = ({ children }) => {
    return _jsx("div", { className: `w-full bg-white border border-gray-200 divide-y rounded-lg shadow-sm`, children: children });
};
