"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = void 0;
exports.Colors = {
    primary: '#5F46CF',
    primary_hover: '#432E8F',
    primary_active: '#32236C',
    secondary: '#FFFFFF',
    secondary_hover: '#E5E7EB',
    secondary_active: '#D1D5DB',
    disabled: '#E5E7EB',
    text_disabled: '#9CA3AF',
    text: '#111827',
    border: '#ccc',
    error: '#B91C1C',
};
