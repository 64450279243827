import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { RosettaSDKUtils } from '@axon/rosetta-sdk';
import { Checkbox, FormSections, useLocalizeMessage, Input, ModalWindow, TextArea } from 'libs.nucleus';
import { StudyConfigurationContext } from '../../../../contexts';
export const ParticipantStatusModal = ({ onClose, initialParticipantStatus, onSave }) => {
    const translate = useLocalizeMessage();
    const { currentStudy } = useContext(StudyConfigurationContext);
    const [hasNameError, setHasNameError] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [participantStatus, setParticipantStatus] = useState(initialParticipantStatus || RosettaSDKUtils.createParticipantStatus({ value: '', description: '' }));
    const isDefaultEdition = useMemo(() => participantStatus.isDefault, []);
    const isSaveEnabled = !isPristine && participantStatus.value && !hasNameError;
    /**
     * Handles the save of the participant status only if the save is enabled
     */
    const handleOnSave = async () => {
        if (isSaveEnabled) {
            onSave(participantStatus);
        }
    };
    /**
     * Handles the change of the default checkbox and updates the participant status information
     */
    const onDefaultChange = useCallback((value) => {
        setParticipantStatus((prev) => ({ ...prev, isDefault: value }));
        setIsPristine(false);
    }, []);
    /**
     * Handles the changes on any input or textarea,
     * in order for it to work, the id of the input/textarea needs to match with the key of the participantStatus
     */
    const handleInputChange = useCallback((event) => {
        const { id, value } = event.target;
        if (id === 'value') {
            validateStatusName(value);
        }
        setParticipantStatus((prevStudyDetails) => ({ ...prevStudyDetails, [id]: value }));
        setIsPristine(false);
    }, []);
    const validateStatusName = (value) => {
        setHasNameError(currentStudy.participantStatuses.some((status) => status.value.toLowerCase().trim() === value.toLowerCase().trim() && status.id !== participantStatus.id));
    };
    /**
     * Handles the form submit
     */
    const onSubmit = (event) => {
        event.preventDefault();
        handleOnSave();
    };
    /**
     * Handles the enter key press to submit the form
     * As we have a textarea, we need to check if the shift key is pressed to avoid submitting the form
     */
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleOnSave();
        }
    };
    const cancelButton = { label: translate('Cancel'), onClick: onClose };
    const saveButton = {
        label: initialParticipantStatus ? translate('Save') : translate('Create'),
        onClick: handleOnSave,
        disabled: !isSaveEnabled,
    };
    const formSections = [
        {
            title: translate('Details'),
            description: translate('Enter a status name and status description and choose whether it is the default status.'),
            content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(Input, { dataTestId: 'participant-status-modal-name', errorMessage: translate('This status name is already in use'), hasError: hasNameError, id: 'value', label: translate('Status'), onChange: handleInputChange, value: participantStatus.value, containLabels: true, width: 'full' }), _jsx(TextArea, { dataTestId: 'participant-status-modal-description', disabled: participantStatus.isBuiltIn, id: 'description', label: translate('Description'), onChange: handleInputChange, value: participantStatus.description, width: 'full' }), _jsx(Checkbox, { checked: participantStatus.isDefault || false, disabled: isDefaultEdition, onChange: onDefaultChange, label: translate('Set as default status') })] })),
        },
    ];
    return (_jsx(ModalWindow, { title: initialParticipantStatus ? translate('Edit participant status') : translate('Create participant status'), isOpen: true, closeWindow: onClose, footerSecondaryActionButtons: [cancelButton], footerPrimaryActionButton: saveButton, width: 'full', children: _jsx("form", { onKeyUp: handleEnterPress, onSubmit: onSubmit, children: _jsx(FormSections, { sections: formSections }) }) }));
};
