"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlainTime = void 0;
/**
 * This implementation is based on the Temporal PlainTime proposal, but strips the seconds part of the time value,
 * as they are not used in eCOAs and it simplifies the implementation.
 */
class PlainTime {
    constructor(timeString) {
        // In order to properly compare values that have 00 seconds with values that don't include seconds, normalize the
        // value by removing seconds.
        this.timeString = timeString.replace(/(\d\d:\d\d):\d\d/, '$1');
        // If we ever decide to include seconds, the correct code to normalize the value is:
        // this.dateTimeIsoString = dateTimeIsoString.replace(/(\d\d:\d\d(?!:))/, '$1:00');
    }
    static from(timeString) {
        return new PlainTime(timeString);
    }
    toString() {
        return this.timeString;
    }
}
exports.PlainTime = PlainTime;
