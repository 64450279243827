import { jsx as _jsx } from "react/jsx-runtime";
import FloatingRect from './spreadsheet_floating_rect';
import { useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { getSelectedDimensions } from '../../utils/spreadsheet';
const Selected = () => {
    const selected = useSpreadsheetSelector((state) => state.selected);
    const dimensions = useSpreadsheetSelector((state) => selected && getSelectedDimensions(state.rowDimensions, state.columnDimensions, state.data, state.selected));
    const dragging = useSpreadsheetSelector((state) => state.dragging);
    const hidden = useSpreadsheetSelector((state) => state.selected.size(state.data) < 2);
    return _jsx(FloatingRect, { variant: 'selected', dimensions: dimensions, dragging: dragging, hidden: hidden });
};
export default Selected;
