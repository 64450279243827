import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useEnvironmentSites = () => {
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchEnvironmentSites = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/sites`);
        return response?.data || [];
    };
    const { isFetching, isLoading, isError, error, data } = useQuery({
        queryKey: ['entities', entityId.toString(), 'studies', studyId.toString(), 'sites'],
        queryFn: fetchEnvironmentSites,
        placeholderData: keepPreviousData,
    });
    return {
        isFetching,
        isLoading,
        isError,
        error,
        environmentSites: data || [],
    };
};
