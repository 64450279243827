export class ConsoleFlagConnector {
    constructor(flagManager) {
        this.flagManager = flagManager;
        this.flags = flagManager.getFlags();
        window.listFlags = () => this.listFlags();
        window.setFlag = (flagId, value) => this.setFlag(flagId, value);
        window.clearOverrides = () => this.clearOverrides();
        console.info('Flag console cli enabled');
        console.info(' listFlags(): lists all flags');
        console.info(' setFlag([flagId, value]): sets a flag value');
        console.info(' clearOverrides(): clears all overrides');
    }
    notifyUpdate(flag, newValue) {
        console.info(`Flag '${flag.description}' updated with value ${newValue}`);
    }
    listFlags() {
        for (const flagId in this.flags) {
            const flag = this.flags[flagId];
            const value = this.flagManager.getValue(flag);
            console.info(`id:${flagId} value:${value}${value !== flag.value ? '[overriden]' : ''} description:'${flag.description}' owner:${flag.owner}`);
        }
    }
    setFlag(flagId, value) {
        this.flagManager.setValue(this.flags[flagId], value);
    }
    clearOverrides() {
        for (const flagId in this.flags) {
            const flag = this.flags[flagId];
            const value = this.flagManager.getValue(flag);
            if (value === flag.value)
                continue;
            this.flagManager.setValue(flag, flag.value);
        }
    }
}
