import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { moveCursorToEnd } from '../../utils/spreadsheet';
/** The default Spreadsheet DataEditor component */
const DataEditor = ({ onChange, cell }) => {
    const inputRef = React.useRef(null);
    const handleChange = React.useCallback((event) => {
        onChange({ ...cell, value: event.target.value });
    }, [onChange, cell]);
    React.useEffect(() => {
        if (inputRef.current) {
            moveCursorToEnd(inputRef.current);
        }
    }, [inputRef]);
    const value = cell?.value ?? '';
    return (_jsx("div", { className: SpreadsheetStyles.dataEditor, children: _jsx("input", { ref: inputRef, type: 'text', onChange: handleChange, value: value, autoFocus: true, className: SpreadsheetStyles.dataEditorInput }) }));
};
export default DataEditor;
