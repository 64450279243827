// Action types
export var Actions;
(function (Actions) {
    Actions["UPDATE_INITIAL_STATE"] = "UPDATE_INITIAL_STATE";
    Actions["SET_DATA"] = "SET_DATA";
    Actions["SELECT_ENTIRE_ROW"] = "SELECT_ENTIRE_ROW";
    Actions["SELECT_ENTIRE_COLUMN"] = "SELECT_ENTIRE_COLUMN";
    Actions["SELECT_ENTIRE_WORKSHEET"] = "SELECT_ENTIRE_WORKSHEET";
    Actions["SET_SELECTION"] = "SET_SELECTION";
    Actions["SELECT"] = "SELECT";
    Actions["ACTIVATE"] = "ACTIVATE";
    Actions["SET_CELL_DATA"] = "SET_CELL_DATA";
    Actions["SET_CELL_DIMENSIONS"] = "SET_CELL_DIMENSIONS";
    Actions["COPY"] = "COPY";
    Actions["CUT"] = "CUT";
    Actions["PASTE"] = "PASTE";
    Actions["EDIT"] = "EDIT";
    Actions["VIEW"] = "VIEW";
    Actions["CLEAR"] = "CLEAR";
    Actions["BLUR"] = "BLUR";
    Actions["KEY_PRESS"] = "KEY_PRESS";
    Actions["KEY_DOWN"] = "KEY_DOWN";
    Actions["DRAG_START"] = "DRAG_START";
    Actions["DRAG_END"] = "DRAG_END";
    Actions["COMMIT"] = "COMMIT";
    Actions["SHOW_CONTEXT_MENU"] = "SHOW_CONTEXT_MENU";
    Actions["HIDE_CONTEXT_MENU"] = "HIDE_CONTEXT_MENU";
    Actions["MOVE_ROW"] = "MOVE_ROW";
    Actions["INSERT_ROW"] = "INSERT_ROW";
    Actions["DELETE_ROW"] = "DELETE_ROW";
    Actions["SHOW_SELECT"] = "SHOW_SELECT";
    Actions["HIDE_SELECT"] = "HIDE_SELECT";
    Actions["CLEAR_OTHER_CELLS"] = "CLEAR_OTHER_CELLS";
    Actions["RESET_STATE"] = "RESET_STATE";
})(Actions || (Actions = {}));
