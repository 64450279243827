import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import SpreadsheetStyles from './spreadsheet_styles';
export const TRUE_TEXT = 'TRUE';
export const FALSE_TEXT = 'FALSE';
/** The default Spreadsheet DataViewer component */
const DataViewer = ({ cell, }) => {
    const value = getValue(cell);
    return typeof value === 'boolean' ? (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerBoolean), children: convertBooleanToText(value) })) : (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth), children: value }));
};
export default DataViewer;
function getValue(cell) {
    const baseValue = cell?.value;
    if (baseValue && typeof baseValue === 'object') {
        return baseValue.toString();
    }
    return baseValue;
}
export function convertBooleanToText(value) {
    return value ? TRUE_TEXT : FALSE_TEXT;
}
