import { AssetTypes } from 'libs.localization.common';
export const getProtocolScheduleAsset = (study) => ({
    type: AssetTypes.PROTOCOL_SCHEDULE,
    id: study.id,
    version: '1',
    messages: [
        {
            namespace: 'protocol_schedule',
            messages: Object.values(study.getAllVisits()).reduce((acc, encounter) => ({ ...acc, [encounter.id]: encounter.name }), {}),
        },
    ],
    preview: {
        engine: 'react',
        id: AssetTypes.PROTOCOL_SCHEDULE,
        default_viewport: 'iPadLandscape',
        info: {
            display_name: '',
            list: [],
        },
    },
});
