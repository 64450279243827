import { jsx as _jsx } from "react/jsx-runtime";
import FloatingRect from './spreadsheet_floating_rect';
import { useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { getRangeDimensions } from '../../utils/spreadsheet';
const Copied = () => {
    const range = useSpreadsheetSelector((state) => state.copied);
    const dimensions = useSpreadsheetSelector((state) => range && getRangeDimensions(state.rowDimensions, state.columnDimensions, range));
    const hidden = range === null;
    return _jsx(FloatingRect, { variant: 'copied', dimensions: dimensions, hidden: hidden, dragging: false });
};
export default Copied;
