import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Input, Link, Text, useLocalizeMessage } from 'libs.nucleus';
import { AuthWrapper } from '../../components';
import { AuthContext, AuthRoute, useEmailField } from '../../features';
import { useBooleanState } from '../../hooks';
export const LoginPage = () => {
    const translate = useLocalizeMessage();
    const { getIDPConfiguration, login, error, redirectToDefaultPage, setError, ssoLogin } = useContext(AuthContext);
    const passwordRef = useRef(null);
    const { email, emailError, emailHasError, emailTouched, onEmailBlur, onEmailChange } = useEmailField();
    const [password, setPassword] = useState('');
    const [isPasswordVisible, showPassword, hidePassword] = useBooleanState(false);
    const isNextDisabled = isPasswordVisible ? !password : !email || emailHasError;
    const onPasswordChange = (event) => {
        setPassword(event.target.value);
        setError(undefined);
    };
    const onNext = async () => {
        if (!isNextDisabled) {
            if (isPasswordVisible) {
                await login(email, password);
                await redirectToDefaultPage();
            }
            else {
                validateEmail();
            }
        }
    };
    const validateEmail = async () => {
        const ipdList = await getIDPConfiguration(email);
        if (ipdList.length > 0) {
            await ssoLogin(ipdList[0], email);
        }
        else {
            showPassword();
            passwordRef.current?.focus();
        }
    };
    const onSignUpClick = () => {
        const url = 'https://signup.medable.com/';
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (_jsx(AuthWrapper, { onSubmit: onNext, error: error, title: translate('Sign in to your account'), children: _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { "data-testid": 'login-email-input', errorMessage: emailError, hasError: emailTouched && emailHasError, id: 'email', label: translate('Email'), onBlur: onEmailBlur, onChange: onEmailChange, required: true, hideRequiredIndicator: true, value: email, width: 'full' }), isPasswordVisible && (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'relative', children: [_jsx("div", { className: 'absolute right-0 top-0', children: _jsx(Link, { LinkComponent: RouterLink, to: AuthRoute.FORGOT_PASSWORD, children: translate('Forgot password?') }) }), _jsx(Input, { "data-testid": 'login-password-input', id: 'password', label: translate('Password'), onChange: onPasswordChange, ref: passwordRef, required: true, hideRequiredIndicator: true, type: 'password', value: password, width: 'full' })] }), _jsx(Button, { label: translate('Sign in'), disabled: isNextDisabled })] })), !isPasswordVisible && _jsx(Button, { label: translate('Continue'), disabled: isNextDisabled }), _jsxs("div", { className: 'flex items-center max-w-[27.5rem]', children: [_jsx("div", { className: 'border-b w-full' }), _jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('or') }), _jsx("div", { className: 'border-b w-full' })] }), isPasswordVisible ? (_jsx(Button, { type: 'button', label: translate('Sign in with SSO'), onClick: hidePassword, variant: 'secondary' })) : (_jsx(Button, { type: 'button', label: translate('Sign in with password'), onClick: showPassword, variant: 'secondary' })), _jsxs("div", { className: 'flex gap-1 text-sm self-center mt-10', children: [translate("Don't have an account?"), _jsx(Link, { to: '#', onClick: onSignUpClick, children: translate('Sign up') })] })] }) }));
};
