import styled from 'styled-components';
export const PreviewSection = styled.section `
  margin: ${(props) => (props.$noFrame ? '0' : '1em')};
`;
export const IFrame = styled.iframe `
  border: ${(props) => (props.$noFrame ? '0' : '1px solid rgba(209, 213, 219, 1)')};
  border-radius: ${(props) => (props.$noFrame ? '0' : '6px')};
  box-shadow: ${(props) => props.$noFrame ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.06),0px 1px 3px 0px rgba(0, 0, 0, 0.1)'};
  transition: all 300ms ease-in-out;
  transform-origin: top left;
`;
export const Message = styled.div `
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: grid;
  place-items: center;
  border: ${(props) => (props.$noFrame ? '0' : '1px solid rgba(209, 213, 219, 1)')};
  border-radius: ${(props) => (props.$noFrame ? '0' : '6px')};
  box-shadow: ${(props) => props.$noFrame ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.06),0px 1px 3px 0px rgba(0, 0, 0, 0.1)'};
  transition: all 300ms ease-in-out;
  transform-origin: top left;
`;
export const DeviceSelectorWrapper = styled.div `
  display: flex;
  gap: 1.5em;
`;
export const ZoomControlWrapper = styled.div `
  padding: 0.5em;
  display: flex;
  gap: 16px;
`;
/**
 * The container styles should match the report generation styles.
 * Any updates made to the container should also be reflected in the report generation styles under the getSingleScreenHTMLTemplate function, located at:
 * medable/libs/screenshot_report/src/screenshot_report.ts.
 *
 * The font-family is set to match the font-family used in the Patient web app.
 * */
export const DevicePreviewContainer = styled.div `
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  ol {
    list-style: revert;
  }
`;
