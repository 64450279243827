import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus';
import { PageHeader, PageWrapper } from 'libs.react';
import { HelpSupportLinks } from '../../components/help_support/help_support_links.component';
import { HelpSupportQrCode } from '../../components/help_support/help_support_qr_code.component';
import { StudyResourcesList } from '../../components/help_support/study_resources_list.component';
export const HelpSupportPage = () => {
    const translate = useLocalizeMessage();
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Resources') }), _jsxs("div", { className: 'flex flex-col gap-8', children: [_jsx(HelpSupportLinks, {}), _jsx(HelpSupportQrCode, {}), _jsx(StudyResourcesList, {})] })] }));
};
