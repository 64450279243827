import { keepPreviousData, QueryClientContext, useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { ApiClientService, DEFAULT_PAGE_SIZE, LibraryEndpoint, LibraryResourceStatus, useApiClient, } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useStudyVersion = ({ pageSize = DEFAULT_PAGE_SIZE, search = '' } = {}) => {
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const { studyId } = useContext(StudyConfigurationContext);
    const queryClient = useContext(QueryClientContext);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
    const retrieveVersions = async () => {
        const searchParams = new URLSearchParams({
            limit: pageSize.toString(),
            page: (pagination.pageIndex + 1).toString(),
            studyId,
            ...(search && { search }),
        });
        searchParams.append('status', LibraryResourceStatus.APPROVED);
        searchParams.append('status', LibraryResourceStatus.PUBLISHED);
        const endpoint = `${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`;
        const { data: response } = await libraryClient.get(endpoint);
        const versions = response.data.map((version) => ({
            ...version,
            ...(typeof version.metadata === 'string' && { metadata: JSON.parse(version.metadata) }),
        }));
        return { versions, totalPages: Math.ceil(response.total / pagination.pageSize) };
    };
    const { data = { totalPages: 0, versions: [] }, isError, isFetching, isLoading, } = useQuery({
        queryKey: ['study-configs', studyId, { page: pagination.pageIndex + 1 }, search],
        queryFn: retrieveVersions,
        placeholderData: keepPreviousData,
    });
    const invalidateVersions = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['study-configs', studyId] });
    };
    return {
        invalidateVersions,
        isError,
        isFetching,
        isLoading,
        pagination,
        setPagination,
        totalPages: data.totalPages,
        versions: data.versions,
    };
};
