/**
 * Interface for ranges between two points
 */
/** Range between two points. Creates a normalized range between two given points */
export class PointRange {
    /** The top-left point */
    start;
    /** The bottom-right point */
    end;
    constructor(source, target) {
        this.start = {
            row: Math.min(source.row, target.row),
            column: Math.min(source.column, target.column),
        };
        this.end = {
            row: Math.max(source.row, target.row),
            column: Math.max(source.column, target.column),
        };
    }
    /** Iterates through all the existing points in given range */
    *[Symbol.iterator]() {
        for (let { row } = this.start; row <= this.end.row; row++) {
            for (let { column } = this.start; column <= this.end.column; column++) {
                yield { row, column };
            }
        }
    }
    /** Returns the size (rows x columns) of the given range */
    size() {
        const rows = this.end.row + 1 - this.start.row;
        const columns = this.end.column + 1 - this.start.column;
        return rows * columns;
    }
    /** Returns whether given point exists in given range */
    has(point) {
        return (point.row >= this.start.row &&
            point.column >= this.start.column &&
            point.row <= this.end.row &&
            point.column <= this.end.column);
    }
    /** Limits given masked range with given mask */
    mask(mask) {
        return new PointRange({
            row: mask.start.row > this.start.row ? mask.start.row : this.start.row,
            column: mask.start.column > this.start.column ? mask.start.column : this.start.column,
        }, {
            row: mask.end.row < this.end.row ? mask.end.row : this.end.row,
            column: mask.end.column < this.end.column ? mask.end.column : this.end.column,
        });
    }
    /** Returns whether given range is equal to this range */
    equals(range) {
        return (this.start.row === range.start.row &&
            this.start.column === range.start.column &&
            this.end.row === range.end.row &&
            this.end.column === range.end.column);
    }
}
