import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, ModalWindow, Spinner, Tabs } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState, useTabNavigation } from 'libs.react';
import { ActivitiesList } from '../../components/activities_list/activities_list.component';
import { ActivityView } from '../../components/activity/activity_modal/activity_view';
import { VisitActivityResponses } from '../../components/visit_activity_responses/visit_activity_responses.component';
import { VisitDetails } from '../../components/visit_details/visit_details.component';
import { getApiInstance } from '../../helpers/api.helpers';
import { getRouteLink } from '../../helpers/links.helpers';
import { getParticipantDetails, getParticipantNumber, isParticipantDeactivated, } from '../../helpers/participants.helpers';
import { useAxonAccountContext } from '../../hooks/use_axon_account_context';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useSiteContext } from '../../hooks/use_site_context';
export const VisitDetailsPage = () => {
    const [isActivityListOpen, setIsActivityListModalOpen, setIsActivityListModalClosed] = useBooleanState(false);
    const [isActivityModalOpen, setIsActivityModalOpen, setIsActivityModalClosed] = useBooleanState(false);
    const [visitActivity, setVisitActivity] = useState({});
    const match = useMatch('/site/study/:studyId/participants/:participantId/visit/:visitId');
    const participantId = match?.params.participantId;
    const visitId = match?.params.visitId;
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const account = useAxonAccountContext();
    const translate = useLocalizeMessage();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useTabNavigation(['#details', '#responses']);
    const getVisitActivityResponses = async () => {
        if (!environment) {
            return [];
        }
        const response = await getApiInstance(environment).get(`v2/accounts/${account._id}/c_sites/${site._id}/c_task_responses`, {
            params: {
                sort: JSON.stringify({ _id: -1 }),
                where: JSON.stringify({
                    'c_public_user._id': participantId,
                    'c_visit._id': visitId,
                }),
                expand: ['c_step_responses.c_step', 'c_task'],
            },
        });
        return response?.data?.data || [];
    };
    //  Getting participant details here because of the participant number on breadcrumbs
    //  this re-uses the cached data if exists so the call isn't always made
    const getParticipantData = async () => {
        if (!environment) {
            return [];
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const { data: participantDetails, isLoading: isParticipantLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: !queryClient.getQueryData(['participantDetails', participantId]),
    });
    const getVisit = async () => {
        if (!environment) {
            return;
        }
        //  Visits list might have already been cached in the previous pages, so get the cached data and use that to get the particular visit
        //  Fallback to fetching the visits if the cached data doesn't exist
        const cachedVisits = participantDetails
            ? await queryClient.getQueryData([
                'participantVisits',
                participantId,
                getParticipantNumber(participantDetails) || '',
            ])
            : undefined;
        let visits = cachedVisits;
        if (!visits) {
            //  NB: There is no endpoint to fetch one visit, so fetching all visits and filtering by visitId
            const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_visit_schedule`);
            visits = response?.data.data || [];
        }
        return (visits || []).find((visit) => visit.c_visit === visitId);
    };
    const { data: visit, isLoading } = useQuery({
        queryKey: ['getVisitDetails', participantId, visitId],
        queryFn: getVisit,
        enabled: true,
    });
    const { data: visitActivityResponses, isLoading: isActivityResponsesLoading } = useQuery({
        queryKey: ['getVisitActivityResponses', account._id, site._id, visitId, participantId],
        queryFn: getVisitActivityResponses,
        enabled: true,
    });
    const invalidateCaches = () => {
        queryClient.invalidateQueries({
            queryKey: ['getVisitActivityResponses', account._id, site._id, visitId, participantId],
        });
    };
    if (isLoading || isParticipantLoading || !visit) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const headerActions = [];
    if (!isParticipantDeactivated(participantDetails)) {
        headerActions.push({
            name: translate('Start Visit'),
            onClick: () => setIsActivityListModalOpen(),
            variant: 'primary',
        });
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: [
                    { name: translate('Participants'), path: getRouteLink(`participants`, environment) },
                    {
                        name: getParticipantNumber(participantDetails),
                        path: getRouteLink(`participants/${participantId}#visits`, environment),
                    },
                    { name: visit.c_name ?? '', path: '' },
                ], options: headerActions, title: visit.c_name ?? '' }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Responses')], tabContent: [
                    _jsx(VisitDetails, { visit: visit }, 'visit-details'),
                    _jsx(VisitActivityResponses, { isLoading: isActivityResponsesLoading, visitActivityResponses: visitActivityResponses ?? [] }, 'visit-activity-responses'),
                ], defaultIndex: activeTab, onTabChange: setActiveTab }), _jsx(ModalWindow, { title: visit.c_name, subtitle: '', isOpen: isActivityListOpen, hideCloseIcon: true, closeWindow: () => { }, footerSecondaryActionButtons: [
                    {
                        disabled: false,
                        label: translate('Exit Visit'),
                        content: undefined,
                        onClick: () => setIsActivityListModalClosed(),
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(ActivitiesList, { visit: visit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, onActionComplete: invalidateCaches }) }), _jsx(ModalWindow, { title: visitActivity.activity?.c_group_task ? visitActivity.activity.c_group_task.c_assignment.c_name : '', subtitle: '', isOpen: isActivityModalOpen, hideCloseIcon: true, closeWindow: () => { }, width: 'full', children: _jsx(ActivityView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed }) })] }));
};
