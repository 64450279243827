export const hasCorrectParticipantIdPattern = (value, pattern) => {
    const patternArray = pattern.split('');
    const userInputArray = value.split('');
    const lettersRegex = /^[a-zA-Z]+$/;
    const numbersRegex = /^[0-9]+$/;
    const finalValues = [];
    for (let i = 0; i < patternArray.length; i++) {
        if (patternArray[i] === '@') {
            finalValues.push(lettersRegex.test(userInputArray[i]));
        }
        else if (patternArray[i] === '#') {
            finalValues.push(numbersRegex.test(parseInt(userInputArray[i]).toString()));
        }
    }
    return finalValues.indexOf(false) === -1;
};
