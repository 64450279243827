import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useMemo } from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { GenericUtils } from '../../utils';
const SelectDataViewer = ({ cell, getOptions, row }) => {
    const rowCells = useSpreadsheetSelector((state) => state.data[row]);
    const options = useMemo(() => getOptions(rowCells), [rowCells, getOptions]);
    const option = useMemo(() => (cell?.value ? options.find((option) => GenericUtils.isSameString(option.value, cell.value)) : undefined), [options, cell?.value]);
    return (_jsx("span", { className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth), children: option?.label }));
};
export default SelectDataViewer;
