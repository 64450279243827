import { jsx as _jsx } from "react/jsx-runtime";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useState } from 'react';
import { Dropdown, Icon } from 'libs.nucleus';
import { FormatterWrapper } from './formatter_wrapper';
const commands = {
    bullet: INSERT_UNORDERED_LIST_COMMAND,
    number: INSERT_ORDERED_LIST_COMMAND,
    normal: REMOVE_LIST_COMMAND,
};
export const FormatList = () => {
    const [editor] = useLexicalComposerContext();
    const [formatList, setFormatList] = useState('normal');
    const onFormatList = useCallback((listType) => {
        editor.dispatchCommand(listType !== formatList ? commands[listType] : commands.normal, undefined);
        setFormatList(listType !== formatList ? listType : 'normal');
    }, [editor, formatList]);
    return (_jsx(FormatterWrapper, { children: _jsx(Dropdown, { variant: 'icon', iconName: 'formatList', iconSize: 20, label: 'Format list', items: [
                {
                    id: 'bullet',
                    content: _jsx(Icon, { name: 'unorderedList', color: 'black' }),
                    onClick: () => onFormatList('bullet'),
                },
                {
                    id: 'number',
                    content: _jsx(Icon, { name: 'orderedList', color: 'black' }),
                    onClick: () => onFormatList('number'),
                },
            ], "aria-label": `Format list` }) }));
};
