export var StudyAssetType;
(function (StudyAssetType) {
    StudyAssetType["PARTICIPANT_ACTIVITIES"] = "Participant activities";
    StudyAssetType["SITE_ACTIVITIES"] = "Site activities";
    StudyAssetType["SITE_RESOURCES"] = "Site resources";
    StudyAssetType["PARTICIPANT_RESOURCES"] = "Participant resources";
    StudyAssetType["SIGN_IN"] = "Sign in";
    StudyAssetType["SITE_PARTICIPANT_RESOURCES"] = "Site and Participant resources";
    StudyAssetType["SITE_VISIT_SCHEDULE"] = "Site visit schedule";
    StudyAssetType["PARTICIPANT_STATUS"] = "Participant statuses";
    StudyAssetType["PROTOCOL_SCHEDULE"] = "Site visit names";
    StudyAssetType["ACTIVITY_NAMES"] = "Activity names";
    StudyAssetType["NOTIFICATIONS"] = "Notifications";
})(StudyAssetType || (StudyAssetType = {}));
