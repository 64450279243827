import { QueryClientContext, useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const useEnvironmentSiteMutation = (environmentId, environmentSiteId) => {
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const queryClient = useContext(QueryClientContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const { isPending, isError, error, mutateAsync } = useMutation({
        mutationFn: (data) => {
            if (environmentSiteId) {
                return momClient.put(`/v1/entities/${entityId}/studies/${studyId}/environments/${environmentId}/sites/${environmentSiteId}`, data);
            }
            return momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${environmentId}/sites`, data);
        },
        onSuccess: async () => {
            if (environmentSiteId) {
                await queryClient?.invalidateQueries({
                    queryKey: [
                        'entities',
                        entityId.toString(),
                        'studies',
                        studyId.toString(),
                        'environments',
                        environmentId.toString(),
                        'sites',
                        environmentSiteId.toString(),
                    ],
                });
            }
            else {
                await queryClient?.invalidateQueries({
                    queryKey: ['entities', entityId.toString(), 'studies', studyId.toString(), 'sites'],
                });
            }
        },
    });
    return {
        isPending,
        isError,
        error,
        mutateAsync,
    };
};
