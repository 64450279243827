import { AssetTypes } from 'libs.localization.common';
import { getParticipantStatusesAsset, getProtocolScheduleAsset, getSiteVisitScheduleAsset, getSupportInformationAsset, getActivityNamesAsset, getResourceTitlesAsset, getSignInStudyNameAsset, } from './assets';
import { StudyAssetType } from '../types';
/**
 * Defines the asset conditions and their display names for different study assets.
 */
export const assetConditions = {
    [AssetTypes.ACTIVITY_NAMES]: {
        condition: (study) => study
            .getAllActivities()
            .filter((activity) => !('isBuiltIn' in activity.details && activity.details.isBuiltIn) && !activity.details.isVisitConfirmation).length > 0,
        displayName: 'Activity display name',
        type: StudyAssetType.ACTIVITY_NAMES,
        getAsset: getActivityNamesAsset,
    },
    [AssetTypes.SIGN_IN_STUDY_NAME_PII]: {
        condition: (study) => !!study.name && study.isNoPIIEnabled,
        displayName: 'Sign in study name',
        type: StudyAssetType.SIGN_IN,
        getAsset: getSignInStudyNameAsset,
    },
    [AssetTypes.SIGN_IN_STUDY_NAME]: {
        condition: (study) => !!study.name && !study.isNoPIIEnabled,
        displayName: 'Sign in study name',
        type: StudyAssetType.SIGN_IN,
        getAsset: getSignInStudyNameAsset,
    },
    [AssetTypes.PROTOCOL_SCHEDULE]: {
        condition: (study) => Object.values(study.getAllVisits()).length > 0,
        displayName: 'Protocol schedule',
        type: StudyAssetType.PROTOCOL_SCHEDULE,
        getAsset: getProtocolScheduleAsset,
    },
    [AssetTypes.PARTICIPANT_STATUSES]: {
        condition: (study) => Object.keys(study.participantStatuses).length > 0,
        displayName: 'Participant statuses',
        type: StudyAssetType.PARTICIPANT_STATUS,
        getAsset: getParticipantStatusesAsset,
    },
    [AssetTypes.SITE_VISIT_SCHEDULE]: {
        condition: (study) => Object.keys(study.timelineInfo).filter((t) => !study.timelineInfo[t].hidden).length > 0,
        displayName: 'Visit schedule',
        type: StudyAssetType.SITE_VISIT_SCHEDULE,
        getAsset: getSiteVisitScheduleAsset,
    },
    [AssetTypes.SIGN_IN_SUPPORT_INFORMATION]: {
        condition: (study) => Object.keys(study.selfServiceInformation).length > 0,
        displayName: 'Sign in support information',
        type: StudyAssetType.SIGN_IN,
        getAsset: getSupportInformationAsset,
    },
    [AssetTypes.RESOURCE_TITLES]: {
        condition: (study) => Object.keys(study.studyResources).length > 0,
        displayName: 'Resource titles',
        type: StudyAssetType.SITE_PARTICIPANT_RESOURCES,
        getAsset: getResourceTitlesAsset,
    },
};
