import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Locale } from 'libs.i18n-core';
import { LocalizationStatus } from 'libs.localization.common';
import { useLocalization, useLocalizationExport } from 'libs.localization.react';
import { Spinner } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { useAssetContext } from '../../hooks/use_asset_context.hook';
import { TableExport } from '../export_table';
import { SingleExportTranslationModal } from '../single_export_modal';
export const SectionExport = ({ asset, trackingContext = {}, status }) => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const { localizationEntry, localization } = useLocalization(asset);
    const { exportLocalization, isFetching: isExporting } = useLocalizationExport();
    let lastExportedAt = localizationEntry?.supported_locales?.[Locale.en_US]?.last_exported_at;
    if (lastExportedAt) {
        lastExportedAt = lastExportedAt.toDate();
    }
    const locale = localizationEntry?.base_locale ?? Locale.en_US;
    const version = localizationEntry?.supported_locales?.[locale]?.version;
    const { logEvent } = useContext(FirebaseAppContext);
    const { getReviewUrl } = useAssetContext();
    const handleExport = useCallback((resources) => {
        closeModal();
        if (asset && localization) {
            exportLocalization({
                resources,
                locales: [Locale.en_US],
                assets: { [localization?.getId()]: [`${locale}~${version}`] },
            });
            logEvent('translations_base_language_exported', {
                id: asset.id,
                type: asset.type,
                ...trackingContext,
            });
        }
    }, [asset, trackingContext, locale, version]);
    if (!asset) {
        return null;
    }
    return (_jsxs("div", { className: 'relative', children: [isExporting && _jsx(Spinner, { wrapper: 'full' }), _jsx(TableExport, { asset: asset, onExportButtonClick: openModal, data: {
                    locale,
                    last_exported: lastExportedAt,
                    status: localizationEntry?.supported_locales?.[locale]?.status || '',
                    currentVersionStatus: {
                        status: localizationEntry?.supported_locales?.[locale]?.status ?? LocalizationStatus.NOT_AVAILABLE,
                        version: localizationEntry?.supported_locales?.[locale]?.version ?? 0,
                    },
                }, isExporting: isExporting, reviewPath: `${getReviewUrl()}/${Locale.en_US}?assetType=${asset?.type}`, status: status }), isModalOpen && _jsx(SingleExportTranslationModal, { onModalClose: closeModal, onHandleExport: handleExport })] }));
};
