import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, useLocalizeMessage, useDialog, Spinner } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { onSkipVisitConfirmation, onStartVisitConfirmation } from './helpers/api_visit_confirmation_helpers';
export const VisitConfirmation = ({ environment, participantId, siteId, visit, visitIsOutsideVisitWindowRange, onActionComplete, }) => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addNotification } = useToastNotification();
    const queryClient = useQueryClient();
    const visitId = visit.c_visit;
    const invalidateCache = () => {
        queryClient.invalidateQueries({
            queryKey: ['siteActivities', participantId, visitId, siteId],
        });
        queryClient.invalidateQueries({
            queryKey: ['participantActivities', participantId, visitId],
        });
    };
    const onHandleStartVisit = async () => {
        const onStartVisit = await dialog.open({
            title: translate('Are you sure you want to start this visit?'),
            body: (_jsxs(_Fragment, { children: [visitIsOutsideVisitWindowRange && (_jsx("p", { "data-testid": 'outside-visit-range', children: translate('When you click on Start, you are confirming that you will perform this visit outside the target visit window') })), _jsx("p", { "data-testid": 'activities-become-enabled', children: translate('Activities will become available and the visit will be marked as confirmed.') })] })),
            actionButtons: [{ label: translate('Start') }, { label: translate('Cancel') }],
            willCloseOnOutsideClickAndEscapeKeyPress: false,
        });
        if (onStartVisit) {
            setIsSubmitting(true);
            try {
                //  Replace with actual skip visit function
                await onSkipVisitConfirmation({ environment, participantId, visitId });
                invalidateCache();
                setIsSubmitting(false);
                addNotification({
                    title: '',
                    subtitle: translate('Visit started successfully'),
                    type: 'success',
                });
                onActionComplete?.('confirm');
            }
            catch (error) {
                setIsSubmitting(false);
                addNotification({
                    title: '',
                    subtitle: translate('An error occured when confirming the visit'),
                    type: 'error',
                });
            }
        }
    };
    const onHandleSkipVisit = async () => {
        const onSkipVisit = await dialog.open({
            title: visit?.c_name ?? '',
            body: translate('Are you sure you want to skip this visit'),
            actionButtons: [{ label: translate('Skip') }, { label: translate('Cancel') }],
            willCloseOnOutsideClickAndEscapeKeyPress: false,
        });
        if (onSkipVisit) {
            setIsSubmitting(true);
            try {
                await onStartVisitConfirmation({ environment, participantId, visitId });
                invalidateCache();
                addNotification({
                    title: '',
                    subtitle: translate('Visit skipped successfully'),
                    type: 'success',
                });
                onActionComplete?.('skip');
            }
            catch (error) {
                addNotification({
                    title: '',
                    subtitle: translate('An error occured while skipping the visit'),
                    type: 'error',
                });
            }
            finally {
                setIsSubmitting(false);
            }
        }
    };
    return (_jsxs("div", { className: 'flex py-4 justify-between', children: [isSubmitting && _jsx(Spinner, { wrapper: 'full' }), _jsx("div", { className: 'content-center', children: _jsx("p", { children: translate('Activities will be enabled once the visit has started') }) }), _jsxs("div", { className: 'flex gap-2', children: [_jsx(Button, { onClick: onHandleSkipVisit, label: translate('Skip visit'), variant: 'secondary' }), _jsx(Button, { onClick: onHandleStartVisit, label: translate('Start visit') })] })] }));
};
