import { extractClassicAppsBaseUrl } from '../../helpers/study.helpers';
export const buildDocumentUrl = (documentId, environment) => {
    if (!environment) {
        return '';
    }
    const appUrl = extractClassicAppsBaseUrl(environment.cortexURL, 'site');
    return `${appUrl}/documents/${documentId}?orgCode=${environment.code}`;
};
export const buildDocumentCreateUrl = (participantId, environment) => {
    if (!environment || !participantId) {
        return '';
    }
    const appUrl = extractClassicAppsBaseUrl(environment.cortexURL, 'site');
    return `${appUrl}/documents/create/participant/${participantId}?orgCode=${environment.code}`;
};
