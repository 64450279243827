import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner, useLocalizeMessage } from 'libs.nucleus';
import { RadioGroupSection } from 'libs.react';
import { useEnvironments } from '../../hooks';
// todo: make ui compatible with the design - see discussion https://medable.slack.com/archives/C056G8HLWBZ/p1730229534482529
export const SelectEnvironmentStep = ({ value, onChange }) => {
    const translate = useLocalizeMessage();
    const { isLoading, environmentOptions } = useEnvironments();
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return (_jsx("div", { className: 'flex justify-center w-full h-full', children: _jsx("div", { className: 'flex flex-col w-full max-w-2xl h-full justify-center gap-y-3', children: _jsx(RadioGroupSection, { label: translate('Select environment'), options: environmentOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                    dataTestId: `environment-${option.label}`,
                })), selectedValue: value ?? '', onSelectionChange: onChange }) }) }));
};
