import * as Matrix from './spreadsheet_matrix.utils';
import * as Point from './spreadsheet_point.utils';
export { createEmpty as createEmptyMatrix } from './spreadsheet_matrix.utils';
export const PLAIN_TEXT_MIME = 'text/plain';
export const FOCUS_WITHIN_SELECTOR = ':focus-within';
/** Move the cursor of given input element to the input's end */
export function moveCursorToEnd(el) {
    el.selectionStart = el.selectionEnd = el.value.length;
}
/**
 * Creates an array of numbers (positive and/or negative) progressing from start up to, but not including, end. A step of -1 is used if a negative start is specified without an end or step. If end is not specified, it's set to start with start then set to 0.
 * @param end - an integer number specifying at which position to stop (not included).
 * @param start - An integer number specifying at which position to start.
 * @param step - An integer number specifying the incrementation
 */
export function range(end, start = 0, step = 1) {
    const array = [];
    if (Math.sign(end - start) === -1) {
        for (let element = start; element > end; element -= step) {
            array.push(element);
        }
        return array;
    }
    for (let element = start; element < end; element += step) {
        array.push(element);
    }
    return array;
}
/** Return whether given point is active */
export function isActive(active, point) {
    return Boolean(active && Point.isEqual(point, active));
}
/** Get the offset values of given element */
export function getOffsetRect(element) {
    return {
        width: element.offsetWidth,
        height: element.offsetHeight,
        left: element.offsetLeft,
        top: element.offsetTop,
    };
}
/** Write given data to clipboard with given event */
export function writeTextToClipboard(data) {
    navigator.clipboard.writeText(data);
}
/** Read text from given clipboard event */
export function readTextFromClipboard() {
    return navigator.clipboard.readText();
}
/** Get the dimensions of cell at point from state */
export function getCellDimensions(point, rowDimensions, columnDimensions) {
    const cellRowDimensions = rowDimensions && rowDimensions[point.row];
    const cellColumnDimensions = columnDimensions && columnDimensions[point.column];
    return (cellRowDimensions &&
        cellColumnDimensions && {
        ...cellRowDimensions,
        ...cellColumnDimensions,
    });
}
/** Get the dimensions of a range of cells */
export function getRangeDimensions(rowDimensions, columnDimensions, range) {
    const startDimensions = getCellDimensions(range.start, rowDimensions, columnDimensions);
    const endDimensions = getCellDimensions(range.end, rowDimensions, columnDimensions);
    return (startDimensions &&
        endDimensions && {
        width: endDimensions.left + endDimensions.width - startDimensions.left,
        height: endDimensions.top + endDimensions.height - startDimensions.top,
        top: startDimensions.top,
        left: startDimensions.left,
    });
}
/** Get the dimensions of selected */
export function getSelectedDimensions(rowDimensions, columnDimensions, data, selected) {
    const range = selected.toRange(data);
    return range ? getRangeDimensions(rowDimensions, columnDimensions, range) : undefined;
}
/** Get given data as CSV */
export function getCSV(data) {
    const valueMatrix = Matrix.map((cell) => cell?.value || '', data);
    return Matrix.join(valueMatrix);
}
/**
 * Calculate the rows and columns counts of a spreadsheet
 * @param data - the spreadsheet's data
 * @param rowLabels - the spreadsheet's row labels (if defined)
 * @param columnLabels - the spreadsheet's column labels (if defined)
 * @returns the rows and columns counts of a spreadsheet
 */
export function calculateSpreadsheetSize(data, rowLabels, columnLabels) {
    const { columns, rows } = Matrix.getSize(data);
    return {
        rows: rowLabels ? Math.max(rows, rowLabels.length) : rows,
        columns: columnLabels ? Math.max(columns, columnLabels.length) : columns,
    };
}
/** Should spreadsheet handle clipboard event */
export function shouldHandleClipboardEvent(root, mode) {
    return root !== null && mode === 'view' && isFocusedWithin(root);
}
export function isFocusedWithin(element) {
    return element.matches(FOCUS_WITHIN_SELECTOR);
}
export function hasLineBreaker(value) {
    return typeof value === 'string' && value.includes('\n');
}
