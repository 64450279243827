export const DATA_MANAGEMENT_APP_FLAGS = {
    data_management: {
        id: 'data_management',
        value: false,
        description: 'Enables the Data app experience',
        owner: 'Clinical Data',
        name: 'General',
        extra: { section: 'Data', isFirst: true, dataTestId: 'data-management-app-flag' },
    },
};
