import { useLocalizeMessage } from 'libs.nucleus';
export const useTherapeuticAreaOptions = () => {
    const translate = useLocalizeMessage();
    return [
        { label: translate('Psoriasis'), value: 'Psoriasis' },
        { label: translate('Rheumatoid Arthritis'), value: 'Rheumatoid Arthritis' },
        { label: translate('Cardiovascular'), value: 'Cardiovascular' },
        { label: translate('Heart Failure'), value: 'Heart Failure' },
        { label: translate('QT Studies'), value: 'QT Studies' },
        {
            label: translate('Traditional Chinese Medicine - Coronary Artery Disease-Angina'),
            value: 'Traditional Chinese Medicine - Coronary Artery Disease-Angina',
        },
        { label: translate('Acute Kidney Injury'), value: 'Acute Kidney Injury' },
        { label: translate('Diabetes'), value: 'Diabetes' },
        { label: translate('Diabetes Type 1 - Exercise and Nutrition'), value: 'Diabetes Type 1 - Exercise and Nutrition' },
        { label: translate('Diabetes Type 1 - Pediatrics and Devices'), value: 'Diabetes Type 1 - Pediatrics and Devices' },
        {
            label: translate('Diabetes Type 1 - Screening, Staging and Monitoring of Pre-clinical'),
            value: 'Diabetes Type 1 - Screening, Staging and Monitoring of Pre-clinical',
        },
        { label: translate('Type 1 Diabetes'), value: 'Type 1 Diabetes' },
        { label: translate('Diabetic Kidney Disease'), value: 'Diabetic Kidney Disease' },
        { label: translate('Dyslipidemia'), value: 'Dyslipidemia' },
        { label: translate('Kidney Transplant'), value: 'Kidney Transplant' },
        { label: translate('Polycystic Kidney Disease'), value: 'Polycystic Kidney Disease' },
        { label: translate('CDAD'), value: 'CDAD' },
        { label: translate("Crohn's Disease"), value: "Crohn's Disease" },
        { label: translate('COVID-19'), value: 'COVID-19' },
        { label: translate('Ebola'), value: 'Ebola' },
        { label: translate('Hepatitis C'), value: 'Hepatitis C' },
        { label: translate('HIV'), value: 'HIV' },
        { label: translate('Influenza'), value: 'Influenza' },
        { label: translate('Malaria'), value: 'Malaria' },
        { label: translate('Tuberculosis'), value: 'Tuberculosis' },
        { label: translate('Virology'), value: 'Virology' },
        { label: translate('Major Depressive Disorder'), value: 'Major Depressive Disorder' },
        { label: translate('Post Traumatic Stress Disorder'), value: 'Post Traumatic Stress Disorder' },
        { label: translate('Schizophrenia'), value: 'Schizophrenia' },
        { label: translate("Alzheimer's"), value: "Alzheimer's" },
        { label: translate("Huntington's Disease"), value: "Huntington's Disease" },
        { label: translate('Multiple Sclerosis'), value: 'Multiple Sclerosis' },
        { label: translate("Parkinson's Disease"), value: "Parkinson's Disease" },
        { label: translate('Traumatic Brain Injury'), value: 'Traumatic Brain Injury' },
        { label: translate('Breast Cancer'), value: 'Breast Cancer' },
        { label: translate('Colorectal Cancer'), value: 'Colorectal Cancer' },
        { label: translate('Lung Cancer'), value: 'Lung Cancer' },
        { label: translate('Pancreatic Cancer'), value: 'Pancreatic Cancer' },
        { label: translate('Prostate Cancer'), value: 'Prostate Cancer' },
        { label: translate('Nutrition'), value: 'Nutrition' },
        { label: translate('Pediatrics'), value: 'Pediatrics' },
        {
            label: translate('Traditional Chinese Medicine - Acupuncture'),
            value: 'Traditional Chinese Medicine - Acupuncture',
        },
        { label: translate('Duchenne Muscular Dystrophy'), value: 'Duchenne Muscular Dystrophy' },
        { label: translate('Rare Diseases'), value: 'Rare Diseases' },
        { label: translate('Asthma'), value: 'Asthma' },
        { label: translate('COPD'), value: 'COPD' },
        { label: translate('Pain'), value: 'Pain' },
        { label: translate('Vaccines'), value: 'Vaccines' },
    ];
};
