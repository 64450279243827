import { useContext, useCallback } from 'react';
import { AssetRole } from 'libs.localization.common';
import { TranslationWorkbenchContext } from '../contexts';
export const useLocaleAccess = (currentRole) => {
    const { participantLocales, siteLocales } = useContext(TranslationWorkbenchContext);
    const checkIsValidLocale = useCallback((locale) => {
        if (currentRole === AssetRole.PARTICIPANT) {
            return participantLocales.includes(locale);
        }
        if (currentRole === AssetRole.SITE) {
            return siteLocales.includes(locale);
        }
        return true;
    }, [currentRole, participantLocales, siteLocales]);
    return { checkIsValidLocale };
};
