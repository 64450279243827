const cleanSelectedConversation = (conversation) => {
    let updatedConversation = conversation;
    // check for system prompt on each conversation
    if (!updatedConversation.prompt) {
        updatedConversation = {
            ...updatedConversation,
            prompt: updatedConversation.prompt || '',
        };
    }
    if (!updatedConversation.messages) {
        updatedConversation = {
            ...updatedConversation,
            messages: updatedConversation.messages || [],
        };
    }
    return updatedConversation;
};
const cleanConversationHistory = (history) => {
    if (!Array.isArray(history)) {
        console.warn('history is not an array. Returning an empty array.');
        return [];
    }
    return history.reduce((acc, conversation) => {
        try {
            if (!conversation.prompt) {
                conversation.prompt = '';
            }
            if (!conversation.messages) {
                conversation.messages = [];
            }
            acc.push(conversation);
            return acc;
        }
        catch (error) {
            console.warn(`error while cleaning conversations' history. Removing culprit`, error);
        }
        return acc;
    }, []);
};
const updateConversations = (updatedConversation, allConversations) => {
    return allConversations.map((c) => {
        return c.key === updatedConversation.key ? updatedConversation : c;
    });
};
const saveConversation = (selectedConversation) => {
    localStorage.setItem('selectedConversation', JSON.stringify(selectedConversation));
};
const saveConversations = (conversations) => {
    localStorage.setItem('conversationHistory', JSON.stringify(conversations));
};
export const ConversationsUtils = {
    cleanConversationHistory,
    cleanSelectedConversation,
    saveConversation,
    saveConversations,
    updateConversations,
};
